const defaultState = {
  isLoggedIn: false,
  token: null,
  remember_me: false,
  profile: null,
};
export default (state = defaultState, action) => {
  switch (action.type) {
    case "POST_SIGNUP":
      return {
        ...state,
        isLoggedIn: false,
        remember_me: false
      };
    case "POST_LOGIN":
      return {
        ...state,
        isLoggedIn: true,
        token: action.data.token,
        profile: action.data.profile
      };
    case "UPDATE_PROFILE":
      return {
        ...state,
        profile: action.data
      };
    case "REFRESH_TOKEN":
      return {
        ...state,
        token: action.data
      };
    case "POST_LOGOUT":
      return {
        ...state,
        isLoggedIn: false,
        token: null,
        remember_me: false
      };
    case "REMEMBER_ME":
      return {
        ...state,
        remember_me: action.data
      };
    default:
      return state;
  }
};
