import withStyles from '@material-ui/core/styles/withStyles';
import { default as React } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { api_url, random_string } from 'redux/config.js'
import Toast from 'views/Toast/Toast';
import SurveyMainComponent from "./components/SurveyMainComponent";
import jwt from "jsonwebtoken";
import pagesStyle from "layouts/PublicLayout.style";
import moment from 'moment';
import ISO6391 from "iso-639-1";
import StarRating from "components/StarRating/StarRating"
import * as Score from "utils/Score";
import commonStyle from "assets/jss/common/common";
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import SnackbarContent from 'components/Snackbar/SnackbarContent';
const styles = (theme) => ({
    ...pagesStyle(theme),
    section: {
        backgroundColor: commonStyle.whitebackgroundColor,
        color: commonStyle.blackColor,
        boxShadow: "0 0 0 1px rgba(0,0,0,.15), 0 12px 18px 1px rgba(0,0,0,.2)",
        padding: 5,
        margin: '5px 0'
    },
    question: {
        marginLeft: 20,
        padding: 5,
        boxShadow: "0 0 0 1px rgba(0,0,0,.15), 0 12px 18px 1px rgba(0,0,0,.2)",
        marginBottom: 10
    },
    sectionTitle: {
        padding: 5
    },
    questionTitle: {
        padding: 5
    },
    questionBody: {
        marginLeft: 20
    }
});
class SurveyAnswerPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            survey_id: this.props.match.params.survey_id,
            response_id: this.props.match.params.response_id,
            survey: null
        }
    }

    componentDidMount() {
        const { survey_id, response_id } = this.state

        this.getSurvey(survey_id)

        document.body.style.overflow = "unset";
    }

    getSurvey = (id) => {
        let self = this;

        const url = api_url + `survey/get`;
        const header = 'Bearer '.concat(this.props.user.token);
        let headers = null;
        if (this.props.user.token) {
            headers = {
                Authorization: header
            };
        }
        this.props.dispatch({ type: 'LOAD_START' });
        axios({
            url,
            method: 'get',
            headers,
            params: {
                id
            }
        })
            .then(function (response) {
                if (response.data.success) {
                    self.setState({
                        survey: response.data.data
                    });
                    console.info(response.data.data.responses.filter(r=>r._id == self.state.response_id)[0])
                    // below is only for test
                    // const invalidAnswer = response.data.data.responses.filter(r=>r._id == self.state.responseID)[0]
                    // const score =  Score.getScoreOfOneResponse(response.data.data.questions[0], invalidAnswer.answers)
                    // debugger
                } else {
                    Toast(400, response.data.error)
                }
                self.props.dispatch({ type: 'LOAD_END' });
            })
            .catch(function (errors) {
                console.info(errors);
                self.props.dispatch({ type: 'LOAD_END' });
                if (!errors.response) {
                    Toast(500);
                    return;
                }
                Toast(errors.response.status);
            });
    };

    getAnswerLabel = (qitem, answer) => {

        let lablesOfOptions = Score.getLabelOfAnswerValue(qitem, answer.value)
        if (qitem.question.type === 'checkbox' && answer.other_value) {
            lablesOfOptions = `${lablesOfOptions}${answer.other_value}`
        }
        return lablesOfOptions
    }
    render() {
        const { classes } = this.props
        const { survey, response_id } = this.state
        if (!survey) return null
        const response = survey.responses.filter(item => item._id === response_id)[0]
        const questions = survey.questions.filter(item => item.language === response.language)[0]
        const main = survey.main.filter(item => item.language === response.language)[0]
        const mainData = {
            image_url: survey.image_url,
            ...main
        }


        return <div >
            <div className={classes.wrapper} ref="wrapper">
                <div className={classes.fullPage}>
                    <SurveyMainComponent data={mainData} />
                    <SnackbarContent
                        color="info"
                        message={<div><b>{response.email}</b></div>}
                    />
                    <div>

                        {
                            questions.sections.map((item, skey) => {
                                return <div className={classes.section} key={skey}>
                                    <div className={classes.sectionTitle}><b>{item.sectionTitle}</b></div>
                                    <div className={classes.sectionBody}>
                                        {
                                            item.questions.map((qitem, qkey) => {
                                                const answer = response.answers.filter(item => item.id === qitem.id)[0]
                                                if (!answer) return null
                                                return <div key={qkey} className={classes.question}>
                                                    <div className={classes.questionTitle}><b>{qitem.questionTitle}</b></div>

                                                    <div className={classes.questionBody}>
                                                        {
                                                            this.getAnswerLabel(qitem, answer)
                                                        }
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
        </div>;
    }
}
const mapStatsToProps = ({ user }) => ({ user });
export default connect(mapStatsToProps)(
    withTranslation()(withStyles(styles, { withTheme: true })(SurveyAnswerPage))
);
