import CampaignSurveyPage from "views/Survey/CampaignSurveyPage"
import SingleSurveyPage from "views/Survey/SingleSurveyPage"
import SingleSurveyEmojiPage from "views/Survey/SingleSurveyEmojiPage"
import SurveyAnswerPage from "views/Survey/SurveyAnswerPage"
import ExtendedForms from "views/Other/Forms/ExtendedForms";
var indexRoutes = [
  { path: "/public/campaign_survey/:token", name: "Home", component: CampaignSurveyPage },
  { path: "/public/single_survey/:survey_id/:survey_type", name: "Home", component: SingleSurveyPage },
  { path: "/public/single_survey_emoji/:survey_id/:survey_type", name: "Home", component: SingleSurveyEmojiPage },
  { path: "/public/survey_answer/:survey_id/:response_id", name: "Home", component: SurveyAnswerPage },
  { path: "/public/ExtendedForms", name: "Home", component: ExtendedForms }
];

export default indexRoutes;
