import withStyles from '@material-ui/core/styles/withStyles';
import commonStyle from "assets/jss/common/common";
import axios from 'axios';
import Badge from "components/Badge/Badge";
import Button from 'components/CustomButtons/Button';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import CustomSelect from "components/CustomSelect/CustomSelect";
import Muted from "components/Typography/Muted";
import { t } from 'i18next';
import { default as React } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { api_url } from 'redux/config.js';
import { getAllLanguages } from "utils/Languages";
import Toast from "views/Toast/Toast";
const styles = {
    ...commonStyle,
    mainData: {
        backgroundColor: commonStyle.whitebackgroundColor,
        color: commonStyle.blackColor,
        boxShadow: "0 0 0 1px rgba(0,0,0,.15), 0 12px 18px 1px rgba(0,0,0,.2)",
        padding: 5,
        margin: '5px 0'
    },
    section: {
        backgroundColor: commonStyle.whitebackgroundColor,
        color: commonStyle.blackColor,
        boxShadow: "0 0 0 1px rgba(0,0,0,.15), 0 12px 18px 1px rgba(0,0,0,.2)",
        padding: 5,
        margin: '5px 0'
    },
    question: {
        marginLeft: 20,
        padding: 5,
        boxShadow: "0 0 0 1px rgba(0,0,0,.15), 0 12px 18px 1px rgba(0,0,0,.2)",
        marginBottom: 10
    },
    sectionTitle: {
        padding: 5
    },
    questionTitle: {
        padding: 5
    },
    questionBody: {
        marginLeft: 20
    }
};

class Component extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedLanguage: 'en',
            languages: getAllLanguages(),
            mainData: [],
            surveyQuestions: [],
            id: this.props.match.params.survey_id
        }
        this.onLoad()
    }

    onLoad = () => {
        let self = this;
        const id = this.props.match.params.survey_id;
        const url = api_url + `survey/get`;

        this.props.dispatch({ type: "LOAD_START" });
        axios({
            url,
            method: "get",
            params: {
                id: id
            },
            headers: {
                authorization: "Bearer ".concat(this.props.user.token)
            }
        })
            .then(function (response) {
                if (!response.data.success) {
                    Toast(400, response.data.error);
                } else {
                    const data = response.data.data
                    self.setState({
                        mainData: data.main,
                        surveyQuestions: data.questions,
                        selectedLanguage: data.main[0].language,
                    });
                }
                self.props.dispatch({ type: "LOAD_END" });
            })
            .catch(function (errors) {
                console.info(errors);
                self.props.history.replace('/')
                self.props.dispatch({ type: "LOAD_END" });
                Toast(500);
            });
    };

    changeLanguage = (language) => {
        const { surveyQuestions } = this.state
        let newSurveyQuestions = [].concat(surveyQuestions)
        let questionOfCurrentLanguage = surveyQuestions.filter((item) => item.language === language)[0]
        if (!questionOfCurrentLanguage) {
            questionOfCurrentLanguage = surveyQuestions[0]
            const newQustionOfCurrentLanguage = {
                ...questionOfCurrentLanguage,
                language: language
            }
            newSurveyQuestions.push(newQustionOfCurrentLanguage)
        }

        const { mainData } = this.state
        let newMainData = [].concat(mainData)

        let mainDataOfCurrentLanguage = mainData.filter((item) => item.language === language)[0]
        if (!mainDataOfCurrentLanguage) {
            mainDataOfCurrentLanguage = mainData[0]
            const newMainDataOfCurrentLanguage = {
                ...mainDataOfCurrentLanguage,
                language: language
            }
            newMainData.push(newMainDataOfCurrentLanguage)
        }
        this.setState({ selectedLanguage: language, mainData: newMainData, surveyQuestions: newSurveyQuestions })

    }

    updateSurvey = async (updateData) => {
        this.props.dispatch({ type: "LOAD_START" });

        const result = await axios({
            url: api_url + "survey/update",
            method: "post",
            headers: {
                authorization: "Bearer ".concat(this.props.user.token)
            },
            data: {
                id: this.state.id,
                updateData: updateData
            }
        });
        this.props.dispatch({ type: "LOAD_END" });
        if (result.data.success) {
            Toast(200, t('Updated successfully'));
        } else {
            Toast(400, result.data.error);
        }
    };
    languageInput = (props) => {
        const { selectedLanguage } = this.state
        const { classes } = this.props
        return <div>
            <Badge color="info">{selectedLanguage}</Badge> <CustomInput
                id="Title"
                formControlProps={{
                    onChange: e => props.onChange(e.target.value),
                    className: classes.noPadding
                }}
                inputProps={{
                    type: "text",
                    value: props.value
                }}
            /></div>

    }
    updateMainData = (name, value) => {
        const newMainData = this.state.mainData.map((item) => {
            if (item.language === this.state.selectedLanguage) {
                item[name] = value
            }
            return item
        })
        this.setState({
            mainData: newMainData
        })
    }
    updateQuestions = (newQuestion) => {

        const newSurveyQuestions = this.state.surveyQuestions.map((item) => {
            if (item.language === this.state.selectedLanguage) {
                return newQuestion
            } else {
                return item
            }
        })
        this.setState({
            surveyQuestions: newSurveyQuestions
        })
    }
    render() {
        if (!this.state.mainData || this.state.mainData.length === 0) return null;
        const { classes } = this.props;
        const { id, languages, mainData, surveyQuestions, selectedLanguage } = this.state
        if (!surveyQuestions) {
            this.props.history.replace('/')
            return null
        }

        let mainDataOfCurrentLanguage = mainData.filter((item) => item.language === selectedLanguage)[0]
        let questionOfCurrentLanguage = JSON.parse(JSON.stringify(surveyQuestions.filter((item) => item.language === selectedLanguage)[0]))
        return <div >
            <p>Choose Language</p>
            <CustomSelect
                onChange={country => {
                    this.changeLanguage(country.value)

                }}
                placeholder={t('Language')}
                value={languages.filter((item) => item.value === selectedLanguage)[0]}
                options={languages}
            />
            <div>
                <div className={classes.mainData}>
                    <div>{t('Survey Title')}</div>
                    <p><b>{mainData[0].title}</b></p>
                    {this.languageInput({ value: mainDataOfCurrentLanguage.title, onChange: (t) => { this.updateMainData('title', t) } })}
                    <div>{t('Survey Descrition')}</div>
                    <p><b>{mainData[0].description}</b></p>
                    {this.languageInput({ value: mainDataOfCurrentLanguage.description, onChange: (t) => { this.updateMainData('description', t) } })}

                </div>
            </div>
            <div>
                {
                    surveyQuestions[0].sections.map((item, skey) => {
                        return <div className={classes.section} key={skey}>
                            <div className={classes.sectionTitle}><b>{item.sectionTitle}</b></div>
                            {this.languageInput({
                                value: questionOfCurrentLanguage.sections[skey].sectionTitle, onChange: (t) => {
                                    questionOfCurrentLanguage.sections[skey].sectionTitle = t
                                    this.updateQuestions(questionOfCurrentLanguage)
                                }
                            })}
                            <div className={classes.sectionBody}>
                                {
                                    item.questions.map((qitem, qkey) => {

                                        return <div key={qkey} className={classes.question}>
                                            <div className={classes.questionTitle}><b>{qitem.questionTitle}</b></div>
                                            {this.languageInput({
                                                value: questionOfCurrentLanguage.sections[skey].questions[qkey].questionTitle, onChange: (t) => {
                                                    questionOfCurrentLanguage.sections[skey].questions[qkey].questionTitle = t
                                                    this.updateQuestions(questionOfCurrentLanguage)
                                                }
                                            })}
                                            <div className={classes.questionBody}>
                                                {
                                                    qitem.question.options && qitem.question.options.map((qoption, qokey) => {
                                                        return <div key={qokey}>
                                                            <div className={classes.questionOption}><b>{qoption.label}</b></div>
                                                            {this.languageInput({
                                                                value: questionOfCurrentLanguage.sections[skey].questions[qkey].question.options[qokey].label, onChange: (t) => {
                                                                    questionOfCurrentLanguage.sections[skey].questions[qkey].question.options[qokey].label = t
                                                                    this.updateQuestions(questionOfCurrentLanguage)
                                                                }
                                                            })}
                                                        </div>
                                                    })
                                                }
                                                {
                                                    !questionOfCurrentLanguage.sections[skey].questions[qkey].question.options && <Muted>
                                                        {t('No translatable option')}
                                                    </Muted>
                                                }
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    })
                }
            </div>
            <Button fullWidth color="info" onClick={() => this.updateSurvey({ main: mainData, questions: surveyQuestions })}>
                {t('Save')}
            </Button>

        </div>;
    }
}
const mapStatsToProps = ({ user }) => ({ user });
export default connect(mapStatsToProps)(
    withTranslation()(withStyles(styles, { withTheme: true })(Component))
);