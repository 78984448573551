
import Email from "@material-ui/icons/Email";
import Layers from "@material-ui/icons/Layers";
import Send from "@material-ui/icons/Send";
import Help from "@material-ui/icons/Help";
import SettingsApplications from "@material-ui/icons/SettingsApplications";
import ViewList from "@material-ui/icons/ViewList";
import CampaignDetailPage from "views/Campaign/CampaignDetailPage";
import CampaignPage from "views/Campaign/CampaignPage";
import CreateMailList from "views/EmailList/CreateMailList";
import EditEmailList from "views/EmailList/EditEmailList";
import EmailListPage from "views/EmailList/EmailListPage";
import EmailSettingPage from "views/EmailSetting/EmailSettingPage";
import SendSurveyPage from "views/SendSurvey/SendSurveyPage";
import SurveyCreatePage from "views/Survey/SurveyCreatePage";
import SurveyEditPage from "views/Survey/EditSurvey/SurveyEditPage";
import SurveyPage from "views/Survey/SurveyPage";
import SurveyRespondentsPage from "views/Survey/SurveyRespondentsPage";
import SurveyPasswordsPage from "views/Survey/SurveyPasswordsPage";
import ViewReportPage from "views/Survey/ViewReportPage";
import HelpSyncAppPage from "views/Help/HelpSyncAppPage";
import CreateSurveyLinkPage from "views/CreateSurveyLink/CreateSurveyLinkPage";
import SurveyQuestionEdit from "views/Survey/EditQuestion/edit"
import InsertLink from "@material-ui/icons/InsertLink";
import SurveyTranslate from "views/Survey/SurveyTranslate";
import DashboardPage from "views/Dashboard/DashboardPage";
import { AccountCircle, DashboardTwoTone } from "@material-ui/icons";
import ProfilePage from "views/Dashboard/ProfilePage";
import PaymentDetailPage from "views/Dashboard/PaymentDetailPage";
import CostomerAllotmentSurveyPage from "views/Survey/CostomerAllotmentSurveyPage";
import SurveyRateMessages from "views/Survey/SurveyRateMessages";


var costumer_routes = [

  {
    path: "/dashboard",
    name: "Dashboard",
    icon: DashboardTwoTone,
    component: DashboardPage,
  },

  {
    path: "/survey/survey",
    name: "Survey",
    icon: ViewList,
    component: SurveyPage,
  },
  {
    path: "/survey/costumer_allotment_survey",
    name: "Allotment Survey",
    icon: ViewList,
    component: CostomerAllotmentSurveyPage,
  },

  {
    path: "/email_list/email_list",
    name: "Email list",
    icon: Email,
    component: EmailListPage,
  },

  {
    path: "/campaign/campaign",
    name: "Campaign",
    icon: Layers,
    component: CampaignPage,
  },

  {
    path: "/sendsurvey/sendsurvey/:campaign_id",
    name: "Send survey",
    icon: Send,
    component: SendSurveyPage,
    hidden: true
  },
  {
    path: "/sendsurvey/sendsurvey/0",
    name: "Send survey",
    icon: Send,
    component: SendSurveyPage,
  },
  {
    path: "/sendsurvey/create_link",
    name: "Create survey link",
    icon: InsertLink,
    component: CreateSurveyLinkPage,
  },
  
  {
    path: "/costumer/survey_rate_messages",
    name: "Survey Rate Message",
    component: SurveyRateMessages,
    icon: Email,
  },
  {
    path: "/emailsetting/index",
    name: "Email setting",
    icon: SettingsApplications,
    component: EmailSettingPage,
  },

  {
    collapse: true,
    path: "/help/",
    name: "Help",
    state: "help",
    icon: Help,
    views: [
      {
        path: "/help/sync_app",
        name: "Sync Email List",
        mini: "SE",
        component: HelpSyncAppPage
      },
    ]
  },
  { redirect: true, path: "/", pathTo: "/dashboard", name: "Dashboard" }
];
export default costumer_routes;
