
import FormControl from "@material-ui/core/FormControl";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Send from "@material-ui/icons/Send";
import ViewList from "@material-ui/icons/ViewList";
import { Editor } from "@tinymce/tinymce-react";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import axios from 'axios';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ISO6391 from "iso-639-1";
import moment from "moment";
import React from "react";
import Datetime from "react-datetime";
import { connect } from 'react-redux';
import CustomSelect from "components/CustomSelect/CustomSelect"
import "react-table/react-table.css";
import { api_url, tinyeditor_api_key } from 'redux/config.js';
import { verifyEmail } from "utils/Validate";
import Toast from 'views/Toast/Toast';
import Muted from "components/Typography/Muted"
import { withTranslation } from 'react-i18next';
import Snackbar from 'components/Snackbar/Snackbar';
import SnackbarContent from 'components/Snackbar/SnackbarContent';
import { CallApi } from 'utils/Request';
import TagsInput from 'react-tagsinput'

const styles = {
  ...regularFormsStyle,
  descriptionTextArea: {
    width: "100%",
    height: 150
  },
  emailTags: {
    width: "100%",
    borderBottom: "1px solid #D2D2D2 !important"
  }
};
class SendSurveyPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      surveys: [],
      emails: [],
      customMsgs: [],
      selectedSurvey: null,
      campaign_id: this.props.match.params.campaign_id,
      campaign_data: null,
      outputData: {
        start_date: moment(new Date()).format('X'),
        additional_emails: [],
        send_type: 'now', // 'daily', 'later',
      }
    };
  }

  componentDidMount() {
    this.onLoad();
  }
  loadCampaignData = () => {
    if (this.state.campaign_id.length < 5) {
      return
    }
    let self = this;
    const params = {
      id: this.state.campaign_id
    }
    this.props.dispatch({ type: 'LOAD_START' });
    CallApi({
      url: 'campaign/get',
      method: 'get',
      params
    }).then((res) => {
      if (res.success) {
        console.info('=== campaign ===', JSON.stringify(res.data, null, 4))
        const res_data = res.data
        self.setState({
          campaign_data: res_data,
          selectedSurvey: res_data.survey,
          outputData: {
            campaign_name: res_data.campaign_name,
            survey: res_data.survey._id,
            email_list: res_data.email_list._id,
            language: res_data.language,
            send_type: res_data.send_type,
            start_date: res_data.start_date,
            message: res_data.message,
            personalized_link: res_data.personalized_link,
            additional_emails: res_data.additional_emails || [],
            email_sender: res_data.email_sender,
            email_subject: res_data.email_subject
          }
        });
      } else {
        Toast(400, res.error);
      }
    }).finally(() => {
      self.props.dispatch({ type: 'LOAD_END' });
    })
  }
  normalize = (response) => {
    const surveys = response[0].data.data.map((item) => ({ _id: item._id, main: item.main }));
    const selectedSurvey = surveys[0] ? surveys[0] : null
    const emails = response[1].data.data.map((item) => ({
      label: item.name,
      value: item._id
    }))
    const customMsgs = response[2].data.data.map((item) => ({
      label: item.name,
      value: item.message
    }))
    this.setState({
      surveys, emails, customMsgs, selectedSurvey,
      outputData: {
        ...this.state.outputData,
        language: surveys[0].main[0].language,
        survey: selectedSurvey._id
      }
    })
    setTimeout(() => {
      this.loadCampaignData()
    }, 0);
  }
  onLoad = () => {
    let self = this;
    const header = 'Bearer '.concat(this.props.user.token);
    let headers = null;
    if (this.props.user.token) {
      headers = {
        Authorization: header
      };
    }
    this.props.dispatch({ type: 'LOAD_START' });
    const ps_survey = axios({ url: api_url + 'survey/all_active', method: 'get', headers })
    const ps_emaillist = axios({ url: api_url + 'emaillist/all', method: 'get', headers })
    const ps_custommessage = axios({ url: api_url + 'custommessage/all', method: 'get', headers })
    Promise.all([ps_survey, ps_emaillist, ps_custommessage])
      .then(function (response) {
        self.normalize(response)
        self.props.dispatch({ type: 'LOAD_END' });
      })
      .catch(function (errors) {
        console.info(errors);
        self.props.dispatch({ type: 'LOAD_END' });
        if (!errors.response) {
          Toast(500);
          return;
        }
        Toast(errors.response.status);
      });
  };
  isEmail = (inputText) => {
    return verifyEmail(inputText)
  }
  setFormData = (name, value) => {
    let outputData = { ...this.state.outputData };
    outputData[name] = value;
    this.setState({ outputData })
  }
  validateForm = () => {
    const { t } = this.props
    const data = this.state.outputData;

    if (!data.survey || data.survey.length === 0) {
      Toast(400, t('Please enter survey')); return false;
    }
    if (!data.campaign_name || data.campaign_name.length === 0) {
      Toast(400, t('Please enter campaign name')); return false;
    }
    if (!data.email_list && data.additional_emails.length === 0) {
      Toast(400, t('Please enter email list or emails')); return false;
    }
    if (!data.email_sender || !verifyEmail(data.email_sender)) {
      Toast(400, t('Please enter email sender')); return false;
    }
    if (!data.email_subject || data.email_subject.length === 0) {
      Toast(400, t('Please enter email subject')); return false;
    }
    if (!data.language || data.language.length === 0) {
      Toast(400, t('Please enter language')); return false;
    }
    if (!data.personalized_link || data.personalized_link.length === 0) {
      Toast(400, t('Please enter personalized link')); return false;
    }
    return true;
  }
  sendSurvey = () => {
    if (!this.validateForm()) return

    let data = {
      ...this.state.outputData,
      campaign_id: this.state.campaign_id
    };
    let self = this;
    const url = api_url + `campaign/send_survey`;
    const header = 'Bearer '.concat(this.props.user.token);
    let headers = null;
    if (this.props.user.token) {
      headers = {
        Authorization: header
      };
    }
    this.props.dispatch({ type: 'LOAD_START' });
    axios({
      url,
      method: 'post',
      headers,
      data
    })
      .then(function (response) {
        if (response.data.success) {
          self.props.history.push('/campaign/campaign')
        } else {
          Toast(400, response.data.error)
        }
        self.props.dispatch({ type: 'LOAD_END' });
      })
      .catch(function (errors) {
        console.info(errors);
        self.props.dispatch({ type: 'LOAD_END' });
        if (!errors.response) {
          Toast(500);
          return;
        }
        Toast(errors.response.status);
      });
  }
  getSurveysOfSelectedLang = () => {
    const surveys = this.state.surveys.map(item => {
      const main = item.main.filter(mi => mi.language === this.state.outputData.language)[0]
      if (main) {
        return {
          label: main.title,
          value: item._id
        }
      } else {
        return {
          label: item.main[0].title,
          value: item._id
        }
      }
    })
    return surveys
  }
  getAvailableLanguages = () => {
    const selectedSurvey = this.state.selectedSurvey
    const availableLanguages = selectedSurvey.main.map(item => {
      return {
        value: item.language,
        label: ISO6391.getName(item.language)
      }
    })
    return availableLanguages
  }
  onChangeCurrentSurvey = (id) => {
    const { surveys, outputData } = this.state
    const newSurvey = surveys.filter(item => item._id === id)[0]
    this.setState({
      selectedSurvey: newSurvey,
      outputData: {
        ...outputData,
        survey: id
      }
    })
  }
  render() {
    const { classes, t } = this.props;
    const { selectedSurvey, outputData, campaign_data } = this.state
    if (!selectedSurvey) {
      return <Muted><h3>...</h3></Muted>
    }
    const surveysOfCurrentLanguage = this.getSurveysOfSelectedLang()
    const availableLanguages = this.getAvailableLanguages()

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12}>
            <Card>
              <CardHeader color="success" icon>
                <CardIcon color="success">
                  <ViewList />
                </CardIcon><h4 className={classes.cardIconTitle}>{t('SEND SURVEY LINK')}</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} md={12}>
                    <CustomInput
                      labelText={t('Campaign name')}
                      id="campaignname"
                      formControlProps={{
                        label: 'Test',
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "text",
                        onChange: (e) => this.setFormData('campaign_name', e.target.value),
                        value: outputData.campaign_name
                      }}
                    />
                    <br />
                  </GridItem>
                  <GridItem xs={12} md={6}>
                    <CustomSelect
                      onChange={updated => {
                        this.onChangeCurrentSurvey(updated.value)
                      }}
                      placeholder={t('Survey')}
                      options={surveysOfCurrentLanguage}
                      value={surveysOfCurrentLanguage.filter(item => item.value === selectedSurvey._id)[0]}
                    />
                    <br />
                    <CustomSelect
                      onChange={selvalue => {
                        this.setFormData('email_list', selvalue.value)
                      }}
                      placeholder={t('E-mail List')}
                      options={this.state.emails}
                      value={this.state.emails.filter(email => email.value == outputData.email_list)[0]}
                      isDisabled={campaign_data}
                    />
                    <br />
                  </GridItem>
                  <GridItem xs={12} md={6}>
                    <CustomSelect
                      onChange={selvalue => {
                        this.setState({
                          outputData: {
                            ...outputData,
                            language: selvalue.value
                          }
                        })
                      }}
                      placeholder={t('Language')}
                      options={availableLanguages}
                      value={{ value: outputData.language, label: ISO6391.getName(outputData.language) }}
                    />
                    <br />
                    <CustomSelect
                      onChange={selvalue => {
                        this.setFormData('message', selvalue.value)
                      }}
                      placeholder={t('Custom Message')}
                      options={this.state.customMsgs}
                    />
                    <br />

                  </GridItem>

                  <GridItem xs={12} md={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.outputData.send_type === "now"}
                          tabIndex={-1}
                          onClick={() => this.setFormData("send_type", 'now')}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked
                          }}
                        />
                      }
                      classes={{
                        label: classes.label
                      }}
                      label={t('Send campaign right now')}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.outputData.send_type === "daily"}
                          tabIndex={-1}
                          onClick={() => this.setFormData("send_type", 'daily')}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked
                          }}
                        />
                      }
                      classes={{
                        label: classes.label
                      }}
                      label={t('Send campaign every day')}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.outputData.send_type === "later"}
                          tabIndex={-1}
                          onClick={() => this.setFormData("send_type", 'later')}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked
                          }}
                        />
                      }
                      classes={{
                        label: classes.label
                      }}
                      label={t('Send campaign later')}
                    />
                    {

                      this.state.outputData.send_type === 'later' && <FormControl fullWidth>
                        <div>
                          {t('Send date')}
                        </div>
                        <Datetime
                          onChange={selvalue => {
                            this.setFormData('start_date', selvalue.format('X'))
                          }}
                          closeOnSelect={true}
                          className={classes.customInput}
                          timeFormat={false}
                          dateFormat="DD/MM/YYYY"
                          inputProps={{ placeholder: t('Start date') }}
                          value={moment.unix(Number(outputData.start_date))}
                        />
                      </FormControl>
                    }
                    <br />
                  </GridItem>
                  <GridItem xs={12} md={12}>
                    <h5>&nbsp;</h5>
                    <p>{t('Message')}</p>
                    <SnackbarContent
                      open
                      message={<div>
                        <div><b>#{t('title')}</b>: {t('Email Title')}</div>
                        <div><b>#{t('name')}</b>: {t('Email Name')}</div>
                        <div><b>#{t('survey')}</b>: {t('Survey Name')}</div>
                      </div>}
                    />
                    <Editor
                      onEditorChange={(txt) => {
                        this.setFormData('message', txt)
                      }}
                      apiKey={tinyeditor_api_key}
                      initialValue={this.state.outputData.message}
                      value={this.state.outputData.message}
                      init={{
                        menubar: false,
                        plugins: [
                          'advlist autolink lists link image charmap print preview anchor',
                          'searchreplace visualblocks code fullscreen',
                          'insertdatetime media table paste code help wordcount'
                        ],
                        toolbar:
                          'undo redo | formatselect | bold italic backcolor link | \
                          alignleft aligncenter alignright alignjustify | \
                          bullist numlist outdent indent | removeformat | help'
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} md={6}>
                    <CustomInput
                      labelText={t('Name for Message to save')}
                      id="nameformessagetosave"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "text",
                        onChange: (e) => this.setFormData('message_name', e.target.value),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} md={6}>
                    <CustomInput
                      labelText={t('Personalize the link')}
                      id="nameformessagetosave"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "text",
                        onChange: (e) => this.setFormData('personalized_link', e.target.value),
                        value: outputData.personalized_link
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} md={12}>
                    <p>{t('Enter E-mails (optional)')} </p>
                    <div className={classes.emailTags}>
                      <TagsInput
                        value={this.state.outputData.additional_emails}
                        onlyUnique={true}
                        onChange={(tags) => this.setFormData('additional_emails', tags)}

                        validate={(inputText) => this.isEmail(inputText)}
                        tagProps={{ className: "react-tagsinput-tag info" }}
                        inputProps={{
                          placeholder: t('email'),
                        }}
                      />
                    </div>
                  </GridItem>
                  <GridItem xs={12} md={6}>
                    <CustomInput
                      labelText={t('E-mail sender')}
                      id="emailsender"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "text",
                        onChange: (e) => this.setFormData('email_sender', e.target.value),
                        value: outputData.email_sender
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} md={6}>
                    <CustomInput
                      labelText={t('Subject')}
                      id="subject"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "text",
                        onChange: (e) => this.setFormData('email_subject', e.target.value),
                        value: outputData.email_subject
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                {!campaign_data && <Button fullWidth color="info" size="lg" onClick={() => this.sendSurvey()}>
                  <Send />
                  {t('Send your survey')}
                </Button>}
                {campaign_data && <Button fullWidth color="info" size="lg" onClick={() => this.sendSurvey()}>
                  <Send />
                  {t('Update')}
                </Button>}
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}


const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps)(withTranslation()(withStyles(styles)(SendSurveyPage)));