// core components
import withStyles from "@material-ui/core/styles/withStyles";
import axios from 'axios';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import { t } from "i18next";
import jwt from "jsonwebtoken";
import pagesStyle from "layouts/PublicLayout.style";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { random_string } from "redux/config";
import { api_url } from 'redux/config.js';
import Toast from 'views/Toast/Toast';
import SurveyMainComponent from "./components/SurveyMainComponent";
import SurveyProgress from "./components/SurveyProgress";
import SurveyScore from "./components/SurveyScore";
import LanguageSelector from "views/Survey/components/LanguageSelector"
import { CallApi } from "utils/Request";
import * as Score from "utils/Score";

const styles = (theme) => ({
  ...pagesStyle(theme),
  surveyImg: {
    width: "100%"
  }
});
class CampaignSurveyPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLanguage: '',

      survey_completed: false,
      token: this.props.match.params.token,
      campaign_data: null,
      takenAlready: false,
      answers: []
    }
  }
  componentDidMount() {
    const token = this.state.token
    jwt.verify(token, random_string, (err, decoded) => {
      if (err) {
        Toast(400, t('Invalid survey link'));
      } else {

        this.setState({
          email: decoded.email,
          campaign_id: decoded.campaign_id
        })
        this.onLoad(decoded.campaign_id)
      }
    });
    document.body.style.overflow = "unset";
  }

  onComplete = (answers) => {
    let self = this;
    const url = api_url + `campaign/complete`;
    const header = 'Bearer '.concat(this.props.user.token);
    let headers = null;
    let attempted_emails = this.state.campaign_data.attempted_emails;
    if (attempted_emails.indexOf(this.state.email)) {
      attempted_emails.push(this.state.email)
    }
    let data = {
      survey: this.state.campaign_data.survey._id,
      email: this.state.email,
      answers: answers,
      campaign_id: this.state.campaign_id,
      attempted_emails,
      language: this.state.campaign_data.survey.main[0].language
    }

    console.info(data)
    if (this.props.user.token) {
      headers = {
        Authorization: header
      };
    }

    this.props.dispatch({ type: 'LOAD_START' });
    axios({
      url,
      method: 'post',
      headers,
      data
    })
      .then(function (response) {
        if (response.data.success) {
          self.setState({
            survey_completed: true,
            answers: answers
          })
          self.sendRateMessage(data.survey, response.data.newresponse._id, answers)
        } else {
          Toast(400, response.data.error)
        }
        self.props.dispatch({ type: 'LOAD_END' });
      })
      .catch(function (errors) {
        console.info(errors);
        self.props.dispatch({ type: 'LOAD_END' });
        if (!errors.response) {
          Toast(500);
          return;
        }
        Toast(errors.response.status);
      });
  };
  sendRateMessage = (survey_id, response_id, answers) => {
    const { data, selectedLanguage } = this.state

    const dataLang = this.getDataOfLang(selectedLanguage)
    const questions = dataLang.questions
    CallApi({
      url: 'rate_message/send_message',
      method: 'post',
      data: {
        survey: survey_id,
        response_id: response_id,
        score: Score.getScoreOfOneResponse(questions, answers)
      }
    }).then((res) => {
      console.info('=== called rate message ===', JSON.stringify(res, null, 4))
    })
  }
  validateAndSave = (data) => {
    const alreadyTaken = data.total_emails_with_isattempt.filter((item) => {
      if (item.attempted && item.email === this.state.email) {
        return true
      } else {
        return false
      }
    });
    if (alreadyTaken.length > 0) {
      this.setState({
        takenAlready: true,
        campaign_data: data,
      })
    } else {
      this.setState({
        selectedLanguage: data.language,
        campaign_data: data
      });
    }
  }
  onLoad = (campaign_id) => {
    let self = this;
    const url = api_url + `campaign/get`;
    const params = {
      id: campaign_id
    }
    this.props.dispatch({ type: 'LOAD_START' });
    axios({
      url,
      method: 'get',
      params
    })
      .then(function (response) {
        if (response.data.success) {
          self.validateAndSave(response.data.data)
        } else {
          Toast(400, response.data.error);
        }
        self.props.dispatch({ type: 'LOAD_END' });
      })
      .catch(function (errors) {
        console.info(errors);
        self.props.dispatch({ type: 'LOAD_END' });
        if (!errors.response) {
          Toast(500);
          return;
        }
        Toast(errors.response.status);
      });
  };
  getDataOfLang = (language) => {
    const { campaign_data, answers, survey_completed, selectedLanguage } = this.state
    const data = campaign_data.survey
    let main = data.main[0]
    let questions = data.questions[0]
    if (language.length > 0) {
      let mainfiltered = data.main.filter(item => item.language === language)[0]
      let questionsfiltered = data.questions.filter(item => item.language === language)[0]
      main = mainfiltered ? mainfiltered : data.main[0]
      questions = questionsfiltered ? questionsfiltered : data.main[0]
    }
    return {
      main, questions
    }
  }
  onChangeLanguage = (language) => {
    this.setState({
      selectedLanguage: language
    })
  }
  render() {
    const { classes } = this.props;
    const { campaign_data, answers, survey_completed, selectedLanguage } = this.state
    if (!campaign_data) return null;
    const survey = campaign_data.survey
    const dataLang = this.getDataOfLang(selectedLanguage)


    const mainData = {
      image_url: survey.image_url,
      ...dataLang.main
    }
    const questions = dataLang.questions
    if (survey_completed) {
      return <div className={classes.wrapper} ref="wrapper">
        <div className={classes.fullPage}><SurveyScore questions={questions} endMessages={survey.endmessages} answers={answers} /></div></div>
    }
    return (
      <div className={classes.wrapper} ref="wrapper">
        <div className={classes.fullPage}>
          <GridContainer>
            <GridItem sm={12} xs={12}>
              <LanguageSelector direction={'horizontal'}
                value={dataLang.main.language}
                languages={survey.main.map(item => item.language)}
                onChange={(language) => this.onChangeLanguage(language)} />
              <SurveyMainComponent data={mainData} />
            </GridItem>
            <GridItem sm={12} xs={12}>
              {!this.state.takenAlready && <SurveyProgress doRefresh={selectedLanguage} questions={questions} saveAnswer={(answers) => this.onComplete(answers)} />
              }
              {
                this.state.takenAlready && <div>
                  <SnackbarContent
                    fullWidth
                    message={
                      <h3>{t('You already have took this survey. Thanks')}</h3>
                    }
                    color="success"
                  />
                </div>
              }
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

CampaignSurveyPage.propTypes = {
  classes: PropTypes.object.isRequired
};


const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps)(withTranslation()(withStyles(styles)(CampaignSurveyPage)));