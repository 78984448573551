import { store } from "redux/store";
import { CallApi } from "utils/Request";

export const loadProfile = () => {
    const url = `user/profile`;
    CallApi({
      url,
    }).then((res) => {
      store.dispatch({ type: "UPDATE_PROFILE", data: res.data });
    })
}