import withStyles from '@material-ui/core/styles/withStyles';
import default_img from "assets/img/image_placeholder.jpg";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { default as React } from 'react';
import { connect } from 'react-redux';
const styles = {

    surveyImg: {
        width: "100%"
    }
};
class SurveyMainComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        const { classes } = this.props;
        let data = this.props.data;
        if (!data.image_url || data.image_url.length < 3) {
            data.image_url = default_img
        }
        return <Card>
            <CardBody>
                <GridContainer>
                    <GridItem sm={2} xs={12}>
                        <img className={classes.surveyImg} src={data.image_url} />
                    </GridItem>
                    <GridItem sm={10} xs={12}>
                        <h3><b>{data.title}</b></h3>
                        <p>{data.description}</p>
                    </GridItem>
                </GridContainer>
            </CardBody>
        </Card>
    }
}
const mapStatsToProps = ({ user }) => ({ user });
export default connect(mapStatsToProps)(
    withStyles(styles, { withTheme: true })(SurveyMainComponent)
);