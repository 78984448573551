// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Cancel from "@material-ui/icons/Cancel";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Layers from "@material-ui/icons/Layers";
import RemoveRedEye from "@material-ui/icons/RemoveRedEye";
import Send from "@material-ui/icons/Send";
import { infoColor } from "assets/jss/material-dashboard-pro-react.jsx";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import axios from 'axios';
import Badge from "components/Badge/Badge";
import Button from "components/CustomButtons/Button.jsx";
import { TransReactTable } from "components/CustomReactTable/TransReactTable";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import Muted from "components/Typography/Muted";
import Success from "components/Typography/Success";
import jwt from "jsonwebtoken";
import moment from "moment";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import ReactTable from "react-table";
import { app_url, random_string } from "redux/config";
import { api_url } from 'redux/config.js';
import Toast from 'views/Toast/Toast';
import CampaignContent from "./CampaignContent";


const styles = {
  ...regularFormsStyle,
  ...extendedFormsStyle,
  ...sweetAlertStyle,
  checkedStatus: {
    textAlign: 'center',
    color: infoColor
  }
};

class CampaignDetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      campaign_id: this.props.match.params.id,
      campaign_data: null
    };
  }
  componentDidMount() {
    const { classes } = this.props;
    this.onLoad()
  }
  onLoad = () => {
    let self = this;
    const url = api_url + `campaign/get`;
    const header = 'Bearer '.concat(this.props.user.token);
    let headers = null;
    const params = {
      id: this.state.campaign_id
    }
    if (this.props.user.token) {
      headers = {
        Authorization: header
      };
    }
    this.props.dispatch({ type: 'LOAD_START' });
    axios({
      url,
      method: 'get',
      headers,
      params
    })
      .then(function (response) {
        if (response.data.success) {
          self.setState({
            campaign_data: response.data.data
          });
        } else {
          Toast(400, response.data.error);
        }
        self.props.dispatch({ type: 'LOAD_END' });
      })
      .catch(function (errors) {
        console.info(errors);
        self.props.dispatch({ type: 'LOAD_END' });
        if (!errors.response) {
          Toast(500);
          return;
        }
        Toast(errors.response.status);
      });
  };
  geEmailsTableData = () => {

    const { classes, t } = this.props;

    if (!this.state.campaign_data) return [];
    const campaign_data = this.state.campaign_data;
    const data = campaign_data.total_emails_with_isattempt.map((item, key) => {
      const token_data = {
        email: item.email,
        campaign_id: campaign_data._id,
      }
      const token = jwt.sign(token_data, random_string)
      const action = (
        <div key={key}>
          <Button justIcon size="sm" color="info" onClick={() => {
            prompt("Please copy link", `${app_url}/public/campaign_survey/${token}`);
          }}>
            <Layers />
          </Button>
          <Button justIcon size="sm" color="info" disabled={!item.attempted} onClick={
            () => {
              const params = {
                response_id: campaign_data.survey.responses.filter(response => (response.email === item.email && response.type === 'campaign'))[0]._id,
                survey_id: campaign_data.survey._id,
              }
              var win = window.open(`/public/survey_answer/${params.survey._id}/${params.response_id}`, '_blank');
              win.focus();
            }
          }>
            <RemoveRedEye />
          </Button>
        </div>
      );
      return {
        email: item.email,
        survey: campaign_data.survey.main[0].title,
        date: moment.unix(campaign_data.start_date).format("MMMM Do YYYY"),
        response_status: <div>
          {item.attempted && <Badge color="success">{t('Attempted')}</Badge>}
          {!item.attempted && <Badge color="gray">{t('No Attempt')}</Badge>}
        </div>,
        active: <div className={classes.checkedStatus}>
          {campaign_data.is_sent && <Success><CheckCircle /></Success>}
          {!campaign_data.is_sent && <Muted><Cancel /></Muted>}
        </div>,
        action
      };
    });
    return data
  }

  sendCampaign = () => {
    let self = this;
    const url = api_url + `campaign/send_campaign_email`;
    const header = 'Bearer '.concat(this.props.user.token);
    let headers = null;
    if (this.props.user.token) {
      headers = {
        Authorization: header
      };
    }
    const params = {
      id: this.state.campaign_id
    }
    this.props.dispatch({ type: 'LOAD_START' });
    axios({
      url,
      method: 'get',
      headers,
      params
    })
      .then(function (response) {
        if (response.data.success) {
          let campaign_data = self.state.campaign_data;
          campaign_data.is_sent = true;
          self.setState({
            campaign_data
          })
          Toast(200, "Successfully sent")
        } else {
          Toast(400, response.data.error)
        }
        self.props.dispatch({ type: 'LOAD_END' });
      })
      .catch(function (errors) {
        console.info(errors);
        self.props.dispatch({ type: 'LOAD_END' });
        if (!errors.response) {
          Toast(500);
          return;
        }
        Toast(errors.response.status);
      });
  };
  render() {
    const { classes, t } = this.props;

    const column = [
      {
        Header: t("Email"),
        accessor: "email"
      },
      {
        Header: t("Survey"),
        accessor: "survey"
      },
      {
        Header: t("Date"),
        accessor: "date"
      },
      {
        Header: t("Respondent Status"),
        accessor: "response_status"
      },
      {
        Header: t("Status"),
        accessor: "active",
      },
      {
        Header: t("Actions"),
        accessor: "action",
        minWidth: 50,
        sortable: false,
      }
    ];
    const { campaign_data } = this.state
    if (campaign_data && !campaign_data.survey) {
      this.props.history.replace('/campaign/campaign')
      return null
    }
    return (
      <div>
        {this.state.alert}
        <GridContainer>
          <GridItem md={12} sm={12}>
            <NavPills
              color="info"
              tabs={[
                {
                  tabButton: t("E-mails"),
                  tabContent: (
                    <div>
                      <Button fullWidth color="success" size="lg" onClick={() => this.sendCampaign()}>
                        <Send />
                        {t('Resend your survey to Not Attempt emails')}
                      </Button>

                      <TransReactTable
                        defaultPageSize={5}
                        data={this.geEmailsTableData()}
                        columns={column}
                      />
                    </div>
                  )
                },
                {
                  tabButton: t("Campaign"),
                  tabContent: (
                    <CampaignContent campaign_data={this.state.campaign_data} />
                  )
                },
              ]}
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps)(withTranslation()(withStyles(styles)(CampaignDetailPage)));