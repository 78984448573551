import {
    primaryColor,
    secondaryColor,
    warningColor,
    dangerColor,
    successColor,
    infoColor,
    roseColor,
    grayColor,
} from "../material-dashboard-pro-react"
export default {

    white: {
        color: 'white !important'
    },
    whiteBackground: {
        background: 'white'
    },
    red: {
        color: 'red'
    },
    secondaryBackground: {
        background: '#00acc10d'
    },
    info: {
        color: infoColor
    },
    success: {
        color: successColor
    },
    gray: {
        color: grayColor
    },
    grayBackground: {
        backgroundColor: grayColor
    },
    infoColor: infoColor,
    blackColor: '#3C4858',
    grayColor: grayColor,
    backgroundColor: '#00acc10d',
    whitebackgroundColor: '#eeeeee',
    blur: {
        filter: 'blur(3px);',
        userSelect: 'none;'
    }
}