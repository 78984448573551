// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { Edit } from "@material-ui/icons";
import RemoveRedEye from "@material-ui/icons/RemoveRedEye";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import axios from "axios";
import Button from "components/CustomButtons/Button.jsx";
import { TransReactTable } from "components/CustomReactTable/TransReactTable";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import moment from "moment";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import ReactTable from "react-table";
import { api_url } from "redux/config.js";
import Toast from "views/Toast/Toast";

const styles = {
  ...regularFormsStyle,
  ...extendedFormsStyle
};

class CampaignPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showactive: "all",
      campaignData: [],
      active: "pendingTransaction",
      data: [],

      loading: false,
      pages: -1,
      paginationState: null
    };
  }
  componentDidMount() { }
  toggleActivateStateOfSurvey = item => {
    const newActive = !item.active;
    this.props.dispatch({ type: "LOAD_START" });
    const activate_term = newActive ? "emaillist/activate" : "emaillist/deactivate";
    axios({
      url: api_url + activate_term,
      method: "post",
      data: {
        id: item._id,
        active: newActive
      },
      headers: {
        authorization: "Bearer " + this.props.user.token
      }
    })
      .then(res => {
        if (res.data.success) {
          const newData = this.state.data.map(ditem => {
            let newitem = ditem;
            if (ditem._id === item._id) {
              newitem.active = newActive;
            }
            return newitem;
          });

          this.setState({
            data: newData
          });
          this.normalizeData(newData);
        } else {
          Toast(400, res.data.error);
        }
      })
      .finally(this.props.dispatch({ type: "LOAD_END" }));
  };
  normalizeData = data => {

    if (!data || data.length === 0) {
      this.setState({
        campaignData: []
      });
    }
    const ndata = data.map((item, key) => {
      const view_url = "/campaign/campaign/" + item._id;
      const edit_url = "/sendsurvey/sendsurvey/" + item._id;
      
      const action = (
        <div key={key}>

          <Button
            justIcon
            size="sm"
            color="info"
            onClick={() => this.props.history.push(view_url)}
          >
            <RemoveRedEye />
          </Button>
          <Button
            justIcon
            size="sm"
            color="info"
            onClick={() => this.props.history.push(edit_url)}
          >
            <Edit />
          </Button>
        </div>
      );

      return {
        campaign_name: item.campaign_name,
        start_date: moment.unix(item.start_date).format("MMMM Do YYYY"),
        total_emails: item.total_emails,
        action
      };
    });
    this.setState({
      campaignData: ndata
    });
  };
  loadData = state => {
    this.setState({
      paginationState: state
    });
    this.props.dispatch({ type: "LOAD_START" });
    let data = {
      page: state.page,
      pageSize: state.pageSize,
      sorted: state.sorted,
      filtered: state.filtered
    };
    const showactive = state.showactive;
    if (showactive && showactive !== "all") {
      data.active = showactive === "active" ? true : false;
    }
    axios({
      url: api_url + "campaign/get",
      method: "post",
      headers: {
        authorization: "Bearer ".concat(this.props.user.token)
      },
      data
    })
      .then(res => {
        if (res.data.success) {
          this.normalizeData(res.data.data.rows);
          this.setState({
            data: res.data.data.rows,
            pages: res.data.data.pages,
            loading: false
          });
        } else {
          Toast(400, res.data.error);
        }
      })
      .catch(e => {
        Toast(500);
      })
      .finally(() => {
        this.props.dispatch({ type: "LOAD_END" });
      });
  };
  handleactive = showactive => {
    this.setState({
      showactive
    });
    this.loadData({
      ...this.state.paginationState,
      showactive
    });
  };
  render() {
    const { classes,  t } = this.props;

    const column = [
      {
        Header: t("Campaign name"),
        accessor: "campaign_name"
      },
      {
        Header: t("Date"),
        accessor: "start_date"
      },
      {
        Header: t("E-mails"),
        accessor: "total_emails"
      },
      {
        Header: t("Actions"),
        accessor: "action",
        sortable: false,
        minWidth: 150
      }
    ];
    return (
      <div>
        <GridContainer>
          <GridItem md={12} sm={12}>
            <Button color="info" href="/sendsurvey/sendsurvey/0">
              {t('Create')}
            </Button>
            <TransReactTable
              defaultPageSize={5}
              data={this.state.campaignData}
              columns={column}
              pages={this.state.pages} // should default to -1 (which means we don't know how many pages we have)
              loading={this.state.loading}
              manual // informs React Table that you'll be handling sorting and pagination server-side
              onFetchData={(state, instance) => {
                // show the loading overlay
                this.setState({ loading: true });
                this.loadData(state);
              }}
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps)(withTranslation()(withStyles(styles)(CampaignPage)));
