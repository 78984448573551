import withStyles from '@material-ui/core/styles/withStyles';
import commonStyle from "assets/jss/common/common";
import axios from 'axios';
import Button from 'components/CustomButtons/Button';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import CustomSelect from "components/CustomSelect/CustomSelect";
import StarRating from "components/StarRating/StarRating";
import { t } from 'i18next';
import { default as React } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { question_types } from "redux/config";
import { api_url } from 'redux/config.js';
import InputModal from "views/Shared/InputModal";
import TitleBox from "views/Shared/TitleBox";
import Toast from "views/Toast/Toast";
import EmojiDropdown from './EmojiDropdown';


const styles = {
    ...commonStyle,
    choiceContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        marginTop: 10
    }
};
class Component extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            questionSchema: null,
            savedOptions: null,

            question: {
                type: 'radio',
                direction: '',
                options: [], //value, label, emoticon
            },
        }
    }
    componentDidMount() {
        this.props.onRef(this)
        this.loadOptions();
        let questionSchema = question_types.filter((item) => item.type === 'radio')[0]
        const question = this.props.question

        this.setState({
            questionSchema: questionSchema,
            question: (question && question.type === questionSchema.type) ? question : {
                type: questionSchema.type,
                direction: questionSchema.directions[0].value,
                options: []
            }
        })
    }
    loadOptions = () => {
        let self = this;
        const url = api_url + `radiooptions/all`;
        const header = 'Bearer '.concat(this.props.user.token);
        let headers = null;
        if (this.props.user.token) {
            headers = {
                Authorization: header
            };
        }
        this.props.dispatch({ type: 'LOAD_START' });
        axios({
            url,
            method: 'get',
            headers
        })
            .then(function (response) {
                if (response.data.success) {
                    self.setState({
                        savedOptions: response.data.data
                    });
                } else {
                    Toast(400, response.data.error)
                }
                self.props.dispatch({ type: 'LOAD_END' });
            })
            .catch(function (errors) {
                console.info(errors);
                self.props.dispatch({ type: 'LOAD_END' });
                if (!errors.response) {
                    Toast(500, 'Connection error!');
                    return;
                }
                Toast(errors.response.status);
            });
    };
    getData() {
        const question = this.state.question
        if (question.direction === '5star' || question.direction === '10star') {
            return this.state.question
        }
        if (this.verifyQuestion()) {
            if (this.state.question.options.length > 0) {

                return {
                    ...this.state.question,
                    options: this.state.question.options.map((op) => {
                        return {
                            label: op.label,
                            value: Number(op.value)
                        }
                    })
                }
            } else {
                return this.state.question
            }
        }
        return null
    }
    verifyQuestion = () => {
        const question = this.state.question
        if (question.options.length === 0) {
            Toast(400, t('Please add options'));
            return false
        }
        for (let i = 0; i < question.options.length; i++) {
            let option = question.options[i]
            if (option.label.length === 0) {
                Toast(400, t('Please enter option label'))
                return false
            }
        }
        return true
    }
    canSave = () => {
        const question = this.state.question
        if (question.direction === '5star' || question.direction === '10star') return false
        if (question.options.length === 0) {
            return false
        }
        return true
    }
    onSaveDlg = () => {
        this.setState({
            alert: <InputModal title={t('Enter Answer Set name')} handleSave={(title) => this.saveSet(title)} handleClose={() => this.setState({ alert: null })} />
        })
    }
    saveSet = (title) => {
        if (title.length === 0) {
            Toast(t('Please choose title')); return
        }
        const question = this.state.question

        let self = this;

        const url = api_url + `radiooptions/add_option`;
        const header = 'Bearer '.concat(this.props.user.token);
        let headers = null;
        if (this.props.user.token) {
            headers = {
                Authorization: header
            };
        }
        this.props.dispatch({ type: 'LOAD_START' });
        axios({
            url,
            method: 'post',
            headers,
            data: { title, options: question.options }
        })
            .then(function (response) {
                Toast(200, t('Successfully saved'));
                self.setState({ alert: null })
                self.props.dispatch({ type: 'LOAD_END' });
            })
            .catch(function (errors) {
                console.info(errors);
                self.props.dispatch({ type: 'LOAD_END' });
                if (!errors.response) {
                    Toast(500, t('Connection error!'));
                    return;
                }
                Toast(errors.response.status);
            });
    };
    setQuestionData = (name, value) => {
        let question = this.state.question;
        question[name] = value;
        this.setState({
            question
        })
    }
    setQuestionItem = (name, index, value) => {
        let question = this.state.question;
        let options = question.options
        options[index][name] = value
        this.setState({
            question: {
                ...question,
                options
            }
        })
    }

    addQuestionItem = () => {
        let question = this.state.question;
        let options = question.options

        options.push({
            value: 0,
            label: '',
            emoticon: ''
        })
        this.setState({
            question: {
                ...question,
                options
            },
        })
    }
    removeQuestionItem = (index,) => {
        let question = this.state.question;
        let options = question.options
        options.splice(index, 1)
        this.setState({
            question: {
                ...question,
                options
            }
        })
    }
    render() {
        const { questionSchema, question, savedOptions } = this.state
        if (!questionSchema || !savedOptions) return null
        const { classes } = this.props

        return <div >
            {this.state.alert}
            <hr />
            <div className={classes.fontBold}>{t('Choose Position for Answers')}:</div>
            {
                questionSchema.directions.map((item, key) => {
                    return <Button
                        key={key}
                        onClick={() => this.setQuestionData('direction', item.value)}
                        size="sm" color={item.value === question.direction ? "info" : null}>{item.label}</Button>
                })
            }

            <hr />
            {

                question.direction === '5star' && <div>
                    <div className={classes.fontBold}>{t('Preview')}:</div>
                    <StarRating total={5} />
                </div>
            }
            {
                question.direction === '10star' && <div>
                    <div className={classes.fontBold}>{t('Preview')}:</div>
                    <StarRating total={10} />
                </div>
            }
            {
                question.direction !== '5star' && question.direction !== '10star' && <TitleBox title='CHOICES'>
                    {
                        question.options.map((item, key) => {
                            return <div key={key} className={classes.choiceContainer}>

                                <EmojiDropdown onChange={(emoji) => this.setQuestionItem('emoticon', key, emoji)} value={item.emoticon} />
                                <CustomInput
                                    formControlProps={{
                                        className: classes.noPadding + ' ' + classes.noMargin,
                                        onChange: e =>
                                            this.setQuestionItem("value", key, Number(e.target.value)),
                                    }}
                                    inputProps={{
                                        placeholder: t('Value'),
                                        value: Number(item.value),
                                        type: 'number',
                                        height: '20px'
                                    }}
                                />
                                <CustomInput

                                    formControlProps={{
                                        className: classes.noPadding + ' ' + classes.noMargin,
                                        onChange: e =>
                                            this.setQuestionItem("label", key, e.target.value),
                                    }}
                                    inputProps={{
                                        placeholder: t('Answer'),
                                        value: item.label
                                    }}
                                />
                                <Button className={classes.noMargin} color="danger" size="sm" onClick={() => this.removeQuestionItem(key)}>
                                    {t('Remove')}</Button>
                            </div>
                        })
                    }
                    <div className={classes.choiceContainer}>

                        <Button className={classes.noMargin} color="success" size="sm" onClick={() => this.addQuestionItem()}>{t('Add')}</Button>
                    </div>
                    <div>
                        {this.canSave() && <Button color="info" onClick={() => this.onSaveDlg()}>{t('Save Set')}</Button>}
                    </div>
                    <hr />
                    <div>
                        <CustomSelect
                            onChange={option => {
                                const optionList = savedOptions[option.value]
                                this.setQuestionData('options', optionList.options)
                            }}
                            placeholder={t('Select Saved Answers Group')}
                            options={savedOptions.map((item, key) => ({ label: item.title, value: key }))}
                        />
                    </div>
                </TitleBox>
            }
        </div>;
    }
}
const mapStatsToProps = ({ user }) => ({ user });
export default connect(mapStatsToProps)(
    withTranslation()(withStyles(styles, { withTheme: true })(Component))
);