import withStyles from '@material-ui/core/styles/withStyles';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Success from "components/Typography/Success";
import { t } from 'i18next';
import { default as React } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as Score from "utils/Score";
const styles = {};

class Component extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            answers: this.props.answers,
            endMessages: this.props.endMessages,
            questions: this.props.questions,
            score: 0,
            endMessage: ''
        }
    }
    componentDidMount() {
        const answers = this.state.answers
        const questions = this.state.questions
        const score = Score.getScoreOfOneResponse(questions, answers)
        const endMessage = this.getEndMessageForScore(score)
        this.setState({
            score, endMessage
        })
    }
    getEndMessageForScore = (score) => {
        const endMessages = this.state.endMessages
        const message = endMessages.filter(item => (item.from <= score && item.to >= score))[0]
        return message
    }
    render() {
        const { endMessage, score } = this.state;
        const { classes } = this.props
        return <div >
            <Card>
                <CardBody>
                    {endMessage && <div>
                        <img src={endMessage.image_url} />
                        <div dangerouslySetInnerHTML={{ __html: endMessage.message }} className={classes.alignCenter} />
                    </div>}
                    {!endMessage && <Success><h3> {t('Your Score')}: {score.toFixed(2)}</h3></Success>}
                </CardBody>
            </Card>
        </div>;
    }
}
const mapStatsToProps = ({ user }) => ({ user });
export default connect(mapStatsToProps)(
   withTranslation()(withStyles(styles, { withTheme: true })(Component))
);