// import
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Assignment from "@material-ui/icons/Assignment";
import ViewList from "@material-ui/icons/ViewList";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import axios from 'axios';
import Badge from "components/Badge/Badge";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput";
import CustomSelect from "components/CustomSelect/CustomSelect";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import React from "react";
import Datetime from "react-datetime";
import { connect } from "react-redux";
import "react-table/react-table.css";
import ReactToPrint from 'react-to-print';
import { api_url } from 'redux/config.js';
import Toast from 'views/Toast/Toast';
import Analytical from "./ReportTypes/Analytical";
import Comparation from "./ReportTypes/Comparation";
import Summary from "./ReportTypes/Summary";
import TextReports from "./ReportTypes/TextReports";
import { getAllLanguages } from "utils/Languages"
import RenderQuestion from "views/Survey/RenderQuestions/RenderQuestion";
import * as Score from "utils/Score";
import commonStyle from "assets/jss/common/common"
import moment from "moment";
import Info from "components/Typography/Info"

import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { withTranslation } from "react-i18next";
import { t } from "i18next";
// const

const styles = {
  ...commonStyle,
  ...regularFormsStyle,
  customInput: {
    paddingTop: 27,
    marginBottom: 17
  },
  filterItem: {
    minWidth: 200,
    display: 'inline'
  }
};
const report_types = [
  {
    label: "Analytical",
    value: "Analytical"
  },
  {
    label: "Summary",
    value: "Summary"
  },
  {
    label: "Text reports",
    value: "Textreports"
  },
  {
    label: "Comparation",
    value: "Comparation"
  }
];

class ViewReportPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.survey_id,

      surveyMain: null,
      surveyQuestion: null,
      surveyResponses: null,

      report: null,
      alert: null,
      show: false,
      emaillist: [],
      filterData: {
        type: report_types[0],
        // response filtering
        from1: Date.now(),
        to1: Date.now(),
        from2: Date.now(),
        to2: Date.now(),
        group: [],

        // question filtering
        section: null,
        question: null,
        answer: [],
      },
      filteredReseponseCount1: 0,
      filteredReseponseCount2: 0,
      reportRef: null
    };
  }
  componentDidMount() {
    this.onLoad()
  }

  onResetFilters = () => {
    this.setState({
      filterData: {
        type: report_types[0],
        // response filtering
        from1: Date.now(),
        to1: Date.now(),
        from2: Date.now(),
        to2: Date.now(),
        group: [],

        // question filtering
        section: null,
        question: null,
        answer: [],
      },
    })
  }

  setFilterData = (name, value) => {
    let filterData = this.state.filterData;
    filterData[name] = value
    this.setState({ filterData })
  }
  onLoad = () => {
    let self = this;
    const id = this.props.match.params.survey_id;
    const url = api_url + `survey/get`;
    const header = "Bearer ".concat(this.props.user.token);
    let headers = null;
    if (this.props.user.token) {
      headers = {
        Authorization: header
      };
    }
    this.props.dispatch({ type: "LOAD_START" });
    const ps_surveydata = axios({
      url, method: "get", params: { id: id }, headers: { authorization: "Bearer ".concat(this.props.user.token) }
    })

    const ps_emaillist = axios({ url: api_url + 'emaillist/all', method: 'get', headers: { authorization: "Bearer ".concat(this.props.user.token) } })

    Promise.all([ps_surveydata, ps_emaillist]).then(function (response) {
      self.setState({
        surveyMain: response[0].data.data.main[0],
        surveyQuestion: response[0].data.data.questions[0],
        surveyResponses: response[0].data.data.responses,
      });
      self.setState({ emaillist: response[1].data.data })
      self.props.dispatch({ type: 'LOAD_END' });
    })
      .catch(function (errors) {
        console.info(errors);
        self.props.dispatch({ type: 'LOAD_END' });
        if (!errors.response) {
          Toast(500);
          return;
        }
        Toast(errors.response.status);
      });
  };
  getOption = () => {
    const section = this.state.filterData.section
    const question = this.state.filterData.question
    const surveyResponses = this.state.surveyResponses
    if (!section || !question) return []
    const selectedQuestion = this.state.surveyQuestion.sections[section.value].questions[question.value]
    if (selectedQuestion.question.type === 'text') return []
    let answers = []
    const isCheckbox = selectedQuestion.question.type === 'checkbox'
    surveyResponses.forEach(response => {
      const answer = response.answers.filter(a => a.id === selectedQuestion.id)[0]
      if (!answer) return null;
      if (isCheckbox) {
        answers = answers.concat(answer.value)
      } else {
        answers.push(answer.value)
      }
    });
    const answers_unique = answers.filter((item, key) => answers.indexOf(item) === key)
    return answers_unique.map(au => ({
      label: Score.getLabelOfAnswerValue(selectedQuestion, isCheckbox ? [au] : au),
      value: au
    }))
  }
  getQuestionList = () => {
    const section = this.state.filterData.section
    if (!section) return []
    const questions = this.state.surveyQuestion.sections[section.value].questions
    if (!questions) return []

    const options = questions.map((item, question_index) => {
      return {
        label: item.questionTitle,
        value: question_index
      }
    })
    return options
  }

  getEmailsFromGroup = (group) => {

    const emaillist = this.state.emaillist
    var emails = [];
    emaillist.forEach(elist => {
      const existIndex = group.findIndex(g => g.value === elist._id)
      if (existIndex > -1) {
        const elist_emails = elist.emails.map(e => e.email)
        emails = emails.concat(elist_emails)
      }
    });
    return emails;
  }

  filterResponses = (date) => {
    let surveyResponses = this.state.surveyResponses
    const surveyQuestion = this.state.surveyQuestion
    // return surveyResponses
    const filterData = this.state.filterData;
    if (filterData.group.length > 0) {
      const emails = this.getEmailsFromGroup(filterData.group)
      if (emails.length > 0) {
        const filtered = surveyResponses.filter(item => {
          const index = emails.filter((email) => email === item.email)
          return index.length > 0

        })
        surveyResponses = filtered
      }
    }
    const from1_start_day = moment(filterData.from1).startOf('day')
    const to1_end_day = moment(filterData.to1).endOf('day')
    const from2_start_day = moment(filterData.from2).startOf('day')
    const to2_end_day = moment(filterData.to2).endOf('day')
    const datefiltered = surveyResponses.filter(item => {
      const response_date = moment(item.created_at).valueOf()

      if (date === 1) {
        return response_date > from1_start_day.valueOf() && response_date < to1_end_day.valueOf()
      } else {
        return response_date > from2_start_day.valueOf() && response_date < to2_end_day.valueOf()
      }
    })
    surveyResponses = datefiltered
    const { section, question, answer } = filterData
    const answerValues = answer.map((a => a.value))
    if (filterData.answer.length > 0) {
      const answerfiltered = surveyResponses.filter(item => {
        const question_id = surveyQuestion.sections[section.value].questions[question.value].id
        const question_type = surveyQuestion.sections[section.value].questions[question.value].question.type
        const response_answer = item.answers.filter(a => a.id === question_id)[0]
        if (response_answer) {
          if (question_type === 'checkbox') {
            const matchedAnswers = response_answer.value.filter(rav => {
              return answerValues.indexOf(rav) >= 0
            })
            return matchedAnswers.length > 0
          } else {
            return answerValues.indexOf(response_answer.value) >= 0
          }
        } else {
          return false
        }
      })
      surveyResponses = answerfiltered
    }
    return surveyResponses
  }
  renderReport = () => {

    let filteredReseponseCount1_ = this.state.filteredReseponseCount1
    let filteredReseponseCount2_ = this.state.filteredReseponseCount2
    const type = this.state.filterData.type
    const responses1 = this.filterResponses(1)
    filteredReseponseCount1_ = responses1.length

    let report = null;
    const { surveyQuestion, surveyMain } = this.state
    switch (type.value) {
      case report_types[0].value:
        report = <Analytical onRef={(ref) => this.setState({ reportRef: ref })} surveyMain={surveyMain}
          time={Date.now()} filterData={this.state.filterData} surveyQuestion={surveyQuestion} surveyResponses={responses1} />
        break;

      case report_types[3].value:

        const responses2 = this.filterResponses(2)
        filteredReseponseCount2_ = responses2.length

        report = <Comparation onRef={(ref) => this.setState({ reportRef: ref })} surveyMain={surveyMain}
          time={Date.now()} filterData={this.state.filterData} surveyQuestion={surveyQuestion} surveyResponses={[responses1, responses2]} />
        break;
      case report_types[1].value:
        report = <Summary onRef={(ref) => this.setState({ reportRef: ref })} surveyMain={surveyMain}
          time={Date.now()} filterData={this.state.filterData} surveyQuestion={surveyQuestion} surveyResponses={responses1} />
        break;
      case report_types[2].value:
        report = <TextReports onRef={(ref) => this.setState({ reportRef: ref })} surveyMain={surveyMain}
          survey_id={this.state.id} time={Date.now()} filterData={this.state.filterData} surveyQuestion={surveyQuestion} surveyResponses={responses1} />
        break;
      default:
        break;
    }
    this.setState({
      report,
      filteredReseponseCount1: filteredReseponseCount1_,
      filteredReseponseCount2: filteredReseponseCount2_
    })
  }

  generatePdf = () => {
    if (!this.state.reportRef) return
    const addon = 'Report'
    const date_ = moment().format('MMM_Do_YY')
    const type = this.state.filterData.type.value
    const fileName = `${addon}_${type}_${date_}.pdf`
    this.state.reportRef.generatePdf(fileName)
  }
  generateCSV = () => {
    if (!this.state.reportRef) return
    this.state.reportRef.generateCSV()
  }

  renderFilter = () => {
    const { classes } = this.props
    const { filteredReseponseCount1, filteredReseponseCount2 } = this.state
    const filterData = this.state.filterData
    const isCompare = filterData.type.value === report_types[3].value
    const period1 = moment(filterData.from1).format('LL') + ' ~ ' + moment(filterData.to1).format('LL')
    const period2 = moment(filterData.from2).format('LL') + ' ~ ' + moment(filterData.to2).format('LL')
    const emailGroup = filterData.group.map(a => a.label).join(',');
    const section = filterData.section ? filterData.section.label : '';
    const question = filterData.question ? filterData.question.label : '';
    const answer = filterData.answer.map(a => a.label).join(',');
    return <Card>
      <CardBody>
        <Info><h5>{t('Filter options')}</h5></Info>
        <table>
          <tbody>
            <tr>
              <td><b>{t('Period')}</b></td>
              <td>{period1}</td>
            </tr>
            {
              isCompare && <tr>
                <td><b>{t('Period 2')}</b></td>
                <td>{period2}</td>
              </tr>
            }
            <tr>
              <td><b>{t('Email Group')}</b></td>
              <td>{emailGroup}</td>
            </tr>
            <tr>
              <td><b>{t('Section')}</b></td>
              <td>{section}</td>
            </tr>
            <tr>
              <td><b>{t('Question')}</b></td>
              <td>{question}</td>
            </tr>
            <tr>
              <td><b>{t('Answer')}</b></td>
              <td>{answer}</td>
            </tr>
            <tr>
              <td><b>{t('Survey Answers') + '1:'}</b></td>
              <td>{filteredReseponseCount1}</td>
            </tr>
            {filterData.type.value == report_types[3].value && <tr>
              <td><b>{t('Survey Answers') + '2:'}</b></td>
              <td>{filteredReseponseCount2}</td>
            </tr>}
          </tbody>
        </table>
      </CardBody>
    </Card>
  }
  render() {
    const { classes } = this.props;

    const { surveyMain, surveyResponses, surveyQuestion, filterData, emaillist } = this.state
    console.info(filterData)
    if (!surveyMain) return '...';
    const options = this.getOption();
    return (
      <div>
        {this.state.alert}
        <GridContainer>
          <GridItem xs={12} sm={12}>
            <Card>
              <CardHeader color="success" icon>
                <CardIcon color="success">
                  <ViewList />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>{surveyMain.title}</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={6} md={6} style={{ textAlign: 'center' }}>
                    <div style={{ width: 100, height: 100, background: '#56b05a', borderRadius: 50, display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 'auto', fontWeight: 'bold', color: 'white', fontSize: '2em' }}><h3>{surveyQuestion.sections.length}</h3></div>
                    <div style={{ fontWeight: 'bold', color: 'black' }}>{t('Sections')}</div>
                  </GridItem>
                  <GridItem xs={6} md={6} style={{ textAlign: 'center' }}>
                    <div style={{ width: 100, height: 100, background: '#56b05a', borderRadius: 50, display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 'auto', fontWeight: 'bold', color: 'white', fontSize: '2em' }}><h3>{surveyResponses.length}</h3></div>
                    <div style={{ fontWeight: 'bold', color: 'black' }}>{t('Responses')}</div>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12}>
            <Card>
              <CardHeader color="success" icon>
                <CardIcon color="success">
                  <Assignment />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>{t('Filter')}</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} md={3}>
                    <FormLabel className={classes.labelHorizontal}>
                      {t('Report Type:')}
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} md={9}>
                    <CustomSelect
                      className={classes.customInput}
                      onChange={type => { this.setFilterData('type', type) }}
                      placeholder="type"
                      value={filterData.type}
                      options={report_types}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} md={3}>
                    <FormLabel className={classes.labelHorizontal}>
                      {t('Choose period:')}
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} md={4}>
                    <FormControl fullWidth>
                      <Datetime
                        closeOnSelect={true}
                        onChange={(m) => this.setFilterData('from1', m.valueOf())}
                        className={classes.customInput}
                        timeFormat={false}
                        value={filterData.from1}
                        inputProps={{ placeholder: t('From') }}
                        dateFormat="DD/MM/YYYY"
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} md={4}>
                    <FormControl fullWidth>
                      <Datetime
                        closeOnSelect={true}
                        onChange={(m) => this.setFilterData('to1', m.valueOf())}
                        className={classes.customInput}
                        value={filterData.to1}
                        timeFormat={false}
                        dateFormat="DD/MM/YYYY"
                        inputProps={{ placeholder: t('To') }}
                      />
                    </FormControl>
                  </GridItem>
                </GridContainer>
                <GridContainer className={filterData.type.value !== report_types[3].value ? classes.hidden : null}>
                  <GridItem xs={12} md={3}>
                    <FormLabel className={classes.labelHorizontal}>
                      {t('Choose Period 2th*:')}
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} md={4}>
                    <FormControl fullWidth>
                      <Datetime
                        closeOnSelect={true}
                        onChange={(m) => this.setFilterData('from2', m.valueOf())}
                        value={filterData.from2}
                        className={classes.customInput}
                        timeFormat={false}
                        dateFormat="DD/MM/YYYY"
                        inputProps={{ placeholder: t('From') }}
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} md={4}>
                    <FormControl fullWidth>
                      <Datetime
                        closeOnSelect={true}
                        onChange={(m) => this.setFilterData('to2', m.valueOf())}
                        value={filterData.to2}
                        className={classes.customInput}
                        timeFormat={false}
                        dateFormat="DD/MM/YYYY"
                        inputProps={{ placeholder: t("To"), }}
                      />
                    </FormControl>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} md={3}>
                    <FormLabel className={classes.labelHorizontal}>
                      {t('Choose groups(contact):')}
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} md={9}>
                    <CustomSelect
                      isMulti={true}
                      className={classes.customInput}
                      onChange={elist => { this.setFilterData('group', elist) }}
                      value={filterData.group}
                      placeholder={t('Groups')}
                      options={emaillist.map((item) => ({ value: item._id, label: item.name }))}
                    />
                  </GridItem>
                  <GridItem xs={12} md={3}>
                    <FormLabel className={classes.labelHorizontal}>
                      {t('Choose section:')}
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} md={9}>
                    <CustomSelect
                      className={classes.customInput}
                      onChange={section => {
                        this.setFilterData('question', null)
                        this.setFilterData('answer', [])
                        this.setFilterData('section', section)
                      }}
                      value={filterData.section}
                      placeholder={t('Section')}
                      options={surveyQuestion.sections.map((item, sectionIndex) => ({ label: item.sectionTitle, value: sectionIndex }))}
                    />
                  </GridItem>
                  <GridItem xs={12} md={3}>
                    <FormLabel className={classes.labelHorizontal}>
                      {t('Choose question:')}
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} md={9}>
                    <CustomSelect
                      className={classes.customInput}
                      onChange={question => {
                        this.setFilterData('answer', [])
                        this.setFilterData('question', question)
                      }}
                      placeholder="Question"
                      value={filterData.question}
                      options={this.getQuestionList()}
                    />
                  </GridItem>
                  <GridItem xs={12} md={3}>
                    <FormLabel className={classes.labelHorizontal}>
                      {t('Choose option:')}
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} md={9}>

                    {<CustomSelect
                      isMulti
                      className={classes.customInput}
                      onChange={answers => {
                        this.setFilterData('answer', answers)
                      }}
                      placeholder={t('Question')}
                      value={filterData.answer}
                      options={options}
                    />}
                  </GridItem>
                  <GridItem>
                    <Button color='danger' onClick={this.onResetFilters}>{t('Reset Filters')}</Button>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem>
            <Button color="success" onClick={() => this.renderReport()}>{t('Send Filter')}</Button>

            <Button color="info" onClick={() => this.generatePdf()}>{t('Print')}</Button>
            <Button color="info" onClick={() => this.generateCSV()}>{t('Export')}</Button>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem>

            {this.renderFilter()}
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} md={12} >
            {this.state.report}
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}


const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps)(withTranslation()(withStyles(styles)(ViewReportPage)));
