import withStyles from '@material-ui/core/styles/withStyles';
import commonStyle from "assets/jss/common/common";
import Button from 'components/CustomButtons/Button';
import CustomSelect from "components/CustomSelect/CustomSelect";
import { t } from 'i18next';
import { default as React } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { question_types } from "redux/config";

const styles = {
    ...commonStyle,
    choiceContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        marginTop: 10
    }
};

class Component extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            questionSchema: null,

            question: {
                type: 'age',
                answertype: '',
                options: [],
            },
        }
    }
    componentDidMount() {
        this.props.onRef(this)
        let questionSchema = question_types.filter((item) => item.type === 'age')[0]
        const question = this.props.question
        this.setState({
            questionSchema: questionSchema,
            question: (question && question.type === questionSchema.type) ? question : {
                type: questionSchema.type,
                answertype: questionSchema.answertypes[0].value,
                options: questionSchema.options,
            }
        })
    }
    getData() {
        return this.state.question
    }

    setQuestionData = (name, value) => {
        let question = this.state.question;
        question[name] = value;
        this.setState({
            question
        })
    }


    render() {
        const { questionSchema, question, } = this.state
        if (!questionSchema) return null
        const { classes } = this.props
        return <div >
            <hr />
            <div className={classes.fontBold}>Choose Answers Type:</div>
            {
                questionSchema.answertypes.map((item, key) => {
                    return <Button
                        key={key}
                        onClick={() => this.setQuestionData('answertype', item.value)}
                        size="sm" color={item.value === question.answertype ? "info" : null}>{item.label}</Button>
                })
            }

            <hr />

            <div className={classes.fontBold}>Preview:</div>
            {
                question.answertype === 'group' && <CustomSelect
                    onChange={country => {
                        // this.setFormData("country", country);
                    }}
                    placeholder={t('Select your Age')}
                    options={question.options}
                />
            }
            {
                question.answertype === 'individual' && <CustomSelect
                    onChange={country => {
                        // this.setFormData("country", country);
                    }}
                    placeholder={t('Select your Age')}
                    options={Array(140).fill(0).map((item, key) => ({ label: key + 14, value: key + 14 }))}
                />
            }
        </div>;
    }
}
const mapStatsToProps = ({ user }) => ({ user });
export default connect(mapStatsToProps)(
    withTranslation()(withStyles(styles, { withTheme: true })(Component))
);