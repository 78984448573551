import withStyles from '@material-ui/core/styles/withStyles';
import commonStyle from "assets/jss/common/common";
import CustomSelect from "components/CustomSelect/CustomSelect";
import { t } from 'i18next';
import ISO6391 from "iso-639-1";
import { default as React } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { question_types } from "redux/config";


const styles = {
    ...commonStyle,
    choiceContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        marginTop: 10
    }
};

class Component extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            questionSchema: null,
            languages: [],
            question: {
                type: 'nation',
            },
        }
    }
    componentDidMount() {
        this.props.onRef(this)
        const languages = ISO6391.getAllNames().map((lang) => ({ label: lang, value: lang }))

        let questionSchema = question_types.filter((item) => item.type === 'nation')[0]
        const question = this.props.question

        this.setState({
            languages,
            questionSchema: questionSchema,
            question: (question && question.type === questionSchema.type) ? question : {
                type: questionSchema.type,
            }
        })
    }
    getData() {
        return this.state.question
    }
    
    setQuestionData = (name, value) => {
        let question = this.state.question;
        question[name] = value;
        this.setState({
            question
        })
    }


    render() {
        const { questionSchema, languages } = this.state
        if (!questionSchema) return null
        const { classes } = this.props
        return <div >
            <hr />

            <div className={classes.fontBold}>{t('Preview')}:</div>
            {
                <CustomSelect
                    onChange={country => {
                        // this.setFormData("country", country);
                    }}
                    placeholder={t('Select your Nationality')}
                    options={languages}
                />
            }
        </div>;
    }
}
const mapStatsToProps = ({ user }) => ({ user });
export default connect(mapStatsToProps)(
   withTranslation()(withStyles(styles, { withTheme: true })(Component))
);