
import { persistCombineReducers } from 'redux-persist'
import storage from 'redux-persist/es/storage'

import user from './user'
import state from "./state";
const config = {
    key: 'root',
    storage,
}

export default persistCombineReducers(config, {
  user,
  state
})