export default {

    noPadding: {
        padding: 0
    },
    noVPadding: {

        paddingTop: 0,
        paddingBottom: 0
    },
    noHPadding: {
        paddingLeft: 0,
        paddingRight: 0
    },
    padding10: {
        padding: 10
    },
    paddingTop10: {
        paddingTop: 10
    },
    paddingTop20: {
        paddingTop: 20
    },
    paddingH5: {
        paddingLeft: 5,
        paddingRight: 5
    },
    paddingLeft10: {
        paddingLeft: 10
    },
    paddingLeft20: {
        paddingLeft: 20
    }
}