import React, { Component, useState, useEffect } from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
import Contacts from "@material-ui/icons/Contacts";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import ViewList from "@material-ui/icons/ViewList";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import Lock from "@material-ui/icons/Lock";
import AccountCircle from "@material-ui/icons/AccountCircle";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Badge from "components/Badge/Badge";
import Table from "components/Table/Table.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import SweetAlert from "react-bootstrap-sweetalert";
import ReactTable from "react-table";

import "react-table/react-table.css";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import { response_detail } from "views/DataDemo.jsx";
import axios from 'axios';
import { connect } from 'react-redux';
import { api_url } from 'redux/config.js'
import Toast from 'views/Toast/Toast';

import { getData as getCountryList } from "country-list";
import CustomSelect from "components/CustomSelect/CustomSelect"
import { CallApi } from "utils/Request";
import { useTranslation } from "react-i18next";
const styles = {
  ...regularFormsStyle
};


function EditCostumerPage(props) {
  const [isLoading, setIsLoading] = useState(false)
  const [countries, setCountries] = useState([])
  const user_id = props.match.params.costumer_id
  const [profileData, setProfileData] = useState(null)
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const { classes } = props;
  const { t } = useTranslation()
  useEffect(() => {
    const countryOptions = getCountryList().map(item => {
      return {
        value: item.code,
        label: item.name
      };
    });
    setCountries(countryOptions)
  }, [])
  useEffect(() => {
    if (user_id) {
      onLoadProfile(user_id)
    }
  }, [user_id])

  const onLoadProfile = (user_id) => {
    setIsLoading(true)
    CallApi({
      url: 'user/profile/' + user_id,
      method: 'get',
    }).then((res) => {
      if (res.success) {
        setProfileData(res.data)
      } else {
        Toast(400, res.error)
      }
    }).finally(() => {
      setIsLoading(false)
    })
  };
  const updateProfileData = (name, value) => {
    setProfileData({
      ...profileData,
      [name]: value
    })
  }
  const onSubmitProfile = () => {
    const { _id, email_verified, __v, role, active, free_response, deleted, password, ...formdata } = profileData
    setIsLoading(true)
    CallApi({
      url: `user/update_profile/` + user_id,
      method: 'post',
      data: formdata
    }).then((res) => {
      if (res.success) {
        Toast(200, t("Successfully updated"))
      } else {
        Toast(400, res.error)
      }
    }).finally(() => {
      setIsLoading(false)
    })
  };
  const updatePassword = () => {

    if (password.length < 6) {
      Toast(400, t('Password should be at least 6 characters')); return;
    }
    if (password !== passwordConfirm) {
      Toast(400, t('Password not matched')); return;
    }
    setIsLoading(true)
    CallApi({
      url: `user/update_password/` + user_id,
      method: 'post',
      data: {
        password: password
      }
    }).then((res) => {
      if (res.success) {
        Toast(200, t("Successfully updated"))
      } else {
        Toast(400, res.error)
      }
    }).finally(() => {
      setIsLoading(false)
    })
  };

  if (!profileData) {
    return null
  }
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <AccountCircle />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t('Update common')}</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem sm={6} xs={12}>
                  <CustomInput
                    labelText={t("First name")}
                    id="Name"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: profileData.first_name,
                      onChange: (e) => updateProfileData('first_name', e.target.value)
                    }}
                  />
                </GridItem>
                <GridItem sm={6} xs={12}>
                  <CustomInput
                    labelText={t("Last name")}
                    id="lastName"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: profileData.last_name,
                      onChange: (e) => updateProfileData('last_name', e.target.value)
                    }}

                  />
                </GridItem>
                <GridItem sm={6} xs={12}>
                  <CustomInput
                    labelText={t("Email")}
                    id="Email"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: profileData.email,
                      onChange: (e) => updateProfileData('email', e.target.value)
                    }}


                  />
                </GridItem>
                <GridItem sm={6} xs={12}>
                  <CustomInput
                    labelText={t("Company Name")}
                    id="CompanyName"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: profileData.company_name,
                      onChange: (e) => updateProfileData('company_name', e.target.value)
                    }}


                  />
                </GridItem>
                <GridItem sm={6} xs={12}>
                  <CustomInput
                    labelText={t("Company Address")}
                    id="CompanyAddress"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: profileData.company_address,
                      onChange: (e) => updateProfileData('company_address', e.target.value)
                    }}
                  />
                </GridItem>
                <GridItem sm={6} xs={12}>
                  <CustomInput
                    labelText={t("Company Tax Number")}
                    id="CompanyTaxNumber"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: profileData.tax_id,
                      onChange: (e) => updateProfileData('tax_id', e.target.value)
                    }}
                  />
                </GridItem>
                <GridItem sm={6} xs={12}>
                  <CustomInput
                    labelText={t("Company Tax Office")}
                    id="CompanyTaxOffice"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: profileData.tax_office,
                      onChange: (e) => updateProfileData('tax_office', e.target.value)
                    }}
                  />
                </GridItem>
                <GridItem sm={4} xs={12}>
                  <CustomSelect
                    onChange={country => {
                      updateProfileData("country", country);
                    }}
                    placeholder="Country"
                    options={countries}
                    value={profileData.country}
                  />
                </GridItem>
                <GridItem sm={4} xs={12}>
                  <CustomInput
                    labelText={t("City")}
                    id="City"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: profileData.city,
                      onChange: (e) => updateProfileData('city', e.target.value)
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button color="info" onClick={() => onSubmitProfile()}>{t('Update')}</Button>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <Lock />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t('Change Password')}</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem sm={4} xs={12}>
                  <CustomInput
                    labelText={t("New Password")}
                    id="NewPassword"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "password",
                      value: password,
                      onChange: (e) => setPassword(e.target.value)
                    }}
                  />
                </GridItem>
                <GridItem sm={4} xs={12}>
                  <CustomInput
                    labelText={t("Confirmation")}
                    id="Confirmation"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "password",
                      value: passwordConfirm,
                      onChange: (e) => setPasswordConfirm(e.target.value)
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button color="info" onClick={() => updatePassword()}>{t('Update')}</Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps)(withStyles(styles)(EditCostumerPage));