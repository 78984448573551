import withStyles from '@material-ui/core/styles/withStyles';
import commonStyle from "assets/jss/common/common";
import CustomSelect from "components/CustomSelect/CustomSelect";
import Danger from "components/Typography/Danger";
import { t } from 'i18next';
import PropTypes from "prop-types";
import { default as React } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { question_types } from "redux/config";

const styles = {
    ...commonStyle
};


class Component extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            questionSchema: null,
            question: null,
            answer: null
        }
        this.initState()
    }
    initState = () => {
        const question = this.props.question
        const answer = this.props.answer
        let questionSchema = question_types.filter((item) => item.type === question.question.type)[0]
        this.state = {
            questionSchema,
            question,
            answer: answer ? answer : {
                id: question.id,
            }
        }
    }
    setAnswerData = (value) => {
        let { answer, question } = this.state;
        answer.value = value
        this.setState({
            answer: answer,
            error: null
        }, () => {
            console.info(answer)
            if (this.props.onChange) {
                this.props.onChange({
                    isValid: this.isValid(), answer: {
                        id: question.id,
                        ...answer
                    }
                })
            }
        })
    }

    isValid = () => {
        const { question, answer } = this.state
        if (question.required && (!answer || !answer)) {
            this.showError(t('This question is required'));
            return false
        }
        return true
    }
    showError = (message) => {
        this.setState({ error: <Danger>*{message}</Danger> })
    }
    render() {
        const { classes } = this.props
        const { questionSchema, error, answer } = this.state
        const question = this.state.question.question

        return <div >
            {error}
            {
                question.answertype === 'group' && <CustomSelect
                    onChange={data => {
                        this.setAnswerData(data.value);
                    }}
                    placeholder={t('Select your Age')}
                    options={questionSchema.options}
                />
            }
            {
                question.answertype === 'individual' && <CustomSelect
                    onChange={data => {
                        this.setAnswerData(data.value);
                    }}
                    placeholder={t('Select your Age')}
                    options={Array(100).fill(0).map((item, key) => ({ label: key + 14, value: key + 14 }))}
                />
            }
        </div>;
    }
}
Component.propTypes = {
    question: PropTypes.object,
    answer: PropTypes.object,
    onChange: PropTypes.func
}
const mapStatsToProps = ({ user }) => ({ user });
export default connect(mapStatsToProps)(
   withTranslation()(withStyles(styles, { withTheme: true })(Component))
);