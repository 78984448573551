import withStyles from "@material-ui/core/styles/withStyles";
import { default as React } from "react";
import axios from "axios";
import { connect } from "react-redux";
import Danger from "components/Typography/Danger";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { withTranslation } from "react-i18next";
import { t } from "i18next";
const styles = {};
class ActivateFailed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        <Card>
          <CardBody>
            <Danger>
              <h1>
                {t('Unfortunately activation failed, please contact support team')}
              </h1>
            </Danger>
          </CardBody>
        </Card>
      </div>
    );
  }
}
const mapStatsToProps = ({ user }) => ({ user });
export default connect(mapStatsToProps)(
  withTranslation()(withStyles(styles, { withTheme: true })(ActivateFailed))
);
