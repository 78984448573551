export default {

    noMargin: {
        margin: 0
    },
    marginTop10: {
        marginTop: 10
    },
    marginH10: {
        marginLeft: '10px !important',
        marginRight: '10px !important'
    },
    marginH5: {
        marginLeft: 5,
        marginRight: 5
    },
    marginV10: {
        marginTop: 10,
        marginBottom: 10
    },
    marginLeft10: {
        marginLeft: 10
    }
}