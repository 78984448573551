import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Footer from "components/Footer/Footer.jsx";

import bgImage from "assets/img/register.jpeg";
import routes from "routes/public.jsx";
import ButterToast, { POS_TOP, POS_CENTER } from "butter-toast";
import LanguageSelector from "components/LanguageSelect/LanguageSelector.js";
import { FadeLoader } from "halogenium";
import Transition from "react-transition-group/Transition";
import { connect } from "react-redux";

const styles = (theme) => ({
  blockUserActivity: {
    width: "100vw",
    height: "100vh",
    background: "#989898",
    opacity: "0.2",
    zIndex: 10000,
    position: "fixed"
  },
  loader: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%,-50%)",
    zIndex: 5000
  },
})
class PublicLayout extends React.Component {
  componentDidMount() {
    document.body.style.overflow = "unset";
  }
  render() {
    const { classes } = this.props;
    const transitionStyles = {
      exiting: { transition: `opacity 300ms ease-in-out`, opacity: 0 }
    };
    return (
      <div>
        <ButterToast
          position={{ vertical: POS_TOP, horizontal: POS_CENTER }}
        />
        <Transition in={this.props.state.loading} unmountOnExit timeout={300}>
          {state => {
            return (
              <div
                style={{
                  ...transitionStyles[state]
                }}
                className={classes.blockUserActivity}
              />
            );
          }}
        </Transition>
        {this.props.state.loading && (
          <FadeLoader
            className={classes.loader}
            color="#26A65B"
            size="16px"
            margin="4px"
          />
        )}
        <Switch>
          {routes.map((prop, key) => {
            return (
              <Route
                path={prop.path}
                component={prop.component}
                key={key}
              />
            );
          })}
        </Switch>
        <Footer fluid />
        {/* <LanguageSelector /> */}

      </div>
    );
  }
}

PublicLayout.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = ({ user, state }) => ({
  user,
  state
});
export default connect(mapStateToProps)(withStyles(styles)(PublicLayout));
