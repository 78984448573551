// core components
import withStyles from "@material-ui/core/styles/withStyles";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import axios from 'axios';
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from 'react-redux';
import { api_url } from 'redux/config.js';
import { verifyEmail } from "utils/Validate";
import Toast from 'views/Toast/Toast';
import SurveyMainComponent from "./components/SurveyMainComponent";
import SurveyProgress from "./components/SurveyProgress";
import SurveyScore from "./components/SurveyScore"
import LanguageSelector from "views/Survey/components/LanguageSelector"
import * as Score from "utils/Score";

import pagesStyle from "layouts/PublicLayout.style";
import { withTranslation } from "react-i18next";
import { t } from "i18next";
import { CallApi } from "utils/Request";
const styles = (theme) => ({
  ...sweetAlertStyle,
  ...pagesStyle(theme),
  surveyImg: {
    width: "100%"
  }
})
class SingleSurveyPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLanguage: '',
      survey_completed: false,
      survey_id: this.props.match.params.survey_id,
      survey_type: this.props.match.params.survey_type, // email, nocondition, password
      data: null,
      takenAlready: false,
      email: '',
      freeid: '',
      conditionInput: {

      }
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.style.overflow = "unset";
    this.onLoad(this.state.survey_id);
    if (this.state.survey_type !== 'nocondition') {
      this.basicAlert();
    }
  }

  onComplete = (answers) => {
    let self = this;
    const url = api_url + `survey/complete`;
    const header = 'Bearer '.concat(this.props.user.token);
    let headers = null;
    let data = {
      survey: this.state.data._id,
      survey_type: this.state.survey_type,
      email: this.state.conditionInput.email,
      freeid: this.state.conditionInput.freeid,
      password: this.state.conditionInput.password,
      answers: answers,
      language: this.state.data.main[0].language
    }

    if (this.props.user.token) {
      headers = {
        Authorization: header
      };
    }
    this.props.dispatch({ type: 'LOAD_START' });
    axios({
      url,
      method: 'post',
      headers,
      data
    })
      .then(function (response) {
        if (response.data.success) {
          self.setState({
            survey_completed: true,
            answers: answers
          })
          self.sendRateMessage(data.survey, response.data.newresponse._id, answers)

        } else {
          Toast(400, response.data.error)
        }
        self.props.dispatch({ type: 'LOAD_END' });
      })
      .catch(function (errors) {
        console.info(errors);
        self.props.dispatch({ type: 'LOAD_END' });
        if (!errors.response) {
          Toast(500);
          return;
        }
        Toast(errors.response.status);
      });
  };
  sendRateMessage = (survey_id, response_id, answers) => {
    const { data, selectedLanguage } = this.state

    const dataLang = this.getDataOfLang(selectedLanguage)

    const questions = dataLang.questions
    CallApi({
      url: 'rate_message/send_message',
      method: 'post',
      data: {
        survey: survey_id,
        response_id: response_id,
        score: Score.getScoreOfOneResponse(questions, answers)
      }
    }).then((res) => {
      console.info('=== called rate message ===', JSON.stringify(res, null, 4))
    })
  }
  onLoad = (survey_id) => {
    let self = this;
    const url = api_url + `survey/get`;
    const params = {
      id: survey_id
    }

    this.props.dispatch({ type: 'LOAD_START' });
    axios({
      url,
      method: 'get',
      params
    })
      .then(function (response) {
        if (response.data.success) {
          self.setState({
            data: response.data.data
          });
        } else {
          Toast(400, response.data.error);
        }
        self.props.dispatch({ type: 'LOAD_END' });
      })
      .catch(function (errors) {
        console.info(errors);
        self.props.dispatch({ type: 'LOAD_END' });
        if (!errors.response) {
          Toast(500);
          return;
        }
        Toast(errors.response.status);
      });
  };


  confirmCondition = () => {
    const survey_type = this.state.survey_type
    if (survey_type === 'email') {
      if (!verifyEmail(this.state.conditionInput.email)) {
        Toast(400, t('Invalid email format'))
      } else {
        this.hideAlert()
      }
    }

    if (survey_type === 'password') {
      const passwords = this.state.data.passwords
      if (!passwords || passwords.length === 0) return
      const matches = passwords.filter((item) => item.password == this.state.conditionInput.password);
      if (matches.length > 0) {
        const matched = matches[0];
        if (matched.used) {
          Toast(400, t('This password already used'))
          return
        }
        const now = Date.now();
        if (now > new Date(matched.expire_date)) {
          Toast(400, t('Password expired')); return
        }
        this.hideAlert()
      } else {
        Toast(400, t('Incorrect password'))
      }
    }
    if (survey_type === 'freeid') {
      this.hideAlert()
    }
  }
  basicAlert = () => {
    const survey_type = this.state.survey_type
    let title = '';
    let label = '';
    if (survey_type === 'email') {
      title = t('Please enter email')
      label = t('Email')
    }

    if (survey_type === 'freeid') {
      title = t('Answer by ID free choice')
      label = t('Free ID')
    }
    if (survey_type === 'password') {
      title = t('Please enter password')
      label = t('Password')
    }
    this.setState({
      alert: (
        <SweetAlert
          style={{
            display: "block",
            top: "20%",
            marginTop: 0,
            color: 'black'
          }}
          title={title}
          showConfirm={true}
          onConfirm={() => this.confirmCondition()}

          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        >

          <CustomInput
            labelText={label}
            id="campaignname"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "text",
              onChange: (e) => this.setState({
                conditionInput: {
                  [survey_type]: e.target.value
                }
              }),
            }}
          />
        </SweetAlert>
      )
    });
  };
  hideAlert() {
    this.setState({
      alert: null
    });
  }
  onChangeLanguage = (language) => {
    this.setState({
      selectedLanguage: language
    })
  }
  getDataOfLang = (language) => {
    const { data, } = this.state

    let main = data.main[0]
    let questions = data.questions[0]
    if (language.length > 0) {
      let mainfiltered = data.main.filter(item => item.language === language)[0]
      let questionsfiltered = data.questions.filter(item => item.language === language)[0]
      main = mainfiltered ? mainfiltered : data.main[0]
      questions = questionsfiltered ? questionsfiltered : data.main[0]
    }
    return {
      main, questions
    }
  }
  render() {
    const { classes } = this.props;
    const { data, survey_completed, answers, selectedLanguage } = this.state

    if (!data) return <div>{t('Loading')} ...</div>;
    const dataLang = this.getDataOfLang(selectedLanguage)
    const mainData = {
      image_url: data.image_url,
      ...dataLang.main
    }
    const questions = dataLang.questions

    if (survey_completed) {
      return <div className={classes.wrapper} ref="wrapper">
        <div className={classes.fullPage}><SurveyScore endMessages={data.endmessages} answers={answers} questions={questions} />
        </div>
      </div>
    }
    return (

      <div className={classes.wrapper} ref="wrapper">
        <div className={classes.fullPage}>
          <GridContainer>
            {this.state.alert}
            <GridItem sm={12} xs={12}>
              <LanguageSelector direction={'horizontal'} value={dataLang.main.language} languages={data.main.map(item => item.language)} onChange={(language) => this.onChangeLanguage(language)} />
              <SurveyMainComponent data={mainData} />
            </GridItem>
            <GridItem sm={12} xs={12}>
              <SurveyProgress doRefresh={selectedLanguage} questions={questions} saveAnswer={(answers) => this.onComplete(answers)} />
            </GridItem>
          </GridContainer>
        </div></div>
    );
  }
}

SingleSurveyPage.propTypes = {
  classes: PropTypes.object.isRequired
};


const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps)(withTranslation()(withStyles(styles)(SingleSurveyPage)));