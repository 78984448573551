import withStyles from '@material-ui/core/styles/withStyles';
import Assignment from "@material-ui/icons/Assignment";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Table from "components/Table/Table.jsx";
import moment from "moment";
import { default as React } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
const styles = {};
class CampaignContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null
        }
    }
    componentDidUpdate(prevprops) {
        if (prevprops.campaign_data != this.props.campaign_data) {
            this.setState({
                data: this.props.campaign_data
            })
        }
    }

    getSendDate = (data) => {
        const { t } = this.props
        if (data.sender_type === 'daily') {
            return t('Daily')
        }
        return moment.unix(data.start_date).format("MMMM Do YYYY")
    }
    render() {
        const { classes,  t } = this.props;
        const data = this.state.data;
        if (!data) return null;
        return <div >
            <Card>
                <CardHeader color="rose" icon>
                    <CardIcon color="rose">
                        <Assignment />
                    </CardIcon>
                </CardHeader>
                <CardBody>
                    <Table
                        tableHeaderColor="primary"
                        tableData={[
                            [
                                <b>{t('Campaign name')}</b>,
                                data.campaign_name
                            ],
                            [
                                <b>{t('Send date')}</b>,
                                this.getSendDate(data)
                            ],
                            [
                                <b>{t('Survey')}</b>,
                                data.survey.main[0].title
                            ],
                            [
                                <b>{t('Language')}</b>,
                                data.language
                            ],
                            [
                                <b>{t('Email list')}</b>,
                                data.email_list ? data.email_list.name : ''
                            ],
                            [
                                <b>{t('Message')}</b>,
                                <div dangerouslySetInnerHTML={{ __html: data.message }}></div>

                            ],
                            [
                                <b>{t('Personalized Link')}</b>,
                                data.personalized_link
                            ],
                            [
                                <b>{t('Additional Emails')}</b>,
                                data.additional_emails.join()
                            ],
                            [
                                <b>{t('Email Sender')}</b>,
                                data.email_sender
                            ],
                            [
                                <b>{t('Subject')}</b>,
                                data.email_subject
                            ],
                        ]}
                        coloredColls={[3]}
                        colorsColls={["primary"]}
                    />
                </CardBody>
            </Card>
        </div>;
    }
}
const mapStatsToProps = ({ user }) => ({ user });
export default connect(mapStatsToProps)(
    withTranslation()(withStyles(styles, { withTheme: true })(CampaignContent))
);