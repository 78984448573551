// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import ViewList from "@material-ui/icons/ViewList";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { TransReactTable } from "components/CustomReactTable/TransReactTable";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import ReactTable from "react-table";
import "react-table/react-table.css";

const column = [{
  Header: "Title",
  accessor: "title"
},
{
  Header: "Name",
  accessor: "name"
},
{
  Header: "Email",
  accessor: "email"
},
{
  Header: "Action",
  accessor: "action"
}
];
const styles = {
  ...regularFormsStyle,
  ...sweetAlertStyle
};
class EmaillistTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emails: []
    };
  }
  componentDidMount() {
    this.renderEmail(this.props.emails)
  }
  componentDidUpdate(prevProps) {
    if (prevProps.emails !== this.props.emails) {
      this.renderEmail(this.props.emails)
    }
  }
  removeEmail = (key) => {
    const { t } = this.props

    if (!window.confirm(t('Are you sure?'))) return;
    let emails = this.state.emails;
    emails.splice(key, 1);
    this.props.removeEmail(emails)
  }
  renderEmail = (emails) => {
    const { t } = this.props
    let emailcomps = [];
    if (emails) {
      emailcomps = emails.map((item, key) => {
        return {
          ...item,
          action: <Button color="danger" size="sm" onClick={() => this.removeEmail(key)}>{t('Delete')}</Button>
        }
      })
    }
    this.setState({
      emails: emailcomps
    })

  }
  downloadCSV = () => {
    const rows = this.state.emails.map((email) => {
      return [email.email, email.name]
    })
    let csvContent = "data:text/csv;charset=utf-8,"
      + rows.map(e => e.join(",")).join("\n");
    var encodedUri = encodeURI(csvContent);
    window.open(encodedUri);

  }


  render() {
    const { classes, t } = this.props;
    return (
      <div>
        {this.state.alert}
        <Card className={classes.zeroZindex}>
          <CardHeader color="success" icon>
            <CardIcon color="success">
              <ViewList />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{t('E-mails')}</h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12}>
                <Button
                  size="sm"
                  color="danger"
                  onClick={() => {
                    this.props.addEmail();
                  }}
                >
                  {t('Add new email in list')}
                </Button>

                <Button size="sm" color="info" onClick={() => { this.downloadCSV() }}>{t('Download CSV')}</Button>
                <Button size="sm" color="info" onClick={() => { this.props.uploadCSV() }}>{t('Upload CSV')}</Button>
              </GridItem>
              <GridItem xs={12} sm={12}>
                <TransReactTable
                  defaultPageSize={5}
                  data={this.state.emails}
                  columns={column}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </div>
    );
  }
}
const mapStatsToProps = ({ user }) => ({ user });
export default connect(mapStatsToProps)(
  withTranslation()(withStyles(styles, { withTheme: true })(EmaillistTable))
);
