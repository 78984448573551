// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AccountCircle from "@material-ui/icons/AccountCircle";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { getData as getCountryList } from "country-list";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import "react-table/react-table.css";
import { CallApi } from "utils/Request";
import { verifyEmail } from "utils/Validate";
import Toast from 'views/Toast/Toast';

const styles = {
  ...regularFormsStyle
};


function CreateCostumerPage(props) {
  const [isLoading, setIsLoading] = useState(false)

  const [countries, setCountries] = useState([])
  const [profileData, setProfileData] = useState({
    username: "",
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    password_confirm: "",
    company_name: "",
    company_address: "",
    tax_id: "",
    tax_office: "",
    country: null,
    city: ""
  })

  const { classes } = props;

  useEffect(() => {
    const countryOptions = getCountryList().map(item => {
      return {
        value: item.code,
        label: item.name
      };
    });
    setCountries(countryOptions)
  }, [])
  const verfiyForm = () => {
    const { username, email, password, password_confirm } = profileData;
    if (username.length === 0) {
      Toast(400, t('Please enter user name'));
      return false;
    }
    if (!verifyEmail(email)) {
      Toast(400, t('Invalid email address!'));
      return false;
    }
    if (password.length < 6) {
      Toast(400, t('Password should be at least 6 letters'));
      return false;
    }
    if (password !== password_confirm) {
      Toast(400, t('Password confirm mismatched'));
      return false;
    }
    return true;
  };
  const updateProfileData = (name, value) => {
    setProfileData({
      ...profileData,
      [name]: value
    })
  }
  const onSubmitProfile = () => {
    if (!verfiyForm()) {
      console.info(profileData)
      return
    }
    setIsLoading(true)
    const data = {
      ...profileData,
      email_verified: true
    }
    CallApi({
      url: `auth/admin/create_user`,
      method: 'post',
      data: data
    }).then((res) => {

      if (res.success) {
        props.history.replace('/admin/costumers')
        Toast(200, t('Successfully updated'))
      } else {
        Toast(400, res.error)
      }

    }).finally(() => {
      setIsLoading(false)
    })
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <AccountCircle />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t('Update common')}</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem sm={6} xs={12}>
                  <CustomInput
                    labelText={t('Username')}
                    id="Username"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: profileData.username,
                      onChange: (e) => updateProfileData('username', e.target.value)
                    }}
                  />
                </GridItem>
                <GridItem sm={6} xs={12}>
                  <CustomInput
                    labelText={t("First name")}
                    id="Name"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: profileData.first_name,
                      onChange: (e) => updateProfileData('first_name', e.target.value)
                    }}
                  />
                </GridItem>
                <GridItem sm={6} xs={12}>
                  <CustomInput
                    labelText={t("Last name")}
                    id="lastName"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: profileData.last_name,
                      onChange: (e) => updateProfileData('last_name', e.target.value)
                    }}

                  />
                </GridItem>
                <GridItem sm={6} xs={12}>
                  <CustomInput
                    labelText={t("Email")}
                    id="Email"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: profileData.email,
                      onChange: (e) => updateProfileData('email', e.target.value)
                    }}


                  />
                </GridItem>
                <GridItem sm={6} xs={12}>
                  <CustomInput
                    labelText={t("Company Name")}
                    id="CompanyName"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: profileData.company_name,
                      onChange: (e) => updateProfileData('company_name', e.target.value)
                    }}


                  />
                </GridItem>
                <GridItem sm={6} xs={12}>
                  <CustomInput
                    labelText={t("Company Address")}
                    id="CompanyAddress"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: profileData.company_address,
                      onChange: (e) => updateProfileData('company_address', e.target.value)
                    }}
                  />
                </GridItem>
                <GridItem sm={6} xs={12}>
                  <CustomInput
                    labelText={t("Company Tax Number")}
                    id="CompanyTaxNumber"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: profileData.tax_id,
                      onChange: (e) => updateProfileData('tax_id', e.target.value)
                    }}
                  />
                </GridItem>
                <GridItem sm={6} xs={12}>
                  <CustomInput
                    labelText={t("Company Tax Office")}
                    id="CompanyTaxOffice"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: profileData.tax_office,
                      onChange: (e) => updateProfileData('tax_office', e.target.value)
                    }}
                  />
                </GridItem>
                <GridItem sm={6} xs={12}>
                  <CustomSelect
                    onChange={country => {
                      updateProfileData("country", country);
                    }}
                    placeholder="Country"
                    options={countries}
                    value={profileData.country}
                  />
                </GridItem>
                <GridItem sm={6} xs={12}>
                  <CustomInput
                    labelText={t("City")}
                    id="City"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "text",
                      value: profileData.city,
                      onChange: (e) => updateProfileData('city', e.target.value)
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem sm={6} xs={12}>
                  <CustomInput
                    labelText={t("New Password")}
                    id="NewPassword"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "password",
                      value: profileData.password,
                      onChange: (e) => updateProfileData('password', e.target.value)
                    }}
                  />
                </GridItem>
                <GridItem sm={4} xs={12}>
                  <CustomInput
                    labelText={t("Confirmation")}
                    id="Confirmation"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "password",
                      value: profileData.password_confirm,
                      onChange: (e) => updateProfileData('password_confirm', e.target.value)
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button color="info" onClick={() => onSubmitProfile()}>{t('Save')}</Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps)(withTranslation()(withStyles(styles)(CreateCostumerPage)));