import withStyles from "@material-ui/core/styles/withStyles";
import { default as React } from "react";
import axios from "axios";
import { connect } from "react-redux";
import Success from "components/Typography/Success";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { withTranslation } from "react-i18next";
import { t } from "i18next";
const styles = {};
class ActivateSuccess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        <Card>
          <CardBody>
            <Success>
              <h1>{t('Successfully activated, you can use account now.')}</h1>
            </Success>
            <Button href="/auth/login" color="primary">
              {t('Log in')}
            </Button>
          </CardBody>
        </Card>
      </div>
    );
  }
}
const mapStatsToProps = ({ user }) => ({ user });
export default connect(mapStatsToProps)(
  withTranslation()(withStyles(styles, { withTheme: true })(ActivateSuccess))
);
