/*eslint-disable*/
import React, { useState } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classnames from "classnames";
import { makeStyles } from '@material-ui/core';
import { LANGUAGES } from "translations/i18n";
import Button from "components/CustomButtons/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { changeLanguage } from "i18next";
// import i18n from "translations/i18n";

const useStyles = makeStyles((theme) => {
  return {
    fixed: {
      position: 'fixed',
      top: 180,
      right: 0,
      zIndex: 1031,
      borderRadius: '8px 0 0 8px',
      textAlign: 'center',
    },
    toggleBtnContainer: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    toggleBtn: {
      borderRadius: '8px 0 0 8px',
      padding: 10,
      backgroundColor: '#0000004d'
    },
    menu: {
      position: 'absolute',
      width: 200,
      backgroundColor: 'white',
      border: '1px solid',
      borderRadius: 8,
      top: 0,
      right: 80
    }
  }
})
export default function LanguageSelector(props) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const handleClick = () => {
    setOpen(!open)
  };
  return (
    <ClickAwayListener onClickAway={()=>setOpen(false)}>
      <div className={classes.fixed}>

        <div >
          <div onClick={handleClick} className={classes.toggleBtnContainer}>
            <div className={classes.toggleBtn}>
              <i className="fa fa-cog fa-2x" />
            </div>
          </div>
          {
            open && <div className={classes.menu}>
              {
                LANGUAGES.map((ln, key) => {
                  return <Button simple color='info' onClick={() => {
                    setOpen(false)
                    changeLanguage(ln.value)
                  }} key={key}>
                    {ln.label}
                  </Button>
                })
              }
            </div>
          }
        </div>
      </div>
    </ClickAwayListener>
  );
}

LanguageSelector.propTypes = {

};
