import withStyles from '@material-ui/core/styles/withStyles';
import Assignment from "@material-ui/icons/Assignment";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import axios from 'axios';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from "components/Card/CardIcon.jsx";
import Button from 'components/CustomButtons/Button';
import { t } from 'i18next';
import { default as React } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { api_url } from 'redux/config.js';
import TitleBox from "views/Shared/TitleBox";
import Toast from "views/Toast/Toast";
import EditModal from './EditModal';

const styles = { ...regularFormsStyle };
class Component extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            endmessages: this.props.endmessages ? this.props.endmessages : [],
            survey_id: this.props.survey_id
        }
    }
    duplicateIndex = (data) => {
        let endmessages = this.state.endmessages
        const { from, to, message } = data
        for (let index = 0; index < endmessages.length; index++) {
            const element = endmessages[index];

            if (element.from > from && element.from < to) {
                return index
            }
            if (element.to > from && element.to < to) {
                return index
            }
            if (element.to === to && element.from === from) {
                return index
            }
        }
        return -1
    }
    saveEndMessage = (data) => {
        let endmessages = this.state.endmessages
        const { from, to, message } = data
        if (from >= to || from < 0) {
            Toast(400, t('Invalid from or to value'));
            return
        } else {
            if (this.duplicateIndex(data) === -1) {
                endmessages.push(data)
                this.onSave(endmessages)
            } else {
                Toast(400, t('Already existing from~to range'));
            }
        }
    }
    editEndMessage = (key, data) => {
        if (this.duplicateIndex(data) === key) {
            let endmessages = this.state.endmessages;
            endmessages[key] = data;
            this.onSave(endmessages)
        } else {
            Toast(400, t('Already existing from~to range'));
        }
    }
    onSave = (endmessages) => {
        let self = this;
        const url = api_url + `survey/update`;
        const header = 'Bearer '.concat(this.props.user.token);
        let headers = null;
        if (this.props.user.token) {
            headers = {
                Authorization: header
            };
        }
        this.props.dispatch({ type: 'LOAD_START' });
        axios({
            url,
            method: 'post',
            headers,
            data: {
                id: this.state.survey_id,
                updateData: {
                    endmessages
                }
            }
        })
            .then(function (response) {
                self.setState({
                    endmessages: endmessages
                });
                self.setState({ alert: null })

                self.props.dispatch({ type: 'LOAD_END' });
            })
            .catch(function (errors) {
                console.info(errors);
                self.props.dispatch({ type: 'LOAD_END' });
                if (!errors.response) {
                    Toast(500, t('Connection error!'));
                    return;
                }
                Toast(errors.response.status);
            });
    };
    onRemove = (key) => {
        let endmessages = this.state.endmessages
        if (window.confirm(t('Are you sure?'))) {
            endmessages.splice(key, 1);
            this.onSave(endmessages)
        }
    }
    render() {
        const { classes } = this.props
        const endmessages = this.state.endmessages
        return <div >
            {this.state.alert}
            <Card>
                <CardHeader color="success" icon>
                    <CardIcon color="success">
                        <Assignment />
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>{t('End Messages')}</h4>
                </CardHeader>
                <CardBody className={classes.overflowAuto}>

                    {
                        endmessages.map((item, key) => {
                            return <TitleBox
                                key={key}
                                title={<div>{item.from} ~ {item.to}</div>}
                                rightButton={<div>
                                    <Button simple size="sm" onClick={() => this.onRemove(key)}>{t('Remove')}</Button>
                                    <Button simple size="sm" onClick={() => this.setState({
                                        alert: <EditModal
                                            endmessage={item}
                                            handleSave={(data) => this.editEndMessage(key, data)}
                                            handleClose={() => this.setState({ alert: null })} />
                                    })}
                                    >{t('Edit')}</Button>
                                </div>}
                            >

                                <div dangerouslySetInnerHTML={{ __html: item.message }} />
                            </TitleBox>
                        })
                    }
                </CardBody>
                <CardFooter>
                    <Button
                        color="info"
                        onClick={() => this.setState({ alert: <EditModal handleSave={(data) => this.saveEndMessage(data)} handleClose={() => this.setState({ alert: null })} /> })}
                    >
                        {t('Add New End Message')}
                    </Button>
                </CardFooter>
            </Card>
        </div>;
    }
}
const mapStatsToProps = ({ user }) => ({ user });
export default connect(mapStatsToProps)(
    withTranslation()(withStyles(styles, { withTheme: true })(Component))
);