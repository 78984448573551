// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Switch from "@material-ui/core/Switch";
import Assignment from "@material-ui/icons/Assignment";
import Create from "@material-ui/icons/Create";
import Edit from "@material-ui/icons/Edit";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import commonStyle from "assets/jss/common/common";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import axios from "axios";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect";
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { t } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import "react-table/react-table.css";
import { api_url } from "redux/config.js";
import { getAllLanguages } from "utils/Languages";
import InputModal from "views/Shared/InputModal";
import TitleBox from "views/Shared/TitleBox";
import Toast from "views/Toast/Toast";
import RenderQuestion from "../RenderQuestions/RenderQuestion";
import EndMessageSection from "./components/EndMessageSection";




const SortableItem = sortableElement(({ value }) => <div>{value}</div>);

const SortableContainer = sortableContainer(({ children }) => {
  return <div>{children}</div>;
});


// survey part end
const styles = {
  ...regularFormsStyle,
  descriptionTextArea: {
    width: "100%",
    height: 150,
    borderColor: `#D2D2D2 !important`,
    borderWidth: `1px !important`,
    borderRadius: 4,
    fontSize: 14,
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: 400,
    color: `#495057`
  },
  overflowAuto: {
    overflow: "auto"
  }
};

class SurveyEditPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.survey_id,
      image_file: null,
      image_url: '',
      endmessages: [],
      mainData: [],
      languages: getAllLanguages(),
      surveyQuestions: [],

      selectedLanguage: 'en',
      available_languages: [],
      alert: null
    };
  }
  componentDidMount() {

    this.onLoad()
  }


  verifyMainData = () => {
    const { mainData, selectedLanguage } = this.state
    let mainDataOfCurrentLanguage = mainData.filter((item) => item.language === selectedLanguage)[0]
    const { title } = mainDataOfCurrentLanguage;
    if (title.length === 0) {
      Toast(400, t('Please set title'));
      return false;
    }
    return true;
  };
  saveSurvey = async () => {
    if (this.verifyMainData()) {
      this.props.dispatch({ type: "LOAD_START" });

      if (this.state.image_file) {
        const fileData = new FormData();
        fileData.append("file", this.state.image_file);
        const result = await axios({
          url: api_url + "utils/file_upload",
          method: "post",
          data: fileData
        });
        if (result.data.success) {
          this.setState({
            image_url: result.data.file_url
          })
          this.updateSurvey({ image_url: result.data.file_url, main: this.state.mainData })
        } else {
          Toast(400, result.data.error);
        }
      } else {
        this.updateSurvey({ main: this.state.mainData })

      }

      this.props.dispatch({ type: "LOAD_END" });
    }
  };
  updateSurvey = async (updateData) => {
    this.props.dispatch({ type: "LOAD_START" });

    const result = await axios({
      url: api_url + "survey/update",
      method: "post",
      headers: {
        authorization: "Bearer ".concat(this.props.user.token)
      },
      data: {
        id: this.state.id,
        updateData: updateData
      }
    });
    this.props.dispatch({ type: "LOAD_END" });
    if (result.data.success) {
      Toast(200, t('Updated successfully'));
    } else {
      Toast(400, result.data.error);
    }
  };
  onLoad = () => {
    let self = this;
    const id = this.props.match.params.survey_id;
    const url = api_url + `survey/get`;
    const header = "Bearer ".concat(this.props.user.token);
    let headers = null;
    if (this.props.user.token) {
      headers = {
        Authorization: header
      };
    }
    this.props.dispatch({ type: "LOAD_START" });
    axios({
      url,
      method: "get",
      params: {
        id: id
      },
      headers: {
        authorization: "Bearer ".concat(this.props.user.token)
      }
    })
      .then(function (response) {
        if (!response.data.success) {
          Toast(400, response.data.error);
        } else {
          const data = response.data.data
          self.setState({
            endmessages: data.endmessages,
            mainData: data.main,
            surveyQuestions: data.questions,
            selectedLanguage: data.main[0].language,
            available_languages: data.main.map(m => m.language),
            image_url: data.image_url
          });
        }
        self.props.dispatch({ type: "LOAD_END" });
      })
      .catch(function (errors) {
        console.info(errors);
        self.props.history.replace('/')
        self.props.dispatch({ type: "LOAD_END" });
        Toast(500);
      });
  };
  setMainData = (name, value) => {

    const { mainData, selectedLanguage } = this.state
    let newMainData = mainData.map((item) => {
      if (item.language === selectedLanguage) {
        item[name] = value
      }
      return item
    })
    this.setState({
      mainData: newMainData
    });
  };
  createNewSection = (title) => {
    const surveyQuestions = this.state.surveyQuestions
    const newSurveyQuestions = surveyQuestions.map((question) => {
      let sections = [].concat(question.sections)
      sections.push({
        questions: [],
        display: true,
        sectionTitle: title
      })
      return {
        language: question.language,
        sections: sections
      }
    })
    this.setState({
      surveyQuestions: newSurveyQuestions,
      alert: null
    }, () => this.updateSurvey({ questions: newSurveyQuestions }))
  }
  getSectionTitle = () => {
    this.setState({
      alert: <InputModal title={t('Enter Section Title')} handleSave={(title) => this.createNewSection(title)} handleClose={() => this.setState({ alert: null })} />
    })
  };


  onSectionSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) return
    const surveyQuestions = this.state.surveyQuestions
    const newSurveyQuestions = surveyQuestions.map((question) => {
      let sections = question.sections
      const temp = sections[oldIndex];
      sections[oldIndex] = sections[newIndex]
      sections[newIndex] = temp
      return {
        language: question.language,
        sections: sections
      }
    })
    this.setState({
      surveyQuestions: newSurveyQuestions,
      alert: null
    }, () => this.updateSurvey({ questions: newSurveyQuestions }))
  };
  onQuestionSortEnd = (sectionIndex, oldIndex, newIndex) => {
    if (oldIndex === newIndex) return
    const surveyQuestions = this.state.surveyQuestions
    const newSurveyQuestions = surveyQuestions.map((question) => {
      let sections = question.sections
      let section = sections[sectionIndex]
      let questions = section.questions;

      const temp = questions[oldIndex];
      questions[oldIndex] = questions[newIndex]
      questions[newIndex] = temp
      return {
        language: question.language,
        sections: sections
      }
    })
    this.setState({
      surveyQuestions: newSurveyQuestions,
      alert: null
    }, () => this.updateSurvey({ questions: newSurveyQuestions }))
  };
  setVisibleSector = (sectionKey, visible) => {
    const surveyQuestions = this.state.surveyQuestions
    const newSurveyQuestions = surveyQuestions.map((question) => {
      let sections = question.sections
      sections[sectionKey].display = visible
      return {
        language: question.language,
        sections: sections
      }
    })
    this.setState({
      surveyQuestions: newSurveyQuestions,
    }, () => this.updateSurvey({ questions: newSurveyQuestions }))
  }


  updateSectorTitle = (sectionKey, value) => {
    const surveyQuestions = this.state.surveyQuestions
    const newSurveyQuestions = surveyQuestions.map((question) => {
      let sections = question.sections
      sections[sectionKey].sectionTitle = value
      return {
        language: question.language,
        sections: sections
      }
    })
    this.setState({
      surveyQuestions: newSurveyQuestions,
    }, () => this.updateSurvey({ questions: newSurveyQuestions }))
  }
  removeQuestion = (sectionIndex, questionIndex) => {
    if (!window.confirm(t('Are you sure?'))) return
    const surveyQuestions = this.state.surveyQuestions

    const newSurveyQuestions = surveyQuestions.map((question) => {
      let sections = question.sections
      let section = sections[sectionIndex]
      section.questions.splice(questionIndex, 1)

      return {
        language: question.language,
        sections: sections
      }
    })

    this.setState({
      surveyQuestions: newSurveyQuestions,
      alert: null
    }, () => this.updateSurvey({ questions: newSurveyQuestions }))
  }
  changeLanguage = (language) => {
    const { surveyQuestions, mainData } = this.state
    let newSurveyQuestions = []
    let otherSurveyQuestions = surveyQuestions.filter(q => q.language != language)
    let questionOfCurrentLanguage = surveyQuestions.filter((q) => q.language === language)[0]
    newSurveyQuestions = [questionOfCurrentLanguage, ...otherSurveyQuestions]

    let newMainData = []
    let otherMainData = mainData.filter(q => q.language != language)
    let mainDataOfCurrentLanguage = mainData.filter((q) => q.language === language)[0]
    newMainData = [mainDataOfCurrentLanguage, ...otherMainData]

    this.setState({ selectedLanguage: language, mainData: newMainData, surveyQuestions: newSurveyQuestions }, () => this.updateSurvey({
      main: newMainData,
      questions: newSurveyQuestions
    }))

  }
  render() {
    if (!this.state.mainData || this.state.mainData.length === 0) return null;
    const { classes } = this.props;
    const { id, languages, image_url, mainData, endmessages, surveyQuestions, selectedLanguage, available_languages } = this.state
    if (!surveyQuestions) {
      this.props.history.replace('/')
      return null
    }
    let mainDataOfCurrentLanguage = mainData.filter((item) => item.language === selectedLanguage)[0]
    let questionOfCurrentLanguage = surveyQuestions.filter((item) => item.language === selectedLanguage)[0]
    const { title, description, language } = mainDataOfCurrentLanguage;
    const available_language_options = languages.filter(lang => available_languages.includes(lang.value))
    return (
      <div>
        {this.state.alert}
        <GridContainer>
          <GridItem xs={12} sm={12}>
            <Card>
              <CardHeader color="success" icon>
                <CardIcon color="success">
                  <Edit />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>{t('Edit survey')}</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} md={3}>
                    <br />
                    <ImageUpload
                      addButtonProps={{
                        color: "rose",
                        round: true
                      }}
                      changeButtonProps={{
                        color: "rose",
                        round: true
                      }}
                      removeButtonProps={{
                        color: "danger",
                        round: true
                      }}
                      defaultImage={image_url}
                      handleImageChange={file =>
                        this.setState({
                          image_file: file
                        })
                      }
                    />
                  </GridItem>
                  <GridItem xs={12} md={9}>
                    <CustomInput
                      labelText={t('Title')}
                      id="Title"
                      formControlProps={{
                        onChange: e =>
                          this.setMainData("title", e.target.value),
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "text",
                        value: title
                      }}
                    />
                    <textarea
                      className={classes.descriptionTextArea}
                      placeholder={t('Description')}
                      onChange={e =>
                        this.setMainData("description", e.target.value)
                      }
                      value={description}
                    />
                    <br />
                    <p><b>Choose default language:</b></p>
                    <CustomSelect
                      onChange={country => {
                        this.changeLanguage(country.value)
                      }}
                      placeholder={t('Language')}
                      value={languages.filter((item) => item.value === selectedLanguage)[0]}
                      options={available_language_options}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button color="info" onClick={() => this.saveSurvey()}>
                  {t('Save')}
                </Button>
                <Button color="success" onClick={() => this.getSectionTitle()}>
                  {t('New Section')}
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12}>
            <EndMessageSection endmessages={endmessages} survey_id={this.state.id} />
          </GridItem>
          <GridItem xs={12} sm={12}>
            <Card>
              <CardHeader color="success" icon>
                <CardIcon color="success">
                  <Assignment />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>{t('Edit question')}</h4>
              </CardHeader>
              <CardBody>
                <SortableContainer onSortEnd={this.onSectionSortEnd} pressDelay={200}>
                  {
                    questionOfCurrentLanguage.sections.map((item, key) => {
                      const value = <TitleBox onSaveTitle={(newtitle) => { this.updateSectorTitle(key, newtitle) }}
                        titleEditable={true} title={item.sectionTitle} rightButton={<div>
                          <Switch
                            checked={item.display}
                            onChange={e => {
                              this.setVisibleSector(key, e.target.checked)
                            }}
                            value={`${key}`}
                            color="primary"
                            classes={{

                            }}
                          />
                        </div>} color={commonStyle.whitebackgroundColor} textColor={commonStyle.blackColor}>
                        <SortableContainer onSortEnd={({ oldIndex, newIndex }) => {
                          this.onQuestionSortEnd(key, oldIndex, newIndex)
                        }} pressDelay={200}>
                          {
                            item.questions.map((qitem, qkey) => {
                              const qvalue = <TitleBox key={qkey}
                                rightButton={<div>
                                  <Button justIcon color="info" size="sm" onClick={() => this.props.history.push(`/survey/${id}/${key}/${qkey}/question/edit`)}><Create /></Button>
                                  <Button justIcon color="danger" size="sm" onClick={() => this.removeQuestion(key, qkey)} > <RemoveCircle /></Button>
                                </div>}
                                title={qitem.questionTitle} color={commonStyle.whitebackgroundColor} textColor={commonStyle.blackColor}>

                                <RenderQuestion question={qitem} />
                              </TitleBox>
                              return <SortableItem key={`qitem-${qkey}`} index={qkey} value={qvalue} />
                            })
                          }
                          <Button color="success" size="sm" onClick={() => this.props.history.push(`/survey/${id}/${key}/-1/question/edit`)}>
                            {t('Add New Question')}
                          </Button>
                        </SortableContainer>
                      </TitleBox>
                      return <SortableItem key={`item-${key}`} index={key} value={value} />
                    })
                  }
                </SortableContainer>

                <Button color="success" onClick={() => this.getSectionTitle()}>
                  {t('New Section')}
                </Button>
              </CardBody>
              <CardFooter>

              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps)(withTranslation()(withStyles(styles)(SurveyEditPage)));
