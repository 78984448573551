import withStyles from '@material-ui/core/styles/withStyles';
import { default as React } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { api_url } from 'redux/config.js'
import Toast from 'views/Toast/Toast';
import CustomInput from "components/CustomInput/CustomInput"
import Button from "components/CustomButtons/Button"
import Datetime from "react-datetime";
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
var generator = require('generate-password');
const styles = {};
class GeneratePassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            manual: false,
            count: 1,
            length: 6,
            password: "",
            expireDate: Date.now()
        }
    }
    generatePassword = () => {
        const { manual, count, length, password, expireDate } = this.state
        if (manual && password.length < 6) {
            return Toast(400, t('Password length should be at least 6 letters'))
        }
        if (!manual && (count < 1 || count > 50)) {
            return Toast(400, t('Please set count between 1~50'))
        }
        if (!manual && (length < 6 || length > 50)) {
            return Toast(400, t('Please set length between 1~50'))
        }
        if (manual) {
            this.props.savePasswords([password], expireDate)
        } else {

            let passwords = generator.generateMultiple(count, {
                length: length,
                uppercase: false,
                numbers: true
            });
            this.props.savePasswords(passwords, expireDate)
        }
    }
    render() {
        const { manual, count, length, password, expireDate } = this.state
        return <div >
            <Button color={!manual ? "info" : null} size="sm" onClick={() => this.setState({ manual: false })}>{t('Automatic')}</Button>
            <Button color={manual ? "info" : null} size="sm" onClick={() => this.setState({ manual: true })}>{t('Manual')}</Button>

            {manual && <CustomInput
                labelText={t('Password')}
                id="password"
                formControlProps={{
                    onChange: e => this.setState({ password: e.target.value }),
                    fullWidth: true
                }}
                inputProps={{
                    type: "text",
                    value: password
                }}
            />}
            {!manual && <div><CustomInput
                labelText={t('Count')}
                id="Count"
                formControlProps={{
                    onChange: e => this.setState({ count: e.target.value }),
                    fullWidth: true
                }}
                inputProps={{
                    type: "number",
                    value: count
                }}
            />
                <CustomInput
                    labelText={t('Length')}
                    id="Length"
                    formControlProps={{
                        onChange: e => this.setState({ length: e.target.value }),
                        fullWidth: true,
                    }}
                    inputProps={{
                        type: "number",
                        value: length,
                    }}
                />
                <Datetime
                    onChange={date => {
                        this.setState({ expireDate: date })
                    }}
                    closeOnSelect={true}
                    inputProps={{ placeholder: t('Expire date') }}
                    dateFormat="DD/MM/YYYY"
                    value={expireDate}
                />
            </div>}
            <Button color="success" onClick={this.generatePassword}>Generate</Button>
        </div>;
    }
}
const mapStatsToProps = ({ user }) => ({ user });
export default connect(mapStatsToProps)(
    withTranslation()(withStyles(styles, { withTheme: true })(GeneratePassword))
);