export default {
    // backend 

    'User with same username already exist!': 'User with same username already exist!',
    'User with same email already exist!': 'User with same email already exist!',
    'Wrong email or password!': 'Wrong email or password!',
    'Server error!': 'Server error!',
    'Email sending failed': 'Email sending failed',
    'expired': 'expired',
    'Mongodb has error': 'Mongodb has error',
    'Authentication token is invalid': 'Authentication token is invalid',
    'Invalid survey': 'Invalid survey',
    'User not found': 'User not found',
    'Email list not found': 'Email list not found',
    'Your account is not activated': 'Your account is not activated',
    'There is no survey': 'There is no survey',
    'File not upload': 'File not upload',
    'Old password is not correct': 'Old password is not correct',
    'Invalid smtp configuration': 'Invalid smtp configuration',

    // routes
    'Costumers': 'Costumers',
    'Costumer Payments': 'Costumer Payments',
    'Costumer Update': 'Costumer Update',
    'Costumer Update': 'Costumer Update',
    'Allotment Survey': 'Allotment Survey',
    'Create Allot Survey': 'Create Allot Survey',
    'Response Plan': 'Response Plan',
    'Profile': 'Profile',
    'Register Page': 'Register Page',
    'Login Page': 'Login Page',
    'Activate success': 'Activate success',
    'Activate failed': 'Activate failed',
    'Payment Detail': 'Payment Detail',
    'Profile': 'Profile',
    'Create survey': 'Create survey',
    'View report': 'View report',
    'Edit survey': 'Edit survey',
    'Translate survey': 'Translate survey',
    'Add Question': 'Add Question',
    'Survey respondent': 'Survey respondent',
    'Survey passwords': 'Survey passwords',
    'Create Email': 'Create Email',
    'Edit Email': 'Edit Email',
    'Campaign Detail': 'Campaign Detail',
    'Purchase Responds': 'Purchase Responds',
    'Dashboard': 'Dashboard',
    'Survey': 'Survey',
    'Allotment Survey': 'Allotment Survey',
    'Email list': 'Email list',
    'Campaign': 'Campaign',
    'Send survey': 'Send survey',
    'Create Survery Link': 'Create Survery Link',
    'Email setting': 'Email setting',
    'Help': 'Help',
    'Sync Email List': 'Sync Email List',
    'Dashboard': 'Dashboard',
    'Home': 'Home',
    'NO LIMITS': 'NO LIMITS',
    'Payment details': 'Payment details',
    'Update your info': 'Update your info',
    'SURVEY DETAILS': 'SURVEY DETAILS',
    'TOTAL SURVEYS': 'TOTAL SURVEYS',
    'ALLOTMENT SURVEYS': 'ALLOTMENT SURVEYS',
    'RESPONSE DETAILS': 'RESPONSE DETAILS',
    'TOTAL ALLOWED ANSWERS': 'TOTAL ALLOWED ANSWERS',
    'Survey': 'Survey',
    'Responses': 'Responses',
    'An Error occured with your payment': 'An Error occured with your payment',
    'RESPONSE PLANS': 'RESPONSE PLANS',
    'Package Name': 'Package Name',
    'No. of Responses': 'No. of Responses',
    'Price': 'Price',
    'Pay Now': 'Pay Now',
    'Close': 'Close',
    "Excellent": "Excellent",
    "Very Good": "Very Good",
    "Good": "Good",
    "Fair": "Fair",
    "Poor": "Poor",
};