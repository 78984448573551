import withStyles from "@material-ui/core/styles/withStyles";
import ViewList from "@material-ui/icons/ViewList";
import axios from "axios";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { default as React } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { api_url } from 'redux/config.js';
import Toast from 'views/Toast/Toast';



const styles = {};
class EmailListForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.id,
      name: this.props.name ? this.props.name : '',
    };
  }
  componentDidMount() {

  }

  onSave = () => {
    let self = this;
    const url = api_url + `emaillist/save`;
    const header = 'Bearer '.concat(this.props.user.token);
    const data = {
      id: this.state.id,
      name: this.state.name
    }
    let headers = null;
    if (this.props.user.token) {
      headers = {
        Authorization: header
      };
    }
    this.props.dispatch({ type: 'LOAD_START' });
    axios({
      url,
      method: 'post',
      headers,
      data
    })
      .then(function (response) {
        self.props.onSave();
        self.props.dispatch({ type: 'LOAD_END' });
      })
      .catch(function (errors) {
        console.info(errors);
        self.props.dispatch({ type: 'LOAD_END' });
        if (!errors.response) {
          Toast(500);
          return;
        }
        Toast(errors.response.status);
      });
  };
  render() {
    const { classes, t } = this.props;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12}>
            <Card>
              <CardHeader color="success" icon>
                <CardIcon color="success">
                  <ViewList />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>{t('CREATE MAIL LIST')}</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12}>
                    <CustomInput
                      labelText={t('List name')}
                      id="Title"
                      formControlProps={{
                        onChange: e => this.setState({ name: e.target.value }),
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "text",
                        value: this.state.name
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button color="info" onClick={() => this.onSave()}>{t('Save')}</Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
const mapStatsToProps = ({ user }) => ({ user });
export default connect(mapStatsToProps)(
  withTranslation()(withStyles(styles, { withTheme: true })(EmailListForm))
);
