const defaultState = {
  loading: false,
  redirectTo: null,
  app_constants: {
    PAYPAL_CLIENT_ID: ''
  }
};

export default (state = defaultState, action) => {

  switch (action.type) {
    case "LOAD_START":
      return {
        ...state,
        loading: true
      };
    case "LOAD_END":
      return { ...state, loading: false };
    case "SET_REDIRECT_PATH":
      return { ...state, redirectTo: action.data };
    case "REMOVE_REDIRECT_PATH":
      return { ...state, redirectTo: null };
    case "SET_CONSTANTS":
      return { ...state, app_constants: action.data };

    default:
      return state;
  }
};
