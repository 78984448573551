import withStyles from '@material-ui/core/styles/withStyles';
import commonStyle from "assets/jss/common/common";
import Button from 'components/CustomButtons/Button';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import CustomSelect from "components/CustomSelect/CustomSelect";
import Danger from "components/Typography/Danger";
import PropTypes from "prop-types";
import { default as React } from 'react';
import { connect } from 'react-redux';
import { question_types } from "redux/config";
import { isMobile } from "react-device-detect"
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import { withTranslation } from 'react-i18next';

const styles = {
    ...commonStyle,
    optionBtn: {
        minWidth: 150
    }
};

class Component extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            questionSchema: null,
            question: null,
            answer: null
        }
        this.initState()
    }
    initState = () => {

        const question = this.props.question
        const answer = this.props.answer
        let questionSchema = question_types.filter((item) => item.type === question.question.type)[0]
        this.state = {
            questionSchema,
            question,
            answer: answer ? answer : {
                id: question.id,
                value: []
            }
        }
    }
    setAnswerData = (value) => {
        let { answer, question } = this.state;
        answer.value = value
        this.setState({
            answer: answer,
            error: null
        }, () => {
            console.info(answer)
            this.saveAnswerData()
        })
    }

    componentDidMount = () => {
        this.saveAnswerData(true)
    }
    saveAnswerData = (init = false) => {
        let { answer, question } = this.state;
        if (this.props.onChange) {
            this.props.onChange({
                isValid: this.isValid(), answer: {
                    id: question.id,
                    ...answer
                }
            }, init)

        }
    }
    isValid = () => {
        const { question, answer } = this.state

        const questionBody = this.state.question.question
        const answertype = questionBody.answertype
        if (question.required && (!answer || answer.value.length < 1)) {
            this.showError(t('This question is required')); return false
        }
        if (answertype === 'choose' && (!answer || (answer.value.length > 0 && answer.value.length < questionBody.selectable_count))) {
            this.showError(t('Please choose more options')); return false
        }
        return true
    }
    showError = (message) => {
        this.setState({ error: <Danger>* {message}</Danger> })
    }
    toggleOption = (data) => {
        const question = this.state.question.question

        const answertype = question.answertype
        if (answertype === 'one') {
            this.setAnswerData([data]); return
        }
        let answerValue = this.state.answer.value ? this.state.answer.value : []
        const index = answerValue.filter((item) => item === data)
        if (index.length > 0) {
            answerValue = answerValue.filter((item) => item !== data)
        } else {
            if (answertype === 'multiple' || answerValue.length < question.selectable_count)
                answerValue.push(data)
        }

        this.setAnswerData(answerValue)
    }
    setOtherValue = (text) => {
        let { answer } = this.state;
        answer.other_value = text
        this.setState({
            answer: answer,
            error: null
        }, () => {
            console.info(answer)
            this.saveAnswerData()
        })
    }
    renderOtherOption = () => {
        const { classes } = this.props
        const question = this.state.question.question
        const answer = this.state.answer
        const answerValue = answer.value ? answer.value : []
        const has_free = question.has_free
        const otherOptionExist = answerValue.filter((item) => item === 'other')

        if (has_free && otherOptionExist.length > 0) {
            return <div>
                <CustomInput
                    formControlProps={{
                        className: classes.noPadding + ' ' + classes.noMargin,
                        onChange: e => {
                            this.setOtherValue(e.target.value)
                        }
                    }}
                    inputProps={{
                        placeholder: t('Other option'),
                    }}
                />
            </div>
        }
    }
    render() {
        const { classes } = this.props
        const { questionSchema, error, answer } = this.state
        const question = this.state.question.question
        const answerValue = answer.value
        const answertype = question.answertype
        return <div >
            {error}
            {
                question.answertype === 'choose' && <p className={classes.info}>
                    {t('* Please choose more options.')} {question.selectable_count} </p>
            }
            {

                question.direction === 'horizontal' && <div>
                    {
                        question.options.map((item, key) => {
                            return <Button fullWidth={isMobile} className={classes.optionBtn} key={key} color={answerValue.indexOf(item.value) > -1 ? "info" : null} onClick={() => this.toggleOption(item.value)}>{item.label}</Button>
                        })
                    }
                </div>
            }
            {

                question.direction === 'vertical' && <div>
                    {
                        question.options.map((item, key) => {
                            return <div key={key}>
                                <Button fullWidth={isMobile} className={classes.optionBtn} color={answerValue.indexOf(item.value) > -1 ? "info" : null} onClick={() => this.toggleOption(item.value)}>{item.label}
                                </Button>
                            </div>
                        })
                    }
                </div>
            }
            {

                question.direction === 'dropdown' && <div>

                    <CustomSelect
                        isMulti={answertype !== 'one'}
                        onChange={data => {
                            if (answertype === 'one') {
                                this.setAnswerData(question.options.filter(option => option.value === data.value).map(item => item.value))
                            } else {

                                this.setAnswerData(data.map((item) => {
                                    return question.options.filter(option => option.value === item.value)[0].value
                                }));
                            }
                        }}
                        placeholder={t('Choose')}
                        options={question.options.map((item) => ({ label: item.label, value: item.value }))}
                        value={question.options.filter(item => answerValue.indexOf(item.value) > -1)}
                    />
                </div>
            }
            {
                this.renderOtherOption()
            }
        </div>;
    }
}
Component.propTypes = {
    question: PropTypes.object,
    answer: PropTypes.object,
    onChange: PropTypes.func
}
const mapStatsToProps = ({ user }) => ({ user });
export default connect(mapStatsToProps)(
    withTranslation()(withStyles(styles, { withTheme: true })(Component))
);