// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import registerPageStyle from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { getData } from "country-list";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Toast from "views/Toast/Toast";
import axios from "axios";
import { api_url } from "redux/config.js";
import CustomSelect from "components/CustomSelect/CustomSelect"
import Success from "components/Typography/Success";
import { verifyEmail } from "utils/Validate";
import { withTranslation } from "react-i18next";
import { t } from "i18next";

class RegisterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countries: [],
      successed: false,
      formData: {
        username: "",
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        password_confirm: "",
        company_name: "",
        company_address: "",
        tax_id: "",
        tax_office: "",
        country: null,
        city: ""
      }
    };
  }
  componentDidMount() {
    let countries = [];
    getData().map(item => {
      countries.push({
        value: item.code,
        label: item.name
      });
      return null;
    });
    this.setState({ countries });
  }

  verfiyForm = () => {
    const { username, email, password, password_confirm } = this.state.formData;
    if (username.length === 0) {
      Toast(400, t('Please enter user name'));
      return false;
    }
    if (!verifyEmail(email)) {
      Toast(400, t('Invalid email address!'));
      return false;
    }
    if (password.length < 6) {
      Toast(400, t('Password should be at least 6 letters'));
      return false;
    }
    if (password !== password_confirm) {
      Toast(400, t('Password confirm mismatched'));
      return false;
    }
    return true;
  };
  registerUser = () => {
    if (this.verfiyForm()) {
      this.props.dispatch({ type: "LOAD_START" });
      const { password_confirm, ...data } = this.state.formData;
      axios({
        url: api_url + "auth/signup",
        method: "POST",
        data
      })
        .then(response => {
          this.props.dispatch({ type: "LOAD_END" });
          if (response.data.success) {
            this.setState({
              successed: true
            });
            Toast(200, t('Successfully registered!'));
          } else {
            Toast(400, response.data.error);
          }
        })
        .catch(errors => {
          this.props.dispatch({ type: "LOAD_END" });
          Toast(500);
        });
    }
  };
  setFormData = (field, value) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [field]: value
      }
    });
  };

  render() {
    if (this.props.user.isLoggedIn === true) {
      return <Redirect to="/" />;
    }
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={8} md={8}>
            <form>
              <Card className={classes.cardSignup}>
                <h2 className={classes.cardTitle}>{t('Register')}</h2>

                {this.state.successed && (
                  <Success>
                    <h1 className={classes.center}>
                      {t('Successfully registered, please check your email to activate account.')}
                    </h1>
                  </Success>
                )}
                {!this.state.successed && (
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={6} md={6}>
                        <CustomInput
                          formControlProps={{
                            onChange: e =>
                              this.setFormData("username", e.target.value),
                            fullWidth: true
                          }}
                          inputProps={{
                            placeholder: t("Username")
                          }}
                        />
                        <CustomInput
                          formControlProps={{
                            onChange: e =>
                              this.setFormData("first_name", e.target.value),
                            fullWidth: true
                          }}
                          inputProps={{
                            placeholder: t("First name")
                          }}
                        />
                        <CustomInput
                          formControlProps={{
                            onChange: e =>
                              this.setFormData("last_name", e.target.value),
                            fullWidth: true
                          }}
                          inputProps={{
                            placeholder: t("Last name")
                          }}
                        />
                        <CustomInput
                          formControlProps={{
                            onChange: e =>
                              this.setFormData("email", e.target.value),
                            fullWidth: true
                          }}
                          inputProps={{
                            placeholder: t("Email address")
                          }}
                        />
                        <CustomInput
                          formControlProps={{
                            onChange: e =>
                              this.setFormData("password", e.target.value),
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "password",
                            placeholder: t("Password")
                          }}
                        />
                        <CustomInput
                          formControlProps={{
                            onChange: e =>
                              this.setFormData(
                                "password_confirm",
                                e.target.value
                              ),
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "password",
                            placeholder: t("Confirm Password")
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6}>
                        <CustomInput
                          formControlProps={{
                            onChange: e =>
                              this.setFormData("company_name", e.target.value),
                            fullWidth: true
                          }}
                          inputProps={{
                            placeholder: t("Company Name")
                          }}
                        />
                        <CustomInput
                          formControlProps={{
                            onChange: e =>
                              this.setFormData(
                                "company_address",
                                e.target.value
                              ),
                            fullWidth: true
                          }}
                          inputProps={{
                            placeholder: t("Company Address")
                          }}
                        />
                        <CustomInput
                          formControlProps={{
                            onChange: e =>
                              this.setFormData("tax_id", e.target.value),
                            fullWidth: true
                          }}
                          inputProps={{
                            placeholder: t("Tax ID")
                          }}
                        />
                        <CustomInput
                          formControlProps={{
                            onChange: e =>
                              this.setFormData("tax_office", e.target.value),
                            fullWidth: true
                          }}
                          inputProps={{
                            placeholder: t("Tax Office")
                          }}
                        />
                        <CustomSelect
                          onChange={country => {
                            this.setFormData("country", country);
                          }}
                          placeholder="Country"
                          options={this.state.countries}
                        />
                        <CustomInput
                          formControlProps={{
                            onChange: e =>
                              this.setFormData("city", e.target.value),
                            fullWidth: true
                          }}
                          inputProps={{
                            placeholder: t("City")
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <div className={classes.center}>
                      <Button
                        onClick={() => this.registerUser()}
                        round
                        color="primary"
                      >
                        {t('Get started')}
                      </Button>
                      <Button href="/auth/login" simple color="primary">
                        {t('Log in')}
                      </Button>
                    </div>
                  </CardBody>
                )}
              </Card>
            </form>
          </GridItem>
        </GridContainer>

      </div>
    );
  }
}

RegisterPage.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps)(
  withTranslation()(withStyles(registerPageStyle)(RegisterPage))
);
