import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import footerStyle from "assets/jss/material-dashboard-pro-react/components/footerStyle";
import Button from "components/CustomButtons/Button";
import { LANGUAGES } from "translations/i18n";
import { changeLanguage } from "i18next";

function Footer({ ...props }) {
  const { classes, fluid, rtlActive } = props;
  var container = cx({
    [classes.container]: !fluid,
    [classes.containerFluid]: fluid,
  });


  return (
    <footer className={classes.footer}>
      <div className={container}>
        {
          LANGUAGES.map((ln, key) => {
            return <Button simple color='white' onClick={() => changeLanguage(ln.value)} key={key}>
              {ln.label}
            </Button>
          })
        }
        <p className={classes.right}>
          <a href="/" className={classes.anchor}>
            &copy; 2019 {rtlActive ? "توقيت الإبداعية" : "Gooquest Dev TEAM"}
          </a>
        </p>
      </div>
    </footer>
  );
}
Footer.defaultProps = {
}
Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  fluid: PropTypes.bool,
  rtlActive: PropTypes.bool
};

export default withStyles(footerStyle)(Footer);
