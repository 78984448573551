// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Link from "@material-ui/icons/Link";
import notificationsStyle from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import React from "react";
import Select from "react-select";
import "react-table/react-table.css";

import { api_url, app_url } from 'redux/config.js';
import { connect } from 'react-redux';
import Toast from 'views/Toast/Toast';
import axios from 'axios';
import QRCode from "qrcode.react"
import commonStyle from "assets/jss/common/common"
import CustomSelect from "components/CustomSelect/CustomSelect"
import { withTranslation } from "react-i18next";
const styles = {
  ...regularFormsStyle,
  ...notificationsStyle,
  descriptionTextArea: {
    width: "100%",
    height: 150
  },
  flexBaseLine: {
    display: 'flex;',
    alignItems: 'baseline;'
  }
};
class CreateSurveyLinkPage extends React.Component {
  constructor(props) {
    super(props);
    const { t } = props
    this.state = {
      selected_survey: null,
      selectedType: { label: t("Answer By Adding Email"), value: 'email' },
      survey_full: [],
      surveys: [],
      survey_url: '',
      types: [
        { label: t("Answer By Adding Email"), value: 'email' },
        { label: t("Free to Answer"), value: 'nocondition' },
        { label: t("Protection With ID Password"), value: 'password' },
        { label: t("Answer by ID free choice"), value: 'freeid' },
        { label: t("Emojies + Flags at the Beginning"), value: 'emoji_flag_start' },],
      eccs: [
        {
          label: "L-smallest",
          value: "L"
        },
        {
          label: "M",
          value: "M"
        },
        {
          label: "Q",
          value: "Q"
        },
        {
          label: "H-best",
          value: "H"
        }
      ],
      sizes: [],
      selected_size: {
        label: 'Size',
        value: 50
      },
      selected_level: {
        label: "M",
        value: "M"
      },
    };
  }
  normalize = (response) => {
    const survey_full = response.data.data;
    const surveys = response.data.data.map((item) => ({
      label: item.main[0].title,
      value: item._id
    }))

    this.setState({
      surveys, survey_full
    })
  }
  onLoad = () => {
    let self = this;
    const url = api_url + `getall`;
    const header = 'Bearer '.concat(this.props.user.token);
    let headers = null;
    if (this.props.user.token) {
      headers = {
        Authorization: header
      };
    }
    this.props.dispatch({ type: 'LOAD_START' });
    axios({ url: api_url + 'survey/all_active', method: 'get', headers })
      .then(function (response) {
        self.normalize(response)
        self.props.dispatch({ type: 'LOAD_END' });
      })
      .catch(function (errors) {
        console.info(errors);
        self.props.dispatch({ type: 'LOAD_END' });
        if (!errors.response) {
          Toast(500);
          return;
        }
        Toast(errors.response.status);
      });
  };
  componentDidMount() {
    this.onLoad()
    let sizes = [];
    for (let i = 50; i <= 500; i += 50) {
      sizes.push({
        label: `${i}*${i}`,
        value: i
      });
    }
    this.setState({ sizes, selected_size: sizes[0] });
  }
  generateSurveyLink = () => {
    const selected_survey = this.state.selected_survey
    const selectedType = this.state.selectedType
    let url = `${app_url}/public/single_survey/${selected_survey.value}/${selectedType.value}`
    if (selectedType.value === 'emoji_flag_start' || selectedType.value === 'emoji_flag_top') {
      url = `${app_url}/public/single_survey_emoji/${selected_survey.value}/${selectedType.value}`
    }
    this.setState({
      survey_url: url
    })
  }
  copyLink = () => {
    const { t } = this.props
    this.copyToClipboard(this.state.survey_url)
    Toast(200, t('Copied to clipboard'));
  }
  copyToClipboard = str => {
    const el = document.createElement('textarea');  // Create a <textarea> element
    el.value = str;                                 // Set its value to the string that you want copied
    el.setAttribute('readonly', '');                // Make it readonly to be tamper-proof
    el.style.position = 'absolute';
    el.style.left = '-9999px';                      // Move outside the screen to make it invisible
    document.body.appendChild(el);                  // Append the <textarea> element to the HTML document
    const selected =
      document.getSelection().rangeCount > 0        // Check if there is any content selected previously
        ? document.getSelection().getRangeAt(0)     // Store selection if found
        : false;                                    // Mark as false to know no selection existed before
    el.select();                                    // Select the <textarea> content
    document.execCommand('copy');                   // Copy - only works as a result of a user action (e.g. click events)
    document.body.removeChild(el);                  // Remove the <textarea> element
    if (selected) {                                 // If a selection existed before copying
      document.getSelection().removeAllRanges();    // Unselect everything on the HTML document
      document.getSelection().addRange(selected);   // Restore the original selection
    }
  };
  render() {
    const { classes, t } = this.props;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12}>
            <Card>
              <CardHeader color="success" icon>
                <CardIcon color="success">
                  <Link />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>{t('Create survey link')}</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} md={12}>
                    <SnackbarContent
                      fullWidth
                      message={
                        t('Answer Type Choose, one of the options bellow how you need to protect you Survey.')
                      }
                      color="info"
                    />
                  </GridItem>

                  <GridItem xs={12}>
                    {this.state.types.map((item, key) => {
                      return (
                        <Button
                          key={key}
                          size="sm"
                          onClick={() => {
                            this.setState({
                              selectedType: item
                            });
                          }}
                          color={
                            item.value === this.state.selectedType.value ? "info" : null
                          }
                        >
                          {item.label}
                        </Button>
                      );
                    })}
                  </GridItem>
                  <GridItem xs={12}>
                    <CustomSelect
                      onChange={selvalue => {
                        this.setState({
                          selected_survey: selvalue
                        })
                      }}
                      placeholder={t('Survey')}
                      options={this.state.surveys}
                    />
                  </GridItem>
                  <GridItem xs={12}>

                    <div className={classes.flexBaseLine}>
                      <CustomInput
                        labelText={t('SURVEY LINK')}
                        id="SURVEYLINK"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "text",

                          value: this.state.survey_url,
                          disabled: true
                        }}
                      />
                      <Button size="sm" color="info" onClick={this.copyLink}>{t('Copy')}</Button>
                    </div>
                    <div>
                      <Button color="success" disabled={!this.state.selected_survey} onClick={this.generateSurveyLink}>
                        {t('Create Survey Link, QR code')}
                      </Button>
                    </div>
                  </GridItem>
                  <GridItem xs={12} md={6}>
                    <p>{t('ECC')}</p>
                    <CustomSelect
                      onChange={level => { this.setState({ selected_level: level }) }}
                      placeholder="ECC"
                      value={this.state.selected_level}
                      options={this.state.eccs}
                    />
                    <br />
                    <p>{t('Size')}</p>
                    <CustomSelect
                      onChange={size => { this.setState({ selected_size: size }) }}
                      placeholder="Size"
                      value={this.state.selected_size}
                      options={this.state.sizes}
                    />
                  </GridItem>
                  <GridItem xs={12} md={6}>
                    <p>{t('QR image')}</p>
                    {
                      this.state.survey_url && <QRCode value={this.state.survey_url} level={this.state.selected_level.value} size={this.state.selected_size.value} />
                    }

                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps)(withTranslation()(withStyles(styles)(CreateSurveyLinkPage)));