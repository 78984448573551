
import withStyles from '@material-ui/core/styles/withStyles';
import React from 'react';
import Button from "components/CustomButtons/Button"
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { connect } from 'react-redux';
import InputModal from "views/Shared/InputModal";
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

const styles = {};
class Component extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            text: ''
        }
    }
    render() {
        const { text } = this.state
        return (
            <div>
                <Dialog open={true} onClose={this.props.handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{this.props.title || 'Input'}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label={t('Type here')}
                            type="text"
                            fullWidth
                            value={text}
                            onChange={(e) => {
                                this.setState({ text: e.target.value })
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.props.handleSave(text)} color="success">
                            {t('Save')}
                        </Button>
                        <Button onClick={this.props.handleClose} >
                            {t('Cancel')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}
const mapStatsToProps = ({ user }) => ({ user });
export default connect(mapStatsToProps)(
  withTranslation()(withStyles(styles, { withTheme: true })(Component))
);