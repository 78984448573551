// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import axios from 'axios';
import Button from "components/CustomButtons/Button.jsx";
import { TransReactTable } from "components/CustomReactTable/TransReactTable";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { t } from "i18next";
import moment from "moment";
import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import ReactTable from "react-table";
import "react-table/react-table.css";
import { api_url } from 'redux/config.js';
import Toast from 'views/Toast/Toast';
import GeneratePassword from "./components/GeneratePassword";



const styles = {
  ...sweetAlertStyle,
  ...regularFormsStyle,
  customInput: {
    marginBottom: 17
  }
};
const column = [
  {
    Header: "Password",
    accessor: "password",
  },
  {
    Header: "Expire date",
    accessor: "expire_date",
  },
  {
    Header: "Used",
    accessor: "used",
  }
];

class SurveyPasswordsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      title: '',
      id: this.props.match.params.survey_id,
      passwords: [], // row
      password_list: [], // components
      loading: false,
    };
  }

  basicAlert = () => {
    this.setState({
      alert: (
        <SweetAlert
          style={{ display: "block", top: "10%", marginTop: "0", height: '80%', overflow: 'auto' }}
          title={t('Add passwords')}
          showConfirm={false}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        >
          <GeneratePassword savePasswords={(newpasswords, expireDate) => this.setPasswords(newpasswords, expireDate)} />
        </SweetAlert>
      )
    });
  }
  hideAlert() {
    this.setState({
      alert: null
    });
  }
  setPasswords = async (newpasswords, expireDate) => {
    const self = this
    let passwordObjects = newpasswords.map((item) => {
      const now = moment().add(1, 'years');
      return {
        password: item,
        used: false,
        expire_date: expireDate.valueOf()
      }
    })
    const newPasswordsParam = passwordObjects.concat(this.state.passwords)
    this.props.dispatch({ type: "LOAD_START" });


    const data = {
      id: this.state.id,
      passwords: newPasswordsParam
    }
    const result2 = await axios({
      url: api_url + "survey/set_passwords",
      method: "post",
      headers: {
        authorization: "Bearer ".concat(this.props.user.token)
      },
      data: data
    });
    this.props.dispatch({ type: "LOAD_END" });
    if (result2.data.success) {
      self.setState({
        passwords: result2.data.data.passwords
      })
      this.normalizeData(result2.data.data.passwords)
      this.hideAlert()
      Toast(200, t('Updated successfully'));
    } else {
      Toast(400, result2.data.error);
    }
  }


  componentDidMount() {
    const urlParams = new URLSearchParams(this.props.location.search);

    this.setState({
      survey_id: urlParams.get("id"),
      title: urlParams.get("title")
    })
    this.onLoad()
  }


  normalizeData = data => {
    const { classes } = this.props;

    if (!data || data.length === 0) {
      this.setState({
        password_list: []
      });
    }
    const ndata = data.map((item, key) => {

      return {
        password: item.password,
        expire_date: moment(item.expire_date).format("MMMM Do YYYY, h:mm:ss a"),
        used: item.used ? t('Yes') : t('No')
      };
    });
    this.setState({
      password_list: ndata
    });
  };

  onLoad = () => {
    let self = this;
    const id = this.props.match.params.survey_id;
    const url = api_url + `survey/get`;
    const header = "Bearer ".concat(this.props.user.token);
    let headers = null;
    if (this.props.user.token) {
      headers = {
        Authorization: header
      };
    }
    this.props.dispatch({ type: "LOAD_START" });
    axios({
      url,
      method: "get",
      params: {
        id: id
      },
      headers: {
        authorization: "Bearer ".concat(this.props.user.token)
      }
    })
      .then(function (response) {
        console.info(response);
        if (!response.data.success) {
          Toast(400, response.data.error);
        } else {
          if (response.data.data.passwords) {
            self.setState({
              passwords: response.data.data.passwords
            })
            self.normalizeData(response.data.data.passwords)
          }
          self.setState({
            loading: false
          });
        }
        self.props.dispatch({ type: "LOAD_END" });
      })
      .catch(function (errors) {
        console.info(errors);
        self.props.dispatch({ type: "LOAD_END" });
      });
  };
  downloadCSV = () => {
    let rows = this.state.password_list.map((element) => {
      return [element.password, element.expire_date, element.used ? 'yes' : 'no']
    })
    rows.unshift([t("Password"), t("Expire date"), t("Used")])
    let csvContent = "data:text/csv;charset=utf-8,"
      + rows.map(e => e.join(",")).join("\n");
    var encodedUri = encodeURI(csvContent);
    window.open(encodedUri);

  }

  onCopy = () => {
    let str = `${t('Password')},${t('Expire date')},${t('Used')}\n`;
    this.state.password_list.forEach(element => {
      const line = `${element.password},${element.expire_date},${element.used ? t('Yes') : t('No')}\n`;
      str += line;
    });
    this.copyToClipboard(str);
    Toast(200, t('Successfully copied to clipboard'))
  }
  copyToClipboard = str => {
    const el = document.createElement('textarea');  // Create a <textarea> element
    el.value = str;                                 // Set its value to the string that you want copied
    el.setAttribute('readonly', '');                // Make it readonly to be tamper-proof
    el.style.position = 'absolute';
    el.style.left = '-9999px';                      // Move outside the screen to make it invisible
    document.body.appendChild(el);                  // Append the <textarea> element to the HTML document
    const selected =
      document.getSelection().rangeCount > 0        // Check if there is any content selected previously
        ? document.getSelection().getRangeAt(0)     // Store selection if found
        : false;                                    // Mark as false to know no selection existed before
    el.select();                                    // Select the <textarea> content
    document.execCommand('copy');                   // Copy - only works as a result of a user action (e.g. click events)
    document.body.removeChild(el);                  // Remove the <textarea> element
    if (selected) {                                 // If a selection existed before copying
      document.getSelection().removeAllRanges();    // Unselect everything on the HTML document
      document.getSelection().addRange(selected);   // Restore the original selection
    }
  };
  onPDF = () => {
    let self = this;
    const id = this.props.match.params.survey_id;
    const url = api_url + `survey/generate_password_pdf`;
    const header = "Bearer ".concat(this.props.user.token);
    let headers = null;
    if (this.props.user.token) {
      headers = {
        Authorization: header
      };
    }
    this.props.dispatch({ type: "LOAD_START" });
    axios({
      url,
      method: "get",
      params: {
        id: id
      },
      responseType: 'blob',
      headers: {
        authorization: "Bearer ".concat(this.props.user.token)
      }
    })
      .then(function (response) {
        console.info(response);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.pdf');
        document.body.appendChild(link);
        link.click();
        self.setState({
          loading: false
        });
        self.props.dispatch({ type: "LOAD_END" });
      })
      .catch(function (errors) {
        console.info(errors);
        self.props.dispatch({ type: "LOAD_END" });
      });
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        {this.state.alert}

        <GridContainer>
          <GridItem>
            <Button color="success" onClick={this.basicAlert}>{t('Add New')}</Button>
            <Button color="info" onClick={this.onCopy}>{t('Copy')}</Button>
            <Button color="info" onClick={this.downloadCSV}>{t('CSV')}</Button>
            <Button color="info" onClick={this.onPDF}>{t('PDF')}</Button>

          </GridItem>
          <GridItem sm={12}>
            <TransReactTable
              defaultPageSize={this.state.pageSize}
              data={this.state.password_list}
              columns={column}
              loading={this.state.loading}

            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}


const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps)(withTranslation()(withStyles(styles)(SurveyPasswordsPage)));