
import Email from "@material-ui/icons/Email";
import Layers from "@material-ui/icons/Layers";
import Send from "@material-ui/icons/Send";
import Help from "@material-ui/icons/Help";
import SettingsApplications from "@material-ui/icons/SettingsApplications";
import ViewList from "@material-ui/icons/ViewList";
import CampaignDetailPage from "views/Campaign/CampaignDetailPage";
import CampaignPage from "views/Campaign/CampaignPage";
import CreateMailList from "views/EmailList/CreateMailList";
import EditEmailList from "views/EmailList/EditEmailList";
import EmailListPage from "views/EmailList/EmailListPage";
import EmailSettingPage from "views/EmailSetting/EmailSettingPage";
import SendSurveyPage from "views/SendSurvey/SendSurveyPage";
import SurveyCreatePage from "views/Survey/SurveyCreatePage";
import SurveyEditPage from "views/Survey/EditSurvey/SurveyEditPage";
import SurveyPage from "views/Survey/SurveyPage";
import SurveyRespondentsPage from "views/Survey/SurveyRespondentsPage";
import SurveyPasswordsPage from "views/Survey/SurveyPasswordsPage";
import ViewReportPage from "views/Survey/ViewReportPage";
import HelpSyncAppPage from "views/Help/HelpSyncAppPage";
import CreateSurveyLinkPage from "views/CreateSurveyLink/CreateSurveyLinkPage";
import SurveyQuestionEdit from "views/Survey/EditQuestion/edit"
import InsertLink from "@material-ui/icons/InsertLink";
import SurveyTranslate from "views/Survey/SurveyTranslate";
import DashboardPage from "views/Dashboard/DashboardPage";
import { AccountCircle, DashboardTwoTone } from "@material-ui/icons";
import ProfilePage from "views/Dashboard/ProfilePage";
import PaymentDetailPage from "views/Dashboard/PaymentDetailPage";
import PurchageResponds from "views/Dashboard/PurchaseResponds";

var commonRoutes = [

  {
    path: "/dashboard/payment_detail",
    name: "Payment Detail",
    component: PaymentDetailPage,
    hidden: true
  },
  {
    path: "/dashboard/profile",
    name: "Profile",
    component: ProfilePage,
    hidden: true
  },
  {
    path: "/survey/create_survey",
    name: "Create survey",
    icon: ViewList,
    component: SurveyCreatePage,
    hidden: true
  },
  {
    path: "/survey/view_report/:survey_id",
    name: "View report",
    icon: ViewList,
    component: ViewReportPage,
    hidden: true
  },
  {
    path: "/survey/edit/:survey_id",
    name: "Edit survey",
    icon: ViewList,
    component: SurveyEditPage,
    hidden: true
  },
  {
    path: "/survey/translate/:survey_id",
    name: "Translate survey",
    component: SurveyTranslate,
    hidden: true
  },
  {
    path: "/survey/:survey_id/:section_index/:question_index/question/edit",
    name: "Add Question",
    icon: ViewList,
    component: SurveyQuestionEdit,
    hidden: true
  },
  {
    path: "/survey/survey_respondents",
    name: "Survey respondent",
    icon: ViewList,
    component: SurveyRespondentsPage,
    hidden: true
  },

  {
    path: "/survey/survey_passwords/:survey_id",
    name: "Survey passwords",
    icon: ViewList,
    component: SurveyPasswordsPage,
    hidden: true
  },

  {
    path: "/email_list/create_email_list",
    name: "Create Email",
    icon: Email,
    component: CreateMailList,
    hidden: true
  },
  {
    path: "/email_list/edit_email_list/:id",
    name: "Edit Email",
    icon: Email,
    component: EditEmailList,
    hidden: true
  },
  {
    path: "/campaign/campaign/:id",
    name: "Campaign Detail",
    icon: Layers,
    component: CampaignDetailPage,
    hidden: true
  },
  {
    path: "/costumer/purchase_responds",
    name: "Purchase Responds",
    component: PurchageResponds,
    hidden: true
  },

];
export default commonRoutes;
