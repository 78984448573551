export default {
    fontBold: {
        fontWeight: 'bold'
    },
    fontSmall: {
        fontSize: 'small'
    },
    fontItalic: {
        fontStyle: 'italic'
    },
    fontNormalBold: {
        fontWeight: 400
    },
    hover: {
        "&:hover": {
            textDecoration: 'underline'
        }
    }
}