import withStyles from '@material-ui/core/styles/withStyles';
import commonStyle from "assets/jss/common/common";
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import { t } from 'i18next';
import ISO6391 from "iso-639-1";
import { default as React } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { question_types } from "redux/config";


const styles = {
    ...commonStyle,
    choiceContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        marginTop: 10
    }
};

class Component extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            questionSchema: null,
            languages: [],
            number: 0,
            question: {
                type: 'number',
            },
        }
    }
    componentDidMount() {
        this.props.onRef(this)

        let questionSchema = question_types.filter((item) => item.type === 'number')[0]
        const question = this.props.question

        this.setState({
            questionSchema: questionSchema,
            number: 0,
            question: (question && question.type === questionSchema.type) ? question : {
                type: questionSchema.type,
            }
        })
    }
    getData() {
        return this.state.question
    }


    render() {
        const { questionSchema, number } = this.state
        if (!questionSchema) return null
        const { classes } = this.props
        return <div >
            <hr />

            <div className={classes.fontBold}>{t('Preview')}:</div>
            <CustomInput
                formControlProps={{
                    className: classes.noPadding + ' ' + classes.noMargin,
                    onChange: e =>
                        this.setState({ number: e.target.value }),
                }}
                inputProps={{
                    placeholder: t('Value'),
                    value: number,
                    type: 'number'
                }}
            />
        </div>;
    }
}
const mapStatsToProps = ({ user }) => ({ user });
export default connect(mapStatsToProps)(
    withTranslation()(withStyles(styles, { withTheme: true })(Component))
);