// @material-ui/core components
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import withStyles from "@material-ui/core/styles/withStyles";
import Check from "@material-ui/icons/Check";
import Email from "@material-ui/icons/Email";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import axios from 'axios';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { t } from "i18next";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import "react-table/react-table.css";
import { api_url } from 'redux/config.js';
import Toast from 'views/Toast/Toast';


const styles = {
  ...regularFormsStyle,
  descriptionTextArea: {
    width: "100%",
    height: 150
  }
};
class EmailSettingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedType: {
        label: t("Email Account"),
        value: "Email"
      },
      smtpData: {
        user: '',
        host: '',
        port: 0,
        password: '',
        secure: false,
        requireTLS: false,
      },
      types: [
        {
          label: t("Email Account"),
          value: "Email"
        },
        {
          label: t("Send in Blue"),
          value: "Blue"
        }
      ]
    };
  }
  setSmtpData = (name, value) => {

    let smtpData = this.state.smtpData;
    smtpData[name] = value;
    this.setState({
      smtpData
    })
  }
  onLoad = () => {
    let self = this;
    const url = api_url + `emailsetting/get`;
    const header = 'Bearer '.concat(this.props.user.token);
    let headers = null;
    if (this.props.user.token) {
      headers = {
        Authorization: header
      };
    }
    this.props.dispatch({ type: 'LOAD_START' });
    axios({
      url,
      method: 'get',
      headers
    })
      .then(function (response) {
        if (response.data.success) {
          self.setState({
            smtpData: response.data.data ? response.data.data.smtp : {}
          });
        } else {
          Toast(400, response.data.error)
        }
        self.props.dispatch({ type: 'LOAD_END' });
      })
      .catch(function (errors) {
        console.info(errors);
        self.props.dispatch({ type: 'LOAD_END' });
        if (!errors.response) {
          Toast(500, t('Connection error!'));
          return;
        }
        Toast(errors.response.status);
      });
  };
  componentDidMount() {
    this.onLoad()
  }
  verifyForm = () => {
    let smtpData = this.state.smtpData;
    if (!smtpData.user || smtpData.password.length < 1) {
      Toast(400, t('Invalid id or email'));
      return false;
    }
    if (!smtpData.password || smtpData.password.length < 1) {
      Toast(400, t('Invalid password format'));
      return false;
    }
    if (!smtpData.host || smtpData.host.length < 1) {
      Toast(400, t('Invalid hosting address'))
      return false;
    }
    if (!smtpData.port || smtpData.port < 1) {
      Toast(400, t('Invalid port'));
      return false;
    }
    return true
  }
  onSave = () => {
    if (!this.verifyForm()) return
    let self = this;
    const url = api_url + `emailsetting/save_smtp`;
    const header = 'Bearer '.concat(this.props.user.token);
    let headers = null;
    const data = {
      smtp: this.state.smtpData
    }
    if (this.props.user.token) {
      headers = {
        Authorization: header
      };
    }

    this.props.dispatch({ type: 'LOAD_START' });
    axios({
      url,
      method: 'post',
      headers,
      data
    })
      .then(function (response) {

        if (response.data.success) {
          Toast(200, t('Successfully saved'))
        } else {
          Toast(400, response.data.error)
        }
        self.props.dispatch({ type: 'LOAD_END' });
      })
      .catch(function (errors) {
        console.info(errors);
        self.props.dispatch({ type: 'LOAD_END' });
        if (!errors.response) {
          Toast(500, t('Connection error!'));
          return;
        }
        Toast(errors.response.status);
      });
  };
  render() {
    const { classes } = this.props;
    const smtpData = this.state.smtpData;

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12}>
            <Card>
              <CardHeader color="success" icon>
                <CardIcon color="success">
                  <Email />
                </CardIcon>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  {this.state.selectedType.value === "Email" && (
                    <GridItem xs={12}>
                      <CustomInput
                        labelText={t("Email or UserID")}
                        id="Title"
                        formControlProps={{
                          onChange: (e) => this.setSmtpData('user', e.target.value),
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "text",
                          value: smtpData.user
                        }}
                      />
                      <CustomInput
                        labelText={t("Password")}
                        id="password"
                        formControlProps={{
                          onChange: (e) => this.setSmtpData('password', e.target.value),
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "password",
                          value: smtpData.password
                        }}
                      />
                      <CustomInput
                        labelText={t("Smtp host")}
                        id="Title"
                        formControlProps={{
                          onChange: (e) => this.setSmtpData('host', e.target.value),
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "text",
                          value: smtpData.host
                        }}
                      />
                      <CustomInput
                        labelText={t("Smtp port")}
                        id="Title"
                        formControlProps={{
                          onChange: (e) => this.setSmtpData('port', e.target.value),
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "number",
                          value: smtpData.port
                        }}
                      />
                    </GridItem>
                  )}
                  <GridItem>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={smtpData.secure}
                          tabIndex={-1}
                          onChange={(e) => this.setSmtpData("secure", e.target.checked)}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked
                          }}
                        />
                      }
                      classes={{
                        label: classes.label
                      }}
                      label={t('SSL')}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={smtpData.requireTLS}
                          tabIndex={-1}
                          onChange={(e) => this.setSmtpData("requireTLS", e.target.checked)}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked
                          }}
                        />
                      }
                      classes={{
                        label: classes.label
                      }}
                      label={t('TLS')}
                    />
                  </GridItem>

                  {this.state.selectedType.value === "Blue" && (
                    <GridItem xs={12}>
                      <CustomInput
                        labelText={t('API key')}
                        id="Title"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "text"
                        }}
                      />
                    </GridItem>
                  )}
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button color="info" onClick={() => this.onSave()}>{t('Save')}</Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}


const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps)(withTranslation()(withStyles(styles)(EmailSettingPage)));