// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AccountBalanceWallet from "@material-ui/icons/AccountBalanceWallet";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Reply from "@material-ui/icons/Reply";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import ViewList from "@material-ui/icons/ViewList";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import Badge from "components/Badge/Badge";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Button from "components/CustomButtons/Button.jsx";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomModal from 'components/Modal/CustomModal';
import React, { useEffect, useState } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { CallApi } from 'utils/Request';
import { response_detail } from "views/DataDemo.jsx";
import Toast from 'views/Toast/Toast';
import { useSelector } from "react-redux";
import LoadingModal from "components/Loading/Loading";
import { Navigation } from "@material-ui/icons";
import moment from 'moment'
import { loadProfile } from 'views/Services/UserService';
import { useTranslation } from "react-i18next";
import { TransReactTable } from "components/CustomReactTable/TransReactTable";

const styles = {
  ...regularFormsStyle
};
function DashboardPage(props) {
  const { classes, i18n } = props;
  console.info(props.i18n)
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false)
  const user = useSelector(s => s.user)
  const [mysurveys, setMysurveys] = useState([])

  const response_column = [
    {
      Header: t("Survey"),
      accessor: "survey" // String-based value accessors!
    },
    {
      Header: t("Responses"),
      accessor: "responses" // String-based value accessors!
    }
  ];
  useEffect(() => {
    setIsLoading(true)
    CallApi({
      url: 'survey/costumer/dashboard_info',
      method: 'get',
    }).then((res) => {
      if (res.success) {
        setMysurveys(res.data)
      } else {
        Toast(400, res.error)
      }
    }).finally(() => {
      setIsLoading(false)
    })
    loadProfile()
  }, [])
  const getTotalAllowedAnswers = () => {
    const hasFreeResponse = user.profile ? user.profile.free_response : false
    const response_remaining = user.profile ? user.profile.response_remaining : 0
    if (hasFreeResponse) {
      return t('NO LIMITS')
    } else {
      return response_remaining
    }
  }
  const allotmentSurveys = mysurveys.filter(u => u.allotment_from != null).length
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} md={4}>
          <Button
            color="info"
            fullWidth
            size="lg"
            onClick={() => {
              props.history.push('/costumer/purchase_responds')
            }}
          >
            <ShoppingCart />
            {t('Buy more answers')}
          </Button>
        </GridItem>
        <GridItem xs={12} md={4}>
          <Button color="success" fullWidth size="lg" href="/dashboard/payment_detail">
            <AccountBalanceWallet />
            {t('Payment details')}
          </Button>
        </GridItem>
        <GridItem xs={12} md={4}>
          <Button color="danger" fullWidth size="lg" href="/dashboard/profile">
            <AccountCircle />
            {t('Update your info')}
          </Button>
        </GridItem>

        <GridItem xs={12} sm={12}>
          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <ViewList />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t('SURVEY DETAILS')}</h4>
            </CardHeader>
            <CardBody>
              <p>
                {t('TOTAL SURVEYS')} <Badge color="info">{mysurveys.length}</Badge>
              </p>
              <p>
                {t('ALLOTMENT SURVEYS')} <Badge color="danger">{allotmentSurveys}</Badge>
              </p>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12}>
          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <Reply />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t('RESPONSE DETAILS')}</h4>
            </CardHeader>
            <CardBody>
              <p>
                {t('TOTAL ALLOWED ANSWERS')} <Badge color="info">{getTotalAllowedAnswers()}</Badge>
              </p>
              <TransReactTable
                defaultPageSize={5}
                data={mysurveys.map((s, key) => {
                  return {
                    survey: s.main[0] ? s.main[0].title : 'No Title',
                    responses: s.responses.length
                  }
                })}
                columns={response_column}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {isLoading && <LoadingModal />}
    </div>
  );
}

export default withStyles(styles)(DashboardPage);
