import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: { main: "#00acc1" },
    secondary: { main: "#4caf50" },
    info: { main: "#00acc1" }
  },
  typography: { useNextVariants: true }
});
export default theme;
