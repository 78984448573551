// @material-ui/core components
import { makeStyles } from '@material-ui/core';
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import React, { useEffect } from 'react';
import "react-table/react-table.css";
import { CallApi } from 'utils/Request';
import { useDispatch } from 'react-redux';
import Toast from 'views/Toast/Toast';

const useStyles = makeStyles((theme) => {
    return {
        ...regularFormsStyle,
        descriptionTextArea: {
            width: "100%",
            height: 150
        },
        emailTags: {
            width: "100%",
            borderBottom: "1px solid #D2D2D2 !important"
        }
    };
})


export default function DataLoader() {
    const classes = useStyles()
    const dispatch = useDispatch()
    useEffect(() => {
        loadData()
    }, [])
    const loadData = () => {
        CallApi({
            url: 'app/get_constants',
            method: 'get',
        }).then((res) => {
            if (res.success) {
                dispatch({
                    type: 'SET_CONSTANTS',
                    data: res.data
                })
            } else {
                Toast(400, 'App loading failed!')
            }
        }).finally(() => {
        })
    }

    return (
        <div>

        </div>
    );
}