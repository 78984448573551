import withStyles from '@material-ui/core/styles/withStyles';
import Button from 'components/CustomButtons/Button';
import ISO6391 from "iso-639-1";
import { default as React } from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
const styles = {
    textCenter: {
        textAlign: 'center'
    }
};
class CustomComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            languages: [],
            selectedLanguage: this.props.value,
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({
                selectedLanguage: this.props.value
            })
        }
    }
    componentDidMount() {
        const languages = this.props.languages.map(item => {
            return {
                label: ISO6391.getNativeName(item),
                value: item
            }
        })
        this.setState({
            languages, 
            selectedLanguage: this.props.value
        })
    }
    render() {
        const { classes, direction } = this.props
        const { languages, selectedLanguage } = this.state
        return <div className={classes.textCenter}>
            <div>
                {
                    languages.map((item, key) => {
                        const button = <Button key={key} onClick={() => this.props.onChange(item.value)} color={selectedLanguage === item.value ? 'info' : 'white'}>{item.label}</Button>
                        if (direction === 'vertical') {
                            return <div key={key}>
                                {button}
                            </div>
                        } else {
                            return button
                        }
                    })
                }
            </div>
        </div>;
    }
}
CustomComponent.defaultProps = {
    value: '',
    direction: 'vertical'
}
CustomComponent.propTypes = {
    value: PropTypes.string,
    direction: PropTypes.string
}
const mapStatsToProps = ({ user }) => ({ user });
export default connect(mapStatsToProps)(
    withStyles(styles, { withTheme: true })(CustomComponent)
);