import FormControl from "@material-ui/core/FormControl";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Assignment from "@material-ui/icons/Assignment";
import RemoveRedEye from "@material-ui/icons/RemoveRedEye";
import Delete from "@material-ui/icons/Delete";

import ViewList from "@material-ui/icons/ViewList";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import axios from 'axios';
import Badge from "components/Badge/Badge";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Table from "components/Table/Table.jsx";
import jwt from "jsonwebtoken";
import moment from "moment";
import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import Datetime from "react-datetime";
import { connect } from 'react-redux';
import ReactTable from "react-table";
import "react-table/react-table.css";
import { api_url, random_string } from 'redux/config.js';
import { getScoreOfOneResponse } from "utils/Score";
import Toast from 'views/Toast/Toast';
import { TransReactTable } from "components/CustomReactTable/TransReactTable";
import { withTranslation } from "react-i18next";
import { t } from "i18next";

const styles = {
  ...regularFormsStyle,
  customInput: {
    marginBottom: 17
  }
};

class SurveyRespondentsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      show: false,
      responseData: [],
      survey: null,
      survey_id: 0,

      filterData: {
        from: Date.now(),
        to: Date.now(),
        emailidpass: "",
      },
      loading: false,
      pages: -1,
      pageSize: 5,
      paginationState: {},
    };
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(this.props.location.search);

    this.setState({
      survey_id: urlParams.get("id"),
    })

    this.getSurvey(urlParams.get("id"));
  }
  setFilterData = (name, value) => {
    let filterData = this.state.filterData;
    filterData[name] = value;
    this.setState({
      filterData
    })
  }
  getSurvey = (id) => {
    let self = this;
    const urlParams = new URLSearchParams(this.props.location.search);

    const url = api_url + `survey/get?id=${id}`;
    const header = 'Bearer '.concat(this.props.user.token);
    let headers = null;
    if (this.props.user.token) {
      headers = {
        Authorization: header
      };
    }
    this.props.dispatch({ type: 'LOAD_START' });
    axios({
      url,
      method: 'get',
      headers,
    })
      .then(function (response) {
        if (response.data.success) {
          self.setState({
            survey: response.data.data
          }, () => self.normalizeData(response.data.data.responses));
        } else {
          Toast(400, response.data.error)
        }
        self.props.dispatch({ type: 'LOAD_END' });
      })
      .catch(function (errors) {
        console.info(errors);
        self.props.dispatch({ type: 'LOAD_END' });
        if (!errors.response) {
          Toast(500);
          return;
        }
        Toast(errors.response.status);
      });
  };
  remove = (survey_id, response_id) => {
    if (window.confirm(t('Are you sure?'))) {
      const self = this;
      const url = api_url + `survey/remove_response`;

      let headers = null;
      const header = 'Bearer '.concat(this.props.user.token);

      if (this.props.user.token) {
        headers = {
          Authorization: header
        };
      }
      this.props.dispatch({ type: 'LOAD_START' });
      axios({
        url,
        method: 'delete',
        headers,
        params: {
          survey_id,
          response_id
        }
      })
        .then(function (response) {
          if (response.data.success) {

            const { responseData } = self.state
            const newData = responseData.filter((item) => item._id != response_id);
            self.setState({
              responseData: newData
            })
          } else {
            Toast(400, response.data.error)
          }
          self.props.dispatch({ type: 'LOAD_END' });
        })
        .catch(function (errors) {
          console.info(errors);
          self.props.dispatch({ type: 'LOAD_END' });
          if (!errors.response) {
            Toast(500);
            return;
          }
          Toast(errors.response.status);
        });
    }
  }
  normalizeData = (responses) => {
    const survey = this.state.survey
    const questions = survey.questions
    const ndata = responses.map((item, key) => {
      const questionsByLanguage = questions.filter(item => item.language === item.language)[0]
      const action = (
        <div key={key}>

          <Button
            justIcon
            size="sm"
            color="info"
            onClick={
              () => {
                const params = {
                  survey_id: survey._id,
                  response_id: item._id
                }
                var win = window.open(`/public/survey_answer/${params.survey_id}/${params.response_id}`, '_blank');
                win.focus();
              }
            }
          >
            <RemoveRedEye />
          </Button>
          <Button
            justIcon
            size="sm"
            color="danger"
            onClick={() => this.remove(survey._id, item._id)}
          >
            <Delete />
          </Button>
        </div>
      );
      const score = getScoreOfOneResponse(questionsByLanguage, item.answers)
      return {
        _id: item._id,
        emailidpass: item.emailidpass,
        date: moment(item.created_at).format("MMMM Do YYYY, h:mm:ss a"),
        score: score.toFixed(2),
        action
      };
    });
    this.setState({
      responseData: ndata
    })
  }
  sendFilterData = () => {
    const filterData = this.state.filterData
    const responseData = this.state.survey.responses

    const from = moment(filterData.from).startOf('day').valueOf()
    const to = moment(filterData.to).endOf('day').valueOf()
    
    const filtered = responseData.filter((item) => {
      const created_at = moment(item.created_at).valueOf()
      if (created_at > from && created_at <= to) {
        if (filterData.emailidpass.length > 0) {
          return item.emailidpass.indexOf(filterData.emailidpass.trim()) >= 0
        } else {
          return true
        }
      } else {
        return false
      }
    })
    this.normalizeData(filtered)
  }
  render() {
    const { classes } = this.props;
    const { survey } = this.state
    if (!survey) return null;

    const column = [
      {
        Header: t("Email ID"),
        accessor: "emailidpass"
      },
      {
        Header: t('Date'),
        accessor: "date"
      }, {
        Header: t('Score'),
        accessor: "score"
      },
      {
        Header: t('Actions'),
        accessor: "action",
        sortable: false,
        minWidth: 150
      }
    ];
    return (
      <div>
        {this.state.alert}
        <GridContainer>
          <GridItem xs={12} sm={12}>
            <Card>
              <CardHeader color="success" icon>
                <CardIcon color="success">
                  <ViewList />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>{survey.title}</h4>
              </CardHeader>
              <CardBody>
                <p>
                  {t('Total Resonses')} <Badge color="info">{survey.responses.length}</Badge>
                </p>

              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12}>
            <Card>
              <CardHeader color="success" icon>
                <CardIcon color="success">
                  <Assignment />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>{t('Filter')}</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>

                  <GridItem xs={12} md={12}>
                    <CustomInput
                      labelText={t('Search by E-mail OR by ID OR password')}
                      id="Title"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "text",
                        onChange: (e) => this.setFilterData('emailidpass', e.target.value)
                      }}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} md={4}>
                    <FormControl fullWidth>
                      <Datetime
                        className={classes.customInput}
                        timeFormat={false}
                        inputProps={{ placeholder: "From" }}
                        onChange={(e) => this.setFilterData('from', e.valueOf())}
                        value={this.state.filterData.from}
                        dateFormat="DD/MM/YYYY"
                        closeOnSelect={true}

                      />
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} md={4}>
                    <FormControl fullWidth>
                      <Datetime
                        className={classes.customInput}
                        timeFormat={false}
                        inputProps={{ placeholder: "To" }}
                        onChange={(e) => this.setFilterData('to', e.valueOf())}
                        value={this.state.filterData.to}
                        closeOnSelect={true}
                        dateFormat="DD/MM/YYYY"

                      />
                    </FormControl>
                  </GridItem>

                  <GridItem xs={12} md={4}>
                    <FormControl fullWidth>
                      <Button color="success" size="sm" onClick={() => {

                        this.sendFilterData();
                      }}>{t('Send Filter')}</Button>
                    </FormControl>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem sm={12}>
            <TransReactTable
              defaultPageSize={this.state.pageSize}
              data={this.state.responseData}
              columns={column}

            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}


const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps)(withTranslation()(withStyles(styles)(SurveyRespondentsPage)));