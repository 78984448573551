import React, { useState, useEffect } from 'react';
import TagsInput from '@incoqnito.io/react-tagsinput';
import FormControl from "@material-ui/core/FormControl";
// @material-ui/core components
import { makeStyles } from '@material-ui/core'

import Send from "@material-ui/icons/Send";
import ViewList from "@material-ui/icons/ViewList";
import { Editor } from "@tinymce/tinymce-react";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import axios from 'axios';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ISO6391 from "iso-639-1";
import moment from "moment";
import Datetime from "react-datetime";
import { connect } from 'react-redux';
import CustomSelect from "components/CustomSelect/CustomSelect"
import "react-table/react-table.css";
import { api_url } from 'redux/config.js';
import { verifyEmail } from "utils/Validate";
import Toast from 'views/Toast/Toast';
import Muted from "components/Typography/Muted"
import { useSelector } from 'react-redux';
import LoadingImg from 'assets/img/loading.gif'
const useStyles = makeStyles((theme) => {
    return {
        modal: {
            position: 'fixed',
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#00000036',
            zIndex: 9999
        }
    };
})

const loadIconSize = 30

function Loading() {

    return (
        <div>
            <img src={LoadingImg} alt='Loading Image' width={loadIconSize} height={loadIconSize} />
        </div>
    );
}

export default function LoadingModal() {
    const classes = useStyles()
    
    return (
        <div className={classes.modal}>
            <Loading />
        </div>
    );
}