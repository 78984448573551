import React, { useState, useEffect } from 'react';
import TagsInput from '@incoqnito.io/react-tagsinput';
import FormControl from "@material-ui/core/FormControl";
// @material-ui/core components
import { makeStyles } from '@material-ui/core'

import Send from "@material-ui/icons/Send";
import ViewList from "@material-ui/icons/ViewList";
import { Editor } from "@tinymce/tinymce-react";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import axios from 'axios';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ISO6391 from "iso-639-1";
import moment from "moment";
import Datetime from "react-datetime";
import { connect } from 'react-redux';
import CustomSelect from "components/CustomSelect/CustomSelect"
import "react-table/react-table.css";
import { api_url } from 'redux/config.js';
import ReactTable from "react-table";

import Toast from 'views/Toast/Toast';
import Muted from "components/Typography/Muted"
import { useSelector } from 'react-redux';
import { Delete, Reply } from '@material-ui/icons';
import { CallApi } from 'utils/Request';
import LoadingModal from 'components/Loading/Loading';
import ReactTables from 'views/Other/Tables/ReactTables';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PaymentIcon from '@material-ui/icons/Payment';
import CustomCheckbox from 'components/CustomSelect/CustomCheckbox';
import { TransReactTable } from 'components/CustomReactTable/TransReactTable';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => {
    return {
        ...regularFormsStyle,
        descriptionTextArea: {
            width: "100%",
            height: 150
        },
        actionBtn: {
            marginRight: '1rem'
        }
    };
})


export default function CostumerListPage(props) {
    const classes = useStyles()
    const [isLoading, setIsLoading] = useState(false)
    const {t} = useTranslation()
    const [costumerList, setCostumerList] = useState([])

    useEffect(() => {
        loadData()
    }, [])
    const loadData = () => {
        setIsLoading(true)
        CallApi({
            url: 'user/costumers',
            method: 'get',
        }).then((res) => {
            if (res.success) {
                setCostumerList(res.data)
            } else {
                Toast(400, res.error);
            }

        }).finally(() => {
            setIsLoading(false)
        })
    }

    // const onAddNewPlan = () => {
    //     const newplan = {
    //         title: title,
    //         response_limit: response_limit,
    //         price: price,
    //         months: months
    //     }
    //     if (response_limit < 1) {
    //         Toast(400, 'Please set positive Response Limit')
    //         return
    //     }
    //     if (price < 0) {
    //         Toast(400, 'Please set positive Price')
    //         return
    //     }
    //     if (months < 1) {
    //         Toast(400, 'Please set positive Period')
    //         return
    //     }
    //     setIsLoading(true)
    //     CallApi({
    //         url: 'response_plan/add',
    //         method: 'post',
    //         data: newplan
    //     }).then((res) => {
    //         if (res.success) {
    //             loadData()
    //         } else {
    //             Toast(400, res.error)
    //         }
    //     }).finally(() => {
    //         setIsLoading(false)
    //     })
    // }
    // const onDeletePlan = (id) => {
    //     if (!window.confirm('Are you sure?')) {
    //         return
    //     }
    //     setIsLoading(true)
    //     CallApi({
    //         url: 'response_plan/delete/' + id,
    //         method: 'delete',
    //     }).then((res) => {
    //         if (res.success) {
    //             loadData()
    //         } else {
    //             Toast(400, res.error)
    //         }
    //     }).finally(() => {
    //         setIsLoading(false)
    //     })
    // }
    const onToggleActivate = (user_id, check) => {
        if (window.confirm(t('Are you sure?'))) {
            const updateData = {
                user_id,
                active: check
            }
            updateCostumer(updateData)
        }
    }
    const onToggleFreeResponse = (user_id, check) => {
        if (window.confirm(t('Are you sure?'))) {
            const updateData = {
                user_id,
                free_response: check
            }
            updateCostumer(updateData)
        }
    }
    const onDeleteUser = (user_id) => {
        if (window.confirm(t('Are you sure to delete this user?'))) {
            const updateData = {
                user_id,
                deleted: true
            }
            updateCostumer(updateData)
        }
    }
    const updateCostumer = (updateData) => {
        setIsLoading(true)
        CallApi({
            url: 'user/update_user',
            method: 'post',
            data: updateData
        }).then((res) => {
            if (res.success) {
                loadData()
            } else {
                Toast(400, res.error);
            }

        }).finally(() => {
            setIsLoading(false)
        })
    }
    const costumerListComponent = costumerList.map((costumer, key) => {
        return {
            ...costumer,
            activate_action: <div>
                <CustomCheckbox
                    checked={costumer.active}
                    onChange={(check) => onToggleActivate(costumer._id, check)}
                    label={costumer.active ? t('Active') : t('Deactive')}
                />
            </div>,
            set_free_response_action: <div>
                <CustomCheckbox
                    checked={costumer.free_response}
                    onChange={(check) => onToggleFreeResponse(costumer._id, check)}
                    label={costumer.free_response ? t('Active') : t('Deactive')}
                />
            </div>,
            actions: <div>
                <Button color='info' justIcon className={classes.actionBtn}
                    onClick={() => {
                        props.history.push('/admin/costumer_update/' + costumer._id)
                    }}
                ><EditIcon /></Button>
                <Button color='danger' justIcon className={classes.actionBtn}
                    onClick={() => onDeleteUser(costumer._id)}>
                    <DeleteIcon />
                </Button>
                <Button color='warning' justIcon className={classes.actionBtn}
                    onClick={() => {
                        props.history.push('/admin/costumer_payments/' + costumer._id)
                    }}
                ><PaymentIcon /></Button>
            </div>
        }
    })
    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12}>
                    <Card>
                        <CardHeader color="success" icon>
                            <CardIcon color="success">
                                <AccountCircleIcon />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>Customer</h4>
                        </CardHeader>
                        <CardBody>
                            <Button color="info" onClick={() => {
                                props.history.push('/admin/costumer_create/')
                            }}>{t('Create')}</Button>
                            <TransReactTable
                                data={costumerListComponent}
                                columns={[
                                    {
                                        Header: t("First name"),
                                        accessor: "first_name",
                                        width: 150
                                    },
                                    {
                                        Header: t("Last name"),
                                        accessor: "last_name",
                                        width: 150
                                    },
                                    {
                                        Header: t("Company Name"),
                                        accessor: "company_name",
                                        width: 150
                                    },
                                    {
                                        Header: t("Email"),
                                        accessor: "email",
                                        width: 150
                                    },
                                    {
                                        Header: t("Activate"),
                                        accessor: "activate_action",
                                        width: 150
                                    },
                                    {
                                        Header: t("Free Response"),
                                        accessor: "set_free_response_action",
                                        width: 150
                                    },
                                    {
                                        Header: t("Actions"),
                                        accessor: "actions",
                                        sortable: false,
                                        filterable: false
                                    }
                                ]}
                                defaultPageSize={10}
                                className="-striped -highlight"
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
            {isLoading && <LoadingModal />}
        </div>
    );
}