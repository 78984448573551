import withStyles from '@material-ui/core/styles/withStyles';
import commonStyle from "assets/jss/common/common";
import Button from 'components/CustomButtons/Button';
import CustomSelect from "components/CustomSelect/CustomSelect";
import StarRating from "components/StarRating/StarRating";
import Danger from "components/Typography/Danger";
import PropTypes from "prop-types";
import { default as React, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { question_types, emoticons } from "redux/config";
import { isMobile } from "react-device-detect"
import { zoomIn } from 'react-animations';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

const styles = {
    ...commonStyle,
    optionBtn: {
        minWidth: 150
    },
    emojiContainer: {
        display: 'flex',
        animationName: 'zoomIn',
        animationDuration: '0.3s',
    },
    '@keyframes zoomIn': zoomIn,
    emoji: {
        padding: 20,
        width: '100%',
        border: '4px solid transparent',
        cursor: 'pointer'
    },
    selected_emoji: {
        width: '100%',
        border: '4px solid darkslategray',
        padding: 20,
        cursor: 'pointer'
    },

};

function RadioButtonView(props) {
    const { classes, emojimode } = props


    const [questionSchema, setQuestionSchema] = useState(null)
    const [question, setQuestion] = useState(props.question)
    const [answer, setAnswer] = useState(props.answer)

    const [error, setError] = useState(null)
    useEffect(() => {

        let questionSchema_ = question_types.filter((item) => item.type === props.question.question.type)[0]
        setQuestionSchema(questionSchema_)
        setQuestion(props.question)
        setAnswer(props.answer || {
            id: question.id,
            value: null
        })
    }, [props.question, props.answer])



    const getInitAnswer = (q) => {
        const questionBody = q.question
        if (questionBody.direction === 'horizontal' || questionBody.direction === 'vertical' || questionBody.direction === 'dropdown') {
            return questionBody.options[0].value
        } else {
            return 4
        }
    }
    const setAnswerData = (value) => {
        var answerValue = null
        if (answer.value === value) {
            answerValue = null
        } else {
            answerValue = Number(value)
        }
        const newAnswer = {
            ...answer,
            value: answerValue
        }
        setAnswer(newAnswer)
        saveAnswerData(newAnswer)

    }
    useEffect(() => {
        setTimeout(() => {
            const a = {
                id: question.id,
                ...answer
            }
            props.onChange({
                isValid: isValid(a), answer: a
            }, true)
        }, 100);
    }, [])

    const saveAnswerData = (newAnswer) => {
        props.onChange({
            isValid: isValid(newAnswer), answer: newAnswer
        })
    }
    const isValid = (a) => {
        if (question.required && (!a || !a.value)) {
            showError(t('This question is required'));
            return false
        }
        return true
    }
    const showError = (message) => {
        setError(<Danger>*{message}</Danger>)
    }
    const isEmojiMode = () => {
        const { emojimode } = props
        const questionBody = question.question
        const is_emoji = emojimode && questionBody.direction !== '5star' && questionBody.direction !== '10star'
        return is_emoji
    }
    const questionBody = question.question
    const answerValue = answer ? answer.value : null
    if (isEmojiMode()) {
        return <div className={classes.emojiContainer}>
            {
                questionBody.options.slice(0).reverse().map((item, key) => {
                    const emoji = emoticons.filter(emoticon => (emoticon.numValue === item.value))[0]
                    const emoji_class = Number(item.value) === Number(answerValue) ? classes.selected_emoji : classes.emoji;
                    return <div style={{ width: '20%' }}>
                        <img key={key} src={emoji ? emoji.image : ''} alt={item.emoticon} className={emoji_class} onClick={() => setAnswerData(item.value)} />
                        <p style={{ textAlign: 'center' }}><b>{item.label}</b></p>
                    </div>
                })
            }
        </div>
    }
    return <div >
        {error}
        {

            questionBody.direction === 'horizontal' && <div>
                {
                    questionBody.options.map((item, key) => {
                        return <Button fullWidth={isMobile} className={classes.optionBtn} key={key} color={Number(item.value) === Number(answerValue) ? "info" : null} onClick={() => setAnswerData(item.value)}>{item.label}</Button>
                    })
                }
            </div>
        }
        {

            questionBody.direction === 'vertical' && <div>
                {
                    questionBody.options.map((item, key) => {
                        return <div key={key}>
                            <Button fullWidth={isMobile} className={classes.optionBtn} color={Number(item.value) === Number(answerValue) ? "info" : null} onClick={() => setAnswerData(item.value)}>{item.label}
                            </Button>
                        </div>
                    })
                }
            </div>
        }
        {

            questionBody.direction === 'dropdown' && <div>

                <CustomSelect
                    onChange={data => {
                        setAnswerData(questionBody.options.filter(item => item.value === data.value)[0].value);
                    }}
                    placeholder={t('Choose')}
                    options={questionBody.options.map((item) => ({ label: item.label, value: item.value }))}
                    value={questionBody.options.filter((item) => item.value === answerValue)}
                />
            </div>
        }
        {

            questionBody.direction === '5star' && <div>
                <StarRating total={5} onChange={(star) => setAnswerData(star)} star={answerValue} />
            </div>
        }
        {
            questionBody.direction === '10star' && <div>
                <StarRating total={10} onChange={(star) => setAnswerData(star)} star={answerValue} />
            </div>
        }
    </div>;
}

RadioButtonView.defaultProps = {
    question: null,
    answer: null,
    onChange: () => { },
    onInit: () => { }
}

RadioButtonView.propTypes = {
    question: PropTypes.object,
    answer: PropTypes.object,
    onChange: PropTypes.func,
    onInit: PropTypes.func
}
const mapStatsToProps = ({ user }) => ({ user });
export default connect(mapStatsToProps)(
    withTranslation()(withStyles(styles, { withTheme: true })(RadioButtonView))
);