export default {

    "* Please choose more options.": "* Επιλέξτε περισσότερες επιλογές.",
    "1. Download application in your local drive": "1. Κατεβάστε την εφαρμογή στον υπολογιστή σας",
    "2. Go to email list edit page and copy ID of email list": "2. Μεταβείτε στη σελίδα επεξεργασίας λίστας email και αντιγράψτε το αναγνωριστικό της λίστας email",
    "3. Open downloaded application": "3. Ανοίξτε την εφαρμογή που κατεβάσατε",
    "4. Select directory where you saved csv files": "4. Επιλέξτε τον φάκελο όπου αποθηκεύτε τα αρχεία csv",
    "400 Invalid input!": "400 Μη έγκυρη εισαγωγή!",
    "404 Not found!": "404 δεν βρέθηκε!",
    "5. Enter email list ID you copied in step 2": "5. Εισαγάγετε το αναγνωριστικό λίστας email που αντιγράψατε στο βήμα 2",
    "500 Server error!": "500 Σφάλμα Διακομιστή!",
    "6. Click start button": "6. Κάντε κλικ στο κουμπί START",
    "Actions": "Ενέργειες",
    "Actions": "Ενέργειες",
    "Activate": "Ενέργοποιημένο",
    "Activate failed": "Η ενεργοποίηση απέτυχε",
    "Activate success": "Επιτυχημένη ενεργοποίηση",
    "Active": "Ενεργό",
    "Activity": "Δραστηριότητα",
    "Add": "Προσθήκη",
    "Add New": "Προσθεσε νεο",
    "Add new email in list": "Προσθήκη νέου email στη λίστα",
    "Add New End Message": "Προσθήκη νέου μηνύματος τέλους",
    "Add New Question": "Προσθήκη νέας ερώτησης",
    "Add passwords": "Προσθήκη κωδικών πρόσβασης",
    "Add Question": "Προσθήκη ερώτησης",
    "Add to costumers": "Προσθήκη στους πελάτες",
    "Add your question in section": "Προσθέστε την ερώτησή σας στην ενότητα",
    "Additional Emails": "Πρόσθετα email",
    "All": "Ολα",
    "Allotment Survey": "Έτοιμα ερωτηματολόγια",
    "ALLOTMENT SURVEYS": "ΕΤΟΙΜΑ ΕΡΩΤΗΜΑΤΟΛΟΓΙΑ",
    "Already existing from~to range": "Υπάρχει ήδη από ~ έως εύρος",
    "Amount": "Ποσό",
    "An Error occured with your payment": "Παρουσιάστηκε σφάλμα με την πληρωμή σας",
    "Analytical": "Αναλυτικά",
    "Answer": "Απαντήσεις",
    "Answer By Adding Email": "Απαντήσεις με απαίτηση του email",
    "Answer by ID free choice": "Απάντηση με κωδικό ελεύθερης επιλογής",
    "Answer Type Choose, one of the options bellow how you need to protect you Survey.": "Τύπος Απάντησης - Επιλέξτε, μία από τις παρακάτω επιλογές, πώς θα θέλατε να προστατεύσετε τα ερωτηματολόγιά σας",
    "Answers": "Απαντήσεις",
    "API key": "Κλειδί API",
    "Are you sure?": "Είσαι σίγουρος?",
    "Attempted": "ΑΠΑΝΤΗΘΗΚΕ",
    "Authentication token is invalid": "Το διακριτικό ελέγχου ταυτότητας δεν είναι έγκυρο",
    "Automatic": "Αυτόματο",
    "Back": "Πίσω",
    "Buy more answers": "Αγοράστε περισσότερες απαντήσεις",
    "Campaign": "Καμπάνια",
    "Campaign Detail": "Λεπτομέρειες καμπάνιας",
    "Campaign name": "Όνομα καμπάνιας",
    "Cancel": "Άκυρο",
    "Change": "Αλλαγή",
    "Change Password": "Αλλαγή κωδικού",
    "CHOICES": "ΕΠΙΛΟΓΗ",
    "Choose": "Επιλέγω",
    "Choose Answers Type": "Επιλέξτε Τύπο απαντήσεων",
    "Choose costomers for allotment": "Επιλέξτε πελάτες για κατανομή",
    "Choose date": "Επιλέξτε ημερομηνία",
    "Choose groups(contact):": "Επιλέξτε ομάδες (επικοινωνίας):",
    "Choose Language": "Διάλεξε γλώσσα",
    "Choose option:": "Επιλέξτε :",
    "Choose Period 2th*:": "Επιλέξτε 2η Περίοδο* :",
    "Choose Position for Answers": "Επιλέξτε Θέση για Απαντήσεις",
    "Choose question:": "Επιλέξτε ερώτηση:",
    "Choose section:": "Επιλέξτε ενότητα:",
    "Choose Type of Answer": "Επιλέξτε Τύπο Απάντησης",
    "City": "Πόλη",
    "Close": "Κλείσε",
    "Company Address": "Διεύθυνση εταιρίας",
    "Company Name": "Όνομα εταιρείας",
    "Company Name": "Όνομα εταιρείας",
    "Company Tax Number": "ΑΦΜ",
    "Company Tax Office": "ΔOY",
    "Comparation": "Σύγκριτικά απότελέσματα ",
    "Confirm Password": "Επιβεβαίωση Κωδικού",
    "Confirmation": "Επιβεβαίωση",
    "Connection error!": "Σφάλμα σύνδεσης!",
    "Copied to clipboard": "Αντιγράφηκε στο πρόχειρο",
    "Copy": "Αντίγραφή",
    "Costumer Payments": "Πληρωμές πελατών",
    "Costumer Update": "Ενημέρωση πελατών",
    "Costumers": "Πελάτες",
    "Count": "Αρίθμηση",
    "Create": "Δημιουργία",
    "Create Allot Survey": "Δημιουργία Πρωτοτύπων ερωτηματολογίων",
    "Create Email": "Δημιουργία Email",
    "CREATE MAIL LIST": "ΔΗΜΙΟΥΡΓΙΑ ΛΙΣΤΑ EMAILS",
    "Create new emaillist": "Δημιουργία νέας λίστας email",
    "Create new survey": "Δημιουργία νέας έρευνας",
    "Create Personal Information (Sex/Age/Nationality)": "Δημιουργία προσωπικών στοιχείων (Φύλο/Ηλικία/Εθνικότητα)",
    "Create survey": "Δημιουργία έρευνας",
    "Create survey link": "Δημιουργία συνδέσμου έρευνας",
    "Create Survey Link, QR code": "Δημιουργήστε Link για το Ερωτηματολόγιο, QR code",
    "CSV": "CSV",
    "Current Password": "Τρέχων κωδικός πρόσβασης",
    "Custom Message": "Προσαρμοσμένο μήνυμα",
    "Daily": "Καθημερινά",
    "Dashboard": "Κεντρικός Πίνακας",
    "Date": "Ημερομηνία",
    "Deactive": "Ανενεργό",
    "Description": "Περιγραφή",
    "Description": "Περιγραφή",
    "Don't close application": "Μην κλείσετε την εφαρμογή",
    "Done successfully!": "Έγινε με επιτυχία!",
    "Download CSV": "Λήψη CSV",
    "Download Sync app here": "Κατεβάστε την Εφαρμογή Συγχρονισμού από εδώ",
    "E-mail List": "Λίστα ηλεκτρονικού ταχυδρομείου",
    "E-mail sender": "Αποστολέας e-mail",
    "E-mails": "E-mails",
    "ECC": "ECC",
    "Edit": "Επεξεργασία",
    "Edit Email": "Επεξεργασία email",
    "Edit question": "Επεξεργασία ερώτησης",
    "Edit survey": "Επεξεργασία έρευνας",
    "email": "email",
    "Email": "Email",
    "Email Account": "Λογαριασμός ηλεκτρονικού ταχυδρομείου",
    "Email address": "Λίστα email",
    "Email ID": "Email ID",
    "Email list": "Λίστα emails",
    "Email list not found": "Η λίστα email δεν βρέθηκε",
    "Email Name": "Όνομα Email",
    "Email or UserID": "Email ή Χρήστη",
    "Email Sender": "Αποστολέας email",
    "Email sending failed": "Η αποστολή email απέτυχε",
    "Email setting": "Ρυθμίσης email αποστολών",
    "Email Title": "Τίτλος Email",
    "Emails Group": "Ομάδα email",
    "Emails Group:": "Ομάδα email:",
    "Emojies + Flags at the Beginning": "Emojies + Σημαίες γλωσσών στην αρχή",
    "End Message": "Μήνυμα τέλους",
    "End Messages": "Μήνυμα τέλους",
    "Enter Answer Set name": "Εισαγάγετε Απάντηση Τοποθετήστε όνομα",
    "Enter E-mails (optional)": "Εισαγάγατε E-mails (προαιρετικά)",
    "Excel": "Excel",
    "Expire date": "Ημερομηνία Λήξης",
    "Expire Date": "Ημερομηνία Λήξης",
    "expired": "Λήξη",
    "Expired": "Έχει λήξει",
    "Export": "Εξαγωγή",
    "Female": "Θήλυ",
    "File not upload": "Το αρχείο δεν μεταφορτώνεται",
    "Filter": "Φίλτρο",
    "Filter options": "Επιλογές φίλτρου",
    "First name": "Όνομα",
    "First name": "Όνομα",
    "Free Field": "Ελεύθερο πεδίο",
    "Free ID": "Δωρεάν κωδικός",
    "Free Response": "Ελεύθερη απάντηση",
    "Free to Answer": "Ανοικτό για απάντηση χωρίς περιορισμούς",
    "From": "Από",
    "Get started": "Ας αρχίσουμε",
    "Groups": "Ομάδες",
    "Help": "Βοήθεια",
    "Home": "Αρχική",
    "How to use application": "Πως να χρησιμοποιήσετε την εφαρμογή",
    "Incorrect password": "Λάθος κωδικός",
    "Invalid email": "Μη έγκυρη διεύθυνση e-mail!",
    "Invalid email address!": "Μη έγκυρη διεύθυνση φιλοξενίας",
    "Invalid email format": "Μη έγκυρη μορφή email",
    "Invalid from or to value": "Μη έγκυρο αναγνωριστικό ή email",
    "Invalid hosting address": "Μη έγκυρη διεύθυνση φιλοξενίας",
    "Invalid id or email": "Μη έγκυρο email ή ID",
    "Invalid password format": "Μη έγκυρη μορφή κωδικού πρόσβασης",
    "Invalid port": "Μη έγκυρη θύρα",
    "Invalid smtp configuration": "Μη έγκυρη διαμόρφωση smtp",
    "Invalid survey": "Μη έγκυρο Ερωτημαοτολόγιο",
    "Invalid survey link": "Μη έγκυρος σύνδεσμος έρευνας",
    "Label": "Επιγραφή",
    "Language": "Γλώσσα",
    "Last name": "Επίθετο",
    "Length": "Μήκος",
    "List name": "Λίστα ονομάτων",
    "Loading": "Φόρτωση",
    "Log in": "Σύνδεση",
    "Login Page": "Σελίδα σύνδεσης",
    "Male": "Άρρεν",
    "Manual": "Χειροκίνητα",
    "Manual password": "Χειροκίνητος κωδικός πρόσβασης",
    "Message": "Μήνυμα",
    "Minimum Password length is 6": "Το ελάχιστο μήκος κωδικού πρόσβασης είναι 6",
    "Mongodb has error": "Το Mongodb παρουσίασε σφάλμα",
    "Months": "Μήνες",
    "N° of expired passwords": "Αριθμός ληγμένων κωδικών πρόσβασης",
    "N° of passwords": "Αριθμός κωδικών πρόσβασης",
    "N° of unused passwords": "Αριθμός αχρησιμοποίητων κωδικών πρόσβασης",
    "Name": "Όνομα",
    "name": "όνομα",
    "Name for Message to save": "Όνομα για το μήνυμα προς αποθήκευση",
    "New email added!": "Προστέθηκε νέο email!",
    "New Password": "Νέος κωδικός πρόσβασης",
    "NEW RESPONSE": "ΝΕΑ ΑΠΑΝΤΗΣΗ",
    "New Section": "Νέα Ενότητα",
    "Next": "Επόμενο",
    "No": "Όχι",
    "No Attempt": "Καμία προσπάθεια",
    "No data": "Χωρίς δεδομένα",
    "NO LIMITS": "ΧΩΡΙΣ ΟΡΙΑ",
    "No rows found": "Δεν βρέθηκαν σειρές",
    "No translatable option": "Δεν υπάρχει δυνατότητα μετάφρασης",
    "No. of Response": "Αριθμός ανταπόκρισης",
    "No. of Responses": "Αριθμός απαντήσεων",
    "Number of Passwords": "Αριθμός κωδικών πρόσβασης",
    "of": "του",
    "Old password is not correct": "Ο παλιός κωδικός πρόσβασης δεν είναι σωστός",
    "OPEN RESPONDENT'S SURVEY": "ΑΝΟΙΧΤΑ ΕΡΩΤΗΜΑΤΟΛΟΓΙΑ ΓΙΑ ΑΠΑΝΤΗΣΗ",
    "Other option": "Άλλη επιλογή",
    "Package Name": "Ονομα πακέτου",
    "Page": "Σελίδα",
    "Password": "Κωδικός πρόσβασης",
    "Password confirm mismatched": "Η επιβεβαίωση κωδικού πρόσβασης δεν ταιριάζει",
    "Password expired": "Ο κωδικός πρόσβασης έληξε",
    "Password Generator": "Αυτόματη δημιουργία Κωδικού",
    "Password length should be at least 6 letters": "Το μήκος του κωδικού πρόσβασης πρέπει να έχει τουλάχιστον 6 γράμματα",
    "Password not matched": "Ο κωδικός πρόσβασης δεν ταιριάζει",
    "Password should be at least 6 characters": "Ο κωδικός πρόσβασης πρέπει να αποτελείται από τουλάχιστον 6 χαρακτήρες",
    "Password should be at least 6 letters": "Ο κωδικός πρόσβασης πρέπει να αποτελείται από τουλάχιστον 6 γράμματα",
    "Password,Expire Date,Used": "Κωδικός πρόσβασης, Ημερομηνία Λήξης, Χρησιμοποιημένος",
    "Passwords": "Κωδικοί",
    "PASWORDS TO PROTECT YOUR SURVEYS": "ΚΩΔΙΚΟΙ ΓΙΑ ΤΗΝ ΠΡΟΣΤΑΣΙΑ ΤΩΝ ΕΡΕΥΝΩΝ ΣΑΣ",
    "Pay Now": "Πληρώστε τώρα",
    "Payment Detail": "Λεπτομέρεια πληρωμής",
    "Payment details": "Οι λεπτομέρειες πληρωμής",
    "PDF": "PDF",
    "Period": "Περίοδο",
    "Period 1 Score:": "Βαθμολογία περιόδου 1:",
    "Period 2 Score:": "Βαθμολογία περιόδου 2:",
    "Period1:": "Περίοδος 1:",
    "Period2:": "Περίοδος 2:",
    "Period1": "Περίοδος 1",
    "Period2": "Περίοδος 2",
    "Personal Information": "Προσωπικές πληροφορίες",
    "Personalize the link": "Εξατομίκευση του συνδέσμου",
    "Personalized Link": "Εξατομικευμένος σύνδεσμος",
    "Please add options": "Προσθέστε επιλογές",
    "Please choose more options": "Επιλέξτε περισσότερες επιλογές",
    "Please choose number of selectable options": "Επιλέξτε τον αριθμό των επιλέξιμων επιλογών",
    "Please choose title": "Επιλέξτε τίτλο",
    "Please enter all field properly": "Παρακαλώ εισάγετε όλα τα πεδία σωστά",
    "Please enter campaign name": "Εισαγάγετε το όνομα της καμπάνιας",
    "Please enter email": "Εισαγάγετε email",
    "Please enter email list or emails": "Εισαγάγετε τη λίστα email ή emails",
    "Please enter email sender": "Παρακαλώ εισάγετε το email toy αποστολέα",
    "Please enter email subject": "Εισαγάγετε το θέμα του email",
    "Please enter language": "Παρακαλώ εισάγετε τη γλώσσα",
    "Please enter option label": "Εισαγάγετε την ετικέτα επιλογής",
    "Please enter password": "Παρακαλώ εισάγετε κωδικό πρόσβασης",
    "Please enter personalized link": "Εισαγάγετε τον εξατομικευμένο σύνδεσμο",
    "Please enter survey": "Παρακαλώ εισάγετε τo Ερωτηματολόγιο",
    "Please enter user name": "Εισαγάγετε το όνομα χρήστη",
    "Please input more than 3 check options": "Εισαγάγετε περισσότερες από 3 επιλογές ελέγχου",
    "Please insert question name": "Εισαγάγετε το όνομα της ερώτησης",
    "Please set count between 1~50": "Ρυθμίστε το πλήθος μεταξύ 1-50",
    "Please set length between 1~50": "Ορίστε το μήκος μεταξύ 1~50",
    "Please set positive Period": "Ορίστε θετική περίοδο",
    "Please set positive Price": "Ορίστε θετική τιμή",
    "Please set positive Response Limit": "Ορίστε όριο θετικής απόκρισης",
    "Please set title": "Ορίστε τον τίτλο",
    "Preview": "Προεπισκόπηση",
    "Previous": "Προηγούμενο",
    "Price": "Τιμή",
    "Price(€)": "Τιμή (€)",
    "Print": "Εκτύπωση",
    "Profile": "Προφίλ",
    "Protection With ID Password": "Προστασία του ερωτηματολογίου με κωδικό",
    "Purchase Date": "Ημερομηνία αγοράς",
    "Purchase Responds": "Η αγορά Απαντήσεων",
    "Purchased Response Plans": "Αγορασμένο πλάνο Απαντήσεων",
    "QR image": "QR",
    "Question": "Ερώτηση",
    "Question with Rating": "Ερώτηση με Βαθμολογία",
    "Random password": "Τυχαίος κωδικός πρόσβασης",
    "Register": "ΕΓΓΡΑΦΗ",
    "Register Page": "Σελίδα εγγραφής",
    "Relative": "Σχετικό",
    "Relative Answers": "Σχετικές Απαντήσεις",
    "Remove": "Αφαίρεση",
    "Remove": "Αφαίρεση",
    "Resend your survey to Not Attempt emails": "Στείλτε ξανά την έρευνά σας στα μηνύματα ηλεκτρονικού ταχυδρομείου που δεν απάντησαν",
    "Resonse Name": "Όνομα",
    "Respondent list": "Λίστα ερωτηθέντων",
    "Respondent Status": "Κατάσταση ερωτώμενου",
    "Response": "Απάντηση",
    "RESPONSE DETAILS": "ΣΤΟΙΧΕΙΑ ΑΠΑΝΤΗΣΗΣ",
    "Response Limit": "Όριο απαντήσεων",
    "Response Plan": "Πλάνο Απαντήσεων",
    "RESPONSE PLANS": "ΠΛΑΝΑ ΑΠΑΝΤΗΣΗΣ",
    "Responses": "Απαντήσεις",
    "Restart": "Επανεκκίνηση",
    "rows": "σειρές",
    "Save": "Αποθήκευση",
    "Save and add another": "Αποθηκεύστε και προσθέστε νέα ερώτηση",
    "Save Set": "Αποθήκευση όλης της ομάδας απαντήσεων",
    "Score": "Σκορ",
    "Score:": "Σκορ:",
    "Search by E-mail OR by ID OR password": "Αναζήτηση μέσω email ή με ID ή κωδικό πρόσβασης",
    "Section": "Ενότητα",
    "Section Average with Rating": "Μέσος όρος Ενότητας με Βαθμολογία",
    "Sections": "Ενότητες",
    "Select Saved Answers Group": "Επιλέξτε Ομάδα Αποθηκευμένων Απαντήσεων",
    "Select your Age": "Επιλέξτε την ηλικία σας",
    "Select your Nationality": "Επιλέξτε την εθνικότητα σας",
    "Select your Nationality": "Επιλέξτε την εθνικότητα σας",
    "Send campaign every day": "Καθημερινή Αποστολή",
    "Send campaign later": "Αποστολή καμπάνιας αργότερα",
    "Send campaign right now": "Αποστολή καμπάνιας τώρα",
    "Send date": "Ημερομηνία αποστολής",
    "Send Filter": "Αποστολή",
    "Send in Blue": "Αποστολή σε μπλε",
    "Send survey": "Αποστολή Ερωτηματολογίου",
    "SEND SURVEY LINK": "ΑΠΟΣΤΟΛΗ ΣΥΝΔΕΣΜΟΥ ΕΡΕΥΝΑΣ",
    "Send your survey": "Στείλτε την έρευνά σας",
    "Server error!": "Σφάλμα Διακομιστή!",
    "Size": "Μέγεθος",
    "Smtp host": "Smtp host",
    "Smtp port": "Θύρα Smtp",
    "SSL": "SSL",
    "Start date": "Ημερομηνία έναρξης",
    "Status": "Κατάσταση",
    "Subject": "Θέμα",
    "Successfully activated, you can use account now.": "Ενεργοποιήθηκε με επιτυχία, μπορείτε να χρησιμοποιήσετε τον λογαριασμό τώρα.",
    "Successfully added": "Προστέθηκε με επιτυχία",
    "Successfully copied to clipboard": "Αντιγράφηκε με επιτυχία στο πρόχειρο",
    "Successfully registered, please check your email to activate account.": "Η εγγραφή ολοκληρώθηκε με επιτυχία, ελέγξτε το email σας για να ενεργοποιήσετε τον λογαριασμό σας",
    "Successfully registered!": "Η εγγραφή έγινε με επιτυχία!",
    "Successfully saved": "Αποθηκεύτηκε με επιτυχία",
    "Successfully saved!": "Αποθηκεύτηκε με επιτυχία",
    "Successfully updated": "Ενημερώθηκε με επιτυχία",
    "Summary": "Περίληπτικά",
    "Survey": "Ερωτηματολόγιο",
    "survey": "Ερωτηματολόγιο",
    "SURVEY DETAILS": "ΣΤΟΙΧΕΙΑ ΕΡΕΥΝΑΣ",
    "SURVEY LINK": "ΣΥΝΔΕΣΜΟΣ ΕΡΩΤΗΜΑΤΟΛΟΓΙΟΥ",
    "Survey Name": "Όνομα έρευνας",
    "Survey passwords": "Κωδικοί πρόσβασης έρευνας",
    "Survey respondent": "Απαντημένα Ερωτηματολόγια",
    "Sync Email List": "Συγχρονισμός λίστας email",
    "Syncronize with local csv files": "Συγχρονισμός με τοπικά αρχεία csv",
    "Tax ID": "ΑΦΜ",
    "Tax Office": "ΔΟΥ",
    "Text reports": "Αναφορές με κείμενα",
    "There is no survey": "Δεν υπάρχει Ερωτηματολόγιο",
    "This ID will be used to sync local csv files with this email list": "Αυτό το αναγνωριστικό θα χρησιμοποιηθεί για τον συγχρονισμό τοπικών αρχείων csv με αυτήν τη λίστα email",
    "This password already used": "Αυτός ο κωδικός πρόσβασης χρησιμοποιείται ήδη",
    "This question is required": "Αυτή η ερώτηση είναι απαραίτητη",
    "This question is required?": "Αυτή η ερώτηση απαιτείται;",
    "Title": "Τίτλος",
    "title": "Τίτλος",
    "TLS": "TLS",
    "To": "Προς",
    "TOTAL ALLOWED ANSWERS": "ΣΥΝΟΛΟ ΕΠΙΤΡΕΠΟΜΕΝΩΝ ΑΠΑΝΤΗΣΕΩΝ",
    "Total E-mails": "Σύνολο ηλεκτρονικών μηνυμάτων",
    "Total Responses": "Συνολικές απαντήσεις",
    "Total Score:": "Συνολικό σκορ:",
    "TOTAL SURVEYS": "ΣΥΝΟΛΟ ΕΩΤΗΜΑΤΟΛΟΓΙΩΝ",
    "Translate": "Μεταφράσεις",
    "Translate survey": "Μετάφραση έρωτηματολογίου",
    "Type here": "Πληκτρολογίστε εδώ",
    "Undefined Error!": "Απροσδιόριστο σφάλμα!",
    "Unfortunately activation failed, please contact support team": "Δυστυχώς η ενεργοποίηση απέτυχε, επικοινωνήστε με την ομάδα υποστήριξης",
    "Unused": "Αχρησιμοποίητος",
    "Update": "Αναβάθμιση",
    "Update common": "Κοινή αναβάθμιση",
    "Update Password": "Ενημέρωση κωδικού πρόσβασης",
    "Update your info": "Ενημερώστε τις πληροφορίες σας",
    "Updated successfully": "Ενημερώθηκε με επιτυχία",
    "Upload CSV": "Μεταφόρτωση CSV",
    "Use status": "Κατάσταση Χρήσης",
    "Used": "Χρησιμοποιημένος",
    "User not found": "Ο χρήστης δεν βρέθηκε",
    "User with same email already exist!": "Χρήστης με το ίδιο email υπάρχει ήδη!",
    "User with same username already exist!": "Χρήστης με το ίδιο όνομα υπάρχει ήδη!",
    "Username": "Όνομα χρήστη",
    "Value": "Αξία",
    "View report": "Προβολή αναφοράς",
    "View reports": "Προβολή αναφορών",
    "What is your age range?": "Ποιο είναι το ηλικιακό σας εύρος;",
    "What is your gender?": "Ποιο είναι το φύλο σου;",
    "What is your Nationality?": "Ποια είναι η εθνικότητά σου?",
    "WINDOWS": "ΠΑΡΑΘΥΡΑ",
    "Write Your Question Here": "Γράψτε την ερώτησή σας εδώ",
    "Wrong email or password!": "Λάθος email ή κωδικός πρόσβασης!",
    "Yes": "ΝΑΙ",
    "You already have took this survey. Thanks": "Έχετε ήδη συμμετάσχει σε αυτήν την έρευνα. Ευχαριστούμε",
    "You can add emails in csv files in custom local directoy using gooSync application": "",
    "Your account is not activated": "Ο λογαριασμός σας δεν είναι ενεργοποιημένος",
    "Your Score": "Το σκορ σας",
    "Excellent": "Εξαιρετικά",
    "Very Good": "Πολύ καλά",
    "Good": "Καλά",
    "Fair": "Μέτρια",
    "Poor": "Ανεπαρκώς",
};