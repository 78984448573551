import withStyles from '@material-ui/core/styles/withStyles';
import Star from '@material-ui/icons/Star';
import Button from 'components/CustomButtons/Button';
import { default as React } from 'react';
import { connect } from 'react-redux';
const styles = {};
class Component extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            total: this.props.total || 5,
            star: this.props.star || 1,
        }
    }
    onChange = (star) => {
        this.setState({
            star
        })
        if (this.props.onChange) {
            this.props.onChange(star)
        }
    }
    renderStars = () => {
        const { total, star } = this.state
        let stars = []
        for (let i = 1; i <= total; i++) {
            const component = <Button
                key={i}
                onClick={() => this.onChange(i)}
                round justIcon color={i <= star ? 'success' : null}><Star /></Button>
            stars.push(component)
        }
        return stars
    }
    render() {
        const { total, star } = this.state
        return <div >
            {
                this.renderStars()
            }
        </div>;
    }
}
const mapStatsToProps = ({ user }) => ({ user });
export default connect(mapStatsToProps)(
    withStyles(styles, { withTheme: true })(Component)
);