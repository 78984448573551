import withStyles from '@material-ui/core/styles/withStyles';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Table from "components/Table/Table.jsx";
import Info from "components/Typography/Info";
import Success from "components/Typography/Success";
import Badge from "components/Badge/Badge";
import moment from "moment";
import { default as React } from 'react';
import { Pie } from 'react-chartjs-2';
import { connect } from 'react-redux';
import * as Score from "utils/Score";
import TitleBox from "views/Shared/TitleBox";

import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
require('assets/fonts/dejavu-sans-ttf-2.34/DejaVuSans-normal.js')
require('assets/fonts/dejavu-sans-ttf-2.34/DejaVuSans-bold.js')

const report_types = [
    {
        label: t('Analytical'),
        value: "Analytical"
    },
    {
        label: t('Summary'),
        value: "Summary"
    },
    {
        label: t('Text reports'),
        value: "Textreports"
    },
    {
        label: t('Comparation'),
        value: "Comparation"
    }
];
const styles = {
    fontBig: {
        fontSize: '1.6em',
        fontWeight: 400
    },
    fontSmall: {
        fontSize: '1.3em',
        fontWeight: 400
    },
    flexBetween: {
        display: 'flex',
        justifyContent: 'space-around'
    },
    fontBold: {
        fontWeight: 400
    }
};
class Analytical extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filterData: this.props.filterData,
            surveyQuestion: this.props.surveyQuestion,
            surveyResponses: this.props.surveyResponses,
            surveyMain: this.props.surveyMain,
            reportData: null
        }
    }

    componentDidMount() {
        this.props.onRef(this)
        this.initData()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.time !== this.props.time) {
            this.initData()
        }
    }
    getEmailsFromGroup = (group) => {
        const emaillist = this.state.emaillist

        const filtered = emaillist.filter(item => item._id === group.value)
        if (filtered && filtered[0]) {
            return filtered[0].emails
        } else {
            return []
        }
    }
    initData = () => {
        const { filterData, surveyQuestion, surveyResponses, surveyMain } = this.props
        const data1 = this.calculateData(surveyQuestion, surveyResponses[0])
        const data2 = this.calculateData(surveyQuestion, surveyResponses[1])
        this.setState({

            filterData: filterData,
            surveyQuestion: surveyQuestion,
            surveyResponses: surveyResponses,
            surveyMain: surveyMain,
            reportData: [data1, data2]
        })
    }


    calculateData = (filterQuestion, filterResponses) => {
        let reportSections = [];
        let surveyScore = 0
        let totalResult = null
        if (filterQuestion.sections.length > 0) {

            let countSectionScore = 0
            filterQuestion.sections.map((section) => {
                let reportQuestions = []
                let sectionScore = 0
                if (section.questions.length > 0) {
                    let countQuestionScore = 0
                    section.questions.map((q) => {
                        if (Score.SKIPTYPES.indexOf(q.question.type) >= 0) return null;
                        const reportOfQuestion = Score.getReportOfQuestion(q, filterResponses);
                        reportQuestions.push(reportOfQuestion)
                        if (reportOfQuestion.questionScore > 0) {
                            countQuestionScore++;
                            sectionScore += reportOfQuestion.questionScore
                        }
                    })
                    sectionScore = countQuestionScore > 0 ? (sectionScore / countQuestionScore) : 0
                }
                const reportsForSection = {
                    sectionTitle: section.sectionTitle,
                    reportQuestions,
                    sectionScore
                }
                reportSections.push(reportsForSection)
                if (reportsForSection.sectionScore > 0) {
                    countSectionScore++
                    surveyScore += reportsForSection.sectionScore
                }
            })
            totalResult = {
                reportSections: reportSections,
                surveyScore: countSectionScore > 0 ? (surveyScore / countSectionScore) : 0
            }
        }
        return totalResult
    }
    getRandomColor = () => {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    getChartData = (response1, response2) => {
        let labels = [];
        let data = [];
        let backgroundColor = [];


        labels.push(t('Period1'));
        labels.push(t('Period2'));

        data.push(response1.questionScore)
        data.push(response2.questionScore)

        backgroundColor.push('green')
        backgroundColor.push('blue')

        const chartData = {
            labels,
            datasets: [{
                data,
                backgroundColor
            }]
        }
        return chartData
    }
    generateCompareDataForTable = (response1, response2) => {
        let result = []

        response1.reports.map((item) => {
            const answer = item.answer
            const matchedIndex = result.findIndex(item => item.answer === answer);
            const count = item.count
            const percent = item.percent.toFixed(2) + '%'
            if (matchedIndex < 0) {
                result.push({
                    answer: answer,
                    values: [item.answerLabel, count, percent, 0, '0 %']
                })
            } else {
                result[matchedIndex].values[1] = count
                result[matchedIndex].values[2] = percent

            }
        })

        response2.reports.map((item) => {
            const answer = item.answer
            const matchedIndex = result.findIndex(item => item.answer === answer);
            const count = item.count
            const percent = item.percent.toFixed(2) + '%'
            if (matchedIndex < 0) {
                result.push({
                    answer: answer,
                    values: [item.answerLabel, 0, '0 %', count, percent,]
                })
            } else {
                result[matchedIndex].values[3] = count
                result[matchedIndex].values[4] = percent

            }
        })
        return result.map(item => item.values)
    }
    renderAnalytics = (data) => {
        const { classes } = this.props
        if (!data) return null
        console.info(data)
        const filterData = this.props.filterData
        const firstPeriod = moment(filterData.from1).format('YYYY-MM-DD') + ' - ' + moment(filterData.to1).format('YYYY-MM-DD')
        const secondPeriod = moment(filterData.from2).format('YYYY-MM-DD') + ' - ' + moment(filterData.to2).format('YYYY-MM-DD')
        const result = data[0].reportSections.map((section, skey) => {
            const sectionTitle = section.sectionTitle
            return <TitleBox title={sectionTitle} key={skey}>
                {
                    section.reportQuestions.map((question, qkey) => {
                        const questionTitle = question.questionTitle

                        const response1 = question;
                        const response2 = data[1].reportSections[skey].reportQuestions[qkey];
                        const tableData = this.generateCompareDataForTable(response1, response2)
                        const chartdata = this.getChartData(response1, response2)
                        return <TitleBox key={qkey} title={questionTitle} color="#00acc140" textColor="#00acc1">
                            <GridContainer>
                                <GridItem xs={12} md={6} sm={6} lg={6} xl={6}>
                                    <div>
                                        <Badge color="info"><span className={classes.fontBold}>Period1 > </span>{firstPeriod}</Badge>
                                        <span>{" ~ "}</span>
                                        <Badge color="success"><span className={classes.fontBold}>Period2 > </span>{secondPeriod}</Badge>
                                    </div>
                                    <Table
                                        tableHeaderColor="primary"
                                        tableHead={[
                                            t("Activity"),
                                            t("Answers"),
                                            t("Relative"),
                                            t("Answers"),
                                            t("Relative"),
                                        ]}
                                        tableData={tableData}
                                        coloredColls={[2]}
                                        colorsColls={["primary"]}
                                    />
                                    <div className={classes.flexBetween}>
                                        <span className={classes.fontBig}>Score</span>
                                        <Info><span className={classes.fontSmall}>{response1.questionScore}</span></Info>
                                        <Success><span className={classes.fontSmall}>{response2.questionScore}</span></Success>
                                    </div>
                                </GridItem>
                                <GridItem xs={12} md={6} sm={6} lg={6} xl={6}>
                                    <Pie data={chartdata} />
                                </GridItem>
                            </GridContainer>
                        </TitleBox>
                    })
                }
            </TitleBox>
        })
        return result
    }
    addDocText(doc, text, x, y) {
        const docWidth = doc.internal.pageSize.getWidth();

        var line = y // Line height to start text at
        var lineHeight = 4
        var leftMargin = x
        var wrapWidth = docWidth - 2*x

        var splitText = doc.splitTextToSize(text, wrapWidth)
        for (var i = 0, length = splitText.length; i < length; i++) {
            // loop thru each line and increase
            doc.text(splitText[i], leftMargin, line)
            line = lineHeight + line
        }
        return line
    }
    generatePdf = (fileName) => {

        var doc = new jsPDF();
        const docWidth = doc.internal.pageSize.getWidth();
        const docHeight = doc.internal.pageSize.getHeight();
        var startX = 10

        var contentWidth = docWidth - startX * 2
        doc.setFont('DejaVuSans');
        doc.autoTableSetDefaults({ styles: { font: 'DejaVuSans', minCellWidth: 15, fontSize: 8  }, headStyles: { font: 'DejaVuSans' } })
        var finalY = 20;
        var gap = 4

        var head = [];
        var body = [];

        const { filterData, surveyQuestion, surveyResponses, surveyMain } = this.props
        // title
        doc.setFontSize(9);
        finalY = this.addDocText(doc, surveyMain.title, startX, finalY)

        // end title
        // filter part
        const isCompare = filterData.type.value === report_types[3].value
        const period1 = String(moment(filterData.from1).format('LL') + ' ~ ' + moment(filterData.to1).format('LL'))
        const period2 = String(moment(filterData.from2).format('LL') + ' ~ ' + moment(filterData.to2).format('LL'))
        const emailGroup = filterData.group.map(a => a.label).join(',');
        const section = filterData.section ? filterData.section.label : '';
        const question = filterData.question ? filterData.question.label : '';
        const answer = filterData.answer.map(a => a.label).join(',');

        head.push([t('Filter options'), ''])
        body.push([t("Report Type"), "Comparation"])

        body.push([t('Period1'), period1])
        if (isCompare) { body.push([t('Period2'), period2]) }
        body.push([t('Emails Group'), emailGroup])
        body.push([t('Section'), section])
        body.push([t('Question'), question])
        body.push([t('Answers'), answer])
        body.push([t('Total Answers1'), surveyResponses[0].length])
        body.push([t('Total Answers2'), surveyResponses[1].length])

        doc.autoTable({ startY: finalY, head: head, body: body, theme: 'grid' });
        finalY = doc.previousAutoTable.finalY + gap;
        // end filter
        // section and questions

        const { reportData } = this.state
        const firstPeriod = moment(filterData.from1).format('YYYY-MM-DD') + ' - ' + moment(filterData.to1).format('YYYY-MM-DD')
        const secondPeriod = moment(filterData.from2).format('YYYY-MM-DD') + ' - ' + moment(filterData.to2).format('YYYY-MM-DD')

        reportData[0].reportSections.forEach((section, skey) => {
            let sectionTitle = section.sectionTitle;
            if (section.sectionScore > 0) {
                const sectionScore1 = `| ${t('Period 1 Score:')} ` + section.sectionScore.toFixed(2)
                const sectionScore2 = `| ${t('Period 2 Score:')} ` + reportData[1].reportSections[skey].sectionScore.toFixed(2)
                sectionTitle = `${sectionTitle} ${sectionScore1} ${sectionScore2}`
            }

            finalY = doc.previousAutoTable.finalY + gap * 4
            doc.text(sectionTitle, startX, finalY);
            finalY += gap;
            section.reportQuestions.forEach((question, qkey) => {

                const response1 = question;
                const response2 = reportData[1].reportSections[skey].reportQuestions[qkey];
                const tableData = this.generateCompareDataForTable(response1, response2)

                head = []; body = []
                const questionTitle = question.questionTitle
                head.push([{ content: questionTitle, colSpan: 5 }])
                body.push([t('Periods'), { content: firstPeriod, colSpan: 2 }, { content: secondPeriod, colSpan: 2 }])
                body.push([
                    t('Activity'),
                    t('Answer'),
                    t('Relative'),
                    t('Answer'),
                    t('Relative')
                ])
                body = body.concat(tableData)

                body.push([t('Score'), { content: response1.questionScore, colSpan: 2 }, { content: response2.questionScore, colSpan: 2 }])
                doc.autoTable({ startY: finalY, head: head, body: body, theme: 'grid' });
                finalY = doc.previousAutoTable.finalY + gap
            })

        });
        // window.open(URL.createObjectURL(doc.output("blob")))
        doc.save(fileName);
    }
    generateCSV = () => {

        var rows = [];


        const { filterData, surveyQuestion, surveyResponses, surveyMain } = this.props
        const isCompare = filterData.type.value === report_types[3].value
        const period1 = String(moment(filterData.from1).format('LL') + ' ~ ' + moment(filterData.to1).format('LL'))
        const period2 = String(moment(filterData.from2).format('LL') + ' ~ ' + moment(filterData.to2).format('LL'))
        const emailGroup = filterData.group.map(a => a.label).join(',');
        const section = filterData.section ? filterData.section.label : '';
        const question = filterData.question ? filterData.question.label : '';
        const answer = filterData.answer.map(a => a.label).join(',');

        rows.push([t('Filter options')])
        rows.push([
            t('Period1'),
            t('Emails Group'),
            t('Section'),
            t('Question'),
            t('Answers')
        ])
        rows.push([period1, emailGroup, section, question, answer])


        const { reportData } = this.state
        const firstPeriod = moment(filterData.from1).format('YYYY-MM-DD') + ' - ' + moment(filterData.to1).format('YYYY-MM-DD')
        const secondPeriod = moment(filterData.from2).format('YYYY-MM-DD') + ' - ' + moment(filterData.to2).format('YYYY-MM-DD')

        reportData[0].reportSections.forEach((section, skey) => {
            let sectionTitle = section.sectionTitle;
            if (section.sectionScore > 0) {
                const sectionScore1 = `| ${t('Period 1 Score:')} ` + section.sectionScore.toFixed(2)
                const sectionScore2 = `| ${t('Period 2 Score:')} ` + reportData[1].reportSections[skey].sectionScore.toFixed(2)
                sectionTitle = `${sectionTitle} ${sectionScore1} ${sectionScore2}`
            }

            section.reportQuestions.forEach((question, qkey) => {

                const response1 = question;
                const response2 = reportData[1].reportSections[skey].reportQuestions[qkey];
                const tableData = this.generateCompareDataForTable(response1, response2)

                const questionTitle = question.questionTitle
                rows.push([`===> ${questionTitle}`])
                rows.push([t('Periods'), firstPeriod, secondPeriod,])
                rows.push([
                    t('Activity'), 
                    t('Answer'), 
                    t('Relative'), 
                    t('Answer'), 
                    t('Relative')
                ])
                rows = rows.concat(tableData)

                rows.push([t('Score'), response1.questionScore, response2.questionScore])
            })

        });

        let csvContent = "data:text/csv;charset=utf-8,"
            + rows.map(e => e.join(",")).join("\n");
        var encodedUri = encodeURI(csvContent);
        window.open(encodedUri);
    }
    render() {

        const { reportData } = this.state
        if (!reportData) return '...'

        return <div >
            {this.renderAnalytics(reportData)}
        </div>;
    }
}
const mapStatsToProps = ({ user }) => ({ user });
export default connect(mapStatsToProps)(
  withTranslation()(withStyles(styles, { withTheme: true })(Analytical))
);