import withStyles from '@material-ui/core/styles/withStyles';
import { default as React } from 'react';
import { connect } from 'react-redux';
import AgeView from "./AgeView";
import CheckBoxView from "./CheckBoxView";
import DatesView from "./DatesView";
import DefaultView from "./DefaultView";
import GenderView from "./GenderView";
import NationalityView from "./NationalityView";
import NumberView from "./NumberView";
import RadioButtonView from "./RadioButtonView";
import TextAnswerView from "./TextAnswerView";
import YesNoView from "./YesNoView";


const styles = {};
class Component extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        const { question } = this.props
        switch (question.question.type) {
            case 'age':
                return <AgeView {...this.props} />
            case 'checkbox':
                return <CheckBoxView {...this.props} />
            case 'date':
                return <DatesView {...this.props} />
            case 'gender':
                return <GenderView {...this.props} />
            case 'nation':
                return <NationalityView  {...this.props} />
            case 'number':
                return <NumberView {...this.props} />
            case 'radio':
                return <RadioButtonView  {...this.props} />
            case 'text':
                return <TextAnswerView {...this.props} />
            case 'yesno':
                return <YesNoView {...this.props} />
            default:
                return <DefaultView {...this.props} />
        }
    }
}
const mapStatsToProps = ({ user }) => ({ user });
export default connect(mapStatsToProps)(
    withStyles(styles, { withTheme: true })(Component)
);