import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Switch from "@material-ui/core/Switch";
import Check from "@material-ui/icons/Check";
import Edit from "@material-ui/icons/Edit";
import InsertLink from "@material-ui/icons/InsertLink";
import Lock from "@material-ui/icons/Lock";
import PieChart from "@material-ui/icons/PieChart";
import Translate from "@material-ui/icons/Translate";
import ViewList from "@material-ui/icons/ViewList";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import axios from "axios";
import Button from "components/CustomButtons/Button.jsx";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import { api_url, app_url } from "redux/config.js";
import Toast from "views/Toast/Toast";
import Tooltip from '@material-ui/core/Tooltip';
import { TransReactTable } from "components/CustomReactTable/TransReactTable";
import { withTranslation } from "react-i18next";


const styles = {
  ...extendedFormsStyle,
  root: {

    height: 20,
  }
};

class SurveyPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showactive: "active",
      surveyData: [],
      active: "pendingTransaction",
      data: [],

      loading: false,
      pages: -1,
      paginationState: null,

    };
  }
  componentDidMount() {
  }
  toggleActivateStateOfSurvey = item => {
    const newActive = !item.active;
    this.props.dispatch({ type: "LOAD_START" });
    const activate_term = newActive ? "survey/activate" : "survey/deactivate";
    axios({
      url: api_url + activate_term,
      method: "post",
      data: {
        id: item._id,
        active: newActive
      },
      headers: {
        authorization: "Bearer " + this.props.user.token
      }
    })
      .then(res => {
        if (res.data.success) {
          const newData = this.state.data.map(ditem => {
            let newitem = ditem;
            if (ditem._id === item._id) {
              newitem.active = newActive;
            }
            return newitem;
          });

          this.setState({
            data: newData
          });
          this.normalizeData(newData);
        } else {
          Toast(400, res.data.error);
        }
      })
      .finally(this.props.dispatch({ type: "LOAD_END" }));
  };
  normalizeData = data => {
    const { classes, t } = this.props;

    if (!data || data.length === 0) {
      this.setState({
        surveyData: []
      });
    }
    const ndata = data.map((item, key) => {
      const edit_url = "/survey/edit/" + item._id;
      const report_url = "/survey/view_report/" + item._id;
      const action = (
        <div key={key}>
          <Tooltip title={t("View reports")}>
            <a href={report_url}>
              <PieChart color="primary" />
            </a>
          </Tooltip>
          <Tooltip title={t("Edit")}>
            <a
              onClick={() => this.props.history.push(edit_url)}
            >
              <Edit color="primary" />
            </a>
          </Tooltip>

          <Tooltip title={t("Translate")}>
            <a
              onClick={() => this.props.history.push('/survey/translate/' + item._id)}
            >
              <Translate color="primary" />
            </a>
          </Tooltip>

          <Tooltip title={t("Respondent list")}>
            <a
              onClick={() => {
                const url = `/survey/survey_respondents?id=${item._id}`
                this.props.history.push(url);
              }}
            >
              <ViewList color="primary" />
            </a>
          </Tooltip>

          <Tooltip title={t("Response")}>
            <a
              onClick={() => {

                const url = `${app_url}/public/single_survey/${item._id}/nocondition`
                window.open(url, '_blank')
              }}
            >
              <InsertLink color="primary" />
            </a>
          </Tooltip>

          <Tooltip title={t("Passwords")}>
            <a

              onClick={() => {
                const url = `/survey/survey_passwords/${item._id}`
                this.props.history.push(url);
              }}
            >
              <Lock color="primary" />
            </a>
          </Tooltip>
        </div>
      );
      const active = (
        <Switch
          checked={item.active}
          onChange={e => {
            this.toggleActivateStateOfSurvey(item);
          }}
          value="checkedA"
          color="primary"
          classes={{

          }}
        />
      );
      return {
        title: item.main[0].title,
        date: moment(item.updated_at).format("MMMM Do YYYY, h:mm:ss a"),
        responses: item.responses.length,
        active,
        action
      };
    });
    this.setState({
      surveyData: ndata
    });
  };
  loadData = state => {
    this.setState({
      paginationState: state
    });
    this.props.dispatch({ type: "LOAD_START" });
    let data = {
      page: state.page,
      pageSize: state.pageSize,
      sorted: state.sorted.length > 0 ? state.sorted : [{ id: "date", desc: true }],
      mine_only: true,
      filtered: state.filtered
    };

    const showactive = this.state.showactive;
    if (showactive && showactive !== "all") {
      data.active = showactive === "active" ? true : false;
    }
    axios({
      url: api_url + "survey/get",
      method: "post",
      headers: {
        authorization: "Bearer ".concat(this.props.user.token)
      },
      data
    })
      .then(res => {
        if (res.data.success) {
          this.normalizeData(res.data.data.rows);
          this.setState({
            data: res.data.data.rows,
            pages: res.data.data.pages,
            loading: false
          });
        } else {
          Toast(400, res.data.error);
        }
      })
      .catch(e => {
        Toast(500);
      })
      .finally(() => {
        this.props.dispatch({ type: "LOAD_END" });
      });
  };
  handleactive = sa => {
    this.setState({
      showactive: sa
    }, () => {
      this.loadData(this.state.paginationState);
    });
  };
  render() {
    const { classes, t } = this.props;

    const column = [
      {
        Header: t("Title"),
        accessor: "title",
        sortable: true
      },
      {
        Header: t("Date"),
        accessor: "date",
        sortable: true
      },
      {
        Header: t("Response"),
        accessor: "responses",
        sortable: true
      },
      {
        Header: t("Active"),
        accessor: "active",
        sortable: true
      },
      {
        Header: t("Actions"),
        accessor: "action",
        minWidth: 150,
        sortable: false
      }
    ];
    return (
      <div>
        <GridContainer>
          <GridItem md={12} sm={12}>
            <Button color="info" onClick={() => this.props.history.push('/survey/create_survey')} >
              {t('Create new survey')}
            </Button>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.showactive === "all"}
                    tabIndex={-1}
                    onClick={() => this.handleactive("all")}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked
                    }}
                  />
                }
                classes={{
                  label: classes.label
                }}
                label={t("All")}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.showactive === "active"}
                    tabIndex={-1}
                    onClick={() => this.handleactive("active")}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked
                    }}
                  />
                }
                classes={{
                  label: classes.label
                }}
                label={t("Active")}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.showactive === "deactive"}
                    tabIndex={-1}
                    onClick={() => this.handleactive("deactive")}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked
                    }}
                  />
                }
                classes={{
                  label: classes.label
                }}
                label={t("Deactive")}
              />
            </div>
            <TransReactTable
              defaultPageSize={5}
              data={this.state.surveyData}
              columns={column}
              pages={this.state.pages} // should default to -1 (which means we don't know how many pages we have)
              loading={this.state.loading}
              manual // informs React Table that you'll be handling sorting and pagination server-side
              onFetchData={(state, instance) => {
                // show the loading overlay
                this.setState({ loading: true });

                this.loadData(state);
              }}
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps)(withTranslation()(withStyles(styles)(SurveyPage)));
