// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import ViewList from "@material-ui/icons/ViewList";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import axios from "axios";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import React from "react";
import { connect } from "react-redux";
import "react-table/react-table.css";
import { api_url } from "redux/config.js";
import Toast from "views/Toast/Toast";
import { getAllLanguages } from "utils/Languages";
import commonStyle from "assets/jss/common/common"
import Select from "react-select";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import uuid from 'uuidv4';


import CustomSelect from "components/CustomSelect/CustomSelect"
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import { withTranslation } from "react-i18next";
import { t } from "i18next";
const styles = {
  ...commonStyle,
  ...regularFormsStyle,
  descriptionTextArea: {
    width: "100%",
    height: 150
  }
};

class AllotmentSurveyCreatePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languages: getAllLanguages(),
      image_file: null,
      has_personal_info: false,
      formData: {
        image_url: "",
        title: "",
        description: "",
        language: 'en',
        questions: []
      }
    };
  }

  verfiyForm = () => {
    const { title } = this.state.formData;
    if (title.length === 0) {
      Toast(400, t('Please set title'));
      return false;
    }
    return true;
  };
  getQuestionData = () => {
    const has_personal_info = this.state.has_personal_info
    const language = this.state.formData.language

    if (has_personal_info) {
      return [{
        sections: [
          {
            sectionTitle: t('Personal Information'),
            display: true,
            questions: [
              {
                id: uuid(),
                questionTitle: t('What is your gender?'),
                required: true,
                question: {
                  type: 'gender',
                  options: [{ label: 'Female', value: 'female' }, { label: 'Male', value: 'male' }],
                },
              },
              {
                id: uuid(),
                questionTitle: t('What is your age range?'),
                required: true,
                question: {
                  type: 'age',
                  answertype: 'group',
                  options: [
                    { label: '14-19', value: 'a' },
                    { label: '20-29', value: 'b' },
                    { label: '30-39', value: 'c' },
                    { label: '40-49', value: 'd' },
                    { label: '50-59', value: 'e' },
                    { label: '60-69', value: 'f' },
                    { label: '70-79', value: 'g' },
                  ]
                },
              },
              {
                id: uuid(),
                questionTitle: t('What is your Nationality?'),
                required: true,
                question: {
                  type: 'nation',
                },
              }
            ]
          }
        ],
        language: language
      }]
    } else {
      return [{
        sections: [],
        language: language
      }]
    }
  }
  createSurvey = async () => {
    if (this.verfiyForm()) {
      this.props.dispatch({ type: "LOAD_START" });
      const formData = this.state.formData;
      if (this.state.image_file) {
        const fileData = new FormData();
        fileData.append("file", this.state.image_file);
        const result = await axios({
          url: api_url + "utils/file_upload",
          method: "post",
          data: fileData
        });
        if (result.data.success) {
          formData.image_url = result.data.file_url
        } else {
          Toast(400, result.data.error)
        }
      }
      formData.questions = this.getQuestionData()
      console.info("image_uploaded=>", formData.image_url);
      const result2 = await axios({
        url: api_url + "survey/new",
        method: "post",
        headers: {
          authorization: 'Bearer '.concat(this.props.user.token)
        },
        data: formData
      });
      console.info("survey_created", result2);
      this.props.dispatch({ type: "LOAD_END" });
      if (result2.data.success) {
        this.props.history.push("/admin/allotment_survey_list");
      } else {
        Toast(400, result2.data.error);
      }
    }
  };
  setFormData = (field, value) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [field]: value
      }
    });
  };
  toggleHasPersonalInfo = () => {
    const has_personal_info = this.state.has_personal_info
    this.setState({
      has_personal_info: !has_personal_info
    })
  }
  render() {
    const { classes } = this.props;
    const { languages, has_personal_info } = this.state
    const language = this.state.formData.language
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12}>
            <Card>
              <CardHeader color="success" icon>
                <CardIcon color="success">
                  <ViewList />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>{t('Create survey')}</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={3}>
                    <ImageUpload
                      addButtonProps={{
                        color: "rose",
                        round: true
                      }}
                      changeButtonProps={{
                        color: "rose",
                        round: true
                      }}
                      removeButtonProps={{
                        color: "danger",
                        round: true
                      }}
                      handleImageChange={file =>
                        this.setState({
                          image_file: file
                        })
                      }
                    />
                  </GridItem>
                  <GridItem xs={12} sm={9}>
                    <CustomInput
                      id="Title"
                      formControlProps={{
                        className: classes.noPadding,
                        onChange: e =>
                          this.setFormData("title", e.target.value),
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "text",
                        placeholder: t('Title'),

                      }}
                    />
                    <textarea
                      className={classes.descriptionTextArea}
                      placeholder={t('Description')}
                      onChange={e =>
                        this.setFormData("description", e.target.value)
                      }
                    />


                    <CustomSelect
                      onChange={country => {
                        this.setFormData("language", country.value);
                      }}
                      placeholder={t('Language')}
                      value={languages.filter((item) => item.value === language)[0]}
                      options={languages}
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          checked={has_personal_info}
                          onClick={() => this.toggleHasPersonalInfo()}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked
                          }}
                        />
                      }
                      classes={{
                        label: classes.label
                      }}
                      label={t('Create Personal Information (Sex/Age/Nationality)')}
                    />
                  </GridItem>

                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button color="info" onClick={() => this.createSurvey()}>
                  {t('Save')}
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps)(withTranslation()(withStyles(styles)(AllotmentSurveyCreatePage)));
