import withStyles from '@material-ui/core/styles/withStyles';
import jwt from "jsonwebtoken";
import { default as React } from 'react';
import { connect } from 'react-redux';
import { random_string } from "redux/config";
import * as Score from "utils/Score";
import TitleBox from "views/Shared/TitleBox";
import moment from "moment"

import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { t } from 'i18next';
import { withTranslation } from 'react-i18next';

import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMore from '@material-ui/icons/ExpandMore';

require('assets/fonts/dejavu-sans-ttf-2.34/DejaVuSans-normal.js')
require('assets/fonts/dejavu-sans-ttf-2.34/DejaVuSans-bold.js')


const styles = {};
const report_types = [
    {
        label: t('Analytical'),
        value: "Analytical"
    },
    {
        label: t('Summary'),
        value: "Summary"
    },
    {
        label: t('Text reports'),
        value: "Textreports"
    },
    {
        label: t('Comparation'),
        value: "Comparation"
    }
];
const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);
const AccordionSummary = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, .03)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        display: 'block'
    },
}))(MuiAccordionDetails);
const ResponseComponent = (props) => {
    const [expanded, setExpanded] = React.useState(false);
    const { response, survey_id, surveyQuestion, surveyAnswers, response_id } = props

    const responseScore = Score.getScoreOfOneResponse(surveyQuestion, surveyAnswers)
    return <Accordion square expanded={expanded} onChange={() => setExpanded(!expanded)}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMore />}>
            <div onClick={() => {
                var win = window.open(`/public/survey_answer/${survey_id}/${response_id}`, '_blank');
                win.focus();
            }}>{t("OPEN RESPONDENT'S SURVEY")} <b>{response.email || response_id}</b> <span style={{color: 'blue'}}>{responseScore.toFixed(2)}</span></div>
        </AccordionSummary>
        <AccordionDetails>
            {
                response.answerList.map((element, ekey) => {
                    return <TitleBox key={ekey} title={element.questionTitle} color="#00acc140" textColor="#00acc1">
                        <p>{element.answerLabel} </p>
                    </TitleBox>
                })
            }
        </AccordionDetails>
    </Accordion>
}
class TextReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filterData: this.props.filterData,
            surveyQuestion: this.props.surveyQuestion,
            surveyResponses: this.props.surveyResponses,
            surveyMain: this.props.surveyMain,
            reportData: null
        }
    }


    componentDidMount() {
        this.props.onRef(this)
        this.initData()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.time !== this.props.time) {
            this.initData()
        }
    }

    initData = () => {
        const { filterData, surveyQuestion, surveyResponses, surveyMain } = this.props
        const data = this.calculateData(surveyQuestion, surveyResponses)
        this.setState({

            filterData: filterData,
            surveyQuestion: surveyQuestion,
            surveyResponses: surveyResponses,
            surveyMain: surveyMain,
            reportData: data
        })
    }

    getEmailsFromGroup = (group) => {
        const emaillist = this.state.emaillist

        const filtered = emaillist.filter(item => item._id === group.value)
        if (filtered && filtered[0]) {
            return filtered[0].emails
        } else {
            return []
        }
    }
    addDocText(doc, text, x, y) {
        const docWidth = doc.internal.pageSize.getWidth();

        var line = y // Line height to start text at
        var lineHeight = 4
        var leftMargin = x
        var wrapWidth = docWidth - 2 * x

        var splitText = doc.splitTextToSize(text, wrapWidth)
        for (var i = 0, length = splitText.length; i < length; i++) {
            // loop thru each line and increase
            doc.text(splitText[i], leftMargin, line)
            line = lineHeight + line
        }
        return line
    }
    generatePdf = (fileName) => {

        var doc = new jsPDF();
        const docWidth = doc.internal.pageSize.getWidth();
        const docHeight = doc.internal.pageSize.getHeight();

        doc.setFont('DejaVuSans');
        doc.autoTableSetDefaults({ styles: { font: 'DejaVuSans', fontSize: 8 }, headStyles: { font: 'DejaVuSans' } })
        var finalY = 20;
        var startX = 10
        var gap = 4
        var contentWidth = docWidth - startX * 2

        var head = [];
        var body = [];

        const { filterData, surveyQuestion, surveyResponses, surveyMain } = this.props
        // title
        doc.setFontSize(9);
        finalY = this.addDocText(doc, surveyMain.title, startX, finalY)

        // end title
        // filter part
        const isCompare = filterData.type.value === report_types[3].value
        const period1 = String(moment(filterData.from1).format('LL') + ' ~ ' + moment(filterData.to1).format('LL'))
        const period2 = String(moment(filterData.from2).format('LL') + ' ~ ' + moment(filterData.to2).format('LL'))
        const emailGroup = filterData.group.map(a => a.label).join(',');
        const section = filterData.section ? filterData.section.label : '';
        const question = filterData.question ? filterData.question.label : '';
        const answer = filterData.answer.map(a => a.label).join(',');

        head.push([t('Filter options'), ''])
        body.push([t("Report Type"), "Text Reports"])

        body.push([t('Period1'), period1])
        if (isCompare) { body.push([t('Period2'), period2]) }
        body.push([t('Emails Group'), emailGroup])
        body.push([t('Section'), section])
        body.push([t('Question'), question])
        body.push([t('Answers'), answer])
        body.push([t('Total Answers'), surveyResponses.length])

        doc.autoTable({ startY: finalY, head: head, body: body, theme: 'grid' });
        finalY = doc.previousAutoTable.finalY + gap;
        // end filter

        // section and questions

        const surveydata = this.calculateData(surveyQuestion, surveyResponses)
        surveydata.map((response, key) => {

            finalY = doc.previousAutoTable.finalY + gap * 4
            head = [[`${t('Response')} ${key + 1} ${response.email || response.responseID}`, { content: response.responseScore.toFixed(2), styles: { textColor: 'blue' } }]];
            body = [[{ content: t('Question'), styles: { fontStyle: 'bold' } }, { content: t('Answer'), styles: { fontStyle: 'bold' } }]];
            response.answerList.forEach((element, ekey) => {

                const questionTitle = element.questionTitle
                const answerLabel = element.answerLabel
                body.push([questionTitle, answerLabel])

            })
            doc.autoTable({
                startY: finalY, head: head, body: body, theme: 'grid', styles: {
                    minCellWidth: 15
                }
            });
            finalY = doc.previousAutoTable.finalY + gap

        });
        // window.open(URL.createObjectURL(doc.output("blob")))
        doc.save(fileName);
    }

    generateCSV = () => {

        var rows = [];
        const { filterData, surveyQuestion, surveyResponses, surveyMain } = this.props
        const isCompare = filterData.type.value === report_types[3].value
        const period1 = String(moment(filterData.from1).format('LL') + ' ~ ' + moment(filterData.to1).format('LL'))
        const period2 = String(moment(filterData.from2).format('LL') + ' ~ ' + moment(filterData.to2).format('LL'))
        const emailGroup = filterData.group.map(a => a.label).join(',');
        const section = filterData.section ? filterData.section.label : '';
        const question = filterData.question ? filterData.question.label : '';
        const answer = filterData.answer.map(a => a.label).join(',');

        rows.push([t('Filter options')])
        rows.push([
            t('Period1'),
            t('Emails Group'),
            t('Section'),
            t('Question'),
            t('Answers'),
        ])
        rows.push([period1, emailGroup, section, question, answer])

        const surveydata = this.calculateData(surveyQuestion, surveyResponses)
        surveydata.map((response, key) => {

            rows.push([t('Response') + ` ${key + 1}`]);
            rows.push([t('Question'), t('Answer')])
            response.answerList.forEach((element, ekey) => {

                const questionTitle = element.questionTitle
                const answerLabel = element.answerLabel
                rows.push([questionTitle, answerLabel])

            })

        });

        let csvContent = "data:text/csv;charset=utf-8,"
            + rows.map(e => e.join(",")).join("\n");
        var encodedUri = encodeURI(csvContent);
        window.open(encodedUri);
    }
    calculateData = (filterQuestion, filterResponses) => {

        if (filterQuestion.sections.length === 0) return null


        let result = []
        filterResponses.map((response) => {
            const answers = response.answers
            let answerList = []
            filterQuestion.sections.map((section) => {
                section.questions.map((question) => {
                    if (Score.VALUETYPE === question.question.type) return null;
                    const questionTitle = question.questionTitle
                    const answer = answers.filter(a => a.id === question.id)[0]
                    if (!answer) return null
                    const answerLabel = Score.getLabelOfAnswerValue(question, answer.value)
                    answerList.push({
                        questionTitle,
                        answerLabel
                    })
                })
            })
            const responseScore = Score.getScoreOfOneResponse(filterQuestion, answers)
            result.push({
                responseID: response._id,
                email: response.email,
                answerList,
                responseScore: responseScore
            })
        })
        result.sort((a, b) => {
            return b.responseScore - a.responseScore
        })
        return result
    }


    renderTextReport = (data) => {
        if (!data) return null

        const result = data.map((response, pkey) => {
            const params = {
                survey_id: this.props.survey_id,
                response_id: response.responseID
            }
            const surveyResponses = this.state.surveyResponses
            const surveyResponse = surveyResponses.filter(res => res._id == params.response_id)[0]
            const surveyAnswers = surveyResponse ? surveyResponse.answers : []
            return <div key={pkey}><ResponseComponent response={response} surveyQuestion={this.state.surveyQuestion} surveyAnswers={surveyAnswers} survey_id={params.survey_id} response_id={params.response_id} /></div>
        })
        return result
    }

    render() {

        const { reportData, surveyQuestion } = this.state
        if (!reportData) return '...'

        return <div >
            {this.renderTextReport(reportData)}
        </div>;
    }
}
const mapStatsToProps = ({ user }) => ({ user });
export default connect(mapStatsToProps)(
    withTranslation()(withStyles(styles, { withTheme: true })(TextReport))
);