import React from 'react'
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

export default function CustomCheckbox(props) {
    const { label, onChange, checked } = props
    return <FormControlLabel control={<Checkbox name="checkedC" checked={checked} />} label={label} onChange={(e) => onChange(e.target.checked)} />
}


CustomCheckbox.defaultProps = {
    label: 'Option 1',
    onChange: (e) => { console.info(e) },
    checked: false
}
