import withStyles from '@material-ui/core/styles/withStyles';
import commonStyle from "assets/jss/common/common";
import Button from 'components/CustomButtons/Button';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import { t } from 'i18next';
import { default as React } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { question_types } from "redux/config";


const styles = {
    ...commonStyle,
    labels: {
        fontWeight: 'bold',
        width: 100,
        display: 'inline-flex'
    },
    icon: {
        width: 50,
        height: 50
    }
};

class Component extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            questionSchema: null,
            yesno: '',
            question: {
                type: 'yesno',
                options: [],
            },
        }
    }
    componentDidMount() {
        this.props.onRef(this)
        let questionSchema = question_types.filter((item) => item.type === 'yesno')[0]
        const question = this.props.question
        this.setState({
            questionSchema: questionSchema,
            yesno: questionSchema.options[0].value,

            question: (question && question.type === questionSchema.type) ? question : {
                type: questionSchema.type,
                options: questionSchema.options,
            }
        })
    }
    getData() {
        return this.state.question
    }

    setQuestionItem = (name, index, value) => {
        let question = this.state.question;
        let options = question.options
        options[index][name] = value
        this.setState({
            question: {
                ...question,
                options,
            }
        })
    }
    

    render() {
        const { questionSchema, question, yesno } = this.state
        
        if (!questionSchema) return null
        const { classes } = this.props
        return <div >
            <hr />
            {
                question.options.map((item, key) => {
                    return <div key={key}>
                        {item.value === 'yes' && <span className={classes.labels}>{t('Yes')}</span>}
                        {item.value === 'no' && <span className={classes.labels}>{t('No')}</span>}
                        <CustomInput
                            formControlProps={{
                                className: classes.noPadding + ' ' + classes.noMargin,
                                onChange: e =>
                                    this.setQuestionItem("label", key, e.target.value),
                            }}
                            inputProps={{
                                placeholder: t('Label'),
                                value: item.label,
                            }}
                        />
                    </div>
                })
            }
            <hr />

            <div className={classes.fontBold}>{t('Preview')}:</div>
            {

                question.options.map((item, key) => {
                    return <Button key={key} onClick={() => this.setState({ 'yesno': item.value })} color={yesno === item.value ? 'info' : null}>{item.label}</Button>
                })
            }
        </div>;
    }
}
const mapStatsToProps = ({ user }) => ({ user });
export default connect(mapStatsToProps)(
  withTranslation()(withStyles(styles, { withTheme: true })(Component))
);