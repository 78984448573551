import withStyles from '@material-ui/core/styles/withStyles';
import { default as React } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { question_types } from "redux/config"
import TextFormat from "@material-ui/icons/TextFormat";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControlLabel from '@material-ui/core/FormControlLabel';

import CustomInput from "components/CustomInput/CustomInput.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Switch from "@material-ui/core/Switch";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem"
import Button from "components/CustomButtons/Button"
import commonStyle from "assets/jss/common/common";
import Age from "./components/Age"
import CheckBox from "./components/CheckBox"
import Dates from "./components/Dates"
import Gender from "./components/Gender"
import Nationality from "./components/Nationality"
import Number from "./components/Number"
import RadioButton from "./components/RadioButton"
import TextAnswer from "./components/TextAnswer"
import YesNo from "./components/YesNo"
import { api_url } from "redux/config.js";
import Toast from "views/Toast/Toast";
import uuid from 'uuidv4';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

const styles = {
    ...commonStyle,
    footer: {
        borderTop: '1px solid;',
        borderColor: '#999999;',

        justifyContent: 'flex-end;'
    }
};
class Comonent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            survey_id: this.props.match.params.survey_id,
            sectionIndex: parseInt(this.props.match.params.section_index, 10),
            questionIndex: parseInt(this.props.match.params.question_index, 10),

            selected_question_type: 'checkbox',
            surveyQuestions: null,
            mainData: null,
            questionData: null,
            formData: {
                questionTitle: '',
                question: null,
                required: false,
                id: uuid()
            },
        }
        this.child = React.createRef();

    }
    componentDidMount() {
        this.onLoad()
    }

    onLoad = () => {
        let self = this;
        const id = this.state.survey_id;
        const url = api_url + `survey/get`;

        this.props.dispatch({ type: "LOAD_START" });
        axios({
            url,
            method: "get",
            params: {
                id: id
            },
            headers: {
                authorization: "Bearer ".concat(this.props.user.token)
            }
        })
            .then(function (response) {
                if (!response.data.success) {
                    Toast(400, response.data.error);
                } else {
                    self.setDefaultFormData(response.data.data)
                }
                self.props.dispatch({ type: "LOAD_END" });
            })
            .catch(function (errors) {
                console.info(errors);
                self.props.history.replace('/')
                self.props.dispatch({ type: "LOAD_END" });
                Toast(500);
            });
    };
    setDefaultFormData = (data) => {
        const { survey_id, sectionIndex, questionIndex, } = this.state
        const surveyQuestions = data.questions;
        const mainData = data.main
        if (questionIndex >= 0) {
            const questionLang = surveyQuestions.filter((item) => item.language === mainData.language)[0]
            let question = null
            if (questionLang) {
                question = questionLang.sections[sectionIndex].questions[questionIndex]

            } else {
                let questionLang = surveyQuestions[0]

                question = questionLang.sections[sectionIndex].questions[questionIndex]

            }
            this.setState({
                formData: question,
                selected_question_type: question.question.type,

                surveyQuestions,
                mainData,
            })
        } else {

            this.setState({
                surveyQuestions,
                mainData,
            })
        }
    }
    setFormData = (name, value) => {
        let formData = this.state.formData
        formData[name] = value
        this.setState({ formData })
    }
    renderQuestionType = () => {
        const { selected_question_type, formData } = this.state
        switch (selected_question_type) {
            case "checkbox":
                return <CheckBox onRef={ref => (this.child = ref)} question={formData.question} />
            case "radio":
                return <RadioButton onRef={ref => (this.child = ref)} question={formData.question} />
            case "text":
                return <TextAnswer onRef={ref => (this.child = ref)} question={formData.question} />
            case "age":
                return <Age onRef={ref => (this.child = ref)} question={formData.question} />
            case "date":
                return <Dates onRef={ref => (this.child = ref)} question={formData.question} />
            case "nation":
                return <Nationality onRef={ref => (this.child = ref)} question={formData.question} />
            case "gender":
                return <Gender onRef={ref => (this.child = ref)} question={formData.question} />
            case "yesno":
                return <YesNo onRef={ref => (this.child = ref)} question={formData.question} />
            case "number":
                return <Number onRef={ref => (this.child = ref)} question={formData.question} />
            default:
                return null
        }
    }
    onSave = () => {
        const { survey_id, sectionIndex, questionIndex } = this.state
        let formData = this.state.formData

        if (formData.questionTitle.length === 0) {
            Toast(400, t('Please insert question name')); return;
        }
        const question = this.child.getData()
        if (question) {
            formData.question = question
            const surveyQuestions = this.state.surveyQuestions
            const newSurveyQuestions = surveyQuestions.map((question) => {
                let sections = question.sections
                let section = sections[sectionIndex]
                if (questionIndex >= 0) {
                    section.questions[questionIndex] = formData
                } else {
                    section.questions.push(formData)
                }
                return {
                    language: question.language,
                    sections: sections
                }
            })
            this.setState({
                surveyQuestions: newSurveyQuestions
            }, () => this.updateSurveyQuestion(() => this.props.history.push(`/survey/edit/${survey_id}`)))
        }
    }

    updateSurveyQuestion = async (callback) => {
        const survey_id = this.state.survey_id
        const questions = this.state.surveyQuestions
        let formData = {
            id: survey_id,
            updateData: {
                questions: questions,
            }
        }
        console.info(formData)

        this.props.dispatch({ type: "LOAD_START" });
        const result = await axios({
            url: api_url + "survey/update",
            method: "post",
            headers: {
                authorization: "Bearer ".concat(this.props.user.token)
            },
            data: formData
        });
        this.props.dispatch({ type: "LOAD_END" });
        if (result.data.success) {
            callback()
            Toast(200, t('Updated successfully'));
        } else {
            Toast(400, result.data.error);
        }
    };
    onSaveAnother = () => {
        const { survey_id, sectionIndex, questionIndex } = this.state
        let formData = this.state.formData

        if (formData.questionTitle.length === 0) {
            Toast(400, t('Please insert question name')); return;
        }
        const question = this.child.getData()
        if (question) {
            formData.question = question
            const surveyQuestions = this.state.surveyQuestions
            const newSurveyQuestions = surveyQuestions.map((question) => {
                let sections = question.sections
                let section = sections[sectionIndex]
                if (questionIndex >= 0) {
                    section.questions[questionIndex] = formData
                } else {
                    section.questions.push(formData)
                }
                return {
                    language: question.language,
                    sections: sections
                }
            })
            this.setState({
                surveyQuestions: newSurveyQuestions
            }, () => this.updateSurveyQuestion(() => this.setState({
                selected_question_type: 'checkbox',
                formData: {
                    questionTitle: '',
                    question: null,
                    required: false,
                    id: uuid()
                }
            })))
        }
    }
    onCancel = () => {
        const { survey_id, sectionIndex, questionIndex } = this.state

        this.props.history.replace(`/survey/edit/${survey_id}`)
    }
    render() {
        const { classes } = this.props
        const { formData, selected_question_type, surveyQuestions, questionIndex } = this.state
        if (!surveyQuestions) return null
        return <div >
            <Card>
                <CardHeader
                    color="info"
                >
                    <h4 className={classes.noMargin}>{t('Add your question in section')}</h4>

                </CardHeader>
                <CardBody>

                    <CustomInput
                        id="email"
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={{
                            onChange: e => this.setFormData('questionTitle', e.target.value),
                            startAdornment: (
                                <InputAdornment position="start">
                                    <TextFormat className={classes.inputAdornmentIcon} />
                                </InputAdornment>
                            ),
                            placeholder: t('Write Your Question Here'),
                            value: formData.questionTitle

                        }}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={formData.required}
                                onChange={e => {
                                    this.setFormData('required', e.target.checked);
                                }}
                                value="checkedA"
                                color="primary"
                                classes={{
                                    switchBase: classes.switchBase,
                                    checked: classes.switchChecked,
                                    icon: classes.switchIcon,
                                    iconChecked: classes.switchIconChecked,
                                    bar: classes.switchBar
                                }}
                            />
                        }
                        label={t('This question is required?')}
                    />

                </CardBody>
            </Card>

            <Card>
                <CardHeader
                    color="info"
                >
                    <h4 className={classes.noMargin}>{t('Choose Type of Answer')}</h4>

                </CardHeader>
                <CardBody>
                    <GridContainer>
                        <GridItem xs={12} sm={2}>
                            {
                                question_types.map((item, key) => {
                                    return <div key={key}>
                                        <Button
                                            className={classes.justifyLeft}
                                            onClick={() => this.setState({ selected_question_type: item.type })}
                                            fullWidth
                                            color={item.type === selected_question_type ? 'info' : null}>{<item.icon />}{item.title}</Button>
                                    </div>
                                })
                            }
                        </GridItem>
                        <GridItem xs={12} sm={10}>
                            {this.renderQuestionType()}
                        </GridItem>
                    </GridContainer>

                </CardBody>
                <CardFooter className={classes.footer}>
                    <div>
                        <Button color="danger" onClick={() => this.onCancel()}>{t('Cancel')}</Button>
                        {questionIndex === -1 && <Button color="info" onClick={() => this.onSaveAnother()}>{t('Save and add another')}</Button>}
                        <Button color="success" onClick={() => this.onSave()}>{t('Save')}</Button>
                    </div>
                </CardFooter>
            </Card>
        </div>;
    }
}
const mapStatsToProps = ({ user }) => ({ user });
export default connect(mapStatsToProps)(
   withTranslation()(withStyles(styles, { withTheme: true })(Comonent))
);