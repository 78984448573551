import withStyles from '@material-ui/core/styles/withStyles';
import step2 from 'assets/images/help_steps/step2.png';
import step3 from 'assets/images/help_steps/step3.png';
import step4 from 'assets/images/help_steps/step4.png';
import step5 from 'assets/images/help_steps/step5.png';
import step6 from 'assets/images/help_steps/step6.png';
import Button from "components/CustomButtons/Button.jsx";
import { t } from 'i18next';
import { default as React } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { gooSync_windows } from 'redux/config.js';

const styles = {};
class HelpSyncAppPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        return <div >
            <h3>{t('You can add emails in csv files in custom local directoy using gooSync application')}</h3>
            <Button href={gooSync_windows} color="info">WINDOWS</Button>
            <h3>{t('How to use application')}</h3>
            <h5>{t('1. Download application in your local drive')}</h5>
            <h5>{t('2. Go to email list edit page and copy ID of email list')}</h5>
            <img src={step2} width='70%'></img>
            <h5>{t('3. Open downloaded application')}</h5>
            <img src={step3} width='70%'></img>
            <h5>{t('4. Select directory where you saved csv files')}</h5>
            <img src={step4} width='70%'></img>
            <h5>{t('5. Enter email list ID you copied in step 2')}</h5>
            <img src={step5} width='70%'></img>
            <h5>{t('6. Click start button')}</h5>
            <img src={step6} width='70%'></img>
            <br></br>
            <b>&#10029; {t("Don't close application")}</b>
        </div>;
    }
}
const mapStatsToProps = ({ user }) => ({ user });
export default connect(mapStatsToProps)(
    withTranslation()(withStyles(styles, { withTheme: true })(HelpSyncAppPage))
);