
import { DashboardTwoTone, Email, } from "@material-ui/icons";
import ViewList from "@material-ui/icons/ViewList";
import AllotmentSurveyCreatePage from "views/Admin/AllotmentSurveyCreatePage";
import AllotmentSurveyPage from "views/Admin/AllotmentSurveyPage";
import ResponsePlanPage from "views/Admin/ResponsePlanPage";
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import ProfilePage from "views/Dashboard/ProfilePage";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CostumerListPage from "views/Admin/CostumerListPage";
import CostumerPaymentDetailPage from "views/Admin/CostumerPaymentDetailPage";
import EditCostumerPage from "views/Admin/EditCostumerPage";
import CreateCostumerPage from "views/Admin/CreateCostumerPage";

var admin_routes = [

  {
    path: "/admin/costumers",
    name: "Costumers",
    component: CostumerListPage,
    icon: AccountCircleIcon,
  },
  {
    path: "/admin/costumer_payments/:costumer_id",
    name: "Costumer Payments",
    component: CostumerPaymentDetailPage,
    icon: AccountCircleIcon,
    hidden: true
  },
  {
    path: "/admin/costumer_create",
    name: "Costumer Update",
    component: CreateCostumerPage,
    icon: AccountCircleIcon,
    hidden: true
  },
  {
    path: "/admin/costumer_update/:costumer_id",
    name: "Costumer Update",
    component: EditCostumerPage,
    icon: AccountCircleIcon,
    hidden: true
  },
  {
    path: "/admin/allotment_survey_list",
    name: "Allotment Survey",
    icon: ViewList,
    component: AllotmentSurveyPage,
  },
  {
    path: "/admin/survey/create_survey",
    name: "Create Allot Survey",
    icon: ViewList,
    component: AllotmentSurveyCreatePage,
    hidden: true
  },
  {
    path: "/admin/response_plan",
    name: "Response Plan",
    icon: FormatListNumberedIcon,
    component: ResponsePlanPage,
  },
  {
    path: "/dashboard/profile",
    name: "Profile",
    component: ProfilePage,
    icon: AccountCircleIcon,
  },
  { redirect: true, path: "/", pathTo: "/admin/allotment_survey_list", name: "Dashboard" }
];
export default admin_routes;
