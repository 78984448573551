import React, { Component } from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
import Contacts from "@material-ui/icons/Contacts";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import ViewList from "@material-ui/icons/ViewList";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import Lock from "@material-ui/icons/Lock";
import AccountCircle from "@material-ui/icons/AccountCircle";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Badge from "components/Badge/Badge";
import Table from "components/Table/Table.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import SweetAlert from "react-bootstrap-sweetalert";
import ReactTable from "react-table";

import "react-table/react-table.css";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import { response_detail } from "views/DataDemo.jsx";
import axios from 'axios';
import { connect } from 'react-redux';
import { api_url } from 'redux/config.js'
import Toast from 'views/Toast/Toast';

import { getData } from "country-list";
import CustomSelect from "components/CustomSelect/CustomSelect"
import { withTranslation } from "react-i18next";
const styles = {
  ...regularFormsStyle
};

class ProfilePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: "pendingTransaction",
      profileData: null,
      countries: [],

      oldPassword: '',
      newPassword: '',
      confirmNewPass: '',
    };
  }
  componentDidMount() {
    let countries = [];
    getData().map(item => {
      countries.push({
        value: item.code,
        label: item.name
      });
    });
    this.setState({ countries });
    this.onLoad()
  }

  getProfile = () => {
    const profileData = this.state.profileData;
    return profileData
  }
  onLoad = () => {
    let self = this;
    const url = api_url + `user/profile`;
    const header = 'Bearer '.concat(this.props.user.token);
    let headers = null;
    if (this.props.user.token) {
      headers = {
        Authorization: header
      };
    }
    this.props.dispatch({ type: 'LOAD_START' });
    axios({
      url,
      method: 'get',
      headers
    })
      .then(function (response) {
        if (response.data.success) {
          self.setState({
            profileData: response.data.data
          });
        } else {
          Toast(400, response.data.error)
        }
        self.props.dispatch({ type: 'LOAD_END' });
      })
      .catch(function (errors) {
        console.info(errors);
        self.props.dispatch({ type: 'LOAD_END' });
        if (!errors.response) {
          Toast(500, 'Connection error!');
          return;
        }
        Toast(errors.response.status);
      });
  };
  setProfileData = (name, value) => {
    let profileData = this.state.profileData;
    profileData[name] = value;
    this.setState({
      profileData
    })
  }
  updateProfile = () => {
    const { _id, email_verified, __v, password, ...profileData } = this.state.profileData
    let self = this;
    const url = api_url + `user/update_profile`;
    const header = 'Bearer '.concat(this.props.user.token);
    let headers = null;
    if (this.props.user.token) {
      headers = {
        Authorization: header
      };
    }
    this.props.dispatch({ type: 'LOAD_START' });
    axios({
      url,
      method: 'post',
      headers,
      data: profileData
    })
      .then(function (response) {
        if (response.data.success) {
          self.setState({
            profileData: response.data.data
          });
        } else {
          Toast(400, response.data.error)
        }
        self.props.dispatch({ type: 'LOAD_END' });
      })
      .catch(function (errors) {
        console.info(errors);
        self.props.dispatch({ type: 'LOAD_END' });
        if (!errors.response) {
          Toast(500, 'Connection error!');
          return;
        }
        Toast(errors.response.status);
      });
  };
  updatePassword = () => {
    const { oldPassword, newPassword, confirmNewPass } = this.state;
    if (newPassword !== confirmNewPass) {
      Toast(400, 'Confirm password is not matched');
      return;
    }
    if (newPassword.length < 6) {
      Toast(400, "Password minimum length is 6");
      return;
    }
    let self = this;
    const url = api_url + `user/update_password`;
    const header = 'Bearer '.concat(this.props.user.token);
    let headers = null;
    if (this.props.user.token) {
      headers = {
        Authorization: header
      };
    }
    this.props.dispatch({ type: 'LOAD_START' });
    axios({
      url,
      method: 'post',
      headers,
      data: {
        oldPassword,
        newPassword
      }
    })
      .then(function (response) {
        if (response.data.success) {
          self.setState({
            newPassword: '',
            confirmNewPass: '',
          });
          Toast(200, "Successfully updated")
        } else {
          Toast(400, response.data.error)
        }
        self.props.dispatch({ type: 'LOAD_END' });
      })
      .catch(function (errors) {
        console.info(errors);
        self.props.dispatch({ type: 'LOAD_END' });
        if (!errors.response) {
          Toast(500, 'Connection error!');
          return;
        }
        Toast(errors.response.status);
      });
  }
  render() {
    const { classes, t } = this.props;
    const profileData = this.getProfile();
    if (!profileData) return null;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12}>
            <Card>
              <CardHeader color="success" icon>
                <CardIcon color="success">
                  <AccountCircle />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>{t('Update common')}</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem sm={6} xs={12}>
                    <CustomInput
                      labelText={t('First name')}
                      id="Name"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "text",
                        value: profileData.first_name,
                        onChange: (e) => this.setProfileData('first_name', e.target.value)
                      }}
                    />
                  </GridItem>
                  <GridItem sm={6} xs={12}>
                    <CustomInput
                      labelText={t('Last name')}
                      id="lastName"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "text",
                        value: profileData.last_name,
                        onChange: (e) => this.setProfileData('last_name', e.target.value)
                      }}

                    />
                  </GridItem>
                  <GridItem sm={6} xs={12}>
                    <CustomInput
                      labelText={t('Email')}
                      id="Email"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "text",
                        value: profileData.email,
                        onChange: (e) => this.setProfileData('email', e.target.value)
                      }}


                    />
                  </GridItem>
                  <GridItem sm={6} xs={12}>
                    <CustomInput
                      labelText={t('Company Name')}
                      id="CompanyName"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "text",
                        value: profileData.company_name,
                        onChange: (e) => this.setProfileData('company_name', e.target.value)
                      }}


                    />
                  </GridItem>
                  <GridItem sm={6} xs={12}>
                    <CustomInput
                      labelText={t('Company Address')}
                      id="CompanyAddress"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "text",
                        value: profileData.company_address,
                        onChange: (e) => this.setProfileData('company_address', e.target.value)
                      }}
                    />
                  </GridItem>
                  <GridItem sm={6} xs={12}>
                    <CustomInput
                      labelText={t('Company Tax Number')}
                      id="CompanyTaxNumber"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "text",
                        value: profileData.tax_id,
                        onChange: (e) => this.setProfileData('tax_id', e.target.value)
                      }}
                    />
                  </GridItem>
                  <GridItem sm={6} xs={12}>
                    <CustomInput
                      labelText={t('Company Tax Office')}
                      id="CompanyTaxOffice"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "text",
                        value: profileData.tax_office,
                        onChange: (e) => this.setProfileData('tax_office', e.target.value)
                      }}
                    />
                  </GridItem>
                  <GridItem sm={4} xs={12}>
                    <CustomSelect
                      onChange={country => {
                        this.setProfileData("country", country);
                      }}
                      placeholder="Country"
                      options={this.state.countries}
                      value={profileData.country}
                    />
                  </GridItem>
                  <GridItem sm={4} xs={12}>
                    <CustomInput
                      labelText={t('City')}
                      id="City"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "text",
                        value: profileData.city,
                        onChange: (e) => this.setProfileData('city', e.target.value)
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button color="info" onClick={() => this.updateProfile()}>{t('Update')}</Button>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12}>
            <Card>
              <CardHeader color="success" icon>
                <CardIcon color="success">
                  <Lock />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>{t('Change Password')}</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem sm={4} xs={12}>
                    <CustomInput
                      labelText={t('Current Password')}
                      id="CurrentPassword"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "password",
                        value: this.state.oldPassword,
                        onChange: (e) => this.setState({
                          oldPassword: e.target.value
                        })
                      }}
                    />
                  </GridItem>
                  <GridItem sm={4} xs={12}>
                    <CustomInput
                      labelText={t('New Password')}
                      id="NewPassword"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "password",
                        value: this.state.newPassword,
                        onChange: (e) => this.setState({
                          newPassword: e.target.value
                        })
                      }}
                    />
                  </GridItem>
                  <GridItem sm={4} xs={12}>
                    <CustomInput
                      labelText={t('Confirmation')}
                      id="Confirmation"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "password",
                        value: this.state.confirmNewPass,
                        onChange: (e) => this.setState({
                          confirmNewPass: e.target.value
                        })
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button color="info" onClick={() => this.updatePassword()}>{t('Update')}</Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps)(withTranslation()(withStyles(styles)(ProfilePage)));