import React, { useState, useEffect } from 'react';
import TagsInput from '@incoqnito.io/react-tagsinput';
import FormControl from "@material-ui/core/FormControl";
// @material-ui/core components
import { makeStyles } from '@material-ui/core'

import Send from "@material-ui/icons/Send";
import ViewList from "@material-ui/icons/ViewList";
import { Editor } from "@tinymce/tinymce-react";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import axios from 'axios';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ISO6391 from "iso-639-1";
import moment from "moment";
import Datetime from "react-datetime";
import { connect, useDispatch } from 'react-redux';
import CustomSelect from "components/CustomSelect/CustomSelect"
import "react-table/react-table.css";
import { api_url } from 'redux/config.js';
import { verifyEmail } from "utils/Validate";
import Toast from 'views/Toast/Toast';
import { CallApi } from 'utils/Request';
import Muted from "components/Typography/Muted"
import { useSelector } from 'react-redux';
import { Reply } from '@material-ui/icons';
import LoadingModal from 'components/Loading/Loading';
import Table from "components/Table/Table.jsx";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import CustomModal from 'components/Modal/CustomModal';
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import { loadProfile } from 'views/Services/UserService';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => {
  return {
    ...regularFormsStyle,
    descriptionTextArea: {
      width: "100%",
      height: 150
    },
    emailTags: {
      width: "100%",
      borderBottom: "1px solid #D2D2D2 !important"
    }
  };
})


export default function PurchageResponds() {
  const classes = useStyles()
  const { t } = useTranslation();

  const APP_CONSTANTS = useSelector((s) => s.state).app_constants
  const dispatch = useDispatch()
  const [responsePlans, setResponsePlans] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [showPaymentModal, setShowPaymentModal] = useState(false)

  const [selectedPlan, setSelectedPlan] = useState(null)

  useEffect(() => {
    loadData()
    loadProfile()

  }, [])

  const loadData = () => {
    setIsLoading(true)
    CallApi({
      url: 'response_plan/list',
      method: 'get',
    }).then((res) => {
      if (res.success) {
        setResponsePlans(res.data)
      } else {
        Toast(400, res.error)
      }
    }).finally(() => {
      setIsLoading(false)
    })
  }
  // creates a paypal order
  const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            description: selectedPlan.title,
            amount: {
              currency_code: "EUR",
              value: selectedPlan.price,
            },
          },
        ],
        // not needed if a shipping address is actually needed
        application_context: {
          shipping_preference: "NO_SHIPPING",
        },
      })
      .then((orderID) => {
        return orderID;
      });
  };

  // check Approval
  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      const { payer } = details;
      const formdata = {
        title: selectedPlan.title,
        response_limit: selectedPlan.response_limit,
        price: selectedPlan.price,
        detail: details
      }
      setIsLoading(true)
      CallApi({
        url: 'response_plan/purchase_success',
        method: 'post',
        data: formdata
      }).then((res) => {
        if (res.success) {
          Toast(200, 'Successfully purchased!')
          loadProfile()
        } else {
          Toast(400, res.error)
        }
      }).finally(() => {
        setShowPaymentModal(false)
        setIsLoading(false)
      })
    });
  };
  //capture likely error
  const onError = (data, actions) => {
    Toast(400, t("An Error occured with your payment"));
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <Reply />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t('RESPONSE PLANS')}</h4>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={[t("Package Name"), t("No. of Responses"), t("Price"), "#"]}

                tableData={responsePlans.map((res, key) => {
                  const title = res.title
                  const price = res.price + ' €'
                  const limit = res.response_limit
                  const button = (
                    <Button
                      color="info"
                      fullWidth
                      size="lg"
                      onClick={() => {
                        setSelectedPlan(res)
                        setShowPaymentModal(true)
                      }}
                    >
                      <ShoppingCart />
                      {t('Pay Now')}
                    </Button>
                  )
                  return [title, limit, price, button]
                })}
                coloredColls={[3]}
                colorsColls={["primary"]}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <CustomModal
        open={showPaymentModal}
        onClose={() => setShowPaymentModal(false)}
        footer={<div>
          <Button color='danger' onClick={() => setShowPaymentModal(false)}>{t('Close')}</Button>
        </div>
        }
        maxWidth='lg'
      >
        <PayPalScriptProvider options={{ "client-id": APP_CONSTANTS.PAYPAL_CLIENT_ID }}>
          <PayPalButtons
            style={{ layout: "vertical" }}
            createOrder={createOrder}
            onApprove={onApprove}
            onError={onError}
          />
        </PayPalScriptProvider>
      </CustomModal>
      {isLoading && <LoadingModal />}
    </div>
  );
}