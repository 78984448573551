import React, { useState, useEffect } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Icon from "@material-ui/core/Icon";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Check from "@material-ui/icons/Check";
import Email from "@material-ui/icons/Email";
import axios from "axios";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

// import LockOutline from "@material-ui/icons/LockOutline";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { api_url } from "redux/config.js";
import Toast from "views/Toast/Toast";
import { user } from "views/DataDemo.jsx";
import { withTranslation } from "react-i18next";
import { t } from "i18next";

import forgetPasswordStyle from "./ForgetPassword.style";

const ForgetPassword = (props) => {
  const [email, setEmail] = useState('')
  const [codeSent, setCodeSent] = useState(false)
  const classes = props.classes

  const verifyEmail = value => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };
  const sendResetLink = async () => {
    if (!verifyEmail(email)) {
      Toast(400, t('Invalid email'));
      return;
    }
    try {
      let result = await axios.get(api_url + "auth/send_reset_password_link/"+email);
      if (result.data.success) {
        setCodeSent(true)
        Toast(200, 'Password reset link has been sent to your email.')
      } else {
        Toast(400, result.data.error);
      }
    } catch (errors) {
      console.info(errors);
      Toast(500);
    }
  };



  return <div className={classes.container} >
    <GridContainer justify="center">
      <GridItem xs={12} sm={6} md={4}>
        <form>
          <Card className={classes.cardSignup}>
            <CardHeader
              className={`${classes.cardHeader} ${classes.textCenter}`}
              color="info"
            >
              <h4 className={classes.cardTitle}>{t('Log in')}</h4>
            </CardHeader>
            <CardBody>
              <div onKeyPress={(e) => {
                if (e.which == 13) {
                  sendResetLink()
                }
              }}>
                <CustomInput
                  labelText={t('Email')}
                  id="email"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: e => setEmail(e.target.value),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    )
                  }}
                />
                {/* <CustomInput
                  labelText={t('Password')}
                  id="password"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: e =>
                      this.setState({ password: e.target.value }),
                    type: "password",
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    )
                  }}
                /> */}
                <Button
                  onClick={sendResetLink}
                  color="success"
                  block
                >
                  {t('Send Code')}
                </Button>
                <Button
                  href="/auth/login"
                  simple
                  color="info"
                  size="lg"
                  block
                >
                  {t('Log in')}
                </Button>
              </div>
            </CardBody>
          </Card>
        </form>
      </GridItem>
    </GridContainer>
  </div >
}

ForgetPassword.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps)(withTranslation()(withStyles(forgetPasswordStyle)(ForgetPassword)));
