import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import Check from "@material-ui/icons/Check";
import commonStyle from "assets/jss/common/common";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import Button from 'components/CustomButtons/Button';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import CustomSelect from "components/CustomSelect/CustomSelect";
import { t } from "i18next";
import { default as React } from 'react';
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { question_types } from "redux/config";
import TitleBox from "views/Shared/TitleBox";
import Toast from "views/Toast/Toast";


const styles = {
    ...commonStyle,
    ...regularFormsStyle,
    choiceContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        marginTop: 10
    }
};

class Component extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            questionSchema: null,
            answerTypes: [],


            question: {
                type: 'checkbox',
                direction: '',
                answertype: '',
                selectable_count: 0,
                has_free: false,
                options: [], // value, label
            },
        }
    }
    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        const prevQuestionStr = JSON.stringify(prevProps.question)
        const currentQuestionStr = JSON.stringify(this.props.question)
        if (prevQuestionStr !== currentQuestionStr) {
            this.initState()
        }
    }
    initState = () => {
        let questionSchema = question_types.filter((item) => item.type === 'checkbox')[0]
        const question = this.props.question
        this.setState({
            questionSchema: questionSchema,
            answerTypes: questionSchema.answertypes,
            question: (question && question.type === questionSchema.type) ? question : {
                type: questionSchema.type,
                direction: questionSchema.directions[0].value,
                answertype: questionSchema.answertypes[0].value,
                selectable_count: 0,
                has_free: false,
                options: []
            },
        })
    }
    componentDidMount() {
        this.props.onRef(this)
        this.initState()
    }
    getData() {
        const question = this.state.question
        if (question.options.length === 0) {
            Toast(400, t('Please add options')); return null
        }
        if (question.answertype === 'choose') {
            if (question.options.length < 3) {
                Toast(400, t('Please input more than 3 check options')); return null
            }
            if (question.selectable_count === 0) {
                Toast(400, t('Please choose number of selectable options')); return null
            }
        }

        for (let i = 0; i < question.options.length; i++) {
            let option = question.options[i]
            if (option.label.length === 0) {
                Toast(400, t('Please enter option label!'))
                return null
            }
        }
        const has_free = question.has_free;

        let options = question.options.filter((item) => item.value !== 'other')
        if (has_free) {
            options.push({
                value: 'other',
                label: 'other',
                text: ''
            })
            return {
                ...question,
                options
            }
        } else {
            return {
                ...question,
                options
            }
        }
    }

    setQuestionData = (name, value) => {
        let questionSchema = question_types.filter((item) => item.type === 'checkbox')[0]

        let question = this.state.question;
        question[name] = value;
        if (name === 'direction') {

            question.answertype = questionSchema.answertypes[0].value;
            if (value === 'dropdown') {
                this.setState({
                    question,
                    answerTypes: [questionSchema.answertypes[0]]
                })
            } else {
                this.setState({
                    question,
                    answerTypes: questionSchema.answertypes
                })
            }
        } else {
            this.setState({
                question
            })
        }
    }
    setQuestionItem = (name, index, value) => {
        let question = this.state.question;
        let options = question.options
        options[index][name] = value
        this.setState({
            question: {
                ...question,
                options
            }
        })
    }

    addQuestionItem = () => {
        let question = this.state.question;
        let options = question.options

        const newItemValue = this.state.newItemValue !== 'other' ? 0 : 'other'
        options.push({
            value: newItemValue,
            label: ''
        })
        this.setState({
            question: {
                ...question,
                options
            },
        })
    }
    removeQuestionItem = (index,) => {
        let question = this.state.question;
        let options = question.options
        options.splice(index, 1)
        this.setState({
            question: {
                ...question,
                selectable_count: 0,
                options
            }
        })
    }
    getChooseOptions = () => {
        const question = this.state.question
        if (question.options.length < 3) return [];
        let result = []
        for (let i = 2; i < question.options.length; i++) {
            result.push({ label: i, value: i })
        }
        return result
    }
    toggleQuestionOptionHasFree = () => {
        const has_free = this.state.question.has_free
        this.setQuestionData('has_free', !has_free)
    }
    render() {
        const { questionSchema, question, answerTypes } = this.state
        if (!questionSchema) return null
        const { classes } = this.props
        const optionswithoutOther = question.options.filter((item) => item.value !== 'other')
        return <div >
            <hr />
            <div className={classes.fontBold}>{t('Choose Position for Answers')}:</div>
            {
                questionSchema.directions.map((item, key) => {
                    return <Button
                        key={key}
                        onClick={() => this.setQuestionData('direction', item.value)}
                        size="sm" color={item.value === question.direction ? "info" : null}>{item.label}</Button>
                })
            }
            <div className={classes.fontBold}>{t('Choose Answers Type')}:</div>
            {
                answerTypes.map((item, key) => {
                    return <Button
                        key={key}
                        onClick={() => this.setQuestionData('answertype', item.value)}
                        size="sm" color={item.value === question.answertype ? "info" : null}>{item.label}</Button>
                })
            }

            {question.answertype === 'choose' && <CustomSelect
                onChange={count => {
                    this.setQuestionData("selectable_count", count.value);
                }}
                placeholder={t('Select')}
                value={{ label: question.selectable_count, value: question.selectable_count }}
                options={this.getChooseOptions()}
            />}
            <hr />
            <TitleBox title={t('CHOICES')}>
                {
                    optionswithoutOther.map((item, key) => {
                        return <div key={key}>
                            <CustomInput
                                formControlProps={{
                                    className: classes.noPadding + ' ' + classes.noMargin,
                                    onChange: e =>
                                        this.setQuestionItem("value", key, e.target.value),
                                }}
                                inputProps={{
                                    placeholder: t('Value'),
                                    value: item.value,
                                    type: 'number'
                                }}
                            />
                            <CustomInput

                                formControlProps={{
                                    className: classes.noPadding + ' ' + classes.noMargin,
                                    onChange: e =>
                                        this.setQuestionItem("label", key, e.target.value),
                                }}
                                inputProps={{
                                    placeholder: t('Answer'),
                                    value: item.label
                                }}
                            />
                            <Button color="danger" size="sm" onClick={() => this.removeQuestionItem(key)}>Remove</Button>
                        </div>
                    })
                }
                <div className={classes.choiceContainer}>


                    <Button className={classes.noMargin} color="success" size="sm" onClick={() => this.addQuestionItem()}>{t('Add option')}</Button>

                </div>
                <div>
                    <FormControlLabel
                        control={
                            <Checkbox
                                tabIndex={-1}
                                checked={question.has_free}
                                onClick={() => this.toggleQuestionOptionHasFree()}
                                checkedIcon={<Check className={classes.checkedIcon} />}
                                icon={<Check className={classes.uncheckedIcon} />}
                                classes={{
                                    checked: classes.checked
                                }}
                            />
                        }
                        classes={{
                            label: classes.label
                        }}
                        label={t('Free Field')}
                    />
                </div>

            </TitleBox>
        </div>;
    }
}
const mapStatsToProps = ({ user }) => ({ user });
export default connect(mapStatsToProps)(
    withTranslation()(withStyles(styles, { withTheme: true })(Component))
);