import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Footer from "components/Footer/Footer.jsx";

import pagesStyle from "assets/jss/material-dashboard-pro-react/layouts/pagesStyle.jsx";

import bgImage from "assets/img/background.png";
import authRoutes from "routes/auth.jsx";
import ButterToast, { POS_BOTTOM, POS_RIGHT } from "butter-toast";

const styles = {
  fullPage: {
    minHeight: '100vh'
  }
}
class AuthPages extends React.Component {
  componentDidMount() {
    document.body.style.overflow = "unset";
    console.info(this.props)
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <ButterToast
          position={{ vertical: POS_BOTTOM, horizontal: POS_RIGHT }}
        />
        <div ref="wrapper">
          <div
            className={classes.fullPage}
            style={{ backgroundImage: "url(" + bgImage + ")" }}
          >
            <Switch>
              {authRoutes.map((prop, key) => {
                if (prop.collapse) {
                  return null;
                }
                if (prop.redirect) {
                  return (
                    <Redirect from={prop.path} to={prop.pathTo} key={key} />
                  );
                }
                return (
                  <Route
                    path={prop.path}
                    component={prop.component}
                    key={key}
                  />
                );
              })}
            </Switch>
          </div>
          <Footer fluid />
        </div>
      </div>
    );
  }
}

AuthPages.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AuthPages);
