// core components
import withStyles from "@material-ui/core/styles/withStyles";

import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import axios from 'axios';
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from 'react-redux';
import { api_url } from 'redux/config.js';
import { verifyEmail } from "utils/Validate";
import Toast from 'views/Toast/Toast';
import SurveyMainComponent from "./components/SurveyMainComponent";
import SurveyProgress from "./components/SurveyProgress";
import SurveyScore from "./components/SurveyScore"
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.jsx";
import Button from "components/CustomButtons/Button"
import commonStyle from "assets/jss/common/common"
import RenderQuestion from "views/Survey/RenderQuestions/RenderQuestion";
import pagesStyle from "layouts/PublicLayout.style";
import LanguageSelector from "views/Survey/components/LanguageSelector"
import { withTranslation } from "react-i18next";
import { t } from "i18next";
import { changeLanguage } from "i18next";
import { CallApi } from "utils/Request";
import * as Score from "utils/Score";

const styles = (theme) => ({
  ...sweetAlertStyle,
  ...pagesStyle(theme),
  ...commonStyle,
  stepBackground: {
    backgroundColor: 'darkslategray'
  },
  surveyImg: {
    width: "100px"
  },
  questionTitle: {
    padding: 10,
    background: 'white',
    textAlign: 'center',
    fontSize: '1.4rem'
  }
});
class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      survey_completed: false,
      survey_id: this.props.match.params.survey_id,
      survey_type: this.props.match.params.survey_type, // email, nocondition, password
      data: null,
      email: '',
      freeid: '',
      conditionInput: {

      },
      selectedLanguage: null,
      answers: [],
      currentStep: -1,
      questionsWithoutSection: [],
    }
  }
  componentDidMount() {
    document.body.style.overflow = "unset";
    this.onLoad(this.state.survey_id);
  }

  onComplete = (answers) => {
    let self = this;
    const url = api_url + `survey/complete`;
    const header = 'Bearer '.concat(this.props.user.token);
    let headers = null;
    let data = {
      survey: this.state.data._id,
      survey_type: this.state.survey_type,
      email: this.state.conditionInput.email,
      freeid: this.state.conditionInput.freeid,
      password: this.state.conditionInput.password,
      answers: answers,
      language: this.state.selectedLanguage
    }

    if (this.props.user.token) {
      headers = {
        Authorization: header
      };
    }
    this.props.dispatch({ type: 'LOAD_START' });
    axios({
      url,
      method: 'post',
      headers,
      data
    })
      .then(function (response) {
        if (response.data.success) {
          self.setState({
            survey_completed: true,
            answers: answers
          })
          self.sendRateMessage(data.survey, response.data.newresponse._id, answers)
        } else {
          Toast(400, response.data.error)
        }
        self.props.dispatch({ type: 'LOAD_END' });
      })
      .catch(function (errors) {
        console.info(errors);
        self.props.dispatch({ type: 'LOAD_END' });
        if (!errors.response) {
          Toast(500);
          return;
        }
        Toast(errors.response.status);
      });
  };
  sendRateMessage = (survey_id, response_id, answers) => {
    const { data, selectedLanguage } = this.state

    const questions = data.questions[0]

    CallApi({
      url: 'rate_message/send_message',
      method: 'post',
      data: {
        survey: survey_id,
        response_id: response_id,
        score: Score.getScoreOfOneResponse(questions, answers)
      }
    }).then((res) => {
      console.info('=== called rate message ===', JSON.stringify(res, null, 4))
    })
  }
  onLoad = (survey_id) => {
    let self = this;
    const url = api_url + `survey/get`;
    const header = 'Bearer '.concat(this.props.user.token);
    let headers = null;
    const params = {
      id: survey_id
    }
    if (this.props.user.token) {
      headers = {
        Authorization: header
      };
    }
    this.props.dispatch({ type: 'LOAD_START' });
    axios({
      url,
      method: 'get',
      headers,
      params
    })
      .then(function (response) {
        if (response.data.success) {
          self.setState({ data: response.data.data }, () => self.onChangeLanguage(null))
        } else {
          Toast(400, response.data.error);
        }
        self.props.dispatch({ type: 'LOAD_END' });
      })
      .catch(function (errors) {
        console.info(errors);
        self.props.dispatch({ type: 'LOAD_END' });
        if (!errors.response) {
          Toast(500);
          return;
        }
        Toast(errors.response.status);
      });
  };
  onChangeLanguage = (lang) => {
    const data = this.state.data
    const selectedLanguage = lang
    let questions = null;
    if (!selectedLanguage) {
      questions = data.questions[0]
    } else {
      questions = data.questions.filter(item => item.language === selectedLanguage)[0]
      changeLanguage(selectedLanguage)
    }
    let questionsWithoutSection = []
    if (questions) {
      questions.sections.map(section => {
        section.questions.map(question => {
          const newQuestion = {
            ...question,
            sectionTitle: section.sectionTitle
          }
          questionsWithoutSection.push(newQuestion)
        })
      })
      this.setState({
        selectedLanguage,
        questionsWithoutSection: questionsWithoutSection,
        currentStep: 0
      })
    }
  }
  getAnswerByID = (id) => {
    const answer = this.state.answers.filter(item => item.answer.id === id)[0]
    if (answer) {
      return answer;
    } else {
      return null
    }
  }

  changeAnswer = (data) => {
    let currentAnswers = this.state.answers;
    const existIndex = currentAnswers.findIndex(item => item.answer.id === data.answer.id);
    if (existIndex === -1) {
      currentAnswers.push(data)
    } else {
      currentAnswers[existIndex] = data
    }
    this.setState({ answers: currentAnswers })
  }


  onNext = () => {
    const { questionsWithoutSection, currentStep } = this.state
    const answer = this.getAnswerByID(questionsWithoutSection[currentStep].id);
    if (!answer.isValid) return
    if ((questionsWithoutSection.length - 1) === currentStep) {
      this.onComplete(this.state.answers.map((item) => {
        const { isValid, ...answer } = item
        return answer.answer
      }))
    } else {
      this.setState({ currentStep: currentStep + 1 })
    }
  }
  onPrev = () => {
    const { currentStep } = this.state
    this.setState({ currentStep: currentStep - 1 })
  }
  renderOneQuestion = () => {
    const { classes } = this.props
    const { questionsWithoutSection, currentStep } = this.state
    const qitem = questionsWithoutSection[currentStep]
    const answer = this.getAnswerByID(qitem.id);
    const isEmoji = qitem.question.type === 'radio';
    return <div>
      <div className={classes.questionTitle}>{qitem.required ? '* ' : ''}{qitem.sectionTitle} - <b>{qitem.questionTitle}</b></div>
      <div className={classes.padding10}>
        <RenderQuestion key={currentStep} question={qitem}
          answer={answer ? answer.answer : null}
          emojimode={true}
          onChange={(answer, isInit) => {
            this.changeAnswer(answer);

            if (!isInit){
              if (isEmoji) this.onNext()
            } 
          }} />
      </div>

      <div className={classes.textCenter}>
        {currentStep > 0 && <Button onClick={() => this.onPrev()} color="danger">Prev</Button>}
        {!isEmoji &&
          <Button onClick={() => this.onNext()} color="success">{currentStep === (questionsWithoutSection.length - 1) ? 'Complete' : 'Next'}</Button>
        }
      </div>
    </div>
  }
  getLanguageModal = () => {
    const { data, selectedLanguage } = this.state
    const { classes } = this.props
    if (selectedLanguage) return null

    return <SweetAlert
      style={{ display: "block", top: "10%", marginTop: "0" }}
      title={t('Choose Language')}
      showConfirm={false}
      showCancel={false}
      confirmBtnCssClass={
        this.props.classes.button + " " + this.props.classes.success
      }
    >
      <img className={classes.surveyImg} src={data.image_url} />
      <LanguageSelector languages={data.main.map(item => item.language)} onChange={(language) => this.onChangeLanguage(language)} />
    </SweetAlert>
  }
  render() {
    const { classes } = this.props;
    const { data, survey_completed, answers, questionsWithoutSection, currentStep } = this.state

    if (questionsWithoutSection.length === 0) return <div>{t('Loading')} ...</div>;

    const questions = data.questions[0]

    if (survey_completed) {
      return <div className={classes.wrapper} ref="wrapper">
        <div className={classes.fullPage}>
          <SurveyScore endMessages={data.endmessages} answers={answers} questions={questions} />
        </div>
      </div>
    }

    return (<div style={{ minHeight: 'calc(100vh - 100px)' }}>
      {this.getLanguageModal()}
      <div className={classes.flex + ' ' + classes.alignCenter + ' ' + classes.paddingH5 + ' ' + classes.stepBackground}>
        <div className={classes.flexGrow + ' ' + classes.paddingH5}>
          <CustomLinearProgress
            variant="determinate"
            color="success"
            value={100 * (currentStep + 1) / questionsWithoutSection.length}
            classes={{
              root: classes.noMargin
            }}
          />
        </div>
        <Button size="sm" color="danger" onClick={() => this.setState({ currentStep: 0 })}>{t('Restart')}</Button>
      </div>

      {
        this.renderOneQuestion()
      }
    </div>
    );
  }
}

Component.propTypes = {
  classes: PropTypes.object.isRequired
};


const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps)(withTranslation()(withStyles(styles)(Component)));