import withStyles from '@material-ui/core/styles/withStyles';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Table from "components/Table/Table.jsx";
import { default as React } from 'react';
import { Pie } from 'react-chartjs-2';
import { connect } from 'react-redux';
import * as Score from "utils/Score";
import TitleBox from "views/Shared/TitleBox";
import moment from "moment";

import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
require('assets/fonts/dejavu-sans-ttf-2.34/DejaVuSans-normal.js')
require('assets/fonts/dejavu-sans-ttf-2.34/DejaVuSans-bold.js')
const styles = {};
const report_types = [
    {
        label: t('Analytical'),
        value: "Analytical"
    },
    {
        label: t('Summary'),
        value: "Summary"
    },
    {
        label: t('Text reports'),
        value: "Textreports"
    },
    {
        label: t('Comparation'),
        value: "Comparation"
    }
];
class Analytical extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filterData: this.props.filterData,
            surveyQuestion: this.props.surveyQuestion,
            surveyResponses: this.props.surveyResponses,
            surveyMain: this.props.surveyMain,
            reportData: null
        }
    }

    componentDidMount() {
        this.props.onRef(this)
        this.initData()
    }
    initData = () => {
        const { filterData, surveyQuestion, surveyResponses, surveyMain } = this.props
        const data = this.calculateData(surveyQuestion, surveyResponses)
        this.setState({

            filterData: filterData,
            surveyQuestion: surveyQuestion,
            surveyResponses: surveyResponses,
            surveyMain: surveyMain,
            reportData: data
        })
    }
    calculateSummaryData = (filterQuestion, filterResponses) => {
        let reportSections = [];
        let surveyScore = 0
        let totalResult = null
        if (filterQuestion.sections.length > 0) {

            let countSectionScore = 0
            filterQuestion.sections.map((section) => {
                let reportQuestions = []
                let sectionScore = 0
                if (section.questions.length > 0) {
                    let countQuestionScore = 0
                    section.questions.map((q) => {
                        if (Score.VALUETYPE !== q.question.type) return null;
                        const reportOfQuestion = Score.getReportOfQuestion(q, filterResponses);
                        reportQuestions.push(reportOfQuestion)
                        if (reportOfQuestion.questionScore > 0) {
                            countQuestionScore++;
                            sectionScore += reportOfQuestion.questionScore
                        }
                    })
                    sectionScore = countQuestionScore > 0 ? (sectionScore / countQuestionScore) : 0
                }
                const reportsForSection = {
                    sectionTitle: section.sectionTitle,
                    reportQuestions,
                    sectionScore
                }
                reportSections.push(reportsForSection)
                if (reportsForSection.sectionScore > 0) {
                    countSectionScore++
                    surveyScore += reportsForSection.sectionScore
                }
            })
            totalResult = {
                reportSections: reportSections,
                surveyScore: countSectionScore > 0 ? (surveyScore / countSectionScore) : 0
            }
        }
        return totalResult
    }
    addDocText(doc, text, x, y) {
        const docWidth = doc.internal.pageSize.getWidth();

        var line = y // Line height to start text at
        var lineHeight = 4
        var leftMargin = x
        var wrapWidth = docWidth - 2 * x

        var splitText = doc.splitTextToSize(text, wrapWidth)
        for (var i = 0, length = splitText.length; i < length; i++) {
            // loop thru each line and increase
            doc.text(splitText[i], leftMargin, line)
            line = lineHeight + line
        }
        return line
    }
    generatePdf = (fileName) => {

        var doc = new jsPDF();

        const docWidth = doc.internal.pageSize.getWidth();
        const docHeight = doc.internal.pageSize.getHeight();
        var startX = 10

        var contentWidth = docWidth - startX * 2

        doc.setFont('DejaVuSans');
        doc.setFontSize(9)
        doc.autoTableSetDefaults({ styles: { font: 'DejaVuSans', minCellWidth: 15, fontSize: 8 }, headStyles: { font: 'DejaVuSans' } })
        var finalY = 20;
        var gap = 4
        var head = [];
        var body = [];

        const { filterData, surveyQuestion, surveyResponses, surveyMain } = this.props
        // title

        finalY = this.addDocText(doc, surveyMain.title, startX, finalY)
        // end title
        // filter part
        const isCompare = filterData.type.value === report_types[3].value
        const period1 = String(moment(filterData.from1).format('LL') + ' ~ ' + moment(filterData.to1).format('LL'))
        const period2 = String(moment(filterData.from2).format('LL') + ' ~ ' + moment(filterData.to2).format('LL'))
        const emailGroup = filterData.group.map(a => a.label).join(',');
        const section = filterData.section ? filterData.section.label : '';
        const question = filterData.question ? filterData.question.label : '';
        const answer = filterData.answer.map(a => a.label).join(',');

        head.push([t('Filter options'), ''])
        body.push([t("Report Type"), "Analytical"])
        body.push([t("Period1"), period1])
        if (isCompare) { body.push([t('Period2'), period2]) }
        body.push([t('Emails Group'), emailGroup])
        body.push([t('Section'), section])
        body.push([t('Question'), question])
        body.push([t('Answers'), answer])
        body.push([t('Total Answers'), surveyResponses.length])


        doc.autoTable({ startY: finalY, head: head, body: body, theme: 'grid' });
        finalY = doc.previousAutoTable.finalY + gap;
        // end filter

        // summary part
        head = []; body = []
        let scores = []
        const summaryData = this.calculateSummaryData(surveyQuestion, surveyResponses)
        head.push([t('Section Average with Rating'), summaryData.surveyScore.toFixed(2), ''])
        summaryData.reportSections.map((section, skey) => {
            if (section.sectionScore === 0) return null
            const sectionLabel = `Q.${skey}.${section.sectionTitle}`
            const sectionScore = `${t('Score:')}  ${section.sectionScore.toFixed(2)}`
            scores.push(section.sectionScore)
            body.push([sectionLabel, sectionScore, ''])
        })

        doc.autoTable({
            columnStyles: { 2: { cellWidth: 30 } },
            startY: finalY, head: head, body: body, theme: 'grid', didDrawCell: data => {
                if (data.section === 'body' && data.column.index === 2) {
                    var base64Img = 'data:image/jpeg;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mPkW8/wHwADTgG+e7jB5QAAAABJRU5ErkJggg=='
                    doc.addImage(base64Img, 'JPEG', data.cell.x + 1, data.cell.y + 1, scores[data.row.index] / 5 * 10, 5)
                }
            },
        });
        finalY = doc.previousAutoTable.finalY + gap * 2;
        // end summary
        // section and questions

        const surveydata = this.calculateData(surveyQuestion, surveyResponses)
        surveydata.reportSections.forEach(section => {
            const sectionTitle = section.sectionTitle + (section.sectionScore > 0 ? " " + t('Score:') + " " + section.sectionScore.toFixed(2) : '')

            finalY = doc.previousAutoTable.finalY + gap * 4

            finalY = this.addDocText(doc, sectionTitle, startX, finalY)

            section.reportQuestions.forEach((question, ekey) => {

                head = []; body = []
                const questionTitle = question.questionTitle + (question.questionScore > 0 ? " " + t('Score:') + " " + question.questionScore.toFixed(2) : '')
                head.push([questionTitle, '', ''])
                body.push([t('Activity'), t('Answer'), t('Relative Answers')])
                question.reports.forEach((item) => {
                    body.push([item.answerLabel, item.count, item.percent.toFixed(2) + '%'])
                })

                doc.autoTable({ startY: finalY, head: head, body: body, theme: 'grid' });
                finalY = doc.previousAutoTable.finalY + gap
            })

        });
        // window.open(URL.createObjectURL(doc.output("blob")))
        doc.save(fileName);
    }

    generateCSV = () => {

        var rows = [];

        const { filterData, surveyQuestion, surveyResponses, surveyMain } = this.props
        const isCompare = filterData.type.value === report_types[3].value
        const period1 = String(moment(filterData.from1).format('LL') + ' ~ ' + moment(filterData.to1).format('LL'))
        const period2 = String(moment(filterData.from2).format('LL') + ' ~ ' + moment(filterData.to2).format('LL'))
        const emailGroup = filterData.group.map(a => a.label).join(',');
        const section = filterData.section ? filterData.section.label : '';
        const question = filterData.question ? filterData.question.label : '';
        const answer = filterData.answer.map(a => a.label).join(',');

        rows.push(['==> Filter options'])
        rows.push([t('Period1'), t('Emails Group'), t('Section'), t('Question'), t('Answers')])
        rows.push([period1, emailGroup, section, question, answer])



        const summaryData = this.calculateSummaryData(surveyQuestion, surveyResponses)
        rows.push(['===> Section Average with Rating'])
        summaryData.reportSections.map((section, skey) => {
            if (section.sectionScore === 0) return null
            const sectionLabel = `Q.${skey}.${section.sectionTitle}`
            const sectionScore = `${t('Score:')}  ${section.sectionScore.toFixed(2)}`

            rows.push([sectionLabel, sectionScore, ''])
        })




        const surveydata = this.calculateData(surveyQuestion, surveyResponses)
        surveydata.reportSections.forEach(section => {
            const sectionTitle = section.sectionTitle + (section.sectionScore > 0 ? " " + t('Score:') + " " + section.sectionScore.toFixed(2) : '')


            section.reportQuestions.forEach((question, ekey) => {

                const questionTitle = question.questionTitle + (question.questionScore > 0 ? " " + t('Score:') + " " + question.questionScore.toFixed(2) : '')

                rows.push([`===> ${questionTitle}`])
                rows.push([t('Activity'), t('Answer'), t('Relative Answers')])
                question.reports.forEach((item) => {
                    rows.push([item.answerLabel, item.count, item.percent.toFixed(2) + '%'])
                })

            })

        });


        let csvContent = "data:text/csv;charset=utf-8,"
            + rows.map(e => e.join(",")).join("\n");
        var encodedUri = encodeURI(csvContent);
        window.open(encodedUri);
    }
    getEmailsFromGroup = (group) => {

        const emaillist = this.state.emaillist
        var emails = [];
        emaillist.forEach(elist => {
            const existIndex = group.findIndex(g => g.value === elist._id)
            if (existIndex > -1) {
                const elist_emails = elist.emails.map(e => e.email)
                emails = emails.concat(elist_emails)
            }
        });
        return emails;
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.time !== this.props.time) {
            this.initData()
        }
    }

    getRandomColor = () => {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    getChartData = (reports) => {
        let labels = [];
        let data = [];
        let backgroundColor = [];

        reports.map((item) => {
            labels.push(item.answerLabel);
            data.push(item.count)
            backgroundColor.push(this.getRandomColor())
        })
        const chartData = {
            labels,
            datasets: [{
                data,
                backgroundColor
            }]
        }
        return chartData
    }
    calculateData = (filterQuestion, filterResponses) => {
        let reportSections = [];
        let surveyScore = 0
        let totalResult = null
        if (filterQuestion.sections.length > 0) {

            let countSectionScore = 0
            filterQuestion.sections.map((section) => {
                let reportQuestions = []
                let sectionScore = 0
                if (section.questions.length > 0) {
                    let countQuestionScore = 0
                    section.questions.map((q) => {
                        if (Score.SKIPTYPES.indexOf(q.question.type) >= 0) return null;
                        const reportOfQuestion = Score.getReportOfQuestion(q, filterResponses);
                        reportQuestions.push(reportOfQuestion)
                        if (reportOfQuestion.questionScore > 0) {
                            countQuestionScore++;
                            sectionScore += reportOfQuestion.questionScore
                        }
                    })
                    sectionScore = countQuestionScore > 0 ? (sectionScore / countQuestionScore) : 0
                }
                const reportsForSection = {
                    sectionTitle: section.sectionTitle,
                    reportQuestions,
                    sectionScore
                }
                reportSections.push(reportsForSection)
                if (reportsForSection.sectionScore > 0) {
                    countSectionScore++
                    surveyScore += reportsForSection.sectionScore
                }
            })
            totalResult = {
                reportSections: reportSections,
                surveyScore: countSectionScore > 0 ? (surveyScore / countSectionScore) : 0
            }
        }
        return totalResult
    }
    renderAnalytics = (data) => {
        if (!data) return null
        const result = data.reportSections.map((section, skey) => {

            const sectionTitle = section.sectionTitle + (section.sectionScore > 0 ? " " + t('Score:') + " " + section.sectionScore.toFixed(2) : '')
            return <TitleBox title={sectionTitle} key={skey}>
                {
                    section.reportQuestions.map((question, ekey) => {

                        const questionTitle = question.questionTitle + (question.questionScore > 0 ? " " + t('Score:') + " " + question.questionScore.toFixed(2) : '')
                        const chartdata = this.getChartData(question.reports)
                        return <TitleBox key={ekey} title={questionTitle} color="#00acc140" textColor="#00acc1">
                            {question.reports.length > 0 && <GridContainer>
                                <GridItem xs={12} md={6} sm={6} lg={6} xl={6}>
                                    <Table
                                        tableHeaderColor="primary"
                                        tableHead={[t("Activity"), t("Answers"), t("Relative Answer"),]}
                                        tableData={question.reports.map((item) => {
                                            return [item.answerLabel, item.count, item.percent.toFixed(2) + '%']
                                        })}
                                        coloredColls={[2]}
                                        colorsColls={["primary"]}
                                    />
                                </GridItem>
                                <GridItem xs={12} md={6} sm={6} lg={6} xl={6}>
                                    <Pie data={chartdata} />
                                </GridItem>
                            </GridContainer>}
                        </TitleBox>
                    })
                }
            </TitleBox>
        })
        return result
    }

    render() {
        const { reportData } = this.state
        if (!reportData) return '...'
        return <div >
            <h3>{t('Total Score:')} <b>{reportData.surveyScore.toFixed(2)}</b></h3>
            {this.renderAnalytics(reportData)}
        </div>;
    }
}
const mapStatsToProps = ({ user }) => ({ user });
export default connect(mapStatsToProps)(
    withTranslation()(withStyles(styles, { withTheme: true })(Analytical))
);