import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Icon from "@material-ui/core/Icon";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Check from "@material-ui/icons/Check";
import Email from "@material-ui/icons/Email";
import axios from "axios";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

// import LockOutline from "@material-ui/icons/LockOutline";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { api_url } from "redux/config.js";
import Toast from "views/Toast/Toast";
import loginPageStyle from "./LoginPage.style";
import { user } from "views/DataDemo.jsx";
import { withTranslation } from "react-i18next";
import { t } from "i18next";
axios.defaults.headers.post['Content-Type'] = 'application/json';

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      email: "",
      password: "",
      remember_me: false,
      userData: null
    };
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function () {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }
  verifyEmail = value => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };
  // function that verifies if a string has a given length or not
  verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };
  login = async () => {
    let params = {
      email: this.state.email,
      password: this.state.password,
      remember_me: this.state.remember_me
    };
    if (!this.verifyEmail(params.email)) {
      Toast(400, t('Invalid email'));
      return;
    }
    if (!this.verifyLength(params.password, 6)) {
      Toast(400, t('Minimum Password length is 6'));
      return;
    }
    try {
      let result = await axios.post(api_url + "auth/signin", params);
      if (result.data.success) {
        this.props.dispatch({
          type: "POST_LOGIN",
          data: {
            token: result.data.token,
            profile: result.data.profile
          }
        });
        Toast(200, t('You are logged!'));
        this.props.history.push("/");
      } else {
        Toast(400, result.data.error);
      }
    } catch (errors) {
      console.info(errors);
      Toast(500);
    }
  };


  onRememberMe = e => {
    const { dispatch } = this.props;
    this.setState({
      remember_me: e.target.checked
    });
    dispatch({ type: "REMEMBER_ME", data: e.target.checked });
  };
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }
  forget = () => {
    this.props.history.push("/auth/forget_password");
  }
  render() {
    if (this.props.user.isLoggedIn === true) {
     return <Redirect to="/" />;
    }
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <form>
              <Card className={classes.cardSignup}>
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                  color="info"
                >
                  <h4 className={classes.cardTitle}>{t('Welcome!')}</h4>
                </CardHeader>
                <CardBody>
                  <div onKeyPress={(e) => {
                    if (e.which == 13) {
                      this.login()
                    }
                  }}>
                    <CustomInput
                      labelText={t('Email')}
                      id="email"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: e => this.setState({ email: e.target.value }),
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        )
                      }}
                    />
                    <CustomInput
                      labelText={t('Password')}
                      id="password"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: e =>
                          this.setState({ password: e.target.value }),
                        type: "password",
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputAdornmentIcon}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        )
                      }}
                    />
                    {/* <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={e => this.onRememberMe(e)}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{ checked: classes.checked }}
                        />
                      }
                      classes={{ label: classes.label }}
                      label={"Remember me"}
                    /> */}
                    
                    <Button
                      onClick={this.forget}
                      size="sm"
                      simple
                      color='primary'
                    >
                      {t('Forgot password')}
                    </Button>
                    <Button
                      onClick={this.login}
                      color="success"
                      size="lg"
                      block
                    >
                      {t('Log in')}
                    </Button>
                    <Button
                      href="/auth/register"
                      simple
                      color="info"
                      size="lg"
                      block
                    >
                      {t('Register')}
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps)(withTranslation()(withStyles(loginPageStyle)(LoginPage)));
