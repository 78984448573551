import pageStyle from "./pageStyle"
import colorStyle from "./colorStyle"
import marginStyle from "./marginStyle"
import paddingStyle from "./paddingStyle"
import flexStyle from "./flexStyle"
import fontStyle from "./fontStyle"
import sizeStyle from "./sizeStyle"
const style = {
  ...pageStyle,
  ...colorStyle,
  ...marginStyle,
  ...paddingStyle,
  ...flexStyle,
  ...fontStyle,
  ...sizeStyle,
  pointer: {
    cursor: 'pointer'
  },
  logo: {
    height: 40
  },
};
export default style;
