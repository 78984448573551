import { MuiThemeProvider } from "@material-ui/core/styles";
import theme from "assets/jss/MyTheme";
import "assets/scss/material-dashboard-pro-react.css?v=1.3.0";
import { createBrowserHistory } from "history";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Route, Router, Switch } from "react-router-dom";
import { PersistGate } from "redux-persist/es/integration/react";
import { api_url, app_url } from "redux/config";
import { persistor, store } from 'redux/store/index';
import Auth from "routes/Guard/Auth";

import AuthPages from "./layouts/AuthPages";
import PublicLayout from "./layouts/PublicLayout";
import RouterView from "./layouts/RouterView";

import { I18nextProvider } from "react-i18next";
import i18n from "translations/i18n";


const hist = createBrowserHistory();


console.disableYellowBox = true
console.info('==> updated at 2022 3 230 ===>')
console.info('APP_URL=========>', app_url)
console.info('API_URL=========>', api_url)

ReactDOM.render(
  <I18nextProvider i18n={i18n}>

    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <MuiThemeProvider theme={theme}>
          <Router history={hist}>
            <Switch>
              <Route path={"/auth"} component={AuthPages} />;
              <Route path={"/public"} component={PublicLayout} />
              <Auth>
                <Route path={"/"} component={RouterView} />
              </Auth>
            </Switch>
          </Router>
        </MuiThemeProvider>
      </PersistGate>
    </Provider>
  </I18nextProvider>
  ,
  document.getElementById("root")
);
