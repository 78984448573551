import React from 'react'
import { useTranslation } from 'react-i18next';

import ReactTable from "react-table";
import "react-table/react-table.css";

export const TransReactTable = (props) => {
    const { t } = useTranslation()
    return <ReactTable
        {...props}
        previousText={t('Previous')}
        nextText={t('Next')}
        loadingText={t('Loading')}
        noDataText={t('No rows found')}
        pageText={t('Page')}
        ofText={t('of')}
        rowsText={t('rows')}
    />
}