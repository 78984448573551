
import ButterToast, { Cinnamon } from "butter-toast";
import { t } from "i18next";
import React  from 'react';

export default function(code, message) {
  let msg = "";
  switch (code) {
    case 200:
      msg = message ? message : t('Done successfully!');
      ButterToast.raise({
        content: (
          <Cinnamon.Crisp
            title="Success"
            content={msg}
            scheme={Cinnamon.Crisp.SCHEME_GREEN}
          />
        )
      });
      break;
    case 400:
      msg = message ? message : t('400 Invalid input!');
      ButterToast.raise({
        content: (
          <Cinnamon.Crisp
            title="Error"
            content={msg}
            scheme={Cinnamon.Crisp.SCHEME_ORANGE}
          />
        )
      });
      break;

    case 500:
      msg = message ? message : t('500 Server error!');
      ButterToast.raise({
        content: (
          <Cinnamon.Crunch
            title="Error"
            content={msg}
            scheme={Cinnamon.Crunch.SCHEME_RED}
          />
        )
      });
      break;
    case 404:
      msg = message ? message : t('404 Not found!');
      ButterToast.raise({
        content: (
          <Cinnamon.Crunch
            title="Error"
            content={msg}
            scheme={Cinnamon.Crunch.SCHEME_RED}
          />
        )
      });
      break;
    default:
      msg = message ? message : t('Undefined Error!');
      ButterToast.raise({
        content: (
          <Cinnamon.Crunch
            title="Error"
            content={msg}
            scheme={Cinnamon.Crunch.SCHEME_ORANGE}
          />
        )
      });
      break;
  }
}
