import axios from "axios"
import { store } from "redux/store/index";
import { api_url, app_url } from "redux/config.js";

axios.defaults.baseURL = api_url;

const CallApi = (params, options = { showError: true }) => {
    const state = store.getState()
    const user = state.user
    let newparams = params

    const auth_header = "Bearer " + user.token;
    newparams.headers = { ...params.headers, Authorization: auth_header }
    return new Promise((resolve, reject) => {
        axios(newparams).then((res) => {
            resolve(res.data)
        }).catch((error) => {
            console.log('======  api failed  ======>', newparams)
            reject(error)
        })
    })

}
export {
    CallApi
}