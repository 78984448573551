

const styles = {
  TitleBox: {
    marginBottom: 15,
    boxShadow: "0 0 0 1px rgba(0,0,0,.15), 0 12px 18px 1px rgba(0,0,0,.2)",
  },
  title: {
    padding: 4,
    display: "flex",
    justifyContent: "space-between;",
    alignItems: "center;"
  },
  body: {
    padding: 15,
  },
  rightButton: {
    
  }
};

export default styles;
