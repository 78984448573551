// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import React from "react";
import { withTranslation } from "react-i18next";
import "react-table/react-table.css";
import EmailListForm from "./EmailListForm";




const styles = {
  ...regularFormsStyle,
  descriptionTextArea: {
    width: "100%",
    height: 150
  }
};
class CreateMailList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      types: [
        {
          label: "Org",
          value: "Org"
        },
        {
          label: "Import",
          value: "Import"
        },
        {
          label: "Random from textarea",
          value: "Random from textarea"
        }
      ]
    };
  }
  componentDidMount() {}
  render() {
    const { classes, t } = this.props;
    return (
      <div>
        <EmailListForm id={0} onSave = {()=>{
          this.props.history.push('/email_list/email_list')
        }}/>
      </div>
    );
  }
}

export default withTranslation()(withStyles(styles)(CreateMailList));
