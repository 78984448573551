import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Stepper from '@material-ui/core/Stepper';
import withStyles from '@material-ui/core/styles/withStyles';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import Button from 'components/CustomButtons/Button';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import SnackbarContent from 'components/Snackbar/SnackbarContent';
import Space from 'components/Space/Space';
import { default as React, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RenderQuestion from "views/Survey/RenderQuestions/RenderQuestion";
import Toast from "views/Toast/Toast";
import classNames from "classnames";
import { slideInLeft, slideInRight } from 'react-animations'
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            overflowX: 'scroll'
        },
        stepBtn: {
            width: '10rem',
            '& text': {
                fill: 'white'
            }
        },
        activeStepBtn: {
            '& .MuiStepLabel-label.MuiStepLabel-active': {
                color: '#07009f',
                fontWeight: 'bolder'
            }
        },
        alignRight: {
            textAlign: 'right'
        },
        previousButton: {
            [theme.breakpoints.down('sm')]: {
                textAlign: 'left',
            },
            [theme.breakpoints.up('md')]: {
                textAlign: 'right',
            },
        },
        '@keyframes slideInLeft': slideInLeft,
        '@keyframes slideInRight': slideInRight,
        animateLeft: {
            animationName: '$slideInLeft',
            animationDuration: '0.5s',
        },
        animateRight: {
            animationName: '$slideInRight',
            animationDuration: '0.5s',
        },
    }
})
function SurveyProgress(props) {
    const { i18n } = props;
    const { t } = useTranslation();
    const [questions, setQuestions] = useState(props.questions)
    const [activeStep, setActiveStep] = useState(0)
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
    const [slideDirection, setSlideDirection] = useState('in')
    const [refreshCount, setRefreshCount] = useState(0)
    const answers = useRef([])

    const classes = useStyles()

    useEffect(() => {
        init()
    }, [props.doRefresh])

    useEffect(() => {
        const stepContainer = document.getElementById('stepContainer')
        const stepElement = document.getElementById('step' + activeStep)

        stepContainer.scroll(stepElement.offsetLeft - stepContainer.clientWidth / 2 + stepElement.clientWidth / 2, 0)
    }, [activeStep])

    const init = () => {
        setQuestions(props.questions)
        setActiveStep(0)
        setCurrentQuestionIndex(0)
        answers.current = []
    }
    const handleNext = () => {
        updateActiveStep(activeStep + 1);
    }

    const handleBack = () => {
        updateActiveStep(activeStep - 1);
    }
    const totalSteps = () => {
        return getActiveSections().length
    }
    const handleReset = () => {
        updateActiveStep(0);
    }
    const isValidStep = (step) => {
        const activeSections = getActiveSections()[step]
        let invalidAnswerIndex = activeSections.questions.findIndex((qitem) => {
            const answer = getAnswerByID(qitem.id)
            if (qitem.required) {
                return !answer || !answer.isValid
            } else {
                return false
            }
        })
        return invalidAnswerIndex === -1
    }
    const updateActiveStep = (step) => {
        // window.scrollTo(0, 0);
        if (activeStep < step) {
            if (isValidStep(activeStep)) {
                if (step === totalSteps()) {
                    props.saveAnswer(answers.current.map((item) => {
                        const { isValid, ...answer } = item
                        return answer.answer
                    }))
                } else {
                    setActiveStep(step)
                    setCurrentQuestionIndex(0)
                }
            } else {
                Toast(400, t('Please enter all field properly'))
            }
        } else {
            setActiveStep(step)
            setCurrentQuestionIndex(0)
        }
    }
    const getActiveSections = () => {
        const activeSections = questions.sections.filter((section) => section.display == true)
        return activeSections
    }
    const getAnswerByID = (id) => {
        const answer = answers.current.filter(item => item.answer.id === id)[0]
        if (answer) {
            return answer;
        } else {
            return null
        }
    }
    const changeAnswer = (data) => {
        let currentAnswers = [...answers.current];
        const existIndex = currentAnswers.findIndex(item => item.answer.id === data.answer.id);
        if (existIndex === -1) {
            currentAnswers.push(data)
        } else {
            currentAnswers[existIndex] = data
        }
        answers.current = (currentAnswers)
        setRefreshCount((c) => (c + 1))
    }
    const onPrevQuestion = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1)
            setSlideDirection('left')
        }
    }
    const onNextQuestion = () => {
        setCurrentQuestionIndex(currentQuestionIndex + 1)
        setSlideDirection('right')
    }
    const getStepContent = (sectionIndex) => {
        const activeSections = getActiveSections()[sectionIndex]
        const questionCount = activeSections.questions.length
        return activeSections.questions.map((qitem, key) => {
            const title = qitem.required ? '*' + qitem.questionTitle : qitem.questionTitle
            const answer = getAnswerByID(qitem.id);
            const isLastQuestion = key == (questionCount - 1)
            const isFirstQuestion = key == 0
            const idActiveQuestion = key == currentQuestionIndex
            const hideNextButton = (qitem.required && (!answer || !answer.isValid)) || isLastQuestion
            const isRadioQuestion = qitem.question.type == 'radio'
            const isCheckBoxRequireOnlyOneAnswer = qitem.question.type == 'checkbox' && qitem.question.answertype == 'one'
            const slideClass = slideDirection == 'left' ? classes.animateLeft : classes.animateRight
            
            return <div key={key} style={{ display: idActiveQuestion ? 'block' : 'none' }}>
                <GridContainer key={key} className={slideClass}>
                    <GridItem xs={12} md={5} sm={5} className={classes.alignRight}>
                        <h3><b>{title}</b></h3>
                    </GridItem>
                    <GridItem xs={12} md={7} sm={7}>
                        <RenderQuestion
                            key={questions.language + qitem.id}
                            question={qitem}
                            answer={answer ? answer.answer : null}
                            onChange={(answer, isInit) => { // isInit when question component upload data after mounted
                                if (isInit) {
                                    changeAnswer(answer)
                                } else {
                                    changeAnswer(answer)
                                    if (isRadioQuestion || isCheckBoxRequireOnlyOneAnswer) {
                                        if (!isLastQuestion) {
                                            onNextQuestion()
                                        } else {
                                            if ((activeStep + 1) < totalSteps()) {
                                                setTimeout(() => {
                                                    updateActiveStep(activeStep + 1)
                                                }, 100);
                                            }
                                        }
                                    }
                                }
                            }} />
                    </GridItem>
                </GridContainer>

                <GridContainer>
                    <GridItem xs={6} md={5} sm={5} className={classes.previousButton}>
                        {!isFirstQuestion && <Button style={{ padding: 6 }} simple color='info' onClick={onPrevQuestion}>{t('Previous Question')}</Button>}

                    </GridItem>
                    <GridItem xs={6} md={7} sm={7} align='right'>
                        {!hideNextButton && <Button style={{ padding: 6, }} simple color='info' onClick={onNextQuestion}>{t('Next Question')}</Button>}
                    </GridItem>
                </GridContainer>
                <hr />
            </div>
        })
    }

    if (questions && questions.sections.length > 0) {

        const activeSections = getActiveSections()
        return <div>
            <Card>
                <CardBody>
                    <Stepper alternativeLabel nonLinear activeStep={activeStep} className={classes.root} id="stepContainer">
                        {activeSections.map((section, index) => {
                            const stepProps = {};
                            const buttonProps = {};
                            const isActive = activeStep == index
                            const stepButtonClasses = classNames({
                                [classes.stepBtn]: true,
                                [classes.activeStepBtn]: isActive
                            })
                            return (
                                <Step key={index} {...stepProps} id={'step' + index}>
                                    <StepButton
                                        className={stepButtonClasses}
                                        onClick={() => updateActiveStep(index)}
                                        {...buttonProps}
                                        completed={false}
                                    >
                                        {section.sectionTitle}
                                    </StepButton>
                                </Step>
                            );
                        })}
                    </Stepper>
                    <Space height={"2rem"} />

                    <div>
                        {getStepContent(activeStep)}
                    </div>

                </CardBody>
                <CardFooter>
                    <Button disabled={activeStep === 0} onClick={handleBack} style={{ padding: 6 }}>
                        {t('Previous Section')}
                    </Button>
                    <Button
                        color="primary"
                        onClick={handleNext}
                        style={{ padding: 6 }}
                    >
                        {(activeStep + 1) === totalSteps() ? t('Complete') : t('Next Section')}
                    </Button>
                </CardFooter>
            </Card>
        </div>
    } else {
        return <div>
            <SnackbarContent
                fullWidth
                message={t('No data')}
                color="info"
            />
        </div>
    }
}

export default SurveyProgress;