import withStyles from '@material-ui/core/styles/withStyles';
import commonStyle from "assets/jss/common/common";
import { default as React } from 'react';
import { connect } from 'react-redux';
import { emoticons } from "redux/config";

const styles = {
    ...commonStyle,
    emojidropbox: {
        float: 'left',
        width: 60,
        height: 50
    },
    icon: {
        width: 50,
        height: 50
    },
    emoji: {
        border: '2px solid #999999',
        borderRadius: 2,
        width: 50,
        height: 50,
        boxSizing: 'content-box',
        zIndex: 0
    },
    emojiBox: {
        border: '2px solid #999999',
        borderRadius: 2,
        width: 200,
        height: 100,
        display: 'flex',
        flexWrap: 'wrap',
        boxSizing: 'content-box',
        background: 'white',
        zIndex: 1,
        position: 'absolute'
    }
};
class Component extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            emoji: null
        }
    }

    emoticonImage = (value) => {
        const emoji = emoticons.filter(item => item.value === value)
        if (emoji && emoji.length > 0) {
            return emoji[0].image
        } else {
            return null
        }
    }
    componentDidMount() {
        document.addEventListener('mousedown', this.hideBox, false)
    }
    hideBox = (e) => {
        if (this.node.contains(e.target)) {
            return
        }
        this.setState({ showBox: false })
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.hideBox, false)
    }
    onChange = (emoji) => {
        this.setState({ emoji: emoji, showBox: false })
        this.props.onChange(emoji.value)
    }
    render() {
        const { classes, value } = this.props
        const emoticionImage = this.emoticonImage(value)
        const { showBox } = this.state
        return <div className={classes.emojidropbox} ref={node => this.node = node}>
            <div
                className={classes.emoji + ' ' + classes.cursorPonter}
                onClick={() => this.setState({ showBox: !showBox })}
            >
                {emoticionImage && <img alt="emoticon" src={emoticionImage} className={classes.icon} />}
            </div>
            {
                showBox && <div className={classes.emojiBox + ' ' + classes.cursorPonter}>
                    {emoticons.map((item, key) => {
                        return <div
                            key={key}
                            onClick={() => {
                                this.onChange(item)
                            }}
                        ><img alt="emoticon" src={item.image} className={classes.icon} />
                        </div>
                    })}
                </div>
            }
        </div>;
    }
}
const mapStatsToProps = ({ user }) => ({ user });
export default connect(mapStatsToProps)(
    withStyles(styles, { withTheme: true })(Component)
);