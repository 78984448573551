import React, { useState, useEffect } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Icon from "@material-ui/core/Icon";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Check from "@material-ui/icons/Check";
import Email from "@material-ui/icons/Email";
import axios from "axios";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

// import LockOutline from "@material-ui/icons/LockOutline";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { api_url } from "redux/config.js";
import Toast from "views/Toast/Toast";
import { user } from "views/DataDemo.jsx";
import { withTranslation } from "react-i18next";
import { t } from "i18next";

import resetPasswordStyle from "./ResetPassword.style";
import { CallApi } from "utils/Request";

const ResetPassword = (props) => {
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const token = props.match.params.token

  const classes = props.classes

  const updatePassword = () => {

    if (password.length < 6) {
      Toast(400, t('Password should be at least 6 characters')); return;
    }
    if (password !== passwordConfirm) {
      Toast(400, t('Password not matched')); return;
    }
    setIsLoading(true)
    CallApi({
      url: `auth/reset_password/${token}/${password}`,
      method: 'get',
    }).then((res) => {
      if (res.success) {
        props.history.replace("/auth/login");
        Toast(200, t("Successfully updated"))
      } else {
        Toast(400, res.error)
      }
    }).finally(() => {
      setIsLoading(false)
    })
  };



  return <div className={classes.container} >
    <GridContainer justify="center">
      <GridItem xs={12} sm={6} md={4}>
        <form>
          <Card className={classes.cardSignup}>
            <CardHeader
              className={`${classes.cardHeader} ${classes.textCenter}`}
              color="info"
            >
              <h4 className={classes.cardTitle}>{t('Log in')}</h4>
            </CardHeader>
            <CardBody>
              <div onKeyPress={(e) => {
                if (e.which == 13) {
                  updatePassword()
                }
              }}>
                <CustomInput
                  labelText={t('Password')}
                  id="password"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: e => setPassword(e.target.value),
                    type: "password",
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    )
                  }}
                />
                <CustomInput
                  labelText={t('Confirm Password')}
                  id="password2"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: e => setPasswordConfirm(e.target.value),
                    type: "password",
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    )
                  }}
                />
                <Button
                  onClick={updatePassword}
                  color="success"
                  block
                >
                  {t('Change Password')}
                </Button>
              </div>
            </CardBody>
          </Card>
        </form>
      </GridItem>
    </GridContainer>
  </div >
}

ResetPassword.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps)(withTranslation()(withStyles(resetPasswordStyle)(ResetPassword)));
