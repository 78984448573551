import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init
import TRANSLATIONS_EN from "./en/translations";
import TRANSLATIONS_EL from "./el/translations";
import TRANSLATIONS_ES from "./es/translations";
import TRANSLATIONS_DE from "./de/translations";
import TRANSLATIONS_FR from "./fr/translations";
import TRANSLATIONS_PT from "./pt/translations";


export const LANGUAGES = [
  {label: 'English', value: 'en'},
  {label: 'Ελληνικά', value: 'el'},
  // {label: 'Deutsch', value: 'de'},
  // {label: 'Español', value: 'es'},
  // {label: 'Français', value: 'fr'},
  // {label: 'Português', value: 'pt'},
]

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  // .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  // .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    debug: false,
    ns: ["translation"],
    defaultNS: "translation",
    resources: {
      en: {
        translation: TRANSLATIONS_EN
      },
      de: {
        translation: TRANSLATIONS_DE
      },
      fr: {
        translation: TRANSLATIONS_FR
      },
      es: {
        translation: TRANSLATIONS_ES
      },
      el: {
        translation: TRANSLATIONS_EL
      },
      pt: {
        translation: TRANSLATIONS_PT
      },
    },
    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      formatSeparator: ","
    },
    react: {
      wait: true
    }
  });
export default i18n;