// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import ViewList from "@material-ui/icons/ViewList";
import example_csv from "assets/images/example_csv.png";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import axios from "axios";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Papa from "papaparse";
import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import "react-table/react-table.css";
import { api_url, gooSync_windows } from "redux/config.js";
import { verifyEmail } from "utils/Validate.jsx";
import Toast from "views/Toast/Toast";
import EmailListForm from "./EmailListForm";
import EmaillistTable from "./EmaillistTable";

const styles = {
  ...regularFormsStyle,
  ...sweetAlertStyle,
  descriptionTextArea: {
    width: "100%",
    height: 150
  },
  zeroZindex: {
    zIndex: 0
  }
};

class EditEmailList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      data: null,
      newEmail: {},
      csvFile: null,
    };
  }
  componentDidMount() {
    this.onLoad();
  }
  onLoad = () => {
    let self = this;
    const { t } = this.props

    const url = api_url + `emaillist/get/`;
    const header = "Bearer ".concat(this.props.user.token);
    let headers = null;
    if (this.props.user.token) {
      headers = {
        Authorization: header
      };
    }
    this.props.dispatch({ type: "LOAD_START" });
    axios({
      url,
      method: "get",
      params: {
        id: this.state.id
      },
      headers
    })
      .then(function (response) {
        if (response.data.success) {
          self.setState({
            data: response.data.data
          });
        } else {
          Toast(400, response.data.error)
        }
        self.props.dispatch({ type: "LOAD_END" });
      })
      .catch(function (errors) {
        console.info(errors);
        self.props.dispatch({ type: "LOAD_END" });
        if (!errors.response) {
          Toast(500, t("Connection error!"));
          return;
        }
        Toast(errors.response.status);
      });
  };

  setNewEmail = (name, value) => {
    let newEmail = this.state.newEmail;
    newEmail[name] = value;
    this.setState({
      newEmail
    });
  };
  confirmAddEmail = () => {
    let self = this;
    const { t } = this.props

    const url = api_url + `emaillist/add_email`;
    const header = "Bearer ".concat(this.props.user.token);
    const data = {
      id: this.state.id,
      newEmail: this.state.newEmail
    };
    let headers = null;
    if (this.props.user.token) {
      headers = {
        Authorization: header
      };
    }
    this.hideAlert();
    this.props.dispatch({ type: "LOAD_START" });
    axios({
      url,
      method: "post",
      headers,
      data
    })
      .then(function (response) {
        if (response.data.success) {
          self.setState({
            data: response.data.data
          });
        } else {
          Toast(400, response.data.error)
        }
        self.props.dispatch({ type: "LOAD_END" });
        Toast(200, t("New email added!"));
      })
      .catch(function (errors) {
        console.info(errors);
        self.props.dispatch({ type: "LOAD_END" });
        if (!errors.response) {
          Toast(500, t("Connection error!"));
          return;
        }
        Toast(errors.response.status);
      });
  };
  removeEmail = (newemails) => {
    const { t } = this.props
    let self = this;
    const url = api_url + `emaillist/remove_email`;
    const header = "Bearer ".concat(this.props.user.token);
    const data = {
      id: this.state.id,
      newEmails: newemails
    };
    let headers = null;
    if (this.props.user.token) {
      headers = {
        Authorization: header
      };
    }
    this.hideAlert();
    this.props.dispatch({ type: "LOAD_START" });
    axios({
      url,
      method: "post",
      headers,
      data
    })
      .then(function (response) {
        if (response.data.success) {
          self.setState({
            data: response.data.data
          });
        } else {
          Toast(400, response.data.error)
        }
        self.props.dispatch({ type: "LOAD_END" });
        Toast(200, "Email removed!");
      })
      .catch(function (errors) {
        console.info(errors);
        self.props.dispatch({ type: "LOAD_END" });
        if (!errors.response) {
          Toast(500, t("Connection error!"));
          return;
        }
        Toast(errors.response.status);
      });
  }
  addEmail = () => {
    const { classes, t } = this.props;
    this.setState({
      alert: (
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          title="Add e-mail to list"
          onConfirm={() => this.confirmAddEmail()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        >
          <CustomInput
            labelText={t("Title")}
            id="Title"
            formControlProps={{
              onChange: e => this.setNewEmail("title", e.target.value),
              fullWidth: true
            }}
            inputProps={{
              type: "Text"
            }}
          />
          <CustomInput
            labelText={t("Name")}
            id="Name"
            formControlProps={{
              onChange: e => this.setNewEmail("name", e.target.value),
              fullWidth: true
            }}
            inputProps={{
              type: "Text"
            }}
          />
          <CustomInput
            labelText={t("Email")}
            id="Email"
            formControlProps={{
              fullWidth: true,
              onChange: e => this.setNewEmail("email", e.target.value)
            }}
            inputProps={{
              type: "Text"
            }}
          />
        </SweetAlert>
      )
    });
  };
  hideAlert() {
    this.setState({
      alert: null
    });
  }
  uploadCSV = () => {
    this.setState({
      alert: (
        <SweetAlert
          style={{ display: "block", marginTop: "-200px" }}
          title="Import CSV"
          onConfirm={() => this.handleUpload()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        >
          <CustomInput
            labelText="CSV file"
            id="CSVfile"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              type: "file",
              onChange: (e) => this.setState({ csvFile: e.target.files[0] })
            }}
          />
          <b>* CSV file should have following type of DATA</b>
          <img src={example_csv} />
        </SweetAlert>
      )
    });
  }
  handleUpload = () => {

    let self = this;
    let file = this.state.csvFile;
    if (!file) {
      Toast(400, 'Please select csv file');
      return;
    }
    Papa.parse(file, {
      complete: function (results, file) {
        let emails = []
        for (let i = 0; i < results.data.length; i++) {
          const item = results.data[i]
          if (!verifyEmail(item[0])) continue;
          if (item.length === 2 || item.length === 3) {
            emails.push({
              email: item[0],
              title: item[1],
              name: item[2]
            })
          }
        }

        if (emails.length > 0) {
          self.addMultipleEmails(emails)
        } else {
          Toast(400, "Not found emails")
        }
      },
      delimiter: ",",
      error: function (err, file, inputElem, reason) {
        Toast(400, err.message)
      },
    })
  }

  addMultipleEmails = (emails) => {
    const current_emails = this.state.data.emails.map((item) => (item.email))
    const non_repeated_emails = emails.filter((item) => {
      const index = current_emails.indexOf(item.email);
      return index < 0;
    })
    if (non_repeated_emails.length > 0) {
      let self = this;
      const url = api_url + `emaillist/add_multi_email`;
      const header = "Bearer ".concat(this.props.user.token);
      const data = {
        id: this.state.id,
        multipleEmails: non_repeated_emails
      };
      let headers = null;
      if (this.props.user.token) {
        headers = {
          Authorization: header
        };
      }
      this.hideAlert();
      this.props.dispatch({ type: "LOAD_START" });
      axios({
        url,
        method: "post",
        headers,
        data
      })
        .then(function (response) {
          debugger
          if (response.data.success) {
            self.setState({
              data: response.data.data
            });
          } else {
            Toast(400, response.data.error)
          }
          self.props.dispatch({ type: "LOAD_END" });
          Toast(200, "New email added!");
        })
        .catch(function (errors) {
          debugger

          console.info(errors);
          self.props.dispatch({ type: "LOAD_END" });
          if (!errors.response) {
            Toast(500, "Connection error!");
            return;
          }
          Toast(errors.response.status);
        });
    } else {
      this.hideAlert();
      Toast(200, "There is no new emails.");
    }
  }
  render() {
    const { classes, t } = this.props;
    if (!this.state.data) return null;
    return (
      <div>
        {this.state.alert}
        <GridContainer>
          <GridItem xs={12} sm={12}>
            <EmailListForm
              id={this.state.id}
              name={this.state.data.name}
              onSave={() => {
                Toast(200, "Successfully updated");
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12}>
            <Card>
              <CardHeader color="success" icon>
                <CardIcon color="success">
                  <ViewList />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>{t('Syncronize with local csv files')}</h4>
              </CardHeader>
              <CardBody>

                <p>{t('This ID will be used to sync local csv files with this email list')}</p>
                <b>{this.state.id}</b>
                <h3>{t('Download Sync app here')}</h3>
                <Button href={gooSync_windows} color="info">{t('WINDOWS')}</Button>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12}>
            <EmaillistTable
              emails={this.state.data.emails}
              addEmail={this.addEmail}
              addMultipleEmails={this.addMultipleEmails}
              removeEmail={this.removeEmail}
              uploadCSV={this.uploadCSV}
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps)(withTranslation()(withStyles(styles)(EditEmailList)));
