
import withStyles from '@material-ui/core/styles/withStyles';
import React from 'react';
import Button from "components/CustomButtons/Button"
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { connect } from 'react-redux';
import InputModal from "views/Shared/InputModal";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import { Editor } from "@tinymce/tinymce-react";
import ImageUpload from "components/CustomUpload/ImageUpload.jsx";
import axios from "axios";
import { api_url, tinyeditor_api_key } from "redux/config.js";
import Toast from "views/Toast/Toast";
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
const styles = {};
class Component extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            image_url: '',
            message: '',
            from: 0,
            to: 0,
        }
    }
    componentDidMount() {
        const endmessage = this.props.endmessage
        if (endmessage) {
            this.setState({
                message: endmessage.message,
                from: endmessage.from,
                to: endmessage.to,
                image_url: endmessage.image_url
            })
        }
    }
    uploadFile = async (file) => {
        const fileData = new FormData();
        fileData.append("file", file);
        const result = await axios({
            url: api_url + "utils/file_upload",
            method: "post",
            data: fileData
        });
        if (result.data.success) {
            this.setState({
                image_url: result.data.file_url
            })
        } else {
            Toast(400, result.data.error);
        }
    }
    render() {
        const { message, from, to, image_url } = this.state
        return (
            <div>
                <Dialog open={true} onClose={this.props.handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{t('End Message')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                        </DialogContentText>
                        {/* <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="From"
                            type="number"
                            fullWidth
                            value={from}
                            onChange={(e) => {
                                this.setState({ from: Number(e.target.value) })
                            }}
                        /> */}

                        <CustomInput
                            labelText={t('From')}
                            id="from"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                onChange: e => this.setState({ from: Number(e.target.value) }),
                                type: 'number',
                                value: from
                            }}
                        />

                        <CustomInput
                            labelText={t('To')}
                            id="to"
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                onChange: e => this.setState({ to: Number(e.target.value) }),
                                type: 'number',
                                value: to
                            }}
                        />

                        <ImageUpload
                            onRef={(ref) => this.setState({ image_ref: ref })}
                            addButtonProps={{
                                color: "rose", size: 'sm'
                            }}
                            changeButtonProps={{
                                color: "rose", size: 'sm'
                            }}
                            removeButtonProps={{
                                color: "danger", size: 'sm'
                            }}
                            defaultImage={image_url}
                            handleImageChange={file => this.uploadFile(file)}
                        />
                        <Editor
                            onEditorChange={(txt) => this.setState({ message: txt })}
                            apiKey={tinyeditor_api_key}
                            initialValue={this.state.message}
                            init={{
                                menubar: false,
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                ],
                                toolbar:
                                    'undo redo | formatselect | bold italic backcolor link | \
                                  alignleft aligncenter alignright alignjustify | \
                                  bullist numlist outdent indent | removeformat | help'
                            }}
                        />

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.props.handleSave({ message, from, to, image_url })} color="success">
                            {t('Save')}
                        </Button>
                        <Button onClick={this.props.handleClose} >
                            {t('Cancel')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}
const mapStatsToProps = ({ user }) => ({ user });
export default connect(mapStatsToProps)(
    withTranslation()(withStyles(styles, { withTheme: true })(Component))
);