
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Switch from "@material-ui/core/Switch";
import Check from "@material-ui/icons/Check";
import Edit from "@material-ui/icons/Edit";
import InsertLink from "@material-ui/icons/InsertLink";
import Lock from "@material-ui/icons/Lock";
import PieChart from "@material-ui/icons/PieChart";
import Translate from "@material-ui/icons/Translate";
import ViewList from "@material-ui/icons/ViewList";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import axios from "axios";
import Button from "components/CustomButtons/Button.jsx";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import { api_url, app_url } from "redux/config.js";
import Toast from "views/Toast/Toast";
import Tooltip from '@material-ui/core/Tooltip';
import { AddToPhotosOutlined } from "@material-ui/icons";
import CustomizedDialog from "components/Modal/CustomModal";
import { CallApi } from "utils/Request";
import CustomCheckbox from "components/CustomSelect/CustomCheckbox";
import Loading from 'components/Loading/Loading'
import { TransReactTable } from "components/CustomReactTable/TransReactTable";
import { withTranslation } from "react-i18next";
import { t } from "i18next";

const styles = {
  ...extendedFormsStyle,
  root: {

    height: 20,
  }
};

const column = [
  {
    Header: "Title",
    accessor: "title",
    sortable: true
  },
  {
    Header: "Date",
    accessor: "date",
    sortable: true
  },
  {
    Header: "Response",
    accessor: "responses",
    sortable: true
  },
  {
    Header: "Active",
    accessor: "active",
    sortable: true
  },
  {
    Header: "Actions",
    accessor: "action",
    minWidth: 150,
    sortable: false
  }
];
class SurveyPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

      showactive: "all",
      surveyData: [],
      active: "pendingTransaction",
      data: [],

      loading: false,
      isLoading: false,
      pages: -1,
      paginationState: null,

      showCostumersModal: false,
      costumers: [],
      selected_costumers: [],
      allotmentSurvey: null,
    };
  }
  componentDidMount() {
    this.loadCostumers()
  }
  loadCostumers = () => {
    CallApi({
      url: 'user/costumers',
      method: 'get',
    }).then((res) => {
      if (res.success) {
        this.setState({
          costumers: res.data || []
        })
      } else {
        Toast(400, res.error);
      }

    }).finally(() => {

    })
  }
  toggleActivateStateOfSurvey = item => {
    const newActive = !item.active;
    this.props.dispatch({ type: "LOAD_START" });
    const activate_term = newActive ? "survey/activate" : "survey/deactivate";
    CallApi({
      url: activate_term,
      method: "post",
      data: {
        id: item._id,
        active: newActive
      },
    })
      .then(res => {
        if (res.success) {
          const newData = this.state.data.map(ditem => {
            let newitem = ditem;
            if (ditem._id === item._id) {
              newitem.active = newActive;
            }
            return newitem;
          });

          this.setState({
            data: newData
          });
          this.normalizeData(newData);
        } else {
          Toast(400, res.error);
        }
      })
      .finally(this.props.dispatch({ type: "LOAD_END" }));
  };
  normalizeData = data => {
    const { classes } = this.props;

    if (!data || data.length === 0) {
      this.setState({
        surveyData: []
      });
    }
    const ndata = data.map((item, key) => {
      const edit_url = "/survey/edit/" + item._id;
      const report_url = "/survey/view_report/" + item._id;
      const action = (
        <div key={key}>
          <Tooltip title={t("View reports")}>
            <a href={report_url}>
              <PieChart color="primary" />
            </a>
          </Tooltip>
          <Tooltip title={t("Edit")}>
            <a
              onClick={() => this.props.history.push(edit_url)}
            >
              <Edit color="primary" />
            </a>
          </Tooltip>

          <Tooltip title={t("Translate")}>
            <a
              onClick={() => this.props.history.push('/survey/translate/' + item._id)}
            >
              <Translate color="primary" />
            </a>
          </Tooltip>

          <Tooltip title={t("Respondent list")}>
            <a
              onClick={() => {
                const url = `/survey/survey_respondents?id=${item._id}`
                this.props.history.push(url);
              }}
            >
              <ViewList color="primary" />
            </a>
          </Tooltip>

          <Tooltip title={t("Response")}>
            <a
              onClick={() => {

                const url = `${app_url}/public/single_survey/${item._id}/nocondition`
                window.open(url, '_blank')
              }}
            >
              <InsertLink color="primary" />
            </a>
          </Tooltip>

          <Tooltip title={t("Passwords")}>
            <a

              onClick={() => {
                const url = `/survey/survey_passwords/${item._id}`
                this.props.history.push(url);
              }}
            >
              <Lock color="primary" />
            </a>
          </Tooltip>
          <Tooltip title={t("Add to custumers")}>
            <a

              onClick={() => { this.setState({ showCostumersModal: true, allotmentSurvey: item }) }}
            >
              <AddToPhotosOutlined color="primary" />
            </a>
          </Tooltip>
        </div>
      );
      const active = (
        <Switch
          checked={item.active}
          onChange={e => {
            this.toggleActivateStateOfSurvey(item);
          }}
          value="checkedA"
          color="primary"
          classes={{

          }}
        />
      );
      return {
        title: item.main[0].title,
        date: moment(item.updated_at).format("MMMM Do YYYY, h:mm:ss a"),
        responses: item.responses.length,
        active,
        action
      };
    });
    this.setState({
      surveyData: ndata
    });
  };
  loadData = state => {
    this.setState({
      paginationState: state
    });
    this.props.dispatch({ type: "LOAD_START" });
    let data = {
      page: state.page,
      pageSize: state.pageSize,
      sorted: state.sorted.length > 0 ? state.sorted : [{ id: "date", desc: true }],
      filtered: state.filtered
    };
    const showactive = state.showactive;
    if (showactive && showactive !== "all") {
      data.active = showactive === "active" ? true : false;
    }
    CallApi({
      url: "survey/get",
      method: "post",
      data
    })
      .then(res => {
        if (res.success) {
          this.normalizeData(res.data.rows);
          this.setState({
            data: res.data.rows,
            pages: res.data.pages,
            loading: false
          });
        } else {
          Toast(400, res.error);
        }
      })
      .catch(e => {
        Toast(500);
      })
      .finally(() => {
        this.props.dispatch({ type: "LOAD_END" });
      });
  };
  handleactive = showactive => {
    this.setState({
      showactive
    });
    this.loadData({
      ...this.state.paginationState,
      showactive
    });
  };
  onAddAllotments = () => {

    console.info(this.state.selected_costumers)
    console.info(this.state.allotmentSurvey)
    if (this.state.selected_costumers.length < 1) {
      Toast(400, 'Please choose costumers')
      return
    }
    if (!this.state.allotmentSurvey) {
      Toast(400, 'Invalid allotment')
      return
    }
    this.setState({
      isLoading: true
    })
    const formdata = {
      costumer_ids: this.state.selected_costumers,
      allotment_survey: this.state.allotmentSurvey
    }
    CallApi({
      url: 'survey/add_allotment',
      method: 'post',
      data: formdata
    }).then((res) => {
      if (res.success) {
        Toast(200, t('Successfully added'))
      } else {
        Toast(400, res.error)
      }
    }).finally(() => {
      this.setState({
        isLoading: false,
        showCostumersModal: false
      })
    })
  }
  onToggleCostumer = (costumer_id, checked) => {
    const costumerIds = [...this.state.selected_costumers]
    if (checked) {
      this.setState({
        selected_costumers: [...costumerIds, costumer_id]
      })
    } else {
      this.setState({
        selected_costumers: costumerIds.filter(cid => cid !== costumer_id)
      })
    }
  }
  onSelectAllCostumer = () => {
    const allcostomerIds = this.state.costumers.map(u => u._id)
    if (allcostomerIds.length == this.state.selected_costumers.length) {
      this.setState({
        selected_costumers: []
      })
    } else {
      this.setState({
        selected_costumers: allcostomerIds
      })
    }
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer>
          <GridItem md={12} sm={12}>
            <Button color="info" onClick={() => this.props.history.push('/admin/survey/create_survey')} >
              {t('Create new survey')}
            </Button>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.showactive === "all"}
                    tabIndex={-1}
                    onClick={() => this.handleactive("all")}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked
                    }}
                  />
                }
                classes={{
                  label: classes.label
                }}
                label={t('All')}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.showactive === "active"}
                    tabIndex={-1}
                    onClick={() => this.handleactive("active")}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked
                    }}
                  />
                }
                classes={{
                  label: classes.label
                }}
                label={t('Active')}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.showactive === "deactive"}
                    tabIndex={-1}
                    onClick={() => this.handleactive("deactive")}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked
                    }}
                  />
                }
                classes={{
                  label: classes.label
                }}
                label={t('Deactive')}
              />
            </div>
            <TransReactTable
              defaultPageSize={5}
              data={this.state.surveyData}
              columns={column}
              pages={this.state.pages} // should default to -1 (which means we don't know how many pages we have)
              loading={this.state.loading}
              manual // informs React Table that you'll be handling sorting and pagination server-side
              onFetchData={(state, instance) => {
                // show the loading overlay
                this.setState({ loading: true });

                this.loadData(state);
              }}
            />
          </GridItem>
        </GridContainer>
        <CustomizedDialog
          title={t('Choose customers for allotment')}
          footer={
            <div>
              <Button onClick={() => this.onAddAllotments()} color='success'>{t('Add')}</Button>
            </div>
          }
          open={this.state.showCostumersModal}
          onClose={() => this.setState({ showCostumersModal: false })}
          fullWidth={true}
          maxWidth="sm"
        >
          <Button onClick={this.onSelectAllCostumer} color='info' size='sm'>{t('All')}</Button>
          {
            this.state.costumers.map((costumer, key) => {
              const checked = this.state.selected_costumers.filter(cid => cid == costumer._id).length > 0
              return <div key={key}>
                <CustomCheckbox label={costumer.email} onChange={(checked) => this.onToggleCostumer(costumer._id, checked)} checked={checked} />
              </div>
            })
          }

        </CustomizedDialog>
        {this.state.isLoading && <Loading />}
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps)(withTranslation()(withStyles(styles)(SurveyPage)));
