import React, { useState, useEffect } from 'react';
import TagsInput from '@incoqnito.io/react-tagsinput';
import FormControl from "@material-ui/core/FormControl";
// @material-ui/core components
import { makeStyles } from '@material-ui/core'

import Send from "@material-ui/icons/Send";
import ViewList from "@material-ui/icons/ViewList";
import { Editor } from "@tinymce/tinymce-react";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import axios from 'axios';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ISO6391 from "iso-639-1";
import moment from "moment";
import Datetime from "react-datetime";
import { connect } from 'react-redux';
import CustomSelect from "components/CustomSelect/CustomSelect"
import "react-table/react-table.css";
import { api_url } from 'redux/config.js';
import ReactTable from "react-table";

import Toast from 'views/Toast/Toast';
import Muted from "components/Typography/Muted"
import { useSelector } from 'react-redux';
import { Delete, Reply } from '@material-ui/icons';
import { CallApi } from 'utils/Request';
import LoadingModal from 'components/Loading/Loading';
import ReactTables from 'views/Other/Tables/ReactTables';
import CustomCheckbox from 'components/CustomSelect/CustomCheckbox';
import { TransReactTable } from 'components/CustomReactTable/TransReactTable';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => {
    return {
        ...regularFormsStyle,
        descriptionTextArea: {
            width: "100%",
            height: 150
        },
        emailTags: {
            width: "100%",
            borderBottom: "1px solid #D2D2D2 !important"
        }
    };
})


export default function ResponsePlanPage() {
    const classes = useStyles()
    const { t } = useTranslation()
    const [responsePlans, setResponsePlans] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    // new plan
    const [title, setTitle] = useState('New package')
    const [response_limit, setResponse_limit] = useState(100)
    const [price, setPrice] = useState(100)
    const [months, setMonths] = useState(6)
    const [freePlan, setFreePlan] = useState(false)

    useEffect(() => {
        loadData()
    }, [])
    const loadData = () => {
        setIsLoading(true)
        CallApi({
            url: 'response_plan/list',
            method: 'get',
        }).then((res) => {
            if (res.success) {
                setResponsePlans(res.data)
            } else {
                Toast(400, res.error)
            }
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const onAddNewPlan = () => {
        const newplan = {
            title: title,
            response_limit: response_limit,
            price: freePlan ? 0 : price,
            months: months
        }
        if (response_limit < 1) {
            Toast(400, t('Please set positive Response Limit'))
            return
        }
        if (price < 0) {
            Toast(400, t('Please set positive Price'))
            return
        }
        if (months < 1) {
            Toast(400, t('Please set positive Period'))
            return
        }
        setIsLoading(true)
        CallApi({
            url: 'response_plan/add',
            method: 'post',
            data: newplan
        }).then((res) => {
            if (res.success) {
                loadData()
            } else {
                Toast(400, res.error)
            }
        }).finally(() => {
            setIsLoading(false)
        })
    }
    const onDeletePlan = (id) => {
        if (!window.confirm(t('Are you sure?'))) {
            return
        }
        setIsLoading(true)
        CallApi({
            url: 'response_plan/delete/' + id,
            method: 'delete',
        }).then((res) => {
            if (res.success) {
                loadData()
            } else {
                Toast(400, res.error)
            }
        }).finally(() => {
            setIsLoading(false)
        })
    }
    const rows = responsePlans.map((rp, key) => {
        return {
            ...rp,
            actions: <div>
                <Button color='danger' size='sm' onClick={() => onDeletePlan(rp._id)}><Delete /></Button>
            </div>
        }
    })
    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12}>
                    <Card>
                        <CardHeader color="success" icon>
                            <CardIcon color="success">
                                <Reply />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>{t('NEW RESPONSE')}</h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem>
                                    <CustomInput
                                        id="Title"
                                        labelText={t("Title")}
                                        formControlProps={{
                                            onChange: e =>
                                                setTitle(e.target.value),
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "text",
                                            value: title
                                        }}
                                    />
                                </GridItem>
                                <GridItem>
                                    <CustomInput
                                        id="Response"
                                        labelText={t("Response Limit")}
                                        formControlProps={{
                                            className: classes.noPadding,
                                            onChange: e =>
                                                setResponse_limit(e.target.value),
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "number",
                                            value: response_limit
                                        }}
                                    />
                                </GridItem>
                                {!freePlan && <GridItem>
                                    <CustomInput
                                        id="Price"
                                        labelText={t("Price(€)")}
                                        formControlProps={{
                                            className: classes.noPadding,
                                            onChange: e =>
                                                setPrice(e.target.value),
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "number",
                                            value: price
                                        }}
                                    />
                                </GridItem>}
                                <GridItem>
                                    <CustomInput
                                        id="Months"
                                        labelText={t("Months")}
                                        formControlProps={{
                                            className: classes.noPadding,
                                            onChange: e =>
                                                setMonths(e.target.value),
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            type: "number",
                                            value: months
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem>
                                    <CustomCheckbox
                                        checked={freePlan}
                                        onChange={(check) => setFreePlan(!freePlan)}
                                        label={'Free plan'}
                                    />
                                </GridItem>
                            </GridContainer>
                            <Button color='success' onClick={onAddNewPlan}>{t('Save')}</Button>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardHeader color="success" icon>
                            <CardIcon color="success">
                                <Reply />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>{t('RESPONSE DETAILS')}</h4>
                        </CardHeader>
                        <CardBody>
                            <TransReactTable
                                data={rows}
                                columns={[
                                    {
                                        Header: "Title",
                                        accessor: "title"
                                    },
                                    {
                                        Header: "Response Limit",
                                        accessor: "response_limit"
                                    },
                                    {
                                        Header: "Price",
                                        accessor: "price"
                                    },
                                    {
                                        Header: "Duration(months)",
                                        accessor: "months"
                                    },
                                    {
                                        Header: "Actions",
                                        accessor: "actions",
                                        sortable: false,
                                        filterable: false
                                    }
                                ]}
                                defaultPageSize={10}
                                className="-striped -highlight"
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
            {isLoading && <LoadingModal />}
        </div>
    );
}