import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import { connect } from "react-redux";
// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";

import costumerRoutes from "routes/costumer.jsx";
import adminRoutes from "routes/admin.jsx";
import commonRoutes from "routes/common.jsx";

import appStyle from "assets/jss/material-dashboard-pro-react/layouts/dashboardStyle.jsx";

import image from "assets/img/sidebar-2.jpg";
import logo from "assets/img/logo-white.svg";
import Transition from "react-transition-group/Transition";
import { FadeLoader } from "halogenium";
import { USER_ROLES } from "redux/config.js"
import DataLoader from "./DataLoader";
import ButterToast, { POS_TOP, POS_CENTER } from "butter-toast";
import LanguageSelector from "components/LanguageSelect/LanguageSelector.js";

var ps;

class RouterView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      miniActive: false
    };
    this.resizeFunction = this.resizeFunction.bind(this);
  }
  componentDidMount() {
    
  }
  componentWillUnmount() {
    
  }
  
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  getRoutes() {
    let routes = [];
    if (this.props.user.profile) {

      if (this.props.user.profile.role === USER_ROLES.admin) {
        routes = adminRoutes;
      } else {
        routes = costumerRoutes;
      }
      return [...commonRoutes, ...routes];
    } else {
      return []
    }
  }
  sidebarMinimize() {
    this.setState({ miniActive: !this.state.miniActive });
  }
  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  }
  render() {
    const { classes, ...rest } = this.props;
    const mainPanel =
      classes.mainPanel +
      " " +
      cx({
        [classes.mainPanelSidebarMini]: this.state.miniActive,
      });
    const transitionStyles = {
      exiting: { transition: `opacity 300ms ease-in-out`, opacity: 0 }
    };
    return (
      <div>
        <div className={classes.wrapper}>
          <ButterToast
            timeout={10000}
            position={{ vertical: POS_TOP, horizontal: POS_CENTER }}
          />
          <Transition in={this.props.state.loading} unmountOnExit timeout={300}>
            {state => {
              return (
                <div
                  style={{
                    ...transitionStyles[state]
                  }}
                  className={classes.blockUserActivity}
                />
              );
            }}
          </Transition>
          {this.props.state.loading && (
            <FadeLoader
              className={classes.loader}
              color="#26A65B"
              size="16px"
              margin="4px"
            />
          )}
          <Sidebar
            routes={this.getRoutes()}
            logoText={"Gooquest"}
            logo={logo}
            image={image}
            handleDrawerToggle={this.handleDrawerToggle}
            open={this.state.mobileOpen}
            color="blue"
            bgColor="black"
            miniActive={this.state.miniActive}
            {...rest}
          />
          <div className={mainPanel} ref="mainPanel">
            <Header
              sidebarMinimize={this.sidebarMinimize.bind(this)}
              miniActive={this.state.miniActive}
              routes={this.getRoutes()}
              handleDrawerToggle={this.handleDrawerToggle}
              {...rest}
            />
            <div className={classes.content}>
              <div className={classes.container}>
                <Switch>
                  {this.getRoutes().map((prop, key) => {
                    if (prop.redirect)
                      return (
                        <Redirect from={prop.path} to={prop.pathTo} key={key} />
                      );
                    if (prop.collapse)
                      return prop.views.map((prop, key) => {
                        return (
                          <Route
                            exact
                            path={prop.path}
                            component={prop.component}
                            key={key}
                          />
                        );
                      });
                    return (
                      <Route
                        exact
                        path={prop.path}
                        component={prop.component}
                        key={key}
                      />
                    );
                  })}
                </Switch>
              </div>
            </div>
          </div>
          <DataLoader />
          <LanguageSelector />
        </div>
      </div>

    );
  }
}

RouterView.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = ({ user, state }) => ({
  user,
  state
});
export default connect(mapStateToProps)(withStyles(appStyle)(RouterView));
