import { default as React } from 'react';
import PropTypes from 'prop-types';

function Space(props) {
    const { width, height } =  props 
    const style = {
        width: width,
        height: height,
    }
    return <div style={style}>

    </div>;
}

Space.defaultProps = {
    width: 1,
    height: 30,
}
Space.propTypes = {
    width: PropTypes.any,
    height: PropTypes.any
}
export default Space

