import {
  container,
} from "assets/jss/material-dashboard-pro-react.jsx";
import { isMobile } from "react-device-detect";

const pagestyle = {
  content: {
    marginTop: isMobile ? 70 : 70
  },
  container: {
    ...container,
    zIndex: "2"
  },
  hidden: {
    display: 'none'
  },
  h1: {
    fontSize: isMobile ? 30 : 50,
    fontWeight: 400
  },
  h2: {
    fontSize: isMobile ? 20: 30,
    fontWeight: 400
  },
  h3: {
    fontSize: isMobile ? 16 : 20,
    fontWeight: 400
  },
};

export default pagestyle;