import withStyles from '@material-ui/core/styles/withStyles';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Table from "components/Table/Table.jsx";
import { default as React } from 'react';
import { Pie } from 'react-chartjs-2';
import { connect } from 'react-redux';
import * as Score from "utils/Score";
import TitleBox from "views/Shared/TitleBox";
import moment from "moment";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button"
import commonStyle from "assets/jss/common/common"
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.jsx";

import uuid from 'uuidv4';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { t } from 'i18next';
import { withTranslation } from 'react-i18next';
import Toast from 'views/Toast/Toast';
require('assets/fonts/dejavu-sans-ttf-2.34/DejaVuSans-normal.js')
require('assets/fonts/dejavu-sans-ttf-2.34/DejaVuSans-bold.js')

const report_types = [
    {
        label: t('Analytical'),
        value: "Analytical"
    },
    {
        label: t('Summary'),
        value: "Summary"
    },
    {
        label: t('Text reports'),
        value: "Textreports"
    },
    {
        label: t('Comparation'),
        value: "Comparation"
    }
];
const styles = {
    ...commonStyle,
    rating_record: {
        border: '1px solid lightgray'
    },
    table: {
        border: '1px solid lightgray',
        width: '100%',
        borderCollapse: 'collapse'
    },
    th: {
        textAlign: 'left',
        border: '1px solid lightgray',
    },
    td: {
        textAlign: 'left',
        border: '1px solid lightgray',
    },
    borderLineRoot: {
        height: 10,
    },
    borderLineBar: {
        height: 10,
    }
};
class Analytical extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tab_index: 0,
            filterData: this.props.filterData,
            surveyQuestion: this.props.surveyQuestion,
            surveyResponses: this.props.surveyResponses,
            surveyMain: this.props.surveyMain,
            reportData: null
        }
    }

    componentDidMount() {
        this.props.onRef(this)
        this.initData()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.time !== this.props.time) {
            this.initData()
        }
    }

    initData = () => {
        const { filterData, surveyQuestion, surveyResponses, surveyMain } = this.props
        const data = this.calculateData(surveyQuestion, surveyResponses)
        this.setState({

            filterData: filterData,
            surveyQuestion: surveyQuestion,
            surveyResponses: surveyResponses,
            surveyMain: surveyMain,
            reportData: data
        })
    }

    calculateData = (filterQuestion, filterResponses) => {
        let reportSections = [];
        let surveyScore = 0
        let totalResult = null
        if (filterQuestion.sections.length > 0) {

            let countSectionScore = 0
            filterQuestion.sections.map((section) => {
                let reportQuestions = []
                let sectionScore = 0
                if (section.questions.length > 0) {
                    let countQuestionScore = 0
                    section.questions.map((q) => {
                        if (Score.VALUETYPE !== q.question.type) return null;
                        const reportOfQuestion = Score.getReportOfQuestion(q, filterResponses);
                        reportQuestions.push(reportOfQuestion)
                        if (reportOfQuestion.questionScore > 0) {
                            countQuestionScore++;
                            sectionScore += reportOfQuestion.questionScore
                        }
                    })
                    sectionScore = countQuestionScore > 0 ? (sectionScore / countQuestionScore) : 0
                }
                const reportsForSection = {
                    sectionTitle: section.sectionTitle,
                    reportQuestions,
                    sectionScore
                }
                reportSections.push(reportsForSection)
                if (reportsForSection.sectionScore > 0) {
                    countSectionScore++
                    surveyScore += reportsForSection.sectionScore
                }
            })
            totalResult = {
                reportSections: reportSections,
                surveyScore: countSectionScore > 0 ? (surveyScore / countSectionScore) : 0
            }
        }
        console.info('total result ===>', totalResult)
        return totalResult
    }
    renderSectionWithRating = (data) => {
        const { classes } = this.props
        if (!data) return null
        console.info('rendering report===>', data)
        const result = data.reportSections.map((section, skey) => {
            if (section.sectionScore === 0) return null
            return <GridContainer key={uuid()} className={classes.rating_record}>
                <GridItem xs={12} md={8}>
                    Q.{skey + '.' + section.sectionTitle}
                </GridItem>
                <GridItem xs={12} md={4} className={classes.flex + ' ' + classes.alignCenter}>
                    <div>
                        Score:  {section.sectionScore.toFixed(2)}
                    </div>
                    <CustomLinearProgress
                        variant="determinate"
                        color="success"
                        value={100 * section.sectionScore / 5}
                        classes={{
                            root: classes.borderLineRoot,
                            bar: classes.borderLineBar
                        }}
                        style={{ width: "50%", display: "inline-block", margin: '0 0 0 10px', }}

                    />
                </GridItem>
            </GridContainer>
        })
        return result
    }
    addDocText(doc, text, x, y) {
        const docWidth = doc.internal.pageSize.getWidth();

        var line = y // Line height to start text at
        var lineHeight = 4
        var leftMargin = x
        var wrapWidth = docWidth - 2 * x

        var splitText = doc.splitTextToSize(text, wrapWidth)
        for (var i = 0, length = splitText.length; i < length; i++) {
            // loop thru each line and increase
            doc.text(splitText[i], leftMargin, line)
            line = lineHeight + line
        }
        return line
    }

    generatePdf = (fileName) => {

        var doc = new jsPDF();

        const docWidth = doc.internal.pageSize.getWidth();
        const docHeight = doc.internal.pageSize.getHeight();
        var startX = 10
        var contentWidth = docWidth - startX * 2

        doc.setFont('DejaVuSans');
        doc.autoTableSetDefaults({ styles: { font: 'DejaVuSans', minCellWidth: 15, fontSize: 8 }, headStyles: { font: 'DejaVuSans' } })
        var finalY = 20;
        var gap = 4

        var head = [];
        var body = [];

        const { filterData, surveyQuestion, surveyResponses, surveyMain } = this.props
        const { reportData } = this.state
        // title
        doc.setFontSize(9);
        finalY = this.addDocText(doc, surveyMain.title, startX, finalY)
        // end title
        // filter part
        const isCompare = filterData.type.value === report_types[3].value
        const period1 = String(moment(filterData.from1).format('LL') + ' ~ ' + moment(filterData.to1).format('LL'))
        const period2 = String(moment(filterData.from2).format('LL') + ' ~ ' + moment(filterData.to2).format('LL'))
        const emailGroup = filterData.group.map(a => a.label).join(',');
        const section = filterData.section ? filterData.section.label : '';
        const question = filterData.question ? filterData.question.label : '';
        const answer = filterData.answer.map(a => a.label).join(',');

        head.push([t('Filter options'), ''])
        body.push([t("Report Type"), "Summary"])

        body.push([t('Period1'), period1])
        if (isCompare) { body.push(['Period2', period2]) }
        body.push([t('Emails Group'), emailGroup])
        body.push([t('Section'), section])
        body.push([t('Question'), question])
        body.push([t('Answers'), answer])
        body.push([t('Total Answers'), surveyResponses.length])

        doc.autoTable({ startY: finalY, head: head, body: body, theme: 'grid' });
        finalY = doc.previousAutoTable.finalY + gap;
        // end filter

        // summary part
        head = []; body = []
        let scores = []
        const summaryData = this.calculateData(surveyQuestion, surveyResponses)
        head.push(['Section Average with Rating', summaryData.surveyScore.toFixed(2), ''])
        summaryData.reportSections.map((section, skey) => {
            if (section.sectionScore === 0) return null
            const sectionLabel = `Q.${skey}.${section.sectionTitle}`
            const sectionScore = `${t('Score:')}  ${section.sectionScore.toFixed(2)}`
            body.push([sectionLabel, sectionScore])
            scores.push(section.sectionScore)

        })

        doc.autoTable({
            columnStyles: { 2: { cellWidth: 30 } },
            startY: finalY, head: head, body: body, theme: 'grid', didDrawCell: data => {
                if (data.section === 'body' && data.column.index === 2) {
                    var base64Img = 'data:image/jpeg;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mPkW8/wHwADTgG+e7jB5QAAAABJRU5ErkJggg=='
                    doc.addImage(base64Img, 'JPEG', data.cell.x + 1, data.cell.y + 1, scores[data.row.index] / 5 * 10, 5)
                }
            },
        });
        finalY = doc.previousAutoTable.finalY + gap * 2;
        // end summary
        // section and questions
        if(!reportData) {
            Toast(400, 'Error to generate PDF report');
        }
        let answerLabelValue = new Set();
        let labels = [];
        console.info('rendering report===>', reportData)
        reportData.reportSections.forEach(section => {
            section.reportQuestions.forEach(question => {
                question.reports.forEach(report => {
                    answerLabelValue.add(report.answerLabel);
                });
            });
        });
        const uniqueLabels = Array.from(answerLabelValue);
        uniqueLabels.forEach((el) => labels.push(el));

        head = []; body = []
        head.push([{ content: t('Section Average with Rating'), colSpan: 6 }, summaryData.surveyScore.toFixed(2)])
        let header = [t('Question')]
        header = header.concat(uniqueLabels.map(e => e))
        header.push(t('Score'))
        body.push(header)
        summaryData.reportSections.map(section => {
            if (section.sectionScore === 0) return null;
            const sectionTitle = section.sectionTitle
            const sectionScore = section.sectionScore.toFixed(2)

            body.push([{ content: sectionTitle, colSpan: 6, styles: { fillColor: [191, 191, 191], textColor: [255, 255, 255] } }, sectionScore])
            section.reportQuestions.forEach((question, ekey) => {

                const questionTitle = question.questionTitle
                const questionScore = question.questionScore.toFixed(2)
                let questionrecord = [questionTitle];
                uniqueLabels && uniqueLabels.map((el) => {
                    const quest = question.reports.filter(q => q.answerLabel === el)[0];
                    const score = quest ? `${quest.count} (${quest.percent.toFixed(2)}) %` : '0 (0 %)'
                    questionrecord.push(score)
                })
                questionrecord.push(questionScore)
                body.push(questionrecord)
            })

        });

        doc.autoTable({ 
            columnStyles: { 0: { cellWidth: contentWidth / 4 } },
            startY: finalY, head: head, body: body, theme: 'grid' });
        // window.open(URL.createObjectURL(doc.output("blob")))
        doc.save(fileName);
    }

    generateCSV = () => {
        var rows = [];

        const { filterData, surveyQuestion, surveyResponses, surveyMain } = this.props
        const { reportData } = this.state

        const isCompare = filterData.type.value === report_types[3].value
        const period1 = String(moment(filterData.from1).format('LL') + ' ~ ' + moment(filterData.to1).format('LL'))
        const period2 = String(moment(filterData.from2).format('LL') + ' ~ ' + moment(filterData.to2).format('LL'))
        const emailGroup = filterData.group.map(a => a.label).join(',');
        const section = filterData.section ? filterData.section.label : '';
        const question = filterData.question ? filterData.question.label : '';
        const answer = filterData.answer.map(a => a.label).join(',');

        rows.push([t('Filter options')])
        rows.push([
            t('Period1'),
            t('Emails Group'),
            t('Section'),
            t('Question'),
            t('Answers')
        ])
        rows.push([period1, emailGroup, section, question, answer])

        const summaryData = this.calculateData(surveyQuestion, surveyResponses)
        rows.push([t('Section Average with Rating')])
        rows.push([t('label'), t('score')])
        summaryData.reportSections.map((section, skey) => {
            if (section.sectionScore === 0) return null
            const sectionLabel = `Q.${skey}.${section.sectionTitle}`
            const sectionScore = `${t('Score:')}  ${section.sectionScore.toFixed(2)}`
            rows.push([sectionLabel, sectionScore])
        })

        if(!reportData) {
            Toast(400, 'Error to generate CSV report');
        }
        let answerLabelValue = new Set();
        console.info('rendering report===>', reportData)
        reportData.reportSections.forEach(section => {
            section.reportQuestions.forEach(question => {
                question.reports.forEach(report => {
                    answerLabelValue.add(report.answerLabel);
                });
            });
        });
        const uniqueLabels = Array.from(answerLabelValue);
        rows.push([t('Question'), uniqueLabels.map(e => e), t('Score')])
        summaryData.reportSections.map(section => {
            if (section.sectionScore === 0) return null;


            section.reportQuestions.forEach((question, ekey) => {

                const questionTitle = question.questionTitle
                const questionScore = question.questionScore.toFixed(2)
                let questionrecord = [questionTitle];
                uniqueLabels && uniqueLabels.map((el) => {
                    const quest = question.reports.filter(q => q.answerLabel === el)[0];
                    const score = quest ? `${quest.count} (${quest.percent.toFixed(2)}) %` : '0 (0 %)'
                    questionrecord.push(score)
                })
                questionrecord.push(questionScore)
                rows.push(questionrecord)
            })

        });

        let csvContent = "data:text/csv;charset=utf-8,"
            + rows.map(e => e.join(",")).join("\n");
        var encodedUri = encodeURI(csvContent);
        window.open(encodedUri);

    }

    renderQuestionWithRating = (data) => {
        let answerLabelValue = new Set();
        let labels = [];
        const { classes } = this.props
        if (!data) return null
        console.info('rendering report===>', data)
        data.reportSections.forEach(section => {
            section.reportQuestions.forEach(question => {
                question.reports.forEach(report => {
                    answerLabelValue.add(report.answerLabel);
                });
            });
        });
        const uniqueLabels = Array.from(answerLabelValue);
        uniqueLabels.forEach((el) => labels.push(el));
        return <table className={classes.table}>
            <tbody>
                <tr>
                    <th className={classes.th}>Question</th>
                    {uniqueLabels.map((e, key) => {
                        return <th className={classes.th} key={uuid()}>{e}</th>
                    })}
                     <th className={classes.th}>Score</th>
                </tr>
                {data.reportSections.map((section, skey) => {
                    if (section.sectionScore === 0) return null
                    const sectionTitle = section.sectionTitle
                    const sectionScore = section.sectionScore.toFixed(2)
                    const trs = [
                        <tr key={uuid()}>
                            <td className={classes.td}><b>{sectionTitle}</b></td>
                            {uniqueLabels.map(() => (
                                <td className={classes.td} key={uuid()}></td>
                            ))}
                            <td className={classes.td}><b>{sectionScore}</b></td> {/* Coluna "Score" para a linha da seção */}
                        </tr>
                    ];
                    section.reportQuestions.forEach((question, ekey) => {
                        const questionTitle = question.questionTitle
                        const questionScore = question.questionScore.toFixed(2)
                        const newtr = <tr key={uuid()}>
                            <td className={classes.td}>{questionTitle}</td>
                            {uniqueLabels && uniqueLabels.map((el) => {
                                        const quest = question.reports.filter(q => q.answerLabel === el)[0];
                                        return (
                                            <td className={classes.td} key={uuid()}>
                                                {quest ? `${quest.count} (${quest.percent.toFixed(2)}) %` : '0 (0 %)'}
                                            </td>
                                        );
                                    })}
                                    <td className={classes.td}>{questionScore}</td> {/* Coluna "Score" */}
                        </tr>
                        trs.push(newtr)
                    })
                    return trs
                })
                }</tbody>
        </table >
    }

    render() {

        const { tab_index } = this.state

        const { reportData } = this.state
        if (!reportData) return '...'

        return <div >
            <Card>
                <CardBody>
                    <h3>Total Score: <b>{reportData.surveyScore.toFixed(2)}</b></h3>
                    <div>
                        <Button size='sm' color={tab_index === 0 ? 'info' : 'gray'} onClick={() => this.setState({ tab_index: 0 })}>{t('Section Average with Rating')}</Button>
                        <Button size='sm' color={tab_index === 1 ? 'info' : 'gray'} onClick={() => this.setState({ tab_index: 1 })}>{t('Question with Rating')}</Button>
                    </div>
                    <div>

                        {tab_index === 0 && this.renderSectionWithRating(reportData)}
                        {tab_index === 1 && this.renderQuestionWithRating(reportData)}

                    </div>
                </CardBody>
            </Card>
        </div>;
    }
}
const mapStatsToProps = ({ user }) => ({ user });
export default connect(mapStatsToProps)(
    withTranslation()(withStyles(styles, { withTheme: true })(Analytical))
);