
import LoginPage from "views/Auth/LoginPage.jsx";
import RegisterPage from "views/Auth/RegisterPage.jsx";
import ActivateSuccess from "views/Auth/ActivateSuccess.jsx";
import ActivateFailed from "views/Auth/ActivateFailed.jsx";

// @material-ui/icons
import PersonAdd from "@material-ui/icons/PersonAdd";
import Fingerprint from "@material-ui/icons/Fingerprint";
import ForgetPassword from "views/Auth/ForgetPassword";
import ResetPassword from "views/Auth/ResetPassword";

const authRoutes = [
  {
    path: "/auth/register",
    name: "Register Page",
    short: "Register",
    mini: "RP",
    icon: PersonAdd,
    component: RegisterPage
  },
  {
    path: "/auth/login",
    name: "Login Page",
    short: "Login",
    mini: "LP",
    icon: Fingerprint,
    component: LoginPage
  },
  {
    path: "/auth/forget_password",
    name: "Forgot Password Page",
    short: "Reset",
    mini: "LP",
    icon: Fingerprint,
    component: ForgetPassword
  },
  {
    path: "/auth/reset_password/:token",
    name: "Forgot Password Page",
    short: "Reset",
    mini: "LP",
    icon: Fingerprint,
    component: ResetPassword
  },
  {
    path: "/auth/activate_success",
    name: "Activate success",
    component: ActivateSuccess
  },
  {
    path: "/auth/activate_failed",
    name: "Activate failed",
    component: ActivateFailed
  },
];

export default authRoutes;
