import ISO6391 from "iso-639-1";

const getAllLanguages = ()=>{
    return ISO6391.getAllCodes().map((item)=>{
        return {
            label: ISO6391.getNativeName(item),
            value: item
        }
    })
}
export {
    getAllLanguages
}