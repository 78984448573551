import withStyles from '@material-ui/core/styles/withStyles';
import femaleSVG from "assets/images/gender/female.svg";
import maleSVG from "assets/images/gender/male.svg";
import commonStyle from "assets/jss/common/common";
import Button from 'components/CustomButtons/Button';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import { t } from 'i18next';
import { default as React } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { question_types } from "redux/config";


const styles = {
    ...commonStyle,
    choiceContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        marginTop: 10
    },
    icon: {
        width: 50,
        height: 50
    }
};

class Component extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            questionSchema: null,
            languages: [],
            gender: '',
            question: {
                type: 'gender',
                options: [],
            },
        }
    }
    componentDidMount() {
        this.props.onRef(this)
        let questionSchema = question_types.filter((item) => item.type === 'gender')[0]
        const question = this.props.question

        this.setState({
            questionSchema: questionSchema,
            gender: questionSchema.options[0].value,
            question: (question && question.type === questionSchema.type) ? question : {
                type: questionSchema.type,
                options: questionSchema.options,
            }
        })
    }
    getData() {
        return this.state.question
    }

    setQuestionItem = (name, index, value) => {
        let question = this.state.question;
        let options = question.options
        options[index][name] = value
        this.setState({
            question: {
                ...question,
                options,
            }
        })
    }
    
    setQuestionData = (name, value) => {
        let question = this.state.question;
        question[name] = value;
        this.setState({
            question,
        })
    }


    render() {
        const { questionSchema, question, gender } = this.state
        if (!questionSchema) return null
        const { classes } = this.props
        return <div >
            <hr />
            {
                question.options.map((item, key) => {
                    return <div key={key}>
                        {item.value === 'male' && <img alt="male" src={maleSVG} className={classes.icon} />}
                        {item.value === 'female' && <img alt="female" src={femaleSVG} className={classes.icon} />}
                        <CustomInput
                            formControlProps={{
                                className: classes.noPadding + ' ' + classes.noMargin,
                                onChange: e =>
                                    this.setQuestionItem("label", key, e.target.value),
                            }}
                            inputProps={{
                                placeholder: t('Label'),
                                value: item.label,
                            }}
                        />
                    </div>
                })
            }
            <hr />

            <div className={classes.fontBold}>Preview:</div>
            {

                question.options.map((item, key) => {
                    return <Button key={key} onClick={() => this.setState({ gender: item.value })} color={gender === item.value ? 'info' : null}>{item.label}</Button>
                })
            }
        </div>;
    }
}
const mapStatsToProps = ({ user }) => ({ user });
export default connect(mapStatsToProps)(
  withTranslation()(withStyles(styles, { withTheme: true })(Component))
);