import React, { useState, useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Create from "@material-ui/icons/Create";
import Done from "@material-ui/icons/Done";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { infoColor } from "assets/jss/material-dashboard-pro-react.jsx";
import Button from "components/CustomButtons/Button"
import style from "./TitleBox.style";

function TitleBox({ ...props }) {
  const { classes, color, children, className, rightButton, onlyTitle, textColor, onSaveTitle } = props;

  const TitleBoxClasses = classNames({
    [classes.TitleBox]: true,
    [className]: className !== undefined
  });

  const [isEdit, setisEdit] = useState(false)
  const [title, setTitle] = useState(props.title)
  const [newTitle, setnewTitle] = useState(props.title)
  return (
    <div className={TitleBoxClasses}>
      <div
        className={classes.title}
        style={{ background: color, color: textColor }}
      >
        {!props.titleEditable && <div style={{ margin: '0 10px' }}>
          <b>{title}</b>
        </div>}

        {props.titleEditable && <div style={{ display: 'flex', alignItems: 'center' }}>
          {isEdit && <Button size='sm' round justIcon color='success' onClick={() => {
            setTitle(newTitle);
            setisEdit(false)
            return onSaveTitle ? onSaveTitle(newTitle) : ''
          }}><Done /></Button>}
          {!isEdit && <Button size='sm' round justIcon color='danger' onClick={() => setisEdit(true)}><Create /></Button>}
          {!isEdit && <b style={{ margin: '0 10px' }}>{newTitle}</b>}
          {
            isEdit && <CustomInput
              formControlProps={{
                onChange: e =>
                  setnewTitle(e.target.value),
                fullWidth: true,
                style: { padding: 0, margin: '0 10px' }
              }}
              inputProps={{
                value: newTitle
              }}
            />
          }
        </div>}
        <div className={classes.rightButton}>{rightButton}</div>
      </div>
      {!onlyTitle && <div className={classes.body}>{children}</div>}
    </div >
  );
}

TitleBox.defaultProps = {
  color: infoColor,
  textColor: "white",
  titleEditable: false,
  onSaveTitle: null
};

TitleBox.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  titleEditable: PropTypes.bool,
  onSaveTitle: PropTypes.func
};

export default withStyles(style)(TitleBox);
