import moment from 'moment';
import ISO6391 from "iso-639-1";
const VALUETYPE = 'radio'
const SKIPTYPES = ['text']
const LikeList = ['Poor', 'Fair', 'Good', 'Very Good', 'Excellent']
const getValueOfQuestion = (qid, answers) => {
    const answer = answers.filter(item => item.id === qid)[0]
    if (answer) {
        return Number(answer.value || 0)
    }
    return 0
}
const getPageByName = (questions, pageName) => {
    const pages = questions.pages
    const matched = pages.filter((item) => item.name === pageName)
    if (matched && matched[0]) {
        return matched[0]
    } else {
        return null
    }
}
const getQuestionByName = (elements, questionName) => {
    const matched = elements.filter((item) => item.name === questionName)
    if (matched && matched[0]) {
        return matched[0]
    } else {
        return null
    }
}
const getScoreOfOneResponse = (questions, answers) => {

    let count = 0;
    let sum = 0;
    questions.sections.map((section, pi) => {

        section.questions.map((question, ei) => {
            if (question.question.type === VALUETYPE) {
                // if (question.id == '535b1e80-ea93-472a-9051-7809b4cdb283') {
                //     debugger
                // }
                const value = getValueOfQuestion(question.id, answers)
                if (value > 0) {
                    sum += value; count++;
                }
            }
        })
    })
    if (sum === 0) return sum;
    if (count == 0) return 0
    return sum / count
}

const getLabelOfAnswerValue = (q, v) => {
    const question = q.question
    const value = v
    switch (question.type) {
        case 'age':

            if (question.answertype === 'group') {
                title = question.options.filter(item => item.value === value)[0]
                return title ? title.label : ''
            }
            return value;
        case 'checkbox':
            let title = question.options.filter(item => value.indexOf(item.value) > -1).map(item => item.label)
            return title.join(',')
        case 'date':
            title = value ? moment(value).format('lll') : '';
            return title
        case 'gender':
            title = question.options.filter(item => item.value === value)[0]
            return title ? title.label : ''
        case 'nation':
            title = ISO6391.getName(value);
            return title
        case 'number':
            return value
        case 'radio':
            if (question.direction === '5star' || question.direction === '10star') {
                return `${value} Stars`
            }
            title = question.options.filter(item => Number(item.value) === Number(value))[0]
            return title ? title.label : ''
        case 'text':
            return value
        case 'yesno':
            title = question.options.filter(item => item.value === value)[0]
            return title ? title.label : ''
    }
}
const getReportOfQuestion = (q, responses) => {
    let score = 0
    let result = []
    let sortedResult = []
    if (responses.length === 0) {
        return {
            questionTitle: q.questionTitle,
            reports: result,
            questionScore: score
        }
    }

    for (let index = 0; index < responses.length; index++) {
        const answer = responses[index].answers.filter(a => a.id === q.id)[0];
        if (!answer || !answer.value) continue;
        if (q.question.type === 'checkbox') {
            answer.value.map(av => {
                const answerindex = result.findIndex(function (data) { return data.answer === av })
                if (answerindex < 0) {
                    result.push({
                        answer: av,
                        answerLabel: getLabelOfAnswerValue(q, [av]),
                        count: 1,
                        percent: 100 / responses.length
                    })
                } else {
                    result[answerindex].count++
                    result[answerindex].percent = result[answerindex].count * 100 / responses.length
                }
            })
        } else {

            const answerindex = result.findIndex(function (data) { return data.answer === answer.value })

            if (answerindex < 0) {
                result.push({
                    answer: answer.value,
                    answerLabel: getLabelOfAnswerValue(q, answer.value),
                    count: 1,
                    percent: 100 / responses.length
                })
            } else {
                result[answerindex].count++
                result[answerindex].percent = result[answerindex].count * 100 / responses.length
            }
        }
    }
    if (q.question.options && q.question.options.length > 0) {
        sortedResult = q.question.options.map(option => {
            const matched = result.filter(r => r.answer === option.value)[0]
            if (matched) {
                return matched
            } else {
                return {
                    answer: option.value,
                    answerLabel: option.label,
                    count: 0,
                    percent: 0
                }
            }
        })
    } else {
        sortedResult = result
    }
    if (q.question.type === VALUETYPE) {
        let sum = 0
        let total_count_for_rate = 0
        sortedResult.map((item) => {
            sum = sum + Number(item.answer) * item.count;
            total_count_for_rate += item.count
            return;
        })
        score = sum / Math.max(1, total_count_for_rate)
    }
    const report = {
        questionTitle: q.questionTitle,
        reports: sortedResult,
        questionScore: score
    }
    console.info('=========> question', q);
    console.info('=========> report', report)
    return report
}
export {
    VALUETYPE,
    LikeList,
    SKIPTYPES,   // what to skip
    getScoreOfOneResponse, // get score of one response
    getPageByName, // get page by name from question
    getQuestionByName, // get question by name from elements
    getLabelOfAnswerValue, // get label of choice in question
    getReportOfQuestion,
}