import Hidden from "@material-ui/core/Hidden";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Poppers from "@material-ui/core/Popper";

import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

// @material-ui/icons
import Person from "@material-ui/icons/Person";
import PowerSettingsNew from "@material-ui/icons/PowerSettingsNew";
import headerLinksStyle from "assets/jss/material-dashboard-pro-react/components/headerLinksStyle";
import classNames from "classnames";
import Button from "components/CustomButtons/Button.jsx";
import PropTypes from "prop-types";
import React, { useState } from "react";
// import { Manager, Target, Popper } from "react-popper";
import { connect } from "react-redux";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

const useStyles = makeStyles(theme => {
  return {
    wrapper: {
      display: 'flex',
      alignItems: 'center'
    },
    buttonLink: {
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        margin: "5px 15px 0",
        width: "auto",
        height: "auto",
        "& svg": {
          width: "30px",
          height: "24px",
          marginRight: "19px",
          marginLeft: "3px"
        },
        "& .fab,& .fas,& .far,& .fal,& .material-icons": {
          width: "30px",
          fontSize: "24px",
          lineHeight: "30px",
          marginRight: "19px",
          marginLeft: "3px"
        }
      }
    },

    headerLinksSvg: {
      width: "20px !important",
      height: "20px !important"
    },
    linkText: {
      zIndex: "4",
      fontSize: "14px",
      margin: "0!important",
      textTransform: "none"
    },
    links: {
      width: "20px",
      height: "20px",
      zIndex: "4",
      [theme.breakpoints.down("sm")]: {
        display: "block",
        width: "30px",
        height: "30px",
        color: "inherit",
        opacity: "0.8",
        marginRight: "16px",
        marginLeft: "-5px"
      }
    },
  }
})


function HeaderLinks(props) {

  const [open, setOpen] = useState(false)
  const userData = useSelector(state => state.user)
  const classes = useStyles()

  const logout = () => {
    props.dispatch({ type: "POST_LOGOUT" });
  }

  return (
    <GridContainer alignItems='center'>
      <GridItem xs={12} md={8}>
        <div style={{ padding: '0 1rem' }}><b>{userData.profile.email}</b></div>
      </GridItem>
      <GridItem xs={12} md={4} style={{textAlign: 'right'}}>
        <Button
          color="transparent"
          aria-label="Logout"
          justIcon
          onClick={logout}
          className={classes.buttonLink}
          muiClasses={{
            label: ""
          }}
        >
          <PowerSettingsNew
            className={
              classes.headerLinksSvg +
              " " +
              (classes.links)
            }
          />
          <Hidden mdUp implementation="css">
            <span className={classes.linkText}>
              Logout
            </span>
          </Hidden>
        </Button>
      </GridItem>
    </GridContainer>
  );
}

HeaderLinks.propTypes = {
  classes: PropTypes.object,
  rtlActive: PropTypes.bool
};
const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps)(HeaderLinks);

