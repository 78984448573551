import CheckBox from "@material-ui/icons/CheckBox";
import RadioButtonChecked from "@material-ui/icons/RadioButtonChecked";
import TextFormat from "@material-ui/icons/TextFormat";
import CalendarToday from "@material-ui/icons/CalendarToday";
import DateRange from "@material-ui/icons/DateRange";
import Language from "@material-ui/icons/Language";
import People from "@material-ui/icons/People";
import ToggleOn from "@material-ui/icons/ToggleOn";
import FormatListNumbered from "@material-ui/icons/FormatListNumbered";
const ENV = process.env
const IS_DEV = ENV.REACT_APP_ENV == 'development'
export const api_url = IS_DEV ? ENV.REACT_APP_API_URL_DEV : ENV.REACT_APP_API_URL;
export const app_url = IS_DEV ? ENV.REACT_APP_APP_URL_DEV : ENV.REACT_APP_APP_URL;
export const tinyeditor_api_key = ENV.REACT_APP_TINY_EDITOR_KEY

export const random_string = '8127703015'
export const gooSync_windows = api_url + 'files/gooSync-win32-x64.zip'
export const emoticons = [{
    label: 'Excellent',
    value: 'excellent',
    numValue: 5,
    image: require('assets/images/emoticons/excellent.png')
},
{
    label: 'Very Good',
    value: 'verygood',
    numValue: 4,
    image: require('assets/images/emoticons/verygood.png')
},
{
    label: 'Good',
    value: 'good',
    numValue: 3,
    image: require('assets/images/emoticons/good.png')
},
{
    label: 'Fair',
    value: 'fair',
    numValue: 2,
    image: require('assets/images/emoticons/fair.png')
},
{
    label: 'Poor',
    value: 'poor',
    numValue: 1,
    image: require('assets/images/emoticons/poor.png')
},
]
export const question_types = [{
    type: 'checkbox',
    title: 'Check Box',
    icon: CheckBox,

    directions: [{ label: 'Horizontal', value: 'horizontal' }, { label: 'Vertical', value: 'vertical' }, { label: 'Drop-Down', value: 'dropdown' }],
    answertypes: [{ label: 'One', value: 'one' }, { label: 'Multiple', value: 'multiple' }, { label: 'Choose', value: 'choose' }]

},
{
    type: 'radio',
    title: 'Radio Button',
    icon: RadioButtonChecked,

    directions: [{ label: 'Horizontal', value: 'horizontal' }, { label: 'Vertical', value: 'vertical' }, { label: 'Drop-Down', value: 'dropdown' }, { label: '5 Star Rating', value: '5star' }, { label: '10 Star Rating', value: '10star' },]

},
{
    type: 'text',
    title: 'Text Editor',
    icon: TextFormat,

    answertypes: [{ label: 'Text Box', value: 'textbox' }, { label: 'Text Area', value: 'textarea' }]

},
{
    type: 'age',
    title: 'Age',
    icon: DateRange,

    answertypes: [{ label: 'Group Age', value: 'group' }, { label: 'Individual Age', value: 'individual' }],
    options: [
        { label: '14-19', value: 'a' },
        { label: '20-29', value: 'b' },
        { label: '30-39', value: 'c' },
        { label: '40-49', value: 'd' },
        { label: '50-59', value: 'e' },
        { label: '60-69', value: 'f' },
        { label: '70-79', value: 'g' },
    ]
},
{
    type: 'date',
    title: 'Dates',
    icon: CalendarToday,

    answertypes: [{ label: 'dd/mm/yyyy', value: 'DD/MM/YYYY' }, { label: 'mm/dd/yyyy', value: 'MM/DD/YYYY' }, { label: 'yyyy/mm/dd', value: 'YYYY/MM/DD' }]

},
{
    type: 'nation',
    title: 'Nationality',
    icon: Language
},
{
    type: 'gender',
    title: 'Gender',
    icon: People,

    options: [{ label: 'Female', value: 'female' }, { label: 'Male', value: 'male' }]
},
{
    type: 'yesno',
    title: 'Yes/No',
    icon: ToggleOn,

    options: [{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }]

},
{
    type: 'number',
    title: 'Number',
    icon: FormatListNumbered
}
]
export const USER_ROLES = {
    admin: 'admin',
    costumer: 'costumer'
}

