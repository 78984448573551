import withStyles from '@material-ui/core/styles/withStyles';
import commonStyle from "assets/jss/common/common";
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Danger from "components/Typography/Danger";
import { t } from 'i18next';
import PropTypes from "prop-types";
import { default as React } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { question_types } from "redux/config";


const styles = {
    ...commonStyle
};


class Component extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            questionSchema: null,
            question: null,
            answer: null
        }
        this.initState()
    }
    initState = () => {
        const question = this.props.question
        const answer = this.props.answer
        let questionSchema = question_types.filter((item) => item.type === question.question.type)[0]
        this.state = {
            questionSchema,
            question,
            answer: answer ? answer : {
                id: question.id,
                value: 0
            }
        }
    }
    setAnswerData = (value) => {
        let { answer, question } = this.state;
        answer.value = value
        this.setState({
            answer: answer,
            error: null
        }, () => {
            console.info(answer)
            this.saveAnswerData()
        })
    }

    componentDidMount = () => {
        this.saveAnswerData(true)
    }
    saveAnswerData = (init = false) => {
        let { answer, question } = this.state;
        if (this.props.onChange) {
            this.props.onChange({
                isValid: this.isValid(), answer: {
                    id: question.id,
                    ...answer
                }
            }, init)

        }
    }
    isValid = () => {
        const { question, answer } = this.state
        if (question.required && (!answer || !answer.value)) {
            this.showError(t('This question is required'));
            return false
        }
        return true
    }
    showError = (message) => {
        this.setState({ error: <Danger>*{message}</Danger> })
    }
    render() {
        const { questionSchema, error, answer } = this.state
        const question = this.state.question.question
        const { classes } = this.props
        return <div >
            {error}
            {
                <CustomInput
                    formControlProps={{
                        className: classes.noPadding + ' ' + classes.noMargin,
                        onChange: e =>
                            this.setAnswerData(Number(e.target.value)),
                    }}
                    inputProps={{
                        placeholder: t('Value'),
                        value: answer.value ? answer.value : 0,
                        type: 'number'
                    }}
                />
            }
        </div>;
    }
}
Component.propTypes = {
    question: PropTypes.object,
    answer: PropTypes.object,
    onChange: PropTypes.func
}
const mapStatsToProps = ({ user }) => ({ user });
export default connect(mapStatsToProps)(
    withTranslation()(withStyles(styles, { withTheme: true })(Component))
);