import React, { useState, useEffect } from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";

// @material-ui/icons
import MailOutline from "@material-ui/icons/MailOutline";
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
import Contacts from "@material-ui/icons/Contacts";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import ViewList from "@material-ui/icons/ViewList";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import AccountBalanceWallet from "@material-ui/icons/AccountBalanceWallet";
import AccountCircle from "@material-ui/icons/AccountCircle";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardText from "components/Card/CardText.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Badge from "components/Badge/Badge";
import Table from "components/Table/Table.jsx";

import SweetAlert from "react-bootstrap-sweetalert";
import ReactTable from "react-table";

import "react-table/react-table.css";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import { response_detail } from "views/DataDemo.jsx";
import moment from 'moment'
import Toast from 'views/Toast/Toast';
import { CallApi } from 'utils/Request';
import { useSelector } from "react-redux";
import { TransReactTable } from "components/CustomReactTable/TransReactTable";
import { useTranslation } from "react-i18next";

const styles = {
  ...regularFormsStyle
};
const responsePlanColumn = [
  {
    Header: "Resonse Name",
    accessor: "title"
  },
  {
    Header: "No. of Response",
    accessor: "response_limit"
  },
  {
    Header: "Amount",
    accessor: "price"
  },
  {
    Header: "Purchase Date",
    accessor: "paid_at"
  },
];

function CostumerPaymentDetailPage(props) {
  const { classes } = props;
  const { t } = useTranslation()
  const [responsePlans, setResponsePlans] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [userData, setUserData] = useState(null)
  const user_id = props.match.params.costumer_id

  useEffect(() => {
    loadData()
  }, [])

  const loadData = () => {
    setIsLoading(true)
    CallApi({
      url: 'response_plan/purchased_response_plans/' + user_id,
      method: 'get',
    }).then((res) => {
      if (res.success) {
        setResponsePlans(res.data.map(p => {
          return {
            ...p,
            price: p.price + '€',
            paid_at: moment(p.timestamps).format('l')
          }
        }))
        setUserData(res.user)
      } else {
        Toast(400, res.error)
      }
    }).finally(() => {
      setIsLoading(false)
    })
  }
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <ViewList />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {userData ? (userData.first_name + ' ' + userData.last_name) + "'s " : ''}
                {t('Purchased Response Plans')}
              </h4>
            </CardHeader>
            <CardBody>
              <TransReactTable
                defaultPageSize={5}
                data={responsePlans}
                columns={responsePlanColumn}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default withStyles(styles)(CostumerPaymentDetailPage);
