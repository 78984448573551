import withStyles from '@material-ui/core/styles/withStyles';
import CustomSelect from "components/CustomSelect/CustomSelect";
import Danger from "components/Typography/Danger";
import { t } from 'i18next';
import PropTypes from "prop-types";
import { default as React } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { question_types } from "redux/config";
import { getAllLanguages } from "utils/Languages";

const styles = {};


class Component extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            questionSchema: null,
            question: null,
            languages: [],
            answer: null
        }
        this.initState()
    }
    initState = () => {
        const question = this.props.question
        const answer = this.props.answer
        const languages = getAllLanguages()
        let questionSchema = question_types.filter((item) => item.type === question.question.type)[0]
        const initAnswer = languages[0].value
        this.state = {
            questionSchema,
            languages: languages,
            question,
            answer: answer ? answer : {
                id: question.id,
                value: null
            }
        }
    }
    setAnswerData = (value) => {
        let { answer, question } = this.state;
        answer.value = value
        this.setState({
            answer: answer,
            error: null
        }, () => {
            console.info(answer)
            this.saveAnswerData()
        })
    }

    componentDidMount = () => {
        this.saveAnswerData(true)
    }
    saveAnswerData = (init = false) => {
        let { answer, question } = this.state;
        if (this.props.onChange) {
            this.props.onChange({
                isValid: this.isValid(), answer: {
                    id: question.id,
                    ...answer
                }
            }, init)

        }
    }
    isValid = () => {
        const { question, answer } = this.state
        if (question.required && (!answer || !answer.value)) {
            this.showError(t('This question is required'));
            return false
        }
        return true
    }
    showError = (message) => {
        this.setState({ error: <Danger>*{message}</Danger> })
    }
    render() {
        const { questionSchema, error, languages, answer } = this.state
        const question = this.state.question.question

        return <div >
            {error}
            {
                <CustomSelect
                    onChange={country => {
                        this.setAnswerData(country.value);
                    }}
                    placeholder={t('Select your Nationality')}
                    options={languages}
                    value={languages.filter(item => item.value === answer.value)}
                />
            }
        </div>;
    }
}
Component.propTypes = {
    question: PropTypes.object,
    answer: PropTypes.object,
    onChange: PropTypes.func
}
const mapStatsToProps = ({ user }) => ({ user });
export default connect(mapStatsToProps)(
    withTranslation()(withStyles(styles, { withTheme: true })(Component))
);